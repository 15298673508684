import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeService } from 'src/app/home/components/home/home.service';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'receipt-dialog',
  templateUrl: 'receipt-dialog.html',
  styleUrls: ['receipt-dialog.scss']
})
export class ReceiptDialog {

  id: any;
  data_source: any;
  receipt: any;
  @ViewChild('receipt') content: ElementRef;
  constructor(
    private homeService: HomeService,
    public dialogRef: MatDialogRef<ReceiptDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ReceiptDialog, 
    private _sanitizer: DomSanitizer,
    public cabinetService: Cabinet) {
   
    if(data.data !=null){
      this.receipt = data.data;
      this.decodeReceipt(data.data);
      this.id = data.id;
    }
   
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  HTML_Dowload() {
     //this.homeService.HTML_Dowload_check(this.data_html);
    this.cabinetService.receipt_download(this.id).subscribe(
        res => {
          let file = this.cabinetService.base64ToBlob(res.data, res.contentType, 512);
          var fileURL = URL.createObjectURL(file);
          var savefile = document.createElement("a");
          savefile.download = res.name;
          savefile.href = fileURL;
          savefile.click();
        
        },
        err => {
          this.cabinetService.messageDialog(err.error.status.message, false);
        
        })
   
  }
 
  getHtml(data) {
    return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  public decodeReceipt(data) {
    data = atob(data)
    this.data_source = this._sanitizer.bypassSecurityTrustResourceUrl("data:text/html;base64, " + data);
  }
}