import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent implements OnInit {
  showSpinner = false;
  cancel_data: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public cabinetService: Cabinet) {
    this.cabinetService.clearStorage();
    this.cancel_data = data;
   }

  ngOnInit(): void {
  }
  getData(data){
    return data.split('&');
 }

 reload(){
     window.location.reload();
 }
 
 isRefresh(value: boolean)
 {
     if(value)
     {
         window.location.reload();
     }
 }

 cancel(){
  this.showSpinner = true;
  this.cabinetService.cancelApply(this.cancel_data.id).subscribe(
    res => {
       //this.cabinetService.messageDialog(res.status.message, true);
       this.cabinetService.sendEmail(this.cancel_data.id, false).subscribe(
        res => { });
      window.location.reload();
       this.showSpinner=false;
    },
    err => {
      if (err.error.status.errorCode != 0)
        this.cabinetService.messageDialog(err.error.status.message, true);
    });
}
}
