<div class="container-fluid border shadow-sm bg-white my-3 px-4">
    <div class="row my-4">
        <div class="col-12">
            <h1 class="font-weight-bold cabinet-title">{{ title }}</h1>
        </div>
    </div>
    <div class="row my-4">

        <div class="col-md-6">
            <div class="profile-box">
                <h2 class="profil-title">Profil məlumatları</h2>

                <form [formGroup]="applicationForm" class="customForm">
                    <div class="pt-3 pb-2">

                        <div class="d-flex">

                            <div class="col-6" [ngClass]="{'red':email.invalid && (email.dirty || email.touched)}">
                                <label for="email">E-poçt ünvanı:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)"  type="text" matInput id="email" name="email" formControlName="email" />
                                <div class="form-row justify-content-start ml" *ngIf="email.dirty && email.invalid">
                                    <div class="text-danger" *ngIf="email.errors?.required">
                                        E-poçt ünvanı boş ola bilməz.
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <label for="profile-email">Vəzifəsi:</label>
                                <input type="text" matInput id="profile-email" name="pin" formControlName="position" />
                            </div>
                        </div>


                       <!-- <div class="d-flex">
                            <div class="col-6">
                                <label for="username">Telefon nömrəsi:</label>
                                <input type="text" matInput id="profile-email" formControlName="phone" />
                            </div>
                           
                        </div>-->

                      
                        <div class=" mt-5 pt-5 underwriter-0 ">
                            <div class="d-flex justify-content-end  mr-2">
                                <mat-spinner *ngIf="saving" class="m-3" [diameter]="25" class="my-auto">
                                </mat-spinner>
                                <button mat-button class="custom-btn m-2 " (click)="save()" [disabled]="!saving && applicationForm.dirty && applicationForm.valid
                              ?
                              null : true">Yadda
                                saxla</button>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        </div>


        <div class="col-md-6 right-side" *ngIf="data.length && data.length>0">
            <div class="profile-box h-100">
                <h2 class="profil-title">Təşkilatlar</h2>
                <div class="h-auto pt-3 pb-5 mt-5">
                    <table class="custom-table w-100 text-center font-weight-bold">
                        <tr *ngFor="let item of data">
                            <td>{{item}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>