import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import { HomeService } from '../../home.service';
// import { AccountService } from '../account-service';
import {MatDialog} from '@angular/material/dialog';
import {Cabinet} from '../../cabinet.service';

import {AppConfig} from 'src/app.config';
import {AccountService} from 'src/app/home/components/home/account-service';

export interface DialogData {
  Text: string
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class Breadcrumbcomponent implements OnInit {
  homeUrl: string;
  balance: number;
  breadcrumbConfig: object = {
    bgColor: '#fff',
    fontSize: '14px',
    fontColor: '#999999',
    lastLinkColor: '#000',
    symbol: ""

  };

  toggleLoginMenu: boolean = false;
  one_account = false;
  constructor(
    public cabinetService: Cabinet,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    private dialog: MatDialog) {
    let urls = AppConfig.settings.urls;
    this.homeUrl = urls.homeUrl;
  }
  ngOnInit() {
    this.router.events.subscribe(() => {
      this.toggleLoginMenu = false;
    })
  }
  toggleMenu() {
    this.toggleLoginMenu = !this.toggleLoginMenu;
  }

  getUserName() {
    return this.accountService.getUserName()["username"];
  }

  logout(){
    localStorage.clear();
    this.router.navigate(["/home"]);
  }
}




