<div class="container" *ngIf="!home_page">
  <div class="col-md-12 bg-white pb-5 pt-5 home_container">
    <mat-spinner style="margin:auto!important;" [diameter]="70">
    </mat-spinner>
  </div>
</div>
<div *ngIf="home_page" class="container mb-3">
  <div class="home_container">
    <div class="row">
      <div class="col-md-12 title">
        <span>{{home_page.title}}</span>
      </div>
    </div>
    <div *ngIf="!isTest" class="row home-page-blocks">
      <div *ngIf="qk_home_page" [routerLink]="['/emissionHome']" class="col-md-6 col-sm-12">
        <div class="block_container">
          <div class="block_l">
            <h1 class="card-title">{{qk_home_page.title}}</h1>
            <p class="card-text font" [innerHTML]="qk_home_page.description"></p>
          </div>
          <div class="block_r">
            <i class="ico fa-solid fa-file-invoice"></i>
            <!--            <img class="block-img im" src="/assets/images/Group1.svg">-->
            <!--            <img class="block-img imc " src="/assets/images/Group 276.svg">-->
          </div>
        </div>
      </div>

      <div *ngIf="qkr_home_page" (click)="gotoUrl()" class="col-md-6 col-sm-12">
        <div class="block_container">
          <div class="block_l">
            <h1 class="card-title">{{home_page_cardtitle.title}}</h1>
            <p class="card-text font" [innerHTML]="home_page_cardtitle.description"></p>
          </div>
          <div class="block_r">
            <i class="ico fa-solid fa-file-lines"></i>
            <!--              <img class="block-img im" src="/assets/images/Group3.svg">-->
            <!--              <img class="block-img imc " src="/assets/images/Group 281.svg">-->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTest" class="row home-page-blocks">
      <div *ngIf="qk_home_page" [routerLink]="['/emissionHome']" class="col-md-6 col-sm-12">
        <div class="block_container">
          <div class="block_l">
            <h1 class="card-title">{{qk_home_page.title}}</h1>
            <p class="card-text font" [innerHTML]="qk_home_page.description"></p>
          </div>
          <div class="block_r">
            <i class="ico fa-solid fa-file-invoice"></i>
            <!--              <img class="block-img im" src="/assets/images/Group1.svg">-->
            <!--              <img class="block-img imc " src="/assets/images/Group 276.svg">-->
          </div>
        </div>
      </div>

      <div *ngIf="qkr_home_page" [routerLink]="['/search']" class="col-md-6 col-sm-12">
        <div class="block_container">
          <div class="block_l">
            <h1 class="card-title">{{qkr_home_page.title}}</h1>
            <p class="card-text font" [innerHTML]="qkr_home_page.description"></p>
          </div>
          <div class="block_r">
            <i class="ico fa-solid fa-file-contract"></i>
            <!--            <img class="block-img im" src="/assets/images/Group2.svg">-->
            <!--            <img class="block-img imc " src="/assets/images/Group 280.svg">-->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTest"
         class="row home-page-blocks justify-content-center">
      <div *ngIf="qkr_home_page" (click)="gotoUrl()" class="col-md-6 col-sm-12">
        <div class="block_container">
          <div class="block_l">
            <h1 class="card-title">{{home_page_cardtitle.title}}</h1>
            <p class="card-text font" [innerHTML]="home_page_cardtitle.description"></p>
          </div>
          <div class="block_r">
            <i class="ico fa-solid fa-file-lines"></i>
            <!--            <img class="block-img im" src="/assets/images/Group3.svg">-->
            <!--            <img class="block-img imc " src="/assets/images/Group 281.svg">-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
