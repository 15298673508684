import { ResourceLoader } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { windowTime } from 'rxjs/operators';
import { ApplyType } from 'src/app/models/constants';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-state-registration-dialog',
  templateUrl: './state-registration-dialog.component.html',
  styleUrls: ['./state-registration-dialog.component.scss']
})
export class StateRegistrationDialogComponent implements OnInit {
  showSpinner: boolean;
  hasRequest: boolean;
  infoText;
  constructor(
    public dialogRef: MatDialogRef<StateRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cabinet: Cabinet,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getInfoText(this.data.applytype);
  }
  getInfoText(applytype) {
    switch (applytype) {
      case 'process_1':
        this.infoText = 'qiymətli kağızlarının buraxılışının dövlət qeydiyyatına alınması'
        break;
        case 'process_2':
        this.infoText = 'hüquqi şəxslərin yenidən təşkili zamanı investisiya qiymətli kağızlarının emissiyası və konvertasiyası'
        break;
        case 'process_3':
        this.infoText = 'səhmlərin birləşdirilməsi, xırdalanması və onların nominal dəyərinin artırılması zamanı səhmlərin emissiyası'
        break;
        case 'process_4':
        this.infoText = 'qiymətli kağızlarının dövriyyədən çıxarılmasını'
        break;
        case 'process_5':
        this.infoText = 'qiymətli kağızlarının Azərbaycan Respublikası ərazisindən kənarda buraxılışı və yerləşdirilməsi'
        break;
      case 'process_6':
        this.infoText = 'emissiya prospektində və buraxılışla bağlı qərara əlavə və dəyişikliklərin edilməsi'
        break;
        case 'process_7':
        this.infoText = 'qiymətli kağızlarının emissiyasının yekunları haqqında hesabatın təsdiq edilməsi'
        break;
      default:
        break;
    }
  }

  accept() {
    this.showSpinner = true;
    this.cabinet.accept(this.data.data, this.data.applytype).subscribe(
      res => {
        this.showSpinner = false;
        if (res.status.errorCode == 0) {
          console.log(res.response);
          this.dialogRef.close();
          this.router.navigate(["new-appeal"], { queryParams: { id: `${res.response}` } })
        }
        // window.location.reload();

      },
      err => {
        this.cabinet.messageDialog(err.error.status.message, false);
        this.showSpinner = false;
      });
  }
}
