<app-header></app-header>
<div class="row mx-0 w-100">
    <div class="px-0 qk-menu">
        <app-sidebar></app-sidebar>
    </div>
    
    <div class="qk-item">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet class="main"></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
