import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../account-service';

@Component({
  selector: 'app-oauth-redirect',
  template: ``
})
export class OauthRedirectComponent implements OnInit {
  code: string;
  state: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      const state = params['state'];
      if (code) {
        this.code = code;
        this.state = state;
        this.accountService.setData({
          code: this.code,
          state: this.state
        });
        this.router.navigate(["/emissionHome"]);
      }
    });
  }
 

}