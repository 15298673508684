import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { HomeService } from '../home/components/home/home.service';

@Component({
  selector: 'app-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.scss']
})

export class CabinetComponent implements OnInit {

  choose: boolean = false;
  constructor(private _sanitizer: DomSanitizer, private homeService: HomeService, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private TitleService: Title, public dialog: MatDialog) {
  }
  ngOnInit() {

  }
}

