<breadcrumb>
  <div class="breadcrumbs-container">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6 col-md-8 col-sm-10 col-12 bread">
          <ul class="breadcrumbs">
            <li>
              <a href="/home" style="margin-top: 9px">
                <i class="fa fa-home" aria-hidden="true"></i>
              </a>
            </li>
            <li *ngIf="currentRoute!=='/home'&& currentRoute!=='/login'">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li>
              <app-ng7-dynamic-breadcrumb
                [bgColor]="breadcrumbConfig['bgColor']"
                [fontSize]="breadcrumbConfig['fontSize']"
                [fontColor]="breadcrumbConfig['fontColor']"
                [lastLinkColor]="breadcrumbConfig['lastLinkColor']"
                [symbol]="breadcrumbConfig['symbol']">
              </app-ng7-dynamic-breadcrumb>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</breadcrumb>

