<footer>
    <div class="container">
        <div class="row">
            <h2 class="separator"></h2>
             <p class="p_foot text-center w-100">
               &copy;
               <span>{{date| date: 'yyyy' }}. Bütün hüquqlar qorunur.</span>
             </p>
        </div>
    </div>
</footer>
