import { Component, OnInit, Injectable, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { AppConfig } from 'src/app.config';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-required-new-doc',
  templateUrl: './required-new-doc.component.html',
  styleUrls: ['./required-new-doc.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class RequiredNewDocComponent implements OnInit {
  @Output() addElCount = new EventEmitter<number>();
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  newRequiredForm: FormGroup;
  newRequiredDocs: any[];
  showSpinner: boolean;
  formData: FormData;
  hasRequest: boolean;
  activePortal: any;
  clickedIndex: any;
  status: any;
  acceptType = AppConfig.settings.fileSettings.acceptFiles;;
  acceptTypes = "";
  @Input("status") set statusSet(value: any) {
    this.status = value;
  }

  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    this.getNewRequiredDoc();
    this.setForm();
    this.setAcceptTypes();
  }

  setAcceptTypes() {
    for (let index = 0; index < this.acceptType.length; index++) {
      const item = this.acceptType[index];
      if (this.acceptType.length - 1 >= index)
        this.acceptTypes += ("." + item + ", ")
    }
  }

  setForm() {
    this.newRequiredForm = this.fb.group({
      file: [, Validators.required]
    });
  }

  getNewRequiredDoc() {
    if (this.id) {
      this.showSpinner = true;
      this.cabinetService.getNewRequiredDoc(this.id).subscribe(
        res => {
          this.newRequiredDocs = res.response.docs;
          this.addElCount.emit(this.newRequiredDocs.length);
          // this.newRequiredDocs.push({
          //   'date': new Date(),
          //   'comment': 'test1<br>test2',
          //   'docName': 'doc1',
          //   'key': 'doc_in_9',
          //   'isSystem': false
          // })
          this.activePortal = res.response.activePortal;
          this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }

  sendNewRequiredDoc(index) {
    if (this.id) {
      this.clickedIndex = index;
      this.hasRequest = true;
      this.cabinetService.sendNewRequiredDoc(this.formData, this.id).subscribe(
        res => {
          this.hasRequest = false;
          this.cabinetService.sendEmail(this.id, false).subscribe(
            res => { });
          window.location.reload();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.hasRequest = false;
        })
    }
  }

  setApplyNewRequiredDoc(file, key, isSystem) {
    if (!file) return;
    if (!this.cabinetService.checkFile(file)) return;
    let data = {
      key: key,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      fileExt: file.name.split('.').pop(),
      isSystem: isSystem
    }
    let formData = new FormData();
    formData.append(`isSystem`, data.isSystem);
    formData.append(`doc.key`, data.key);
    formData.append(`doc.file`, data.file);
    formData.append(`doc.fileName`, data.fileName);
    formData.append(`doc.fileSize`, data.fileSize);
    formData.append(`doc.fileExt`, data.fileExt);
    this.formData = formData;
  }
}