import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplyType, placementMethodsConst, qkObservesConst, qkTypesConst } from 'src/app/models/constants';
import { MatDialog } from '@angular/material/dialog';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { StateRegistrationDialogComponent } from 'src/app/cabinet/dialogs/state-registration-dialog/state-registration-dialog.component';
import { CancelDialogComponent } from 'src/app/cabinet/dialogs/cancel-dialog/cancel-dialog.component';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-new-appeal-default-2',
  templateUrl: './new-appeal-default-2.component.html',
  styleUrls: ['./new-appeal-default-2.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppealDefault2Component implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  type = this.activatedRoute.snapshot.queryParamMap['params']['type'];
  applyForm: FormGroup;
  petitionForm: FormGroup;
  dynamicForm: FormGroup;
  subTitle: any;
  hasMB: Boolean;
  hasMDM: Boolean;
  hasBFB: Boolean;
  documents: any;
  showSpinner: boolean;
  portals: any;
  selectedFile: any;
  uploadError: boolean;
  clickDoc: any;
  qkTypes: any;
  isListing:any;
  reasonReorganizations:any;
  placementMethods: any;
  showIamasSpinner: boolean;
  pinInValid: boolean;
  qkAnderrayters: any;
  provideInfos: any;
  qkObserves: any;
  isBond: boolean;
  isObserve: boolean;
  issue_prospectus: boolean;
  paymentTypes: any;
  timeTypes: any;
  hasRequest: boolean;
  purposeApply: any;

  applyPetition: any;
  @Input("applyPetition") set applyPetitionSet(value: any) {
    if(value){
      this.applyPetition = value;
    }
    this.methodsCall();
  }

  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
    this.setTitle();

    // this.confirm();
    // this.getPortal();
  }
  //set Title
  setTitle() {
    this.TitleService.setTitle("QİYMƏTLİ KAĞIZLAR" + " | securities.e-cbar.az/");
    const breadcrumb = { title: "Qi̇ymətli̇ Kağizlar", title2: "Yeni müraciət" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }



  methodsCall(){
    this.setApplyForm();
    this.setPetitionForm();
    this.getQkType();
    this.getProvideInfo();
    this.getPaymentTypes();
    this.getReasonReorganization();
    this.getTimeTypes();
    this.getPurposeApply();
    this.getListing();

  }
  setApplyForm() {
    this.getSubTitle();
    this.applyForm = this.fb.group({
      id: [this.id],
      applyTypeId: [this.applyPetition?.apply?.applyTypeId ? this.applyPetition?.apply?.applyTypeId : (this.type == 'process_2' ? 2 : 0), [Validators.required]],
      qkTypeId: [this.applyPetition?.apply?.qkTypeId, [Validators.required]],
      reasonReorganizationId: [this.applyPetition?.apply?.reasonReorganizationId, [Validators.required]],
      isListingId: [this.applyPetition?.apply?.isListingId, [Validators.required]],

      applyTypeName: [,],
      reasonReorganizationName: [,],
      isListing: [,],
      qkTypeName: [,],


    });
  }

  setPetitionForm() {
    this.petitionForm = this.fb.group({
      protocolOrgName: [this.applyPetition?.petition?.protocolOrgName, [Validators.required]],
      protocolNumber: [this.applyPetition?.petition?.protocolNumber, [Validators.required]],
      protocolDate: [this.applyPetition?.petition?.protocolDate, [Validators.required]],
      qkCost: [this.applyPetition?.petition?.qkCost, [Validators.required]],
      qkQuantity: [this.applyPetition?.petition?.qkQuantity, [Validators.required]],


    });


  }

  addControlsForBond(qkTypeId) {
    if (this.qkTypes.find(x => x.id == qkTypeId)?.key == qkTypesConst.bond) {
      this.petitionForm.addControl('provideInfoId', new FormControl(this.applyPetition?.petition?.provideInfoId, Validators.required));
      this.petitionForm.addControl('circulationPeriod', new FormControl(this.applyPetition?.petition?.circulationPeriod, Validators.required));
      this.petitionForm.addControl('timeTypeId', new FormControl(this.applyPetition?.petition?.timeTypeId, Validators.required));
      this.petitionForm.addControl('provideInfoName', new FormControl(this.applyPetition?.petition?.provideInfoName));
      this.petitionForm.addControl('timeTypeName', new FormControl(this.applyPetition?.petition?.timeTypeName));
      this.isBond = true;
    }
    else {
      this.petitionForm.removeControl('provideInfoId')
      this.petitionForm.removeControl('circulationPeriod');
      this.petitionForm.removeControl('timeTypeId');

      this.petitionForm.removeControl('provideInfoName');
      this.petitionForm.removeControl('timeTypeName');
      this.isBond = false;
    }
  }



  addControlsForObserve(qkObserveId) {
    if (
      this.qkObserves.find(x => x.id == qkObserveId)?.key == qkObservesConst.issue_prospectus ||
      this.qkObserves.find(x => x.id == qkObserveId)?.key == qkObservesConst.base_issue_prospectus
      ) {
      this.applyForm.addControl('paymentTypeId', new FormControl(this.applyPetition?.apply?.paymentTypeId, Validators.required));
      this.applyForm.addControl('paymentTypeName', new FormControl(''));
      this.applyForm.addControl('issueAmount', new FormControl(this.applyPetition?.apply?.issueAmount, Validators.required));
      this.issue_prospectus = true;
    }
    else {
      this.applyForm.removeControl('issueAmount');
      this.applyForm.removeControl('paymentTypeId');
      this.applyForm.removeControl('paymentTypeName');
      this.issue_prospectus = false;
    }
  }







  getQkType() {
    this.cabinetService.getQkType().subscribe((data => {
      this.qkTypes = data.result;
      this.addControlsForBond(this.applyPetition?.apply?.qkTypeId)
    }));
  }

  getReasonReorganization(){
    this.cabinetService.getReasonReorganization().subscribe((data => {
      this.reasonReorganizations = data.result;
    this.addControlsForBond(this.applyPetition?.apply?.reasonReorganizationId)
    }));
  }

  getListing(){
    this.cabinetService.getListing().subscribe((data => {
      this.isListing = data.result;
      this.addControlsForBond(this.applyPetition?.appply?.isListingId)
    }));
  }



  getProvideInfo() {
    this.cabinetService.getProvideInfo().subscribe((data => {
      this.provideInfos = data.result;
    }));
  }



  getPaymentTypes() {
    this.cabinetService.getPaymentTypes().subscribe((data => {
      this.paymentTypes = data.result;
    }));
  }

  getTimeTypes() {
    this.cabinetService.getTimeTypes().subscribe((data => {
      this.timeTypes = data.result;
    }));
  }

  getPurposeApply() {
    this.cabinetService.getPurposeApply().subscribe((data => {
      this.purposeApply = data.result;
      this.applyForm.get('purposeApplyId').setValue(this.purposeApply.find(x => x.key == 'key_4').id);
    }));
  }

  getSubTitle() {
    if (!this.id) {
      this.cabinetService.getApplyTypes().subscribe((data => {
      this.subTitle = "2." + data.result.filter(x => x.key == this.type)[0].name;
      }));
    } else {
      this.subTitle = "2." + this.applyPetition?.apply?.applyType.name;
    }
  }

  // dyanamicDocumentForm() {
  //   console.log(this.documents.length);
  //   const numberOfdocument = this.documents.length || 0;
  //   if (this.t.length < numberOfdocument) {
  //     for (let i = this.t.length; i < numberOfdocument; i++) {
  //       this.t.push(this.fb.group({
  //         documentName: ['', Validators.required],
  //         fileSize: ['', Validators.required],
  //         fileName: ['', Validators.required]
  //       }));
  //     }
  //   } else {
  //     for (let i = this.t.length; i >= numberOfdocument; i--) {
  //       this.t.removeAt(i);
  //     }
  //   }
  // }



  getPortal() {
    console.log(this.hasMB);
    this.cabinetService.getPortal().subscribe((data => {
      // this.portals = data.result;

      if (!this.hasMB) {
        this.portals = data.result.filter(x => x.key != "mb");
      }

    }));

  }

  public get applyType(): typeof ApplyType {
    return ApplyType;
  }

  public findInvalidControls() {
    const invalid = [];
    let controls = this.applyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    controls = this.petitionForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  confirm() {
    console.log(this.findInvalidControls());
    console.log("tyess");

    let apply = this.applyForm;
    let petition = this.petitionForm;
    if (apply.invalid || petition.invalid) return;
    this.id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
    console.log(this.id)
    apply.get('id').setValue(this.id);
    apply.get('qkTypeName').setValue(this.qkTypes.find(x => x.id == apply
      .get('qkTypeId').value).name);
    apply.get('reasonReorganizationName').setValue(this.reasonReorganizations.find(x => x.id == apply
      .get('reasonReorganizationId').value).name);
      apply.get('isListing').setValue(this.isListing.find(x => x.id == apply
        .get('isListingId').value).name);

    if (petition.get('provideInfoId')) {
      petition.get('provideInfoName').setValue(this.provideInfos.find(x => x.id == petition
        .get('provideInfoId').value).name);
    }
    if (petition.get('timeTypeId')) {
      petition.get('timeTypeName').setValue(this.timeTypes.find(x => x.id == petition
        .get('timeTypeId').value).name);
    }

    let applyPetition = this.fb.group({
      apply: [apply.value],
      petition: [petition.getRawValue()]
    });

    const dialogRef = this.dialog.open(StateRegistrationDialogComponent, {
      width: '650px',
      position:{
        top:'110px'
      },
      data: applyPetition.value,
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    });
  }
  gonewappeallist(){
    this.router.navigate(['/new-appeal-list'])
  }
  save() {
    let applyPetition = this.fb.group({
      apply: [this.applyForm.getRawValue()],
      petition: [this.petitionForm.getRawValue()]
    })
    this.saveApplyPetition(applyPetition.value);
  }

  saveApplyPetition(data) {
    this.hasRequest = true;
    this.cabinetService.saveApplyPetition(data).subscribe(
      res => {
        this.cabinetService.messageDialog(res.status.message, false);
        this.hasRequest = false;
        if (res.status.errorCode == 0) {
          console.log(res.response);
          this.router.navigate(["new-appeal"], { queryParams: { id: `${res.response}` } })
        }

      },
      err => {
        this.cabinetService.messageDialog("Səhv baş verdi", false);
        this.hasRequest = false;
      });
  }

  onChange(document, e) {
    this.clickDoc = document;
    this.showSpinner = true;
    this.upload(document, e);
  }

  upload(document, event) {
    this.uploadError = false;
    this.selectedFile = event.target.files[0];
    if (this.selectedFile != undefined && this.selectedFile.type == 'image/jpeg') {
      let textInfo = "\"JPG, JPEG\" olmayan file yükləyə bilərsiniz.";
      this.cabinetService.messageDialog(textInfo, false);
      this.uploadError = true;
      return;
    }

    var i = 0;
    var j = 0;
    if (this.selectedFile && this.selectedFile != undefined) {
      if (this.selectedFile.type == 'application/x-zip-compressed' || this.selectedFile.type == 'application/zip') {
        jsZip.loadAsync(this.selectedFile).then(async (zip) => {
          Object.keys(zip.files).forEach((filename) => {
            if (j > 0) {
              let textInfo = "ZIP file daxilində sadəcə 1 \"JPG, JPEG\" olmayan file yükləyə bilərsiniz.";
              this.cabinetService.messageDialog(textInfo, false);
              this.uploadError = true;
              return;
            }
            j++;

          });
          Object.keys(zip.files).forEach((filename) => {
            if (i == 0) {
              if (filename.split('.').pop().toUpperCase() == "JPG" || filename.split('.').pop().toUpperCase() == "JPEG") {
                let textInfo = "Sənədi ZIP file daxilində \"JPG, JPEG\" olmayan formatda yükləyə bilərsiniz. Sənədi düzgün formatda daxil edin.";
                this.cabinetService.messageDialog(textInfo, false);
                this.uploadError = true;
                return;
              }
            }
            i++;
          });
          this.uploadFileZip(document, event);
        });
      }
      else {
        this.uploadFileZip(document, event);
      }
    }
  }

  uploadFileZip(doc, e) {
    if (this.uploadError) return;
    this.cabinetService.loadFile(this.selectedFile).subscribe((result) => {
      doc.controls.fileName.setValue(e.target.files[0].name);
      doc.controls.fileSize.setValue("[" + this.sizeToBytes(e.target.files[0].size) + "]");

      let file = this.Base64ToBlob(result, this.selectedFile.type, 512);
      var fileURL = URL.createObjectURL(file);
      var savefile = document.createElement("a");
      savefile.download = "demo.pdf";
      savefile.href = fileURL;
      savefile.click();
      this.showSpinner = false;
    }, (err => {
      console.log("err", err)
      this.cabinetService.messageDialog("Xəta baş verdi.", false);
      this.showSpinner = false;
    }), () => {
      this.showSpinner = false;
      this.selectedFile = null;
    })
  }

  //helper methods
  Base64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  sizeToBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  get f() { return this.applyForm.controls; }
  get t() { return this.f.documentArray as FormArray; }

  getRealApplyType() {
    return this.type == this.applyType.TYPE1 || this.applyPetition?.apply?.applyType?.key == this.applyType.TYPE1;
  }

  cancelDialog(){
    this.dialog.open(CancelDialogComponent, {
      width: '400px',
      position: {
        top: '110px'
      },
    data: {
      id: this.id 
    }});
  }
  
}


