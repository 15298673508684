import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ShellComponent} from './shell.component';
import {Ng7DynamicBreadcrumbModule} from 'ng7-dynamic-breadcrumb';
import {BreadcrumbModule} from 'angular-crumbs';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {FooterComponent} from './footer/footer.component';
import {MaterialModule} from '../shared/material.module'
import {breadcrumbcomponent} from './breadcrumb/breadcrumbs.component';
import {HomeService} from '../home/components/home/home.service';
import {AccountService} from '../home/components/home/account-service';
import {SharedModule} from "../shared/shared.module";


@NgModule({
  imports: [
    MaterialModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    Ng7DynamicBreadcrumbModule,
    BreadcrumbModule,
    SharedModule
  ],
  providers: [HomeService, AccountService],
  declarations: [ShellComponent, FooterComponent, breadcrumbcomponent]
})
export class ShellModule { }
