import { Routes, Route, Router } from '@angular/router';
import { CabinetComponent } from './cabinet.component';
import { Injectable, Component, Inject } from '@angular/core';
import { BaseService } from 'src/app/shared/base.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from 'src/app.config';
import { LoadingDialogComponent } from './dialogs/loading/loading.component';
import { MessageDialog } from './dialogs/messageDialog/message-dialog';
import { SelectCompanyDialogComponent } from './dialogs/selectCompany/select-company-dialog.component';
import { HistoricallyDialogComponent } from './dialogs/historically-dialog/historically-dialog.component';
import { DatePipe } from '@angular/common';
//import { LoadingDialogComponent } from './dialogs/loading/loading.component';


@Injectable({
  providedIn: 'root',
})

/**
 * Provides helper methods to create routes.
 */
export class Cabinet extends BaseService {
  myAppUrl: any;
  main_account_id: any;
  my_account_id: any;
  my_role_id: any;
  user_id: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {
    super();
  }


  clearStorage() {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/']);
    }
    var hours = AppConfig.settings.other.clearStorageHour;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString());
    } else {
      if (now - parseInt(setupTime) > hours * 60 * 60 * 1000) {
        localStorage.clear();
        window.location.reload();
        localStorage.setItem('setupTime', now.toString());
      }
    }

    // if (!localStorage.getItem("token")) {
    //   this.router.navigate(["/login"]);
    // }
  }

  sizeToBytes(x) {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  sortByName(data) {
    let lng = 'az';
    const collator = new Intl.Collator(lng);
    data = data.sort(function (a, b) {
      return collator.compare(a.name, b.name);
    });
    return data;
  }
  sortByCode(data) {
    let lng = 'az';
    const collator = new Intl.Collator(lng);
    data = data.sort(function (a, b) {
      return collator.compare(a.code, b.code);
    });
    return data;
  }
  public messageDialog(text: string, isRefresh: boolean) {
    this.dialog.open(MessageDialog, {
      width: '500px',
      position:{
        top:'110px'
      },
      data: { Text: `${text}`, isRefresh: isRefresh },
      autoFocus: false,
    });
  }

  public messageObjectDialog(obj: any) {
    this.dialog.open(MessageDialog, {
      width: '650px',
      position:{
        top:'110px'
      },
      data: { obj_message: `${obj}` },
      autoFocus: true,
    });
  }
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: CabinetComponent,
      children: routes,
      data: { reuse: true },
    };
  }

  public loadingDialog(): MatDialogRef<any> {
    return this.dialog.open(LoadingDialogComponent, {
      position:{
        top:'110px'
      },
      autoFocus: false,
    });
  }

  checkFile(file) {
    let limit = AppConfig.settings.fileSettings.maxLimit;
    let exts = AppConfig.settings.fileSettings.acceptFiles;
    if (file.size > limit * 1024 * 1024) {
      this.messageDialog("Faylın ölçüsü maksimum ölçü limitindən çoxdur. Ölçü limiti: " + limit + ' MB', false);
      return false;
    }
    if (file.size == 0) {
      this.messageDialog("Faylın həcmi 0 KB ola bilməz.", false);
      return false;
    }
    if (!exts.some(x => x == file.name.toLowerCase().split('.').pop())) {
      this.messageDialog("Fayl formatı uyğun deyil. Qəbul olunan fayl formatları: " + exts, false);
      return false;
    }
    return true;
  }

  checkAdoc(file, extHover, extHoverMessage) {
    if (!extHover.some(x => x == file.name.toLowerCase().split('.').pop())) {
      this.messageDialog(extHoverMessage, false);
      return false;
    }
    return true;
  }

  // start lookup
  getApplyTypes(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/apply-type';
    return this.http.get<any>(url);
  }

  getApplyTypeItem(type: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/apply-type-item?key=' + type;
    return this.http.get<any>(url);
  }

  getDocument(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/document';
    return this.http.get<any>(url);
  }
  getPortal(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/portal';
    return this.http.get<any>(url);
  }
  getQkType(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/qk-type';
    return this.http.get<any>(url);
  }
  circulationPeriod(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/circulation-period';
    return this.http.get<any>(url);
  }
  getProvideInfo(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/provide-info';
    return this.http.get<any>(url);
  }
  getQkAnderrayter(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/qk-anderrayter';
    return this.http.get<any>(url);
  }
  getQkObserve(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/qk-observe';
    return this.http.get<any>(url);
  }
  getPaymentTypes(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/payment-type';
    return this.http.get<any>(url);
  }
  getPlacementMethods(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/qk-placment';
    return this.http.get<any>(url);
  }

  getReasonReorganization(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/reason-reorganization';
    return this.http.get<any>(url);
  }

  getListing(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/yesorno';
    return this.http.get<any>(url);
  }

  getTimeTypes(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/time-types';
    return this.http.get<any>(url);
  }


  city(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Lookup/city';
    return this.http.get<any>(url);
  }
  emitentRole(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Lookup/emitent-role';
    return this.http.get<any>(url);
  }
  regionById(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Lookup/region?id=' + id;
    return this.http.get<any>(url);
  }
  GetPaymentDue(amount): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Lookup/payment-due?amount=' + amount;
    return this.http.get<any>(url);
  }
  getPurposeApply(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/purpuse-apply';
    return this.http.get<any>(url);
  }
  getPurposeIssue(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/purpose-issue';
    return this.http.get<any>(url);
  }
  // end lookup

  // start apply
  getApplies(skip: any, limit: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-applies?skip=' + skip + "&limit=" + limit;
    return this.http.get<any>(url);
  }

  getApplyStatus(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-apply-status?id=' + id;
    return this.http.get<any>(url);
  }

  getApplyDefault(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-apply-default?id=' + id;
    return this.http.get<any>(url);
  }

  getApplyNew(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-apply-new?id=' + id;
    return this.http.get<any>(url);
  }

  getApplyUpper(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-apply-upper?id=' + id;
    return this.http.get<any>(url);
  }

  getTempFiles(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/tempFile/get-temp-files?id=' + id;
    return this.http.get<any>(url);
  }

  sendTempFile(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/tempFile/send-temp-file?id=' + id;
    return this.http.post<any>(url, data);
  }

  sendApply(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/apply-send?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getWaitDoc(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-waiting-document?id=' + id;
    return this.http.get<any>(url);
  }

  sendWaitDoc(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/send-wait-doc?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getCorrectDoc(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-correct-document?id=' + id;
    return this.http.get<any>(url);
  }

  sendCorrectDoc(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/send-correct-doc?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  goNext(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/go-next?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getAtributeSelect(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-attribute-select?id=' + id;
    return this.http.get<any>(url);
  }

  getRequiredDocs(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-required-document?id=' + id;
    return this.http.get<any>(url);
  }

  getNewRequiredDoc(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-required-new-document?id=' + id;
    return this.http.get<any>(url);
  }

  sendNewRequiredDoc(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/send-required-new-doc?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getContractDoc(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-contract-document?id=' + id;
    return this.http.get<any>(url);
  }

  sendContractDoc(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/send-contract-doc?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getOutputDoc(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-output-document?id=' + id;
    return this.http.get<any>(url);
  }

  sendReceiptDoc(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/payment/send-receipt?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  sendPayment(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/payment/send-payment?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  // ASAN SIGN
  sendAuthenticate(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/send-authenticate?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getAuthenticateStatus(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/get-authenticate-status?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getBusinessCert(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/get-business-cert?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  sendSign(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/send-sign?applyId=' + id;
    return this.http.post<any>(url, data);
  }
  getSignStatus(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/get-sign-status?applyId=' + id;
    return this.http.post<any>(url, data);
  }

  getFiles(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/get-files?applyId=' + id;
    return this.http.post<any>(url, data);
  }


  getSignatures(data, id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/asanSign/get-signatures?applyId=' + id;
    return this.http.post<any>(url, data);
  }


  sendEmail(id, is_new): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/send-email?applyId=' + id + "&isNew=" + is_new;
    return this.http.get<any>(url);
  }
  cancelReceipt(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/payment/cancel-receipt?applyId=' + id;
    return this.http.post<any>(url, id);
  }

  cancelApply(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/ApplyProcessing/cancel-apply?applyId=' + id;
    return this.http.get<any>(url);
  }

  sendMeas(id, key): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Meas/send-meas?applyId=' + id + "&key=" + key;
    return this.http.get<any>(url);
  }

  getAppealDoc(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-appeal-document?id=' + id;
    return this.http.get<any>(url);
  }

  getAppealDMessage(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-appeal-message?id=' + id;
    return this.http.get<any>(url);
  }

  getNotifications(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-notifications?id=' + id;
    return this.http.get<any>(url);
  }

  getAppliesByObserve(key): Observable<any>{
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/get-applies-by-observe?key=' + key;
    return this.http.get<any>(url);
  }

  readNotification(id, notifyId): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/read-notification?id=' + id + "&notifyId=" + notifyId;
    return this.http.get<any>(url);
  }

  readMessage(id, portal): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyReading/read-message?id=' + id + "&portal=" + portal;
    return this.http.get<any>(url);
  }


  download(path): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/file/download?path=' + path;
    return this.http.get<any>(url);
  }

  receipt_download(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/file/receipt-download?id=' + id;
    return this.http.get<any>(url);
  }
  blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return <File>theBlob;
  }
  base64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  loadFile(file: Blob): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const formData = new FormData();
    formData.append('file', file);
    let url = this.myAppUrl + '/applyProcessing/upload-file';
    return this.http.post<any>(url, formData);
  }
  saveApplyPetition(data): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyProcessing/save-apply-petition';
    return this.http.post<any>(url, data);
  }

  accept(data, applyType): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/applyProcessing/accept?applyType=' + applyType;
    console.log(data.petition.protocolDate);
    //data.petition.protocolDate._d.setTime(data.petition.protocolDate._d.getTime() - new Date().getTimezoneOffset()*60*1000 );
    return this.http.post<any>(url, data);
  }
  // end apply

  //start adapter
  getByPin(pin, birthdate): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let isForeign = false;
    if (pin && (pin.length == 5 || pin.length == 6)) isForeign = true;
    let url = this.myAppUrl + '/adapter/iamas-pin?pin=' + pin + '&isForeign=' + isForeign + '&checkactive=true&isBirth=true&birthdate=' + birthdate;
    return this.http.get<any>(url);
  }

  getByTin(tin): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let isForeign = false;
    let url = this.myAppUrl + '/adapter/avis-getbytin?tin=' + tin +'&islegal=true&isfull=false';
    return this.http.get<any>(url);
  }
  //end adapter

  //start auth
  getLeader(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/account-leader';
    return this.http.get<any>(url);
  }
  //end auth

  //profile
  profile(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/profile/get-user';
    return this.http.get<any>(url);
  }
  currentcompany(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/current-company';
    return this.http.get<any>(url);
  }

  checkUser(pin, date): Observable<any> {
    console.log(date)
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Access/check-user?pin=' + pin + '&birthdate=' + date;
    return this.http.get<any>(url);
  }
  createAccess(model): Observable<any> {
    console.log(model)
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Access/create-access';
    return this.http.post<any>(url, model);
  }
  updateProfile(model): Observable<any> {
    if (model.cityId == 0) { model.cityId = null }
    if (model.regionId == 0) { model.regionId = null }
    console.log(model)
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/profile/update-user';
    return this.http.post<any>(url, model);
  }

  updateCompany(model): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/update-requisite';
    return this.http.post<any>(url, model);
  }

  updateProfileCompany(model): Observable<any> {
    if (model.cityId == 0) { model.cityId = null }
    if (model.regionId == 0) { model.regionId = null }
    console.log(model)
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/update-company';
    return this.http.post<any>(url, model);
  }
  updateAvis(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/update-avis';
    return this.http.get<any>(url);
  }

  // UNDER_WRITER

  u_getStatus(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
    let url = this.myAppUrl + '/lookup/status';
    return this.http.get<any>(url);
  }
  u_getCompany(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
    let url = this.myAppUrl + '/lookup/company';
    return this.http.get<any>(url);
  }
   //profile
    u_profile(): Observable<any> {
      this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
      let url = this.myAppUrl + '/profile/get-user';
      return this.http.get<any>(url);
    }
    u_updateProfile(obj): Observable<any> {
      this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
      let url = this.myAppUrl + '/profile/update-user';
      return this.http.post<any>(url, obj);
    }
    u_getApply(filter): Observable<any> {
      this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
      let url = this.myAppUrl + '/applyReading/get-underwriter-apply?isExport=false';
      return this.http.post<any>(url, filter);
    }
    u_getApplyLogs(id:number): Observable<any>{
      this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
      let url = this.myAppUrl + '/ApplyLog/get-apply-logs?applyId='+id;
      return this.http.get<any>(url);
    }
    openDialogHistoricallys(model:number) {
      const dialogRef = this.dialog.open(HistoricallyDialogComponent,
        {
          width: '600px',
          panelClass: 'underwriter-historically',
          position: {
            top: '30px',
          },
          data: { id: model }

          });
    }

    changeFormatDate(date: string | number | Date | any) {
      if(date.toString().includes(".")){
        date = date.toString().split("/").join(".").split(".").reverse().join("-");
        let new_date = new Date(date);
        return this.datePipe.transform(new_date, 'dd.MM.yyyy')
      }else{
        let new_date = date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3");
        return this.datePipe.transform(new_date, 'dd.MM.yyyy')
      }
    }

    changeFormatDateDashed(date: string | number | Date | any) {
      if(date.toString().includes(".")){
        date = date.toString().split("/").join(".").split(".").reverse().join("-");
        let new_date = new Date(date);
        return this.datePipe.transform(new_date, 'dd-MM-yyyy')
      }else{
        return this.datePipe.transform(date, 'dd-MM-yyyy')
      }
    }

    checkApplyCreateDate(id): Observable<any> {
      this.myAppUrl = AppConfig.settings.other.resourceApiURI;
      let url = this.myAppUrl + '/applyReading/check-apply-create-date?id=' + id;
      return this.http.get<any>(url);
    }

}
