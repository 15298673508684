<div class="container-fluid border shadow-sm my-3" [ngClass]="{'d-none':showSpinner}">
    <div class="row">
        <div class="col-12 d-sm-flex align-items-center justify-content-between bg-white px-4 pt-4">
            <h1 class="font-weight-bold cabinet-title">Müraciətlər</h1>
            <div [ngClass]="{'d-none':dataLength == 0}">
                <button mat-flat-button color="primary" class="custom-btn" (click)="goToNewApplyList()">
          <i class="fas fa-plus"></i>
          Yeni müraciət
        </button>
                <mat-spinner class="ml-2 mt-2" [diameter]="20" style="float: right;" *ngIf="false">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{'d-none':dataLength == 0}">
        <!-- <div class="col-md-12 bg-white pb-5" *ngIf="false">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div> -->
        <div class="col-12 bg-white px-4 pb-4">
            <div class="table-responsive" *ngIf="dataSource">
                <table mat-table [dataSource]="dataSource" class="w-100">

                    <!-- Name Column -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <span id="main" (click)="goToApplyDetail(element.id)">{{element.type}}</span>
                            <span id="sub" class="mt-2">{{element.applyNumber}}</span>
                        </td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">

                            <span id="main" [style.color]="element.statusColor" class="text-nowrap">{{element.status}}</span>

                            <span id="sub" class="mt-2">{{datepipe.transform(element.createdOn, 'dd.MM.yyyy  HH:mm')}}</span>
                        </td>
                    </ng-container>

                    <!-- step Column -->
                    <ng-container matColumnDef="step">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <span id="sub" class="text-uppercase" *ngIf="element.stepData.activeIndex">Mərhələlər <span class="pl-3 ml-5">{{element.stepData.activeIndex}}/{{element.stepData.steps.length}}</span> </span>
                            <div class="w-75">
                                <div class="progress" *ngFor="let item of element.stepData.steps" [ngStyle]="{'width':75/element.stepData.steps.length+'%'}">
                                    <div *ngIf="item.index <= element.stepData.activeIndex" class="progress-bar" [ngStyle]="{'width':width+'%'}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    <div *ngIf="item.index > element.stepData.activeIndex" class="progress-bar" [ngStyle]="{'width':width1+'%'}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="notification">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="icon-box d-flex align-items-center justify-content-center" (click)="goToNotifications(element.id, element.applyNumber)" style="cursor:pointer">
                                <i class="fas fa-bell text-white"></i>
                                <div *ngIf="element.notification" class="sup-icon">
                                    <small>{{element.notification}}</small>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <!-- operation Column -->
                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"><button type="button" (click)="goToApplyDetail(element.id)" class="btn btn-outline-secondary">Ətraflı</button></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[8, 16, 24]" [pageSize]="8" showFirstLastButtons (page)="pageChanged($event)">
                </mat-paginator>
            </div>
            <div class="py-5 text-center" *ngIf="!dataSource">
                <p class="pt-5 mb-0">
                    Hal-hazırda heç bir müraciətiniz mövcud deyil.
                </p>
                <p class="pb-5">Yeni müraciətin yaradılması üçün <a class="text-info" [routerLink]="['/new-appeal-list']">buraya klik edin.</a></p>
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{'d-none':dataLength > 0}">
        <div class="col-12 bg-white d-flex align-items-center justify-content-center" style="height: calc(100vh - 300px)">
            <h5 [style.text-align]="'center'" [style.color]="'#9C9C9C'">
                <p class="m-0">Hal-hazırda heç bir müraciətiniz mövcud deyil.</p>
                Yeni müraciətin yaradılması üçün <span><a [routerLink]="['/new-appeal-list']"  [style.color]="'#03A9D9'" [style.text-decoration]="'none'"> buraya klik edin</a></span>.</h5>
        </div>
    </div>
</div>
<mat-spinner style="position:absolute; top: 40%; left:50%" *ngIf="showSpinner" [diameter]="70">
</mat-spinner>