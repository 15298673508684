import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/base.service';
import { Observable, throwError, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from 'src/app.config';
import jwt_decode from 'jwt-decode';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { LoginASANVM } from 'src/app/models/LoginASANVM';
import { NewCompanyDialogComponent } from 'src/app/cabinet/dialogs/newCompany/new-company-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectCompanyDialogComponent } from 'src/app/cabinet/dialogs/selectCompany/select-company-dialog.component';
import { InfoDialogComponent } from 'src/app/cabinet/dialogs/info-dialog/info-dialog.component';
import { MeasDialogComponent } from 'src/app/cabinet/dialogs/meas-dialog/meas-dialog.component';
import { GppDialogComponent } from 'src/app/cabinet/dialogs/gpp-dialog/gpp-dialog.component';
import { NotficationComponent } from 'src/app/cabinet/dialogs/notfication/notfication.component';



@Injectable({
  providedIn: 'root',
})

export class AccountService extends BaseService {
  private AsanV2Model: any;
  lvm: LoginASANVM;
  myAppUrl: string;
  myAppUnderwriterUrl: string;
  constructor(public dialog: MatDialog,private cabinetService: Cabinet, private http: HttpClient, private router: Router) {
    super();
  }
  setData(data: any) {
    this.AsanV2Model = data;
  }

  getData() {
    return this.AsanV2Model;
  }
  createJWT(token, returnToUrl) {
    //console.log(token);
    if(token && token.token){
      localStorage.setItem("token", token.token);
      localStorage.setItem("tokenExpiration", token.expiresAt);
     
    }
    if (returnToUrl) {
      this.router.navigate([returnToUrl]).then(() => {
        window.location.reload();
      });
    }
  }
  public getStateId(): Observable<any> {
    this.myAppUrl = AppConfig.settings?.other.resourceApiURI;
    return this.http.get(`${this.myAppUrl}/Auth/asanguid`, {responseType: 'text'});
  }
  certAppeal(obj): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/auth/cert-appeal";
    return this.http.post<any>(url, obj);
  }

  bsxmLogin(obj): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/auth/bsxm-login-register";
    return this.http.post<any>(url, obj);
  }

  myAccounts(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/my-accounts';
    return this.http.get<any>(url);
  }

  accountPermission(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/auth/account-permission';
    return this.http.get<any>(url);
  }
  getAccessUser(isActive): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Access/get-access-user?isActive='+isActive;
    return this.http.get<any>(url);
  }
  changeStatus(id,status): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Access/change-status?id='+id+'&status='+status;
    return this.http.get<any>(url);
  }
  changeRole(id,roleId): Observable<any> {
    console.log(roleId)
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Access/change-role?id='+id+'&roleId='+roleId;
    return this.http.get<any>(url);
  }

  normalLogin(obj, type): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    this.myAppUnderwriterUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
    let url = "";
    if(type==0){
       url = this.myAppUrl + "/auth/normal-login";
    }else{
      url = this.myAppUnderwriterUrl + "/auth/normal-login";
    }
    
    return this.http.post<any>(url, obj);
  }
  generateToken(obj): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/auth/generate-token";
    return this.http.post<any>(url, obj);
  }

  generateTokenUnderwriter(obj): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/auth/generate-token-underwriter";
    return this.http.post<any>(url, obj);
  }

  registerComplete(obj): Observable<any> {
    if(obj.cityId==0){ obj.cityId=null}
    if(obj.regionId==0){ obj.regionId=null}
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/auth/register-complete";
    return this.http.post<any>(url, obj);
  }

  u_registerComplete(obj): Observable<any> {
   
    this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
    let url = this.myAppUrl + "/auth/register-complete";
    return this.http.post<any>(url, obj);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      console.log(Error)
      return null;
    }
  }

  getUserName() {
    if (localStorage.getItem("token")) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem("token"));
      if (decodedToken && decodedToken["UserName"]) {
        return {username: decodedToken["UserName"] +" (" + decodedToken["CompanyName"]+")", errorCode: 0};
      } else {
        return {username: null, errorCode: 1};
      }
    } else {
      return {username: null, errorCode: 1};
    }
  }

  getPortal() {
    if (localStorage.getItem("token")) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem("token"));
      if (decodedToken && decodedToken["Portal"]) {
        var path = "appeals"
        if(decodedToken["Portal"]=="UNDER_WRITER"){
          path = "appeals-underwriter";
        }
        return {portal: decodedToken["Portal"] , path: path, errorCode: 0};
      } else {
        return {portal: null, path : null, errorCode: 1};
      }
    } else {
      return {portal: null,  path : null, errorCode: 1};
    }
  }

  is_emitent() {
    if (localStorage.getItem("token")) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem("token"));
      if (decodedToken && decodedToken["Portal"]) {
        if(decodedToken["Portal"]=="UNDER_WRITER"){
         return false;
        }
       
      } else {
        return true;
      }
    } 

    return true;
  }

  getUserEmail() {
    if (localStorage.getItem("token")) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorage.getItem("token"));
      if (decodedToken && decodedToken["UserEmail"]) {
        return {email: decodedToken["UserEmail"], errorCode: 0};
      } else {
        return {email: null, errorCode: 1};
      }
    } else {
      return {email: null, errorCode: 1};
    }
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getAsanTokeTestMain() {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true
    };
    return this.http.get(this.getAsanURLLoginToken(), httpOptions).
      pipe(map(response => {
        this.lvm = new LoginASANVM;
        this.lvm.generalToken = response['data']['token'];
        this.lvm.errorMessage = response['exception'];

        return this.lvm;
      }), catchError(error => {
        this.lvm = new LoginASANVM;
        this.lvm.errorMessage = error['error']['exception']['code'];
        this.lvm.generalToken = null;

        localStorage.removeItem("asanToken")
        // localStorage.clear();
        return throwError(error);
      }));
  }

  getAsanCheck(lvm: LoginASANVM) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': lvm.generalToken
      })
    };
    return this.http.get(this.getAsanURLLoginCheck(), httpOptions).
      pipe(map(response => {
        lvm.checkCode = response['status'];
        return lvm;
      }), catchError(error => {

        localStorage.clear();

        return throwError(error);
      }));
  }
  getMyAccounts(model): Observable<any> {
    var auth_portal = localStorage.getItem("portal");
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    if(auth_portal=="UNDER_WRITER"){
      this.myAppUrl = AppConfig.settings.other.resourceApiUnderwriterURI;
    }
    return this.http.post(this.myAppUrl + "/auth/asan-login/", model).
      pipe(map(response => {
        return response
      }));
  }
  getAsanURLLogin(): string {
    return AppConfig.settings.asan.asanUrlLogin;
  }
  getAsanURLLoginToken(): string {
    return AppConfig.settings.asan.asanUrlLoginToken;
  }
  getAsanURLLoginCheck(): string {
    return AppConfig.settings.asan.asanUrlLoginCheck;
  }
  getAsanURLLogout(): string {
    return AppConfig.settings.asan.asanUrlLogout;
  }


  openDialogNewCompany() {
    const dialogRef = this.dialog.open(NewCompanyDialogComponent, { position:{top:'30px'}});

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }
  openDialogInfo() {
    const dialogRef = this.dialog.open(InfoDialogComponent, { position:{top:'30px'}});

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }
  openDialogAccounts(model) {
    const dialogRef = this.dialog.open(SelectCompanyDialogComponent,
      {
        width: '600px',
        panelClass: 'select-company-dialog',
        position: {
          top: '110px',
        },
        data: { accounts: model }

        });

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    });
  }

  openDialogGpp(model) {
    const dialogRef = this.dialog.open(GppDialogComponent,
      {
        width: '600px',
        position: {
          top: '110px',
        },
      
        data: { model: model}

        });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }
  openDialogNotfication() {
    const dialogRef = this.dialog.open(NotficationComponent,
      {
        width: '650px',
        position: {
          top: '110px',
        },
      
        
       
        });

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    });
  }
  openDialogMeasOrCancel(model,isMeas) {
    const dialogRef = this.dialog.open(MeasDialogComponent,
      {
        width: '600px',
        position: {
          top: '110px',
        },
      
        data: { qk: model,isMeas:isMeas }

        });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
