<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Yeni şirkətin qeydiyyatı</h2>
            <span>Nümayəndiliyinizi təsdiq edən etibarnamənin nömrəsini qeyd edin</span>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="mat-typography">
        <ng-container *ngIf="!newAlready">
            <form action="" class="customForm pl-5 pr-5 pt-3 pb-3" [formGroup]="newForm">
                <div class="col-12" [ngClass]="{'invalid-input': newForm.controls.tin.touched && newForm.controls.tin.invalid }">
                    <label for="application-form-email" class="m-0">Vöen hesabı:<sup
                        class="text-danger star">*</sup></label>
                    <input type="text" id="tin" [formControl]="newForm.controls.tin">
                    <div class="row justify-content-start" *ngIf="newForm.controls.tin.invalid && (newForm.controls.tin.dirty || newForm.controls.tin.touched)">
                        <div class="text-danger" *ngIf="newForm.controls.tin.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div class="col-12" [ngClass]="{'invalid-input': newForm.controls.attorneyNumber.touched && newForm.controls.attorneyNumber.invalid }">
                    <label for="application-form-email" class="m-0">Etibarnamə nömrəsi:<sup
                        class="text-danger star">*</sup></label>
                    <input type="text" id="application-form-email" [formControl]="newForm.controls.attorneyNumber">
                    <div class="row justify-content-start" *ngIf="newForm.controls.attorneyNumber.invalid && (newForm.controls.attorneyNumber.dirty || newForm.controls.attorneyNumber.touched)">
                        <div class="text-danger" *ngIf="newForm.controls.attorneyNumber.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <mat-dialog-actions align="end">
                        <button mat-button class="btn btn1-default" type="button" *ngIf="!showSpinner" (click)="addCompany()" [disabled]="showSpinner">Əlavə et</button>
                    </mat-dialog-actions>
                </div>
                <div class="col-12 next" *ngIf="showSpinner">
                    <button [disabled]="true" mat-button class="btn btn1-default" type="button">
                        Əlavə et
                    </button>
                    <mat-spinner style="margin:7px;" [diameter]="20">
                    </mat-spinner>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="newAlready">
            <div class="col-12 pt-4 pb-4 already">
                <p>Müraciətiniz üçün təşəkkür edirik. Etibarnamə yoxlandıqdan sonra sizə bildiriş göndəriləcəkdir.</p>
            </div>
        </ng-container>
    </mat-dialog-content>

</div>