<app-scroll-to-top></app-scroll-to-top>
<div class="container-fluid" *ngIf="!qk_contact_details">
    <div class="col-md-12 bg-white pb-5 pt-5 home_container">
        <mat-spinner style="margin:auto!important;" [diameter]="70">
        </mat-spinner>
    </div>
</div>
<div class="container-fluid border shadow-sm my-3" *ngIf="qk_contact_details">
    <div class="row">
        <div class="col-12 bg-white px-4 pt-4">
            <h1 class="font-weight-bold cabinet-title">{{qk_contact_details.title}}</h1>
        </div>
    </div>

    <div class="row cd-content contact_base">
        <div class="col-12 bg-white px-4 py-4">
            <div class="contact-details-row row">
                <div class="col-md-12 mb-3">
                    <!-- <p [innerHTML]="qk_contact_details.description"></p> -->
                    <div [innerHTML]="qk_contact_details.description"></div>
                </div>
            </div>
        </div>
    </div>
</div>