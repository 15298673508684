import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/base.service';
import { Observable } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { saveAs, FileSaverOptions } from 'file-saver';
import { AppConfig } from 'src/app.config';



@Injectable({
  providedIn: 'root',
})

export class HomeService extends BaseService {
  HomePageOperations(CURRENCY_HOME_PAGE_1: any) {
    throw new Error('Method not implemented.');
  }

  myAppUrl: string = AppConfig.settings.other.resourceApiURI;
  constructor(private http: HttpClient, private router: Router) {
    super();
    this.clearStorage();
  }

  static(data): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/lookup/static?key=" + data;
    return this.http.get<any>(url);
  }
  print_check(con_tent: any): void {
    document.getElementById("my_print").style.display = "block";
    document.getElementById("my_print").innerHTML = con_tent;
    document.getElementById("my_print").style.marginTop = "400px";
    window.print();
    document.getElementById("my_print").innerHTML = null;
    document.getElementById("my_print").style.marginTop = "0";
    document.getElementById("my_print").style.display = "none";
  }
  HTML_Dowload_check(con_tent: any) {
    const fileName = "Qebz";
    const fileType = "text/html";
    const txtBlob = new Blob([con_tent], { type: fileType });
    this.save(txtBlob, fileName);
  }
  save(blob: Blob, fileName?: string, filtType?: string, option?: FileSaverOptions): void {
    if (!blob) {
      throw new Error('Data argument should be a blob instance');
    }
    const file = new Blob([blob], { type: filtType || blob.type });
    saveAs(file, decodeURI(fileName || 'download'), option);
  }
  clearStorage() {
    var hours = AppConfig.settings.other.clearStorageHour;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if (now - parseInt(setupTime) > hours * 60 * 60 * 1000) {
        var lang = localStorage.getItem('lang');
        localStorage.clear();
        localStorage.setItem('lang', lang);
        window.location.reload();
        localStorage.setItem('setupTime', now.toString());
      }
    }
    if (document.getElementsByClassName("grecaptcha-badge") && document.getElementsByClassName("grecaptcha-badge")[0] && document.getElementsByClassName("grecaptcha-badge")[1]) {
      let element = document.getElementsByClassName("grecaptcha-badge") as HTMLCollectionOf<HTMLElement>;
      element[0].style.display = 'none';
    }
  }

  //Registry start
  getStocks(filter, isExport): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/stocks/get-stocks?isExport=' + isExport;
    return this.http.post<any>(url, filter);
  }

  getBonds(filter, isExport): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/bonds/get-bonds?isExport=' + isExport;
    return this.http.post<any>(url, filter);
  }
  //Registry end
}
