import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';
import { AsanDialog4Component } from '../asan-dialog-4/asan-dialog-4.component';

@Component({
  selector: 'app-asan-dialog-3',
  templateUrl: './asan-dialog-3.component.html',
  styleUrls: ['./asan-dialog-3.component.scss']
})
export class AsanDialog3Component implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  nowDate = new Date();
  hasStatus: boolean;
  hasRequest: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public cabinetService: Cabinet,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
    console.log(data)
    this.checkStatus();
  }

  ngOnInit(): void {

  }

  closeDialog() {
    window.location.reload();
  }

  checkStatus(){
    this.cabinetService.getAuthenticateStatus(this.data.postModel, this.id).subscribe(
      res => {
        console.log(res)
        if(res.response && res.response.status=='OUTSTANDING_TRANSACTION'){
          setTimeout(() => 
          {
             this.checkStatus();
          },
          1200);
        }else if(res.response && res.response.status=='USER_AUTHENTICATED'){

          this.cabinetService.getBusinessCert(this.data.postModel, this.id).subscribe(
            res_cert => {
              this.data.postModel.certificateId = res_cert.response.certificateId;
              this.data.postModel.files = this.data.files;

              this.cabinetService.sendSign(this.data.postModel, this.id).subscribe(
                res_sign => {
                  this.data.postModel.transactionId = res_sign.response.transactionId;
                  this.data.postModel.strTransactionId = res_sign.response.strTransactionId;
                  this.data.postModel.verificationCode = res_sign.response.verificationCode;
                  this.hasStatus = true;
                  this.dialog.closeAll();
                  this.dialog.open(AsanDialog4Component, {
                    width: '650px',
                    position: {
                      top: '110px'
                    },
                    data: {files: this.data.files, getModel: res_sign.response, postModel: this.data.postModel},
                    autoFocus: false,
                  });
                 },
                err => {
                  if (err.error.status.errorCode != 0)
                  this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
                  this.hasRequest = false;
                })
             },
            err => {
              if (err.error.status.errorCode != 0)
              this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
              this.hasRequest = false;
            })

        
        }else{
          this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
        }
      
       
      
      

     
      },
      err => {
        if (err.error.status.errorCode != 0)
        this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
        this.hasRequest = false;
      })
  }

}
