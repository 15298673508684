import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { InfoDialogComponent } from 'src/app/cabinet/dialogs/info-dialog/info-dialog.component';
import { GppDialogComponent } from 'src/app/cabinet/dialogs/gpp-dialog/gpp-dialog.component';
import { ReceiptDialog } from 'src/app/cabinet/dialogs/receipt/receipt-dialog';
import { AppConfig } from 'src/app.config';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class PaymentReceiptComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  receiptDoc: any;
  issueAmount: any;
  hasRequest: boolean;
  commonSpinner: boolean;
  amountStateDuty: number;
  acceptTypes = "";
  acceptType = AppConfig.settings.fileSettings.acceptFiles;
  @Input("issueAmount") set issueSet(value: any) {
    this.issueAmount = value;

  }
  @Input() paymentReceipt;
  @Input() applyNumber;
  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    if (this.paymentReceipt?.receiptName)
      this.paymentReceipt.receiptName = this.paymentReceipt?.receiptName.substring(0, 26);
    this.getAmountStateDuty();
    this.setAcceptTypes();
  }

  setAcceptTypes() {
    for (let index = 0; index < this.acceptType.length; index++) {
      const item = this.acceptType[index];
      if (this.acceptType.length - 1 >= index)
        this.acceptTypes += ("." + item + ", ")
    }
  }

  openInfoDialog() {
    this.dialog.open(InfoDialogComponent, {
      width: '600px',
      position:{
        top:'110px'
      },
      autoFocus: false
    })
  }

  openReceiptDialog() {
    console.log(this.paymentReceipt.receipt);

    this.dialog.open(ReceiptDialog, {
      width: '1000px',
      height: '409px',
      position:{
        top:'110px'
      },
      data: { data: this.paymentReceipt.receipt, id: this.id },
      autoFocus: false
    });
  }

  openGppDialog() {
    this.dialog.open(GppDialogComponent, {
      width: '550px',
      position:{
        top:'110px'
      },
      data:
      {
        applyNumber: this.applyNumber,
        amountStateDuty: this.amountStateDuty,
        totalAmountStateDuty: this.amountStateDuty
      },
      autoFocus: false
    })
  }
  getAmountStateDuty() {
    console.log(this.issueAmount);

    if (this.issueAmount) {
        this.cabinetService.GetPaymentDue(this.issueAmount).subscribe(res => {
        this.amountStateDuty = res.result[0].due;
        return this.amountStateDuty;

      })





    }
  }


  uploadReceipt(event) {
    if (!this.cabinetService.checkFile(event.target.files[0])) return;
    this.receiptDoc = event.target.files[0];
    if (this.receiptDoc) {
      document.getElementsByClassName("custom-file-label")[0].classList.add('check');

      let formData = new FormData();
      formData.append(`doc.file`, this.receiptDoc);
      formData.append(`doc.fileName`, this.receiptDoc.name);
      formData.append(`doc.fileSize`, this.receiptDoc.size);
      formData.append(`doc.fileExt`, this.receiptDoc.name.split('.').pop());
      this.sendReceiptDoc(formData);
    }
  }

  sendReceiptDoc(formData) {
    if (this.id) {
      this.commonSpinner = true;
      this.cabinetService.sendReceiptDoc(formData, this.id).subscribe(
        res => {
          this.commonSpinner = false;
          window.location.reload();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.commonSpinner = false;
        })
    }
  }

  cancelReceipt() {
    if (this.id) {
      this.commonSpinner = true;
      this.cabinetService.cancelReceipt(this.id).subscribe(
        res => {
          this.commonSpinner = false;
          window.location.reload();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.commonSpinner = false;
        })
    }
  }
}
