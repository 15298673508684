<div *ngIf="commonSpinner" class="common-spinner col-md-12 d-flex bg-white pb-5">
    <mat-spinner style="margin: auto!important;" [diameter]="70">
    </mat-spinner>
</div>
<div class="col-12 mb-5 p-0">
    <div class="col-md-9 p-3 payment-box rounded mt-5 title-gray">
        <div>
            <h5 class="d-flex align-items-center mb-0">Dövlət rüsumunun məbləğləri barədə məlumat <span (click)="openInfoDialog()" class="cursor-pointer material-icons-outlined ml-3">
                    help_outline
                </span></h5>
        </div>
        <div class="d-md-flex align-items-center justify-content-between">
            <h6 class="mb-0">Dövlət rüsumunun məbləği: <b class="ml-3 text-dark">{{amountStateDuty}} AZN</b></h6>
            <button *ngIf="paymentReceipt?.isOnline && !paymentReceipt?.status" (click)="openGppDialog()" mat-button class="custom-btn">
                <span>Ödəniş et</span>
            </button>
            <span *ngIf="!paymentReceipt?.isOnline && paymentReceipt?.status" id="sub">
                Ödənilib
            </span>
            <button *ngIf="paymentReceipt?.isOnline && paymentReceipt?.status" (click)="openReceiptDialog()" mat-button class="custom-btn">
                <span>Qəbzə baxış</span>
            </button>
            <input class="d-none" type="file" [accept]="acceptTypes" #receiptFile>
        </div>
        <div *ngIf="paymentReceipt?.isOnline && !paymentReceipt?.hasReceipt">
            <span>Məlumatların və sənədlərin doğruluğundan əminsinizsə ödəniş edin.</span>
        </div>
    </div>
    <div *ngIf="!paymentReceipt?.isOnline" class="col-12 pt-4 ">
        <div class="row">
            <div class="custom-file col-md-4 col-sm-6">
                <input type="file" [accept]="acceptTypes" class="custom-file-input" role='button' (change)="uploadReceipt($event)" id="customFileLangHTML">
                <span class="custom-file-label check" *ngIf="paymentReceipt?.hasReceipt; else elseBlockShow">{{paymentReceipt?.receiptName}}</span>

                <ng-template #elseBlockShow> <span class="custom-file-label">Ödənişin qəbzi
                    </span></ng-template>

            </div>
            <div class="col-md-3 col-sm-6 lbl-gray-txt">
                <p *ngIf="receiptDoc" class="pl-4 pt-2 pb-2"> <b class="float-left">{{receiptDoc.name}}</b> <b class="float-right"> [{{cabinetService.sizeToBytes(receiptDoc.size)}}]</b></p>
                <p *ngIf="!receiptDoc && paymentReceipt?.hasReceipt" class="pl-4 pt-2 pb-2"> <b class="float-left">{{paymentReceipt?.receiptName}}</b> <b class="float-right">
                        [{{cabinetService.sizeToBytes(paymentReceipt?.receiptSize)}}]</b></p>
            </div>
            <!-- <div *ngIf="receiptDoc || paymentReceipt?.hasReceipt" class="col-md-2 col-sm-6 p-0">
                <button *ngIf="paymentReceipt?.hasReceipt" mat-button class="float-right custom-btn"
                    (click)="cancelReceipt()">
                    <span>Ödənişi ləğv edin</span>
                </button>
            </div> -->
        </div>
    </div>
</div>