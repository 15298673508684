import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';
import { AsanDialog3Component } from '../asan-dialog-3/asan-dialog-3.component';

@Component({
  selector: 'app-asan-dialog-2',
  templateUrl: './asan-dialog-2.component.html',
  styleUrls: ['./asan-dialog-2.component.scss']
})
export class AsanDialog2Component implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  asanForm: FormGroup;
  nowDate = new Date();
  hasRequest: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public cabinetService: Cabinet,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
  }

  ngOnInit(): void {
    this.asanForm = this.fb.group({
      phoneNumber: ['', [Validators.required]],
      userId: ['', [Validators.required]]
    });
  }

  closeDialog() {
    window.location.reload();
  }
  
  asanSign() {
    if (this.asanForm.invalid) return;
    if (this.id) {
      this.hasRequest = true;
      var postData = this.asanForm.value;
      postData.key = this.data.key;
      this.cabinetService.sendAuthenticate(this.asanForm.value, this.id).subscribe(
        res => {
          this.hasRequest = false;
          console.log(res)
          postData.transactionId = res.response.transactionId;
          postData.strTransactionId = res.response.strTransactionId;
          postData.verificationCode = res.response.verificationCode;
          
          this.hasRequest = true;
          console.log(this.id)
          this.dialog.closeAll();
          this.dialog.open(AsanDialog3Component, {
            width: '650px',
            position: {
              top: '110px'
            },
            data: {files: this.data.files, postModel: postData, getModel: res.response},
            autoFocus: false,
          });

       
        },
        err => {
          if (err.error.status.errorCode != 0)
          this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
          this.hasRequest = false;
        })
    }
  }
}
