import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';
import { MeasDialogComponent } from '../meas-dialog/meas-dialog.component';
import { NewCompanyDialogComponent } from '../newCompany/new-company-dialog.component';

@Component({
  selector: 'app-apply-document-dialog',
  templateUrl: './apply-document-dialog.component.html',
  styleUrls: ['./apply-document-dialog.component.scss']
})
export class ApplyDocumentDialogComponent implements OnInit {

  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  showSpinner: boolean;
  appealDoc: any;
  downloadSpinner: boolean;
  clickedPath: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private cabinetService: Cabinet,
    private activatedRoute: ActivatedRoute) {
    this.cabinetService.clearStorage();
  }

  ngOnInit() {
    this.getAppealDoc();
  }

  getDocsByPortal(portal)
  {
    return this.appealDoc.incomingDocs.filter(x => x.portal == portal)
  }

  sendMeas(key, docName) {
    console.log(key);
    this.dialog.closeAll();
    this.dialog.open(MeasDialogComponent, {
      width: '550px',
      position: {
        top: '110px'
      },
      data: { isMeas: true, doc_name: docName, doc_key: key },
      autoFocus: false,
    });
  }

  getAppealDoc() {
    if (this.id) {
      this.showSpinner = true;
      this.cabinetService.getAppealDoc(this.id).subscribe(
        res => {
          this.appealDoc = res.response;
          this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
        
    }
  }

  downloadFile(path) {
    this.clickedPath = path;
    this.downloadSpinner = true;
    this.cabinetService.download(path).subscribe(
      res => {
        let file = this.cabinetService.base64ToBlob(res.data, res.contentType, 512);
        var fileURL = URL.createObjectURL(file);
        var savefile = document.createElement("a");
        savefile.download = res.name;
        savefile.href = fileURL;
        savefile.click();
        this.downloadSpinner = false;
      },
      err =>
      {
        this.cabinetService.messageDialog(err.error.status.message, false);
        this.downloadSpinner = false;
      })
  }
}