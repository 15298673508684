<div class="conainer-fluid" *ngIf="waitDocuments?.length">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="waitDocumentForm" *ngIf="waitDocumentForm">
                <i style="color: rgba(0, 0, 0, 0.54);">  Aşağıdakı bölmədən istifadə edərək təqdim olunmuş sənədləri yeniləyə və (və ya) yeni sənəd təqdim edə bilərsiniz.
                </i>
                <div class="row mt-3">
                    <div class="col-md-4">
                        <label class="w-100">Sənədlər</label>
                        <mat-form-field class="w-100">
                            <mat-select formControlName="key" #docKey (selectionChange) = "clearDocument()">
                                <mat-option [value]="">--- Seçin ---</mat-option>
                                <mat-option *ngFor="let item of waitDocuments" [value]="item.key">{{item.docName}}
                                </mat-option>
                                <mat-option [value]="'doc_in_9'">Digər</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="pt-1" *ngIf="!waitDocumentForm.controls.key.valid && waitDocumentForm.controls.key.touched">
                            Seçilməlidir
                        </mat-error>
                    </div>
                    <div class="col-md-4 c-mt">
                        <div class="custom-file">
                            <input type="file" [accept]="acceptTypes" class="custom-file-input" #file formControlName="file" role='button' id="customFileLangHTML" 
                            (change)="setApplyWaitDoc($event.target.files[0], docKey.value)">
                            <span class="custom-file-label" *ngIf="!file.value else elseBlockShow">Faylı seçin  və ya buraya
                                    sürükləyib buraxın
                                </span>
                            <ng-template #elseBlockShow>
                                <span class="custom-file-label check">Faylı seçin  və ya buraya
                                        sürükləyib buraxın</span>
                            </ng-template>
                        </div>
                    </div>

                    <div class="col-md-4 d-flex c-mt">
                        <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
                        <div>
                            <button (click)="sendWaitDoc()" mat-button class="custom-btn" [disabled]="hasRequest || waitDocumentForm.invalid">
                                <span>Göndər</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>