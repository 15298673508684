<div class="container-fluid" *ngIf="!showSpinner">
    <app-new-appeal-default *ngIf="status == 'default' || !id"></app-new-appeal-default>
    <app-new-appeal-new *ngIf="status == 'new'"></app-new-appeal-new>
    <app-new-appeal-sent [status]="status" *ngIf="!((status == 'default' || !id) || status == 'new')"></app-new-appeal-sent>
</div>

<div class="container-fluid border shadow-sm my-3 " *ngIf="showSpinner">
    <div class="row">
        <div class="col-md-12 py-3 pt-5 pb-5 bg-white">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
    </div>
</div>