import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Component, OnInit } from '@angular/core';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { AccountService } from '../account-service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  constructor(
    private router: Router
  ) {
    window.open(
      "https://securities.e-cbar.az/",
      '_blank' // <- This is what makes it open in a new window.
    );
    this.router.navigate(["/login"]);

  }


}
