import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplyType, placementMethodsConst, qkObservesConst, qkTypesConst } from 'src/app/models/constants';
import { MatDialog } from '@angular/material/dialog';
import { StateRegistrationDialogComponent } from '../../dialogs/state-registration-dialog/state-registration-dialog.component';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { GppDialogComponent } from '../../dialogs/gpp-dialog/gpp-dialog.component';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-new-appeal-sent',
  templateUrl: './new-appeal-sent.component.html',
  styleUrls: ['./new-appeal-sent.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppealSentComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  showSpinner: boolean;
  applyUpper: any;
  status: any;
  @Input("status") set statusSet(value: any) {
    this.status = value;
  }
  
  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    this.setTitle();
  }

 
  setTitle() {
    this.TitleService.setTitle("QİYMƏTLİ KAĞIZLAR" + " | securities.e-cbar.az");
    const breadcrumb = { title: "Qi̇ymətli̇ Kağizlar", title2: "Yeni müraciət" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }

}
