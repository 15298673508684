import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfig} from 'src/app.config';
import {Cabinet} from 'src/app/cabinet/cabinet.service';
import {Static} from 'src/app/models/constants';
import {AccountService} from '../../account-service';
import {HomeService} from '../../home.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = "Kapital Bazarı üzrə Elektron Xidmətlər";
  measUrl: string;
  isTest = AppConfig.settings.other.testLogin;
  home_page: any;
  home_page_cardtitle: any;
  qk_home_page: any;
  qkr_home_page: any;

  constructor(
    private TitleService: Title,
    public homeService: HomeService,
    public cabinetService: Cabinet,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
  ) {
    let urls = AppConfig.settings.urls;
    this.measUrl = urls.measUrl;
  }

  ngOnInit(): void {
    this.setTitle(this.title);
    const auth = this.accountService.getPortal();
    if (auth.errorCode == 0) {
      this.router.navigate(["/" + auth.path]);
    }
    this.get_data();
  }

  gotoUrl() {
    window.open(this.measUrl);
  }

  get_data() {
    this.homeService.static(Static.HOME_PAGE).subscribe(
      ((data) => {
        //console.log(data);
        this.home_page = data.result;
        this.home_page["description"] = this.decodeReceipt(this.home_page["description"])
      }
      ));
      this.homeService.static(Static.HOME_PAGE_CARDTITLE).subscribe(
        ((data) => {
          this.home_page_cardtitle = data.result;
          this.home_page_cardtitle["description"] = this.decodeReceipt(this.home_page_cardtitle["description"])
          if(this.home_page_cardtitle["description"].length>200){
            this.home_page_cardtitle["description"] = this.home_page_cardtitle["description"].substring(0, 200) + " ...";
          }
        }
        ));
      this.homeService.static(Static.QKR_HOME_PAGE).subscribe(
        ((data) => {
          //console.log(data);
          this.qkr_home_page = data.result;
          this.qkr_home_page["description"] = this.decodeReceipt(this.qkr_home_page["description"])
          if(this.qkr_home_page["description"].length>200){
            this.qkr_home_page["description"] = this.qkr_home_page["description"].substring(0, 200) + " ...";
          }
        }
        ));
    this.homeService.static(Static.QK_HOME_PAGE).subscribe(
      ((data) => {
        //console.log(data);
        this.qk_home_page = data.result;
        this.qk_home_page["description"] = this.decodeReceipt(this.qk_home_page["description"])
        if(this.qk_home_page["description"].length>200){
          this.qk_home_page["description"] = this.qk_home_page["description"].substring(0, 200) + " ...";
        }
      }));
  }
  public decodeReceipt(data) {
    return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  setTitle(title: string) {
    if (title) {
      this.TitleService.setTitle(title + " |  securities.e-cbar.az");
    }
  }

}
