<div class="container-fluid ">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Tarixçə</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="mat-typography justify-content-center ">

        <mat-spinner *ngIf="showSpinner" class=" mx-auto my-2 " [diameter]="70" >
        </mat-spinner>

        <div class="row bg-white ml-2 d-block ml-5 underwriter-5">
            <div style="padding: 7px;" class="tab   m-3 " *ngFor="let element of applyLogs">
                <span style="color: #03A9D9;;">{{element.date|date: 'dd.MM.YYYY HH:mm'}}</span> -
                <span style="color: rgba(0, 0, 0, 0.74);">{{element.text}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-center" [style.height]="'100px'" *ngIf="applyLogs.length == 0 && showSpinner == false">
              <b>Boşdur</b>
            </div>
        </div>
    </mat-dialog-content>

</div>
