<mat-spinner *ngIf="showSpinner" class="m-3" [diameter]="70" class="spinner">
</mat-spinner>

<div *ngIf="!showSpinner" class="container-fluid border shadow-sm my-3">
    <div class="row pb-1 bg-white">
        <div class="col-12 d-flex align-items-center justify-content-between bg-white px-4 pt-4">
            <h1 class="font-weight-bold cabinet-title">Müraciətlər</h1>
            <div class="float-right">

                <button mat-button class="custom-btn mr-2 ex-button float-left" (click)="filter()">
                  <i class="fas fa-search"></i>
                  <span class="ml-1">Filter et</span>
              </button>

                <mat-spinner class="ml-2 mt-2" [diameter]="20" style="float: right;" *ngIf="miniShowSpinner">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-12 bg-white pb-5" *ngIf="false">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div> -->


        <div class="col-12 bg-white px-4 py-4">
            <table mat-table class="w-100 mb-3 table2  table-responsive">
                <form action="" [formGroup]="applicationForm">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="p-3  ">
                            <div class="row   ">
                                <div class="col-12">
                                    <span class="pb-2">Təşkilat:</span>
                                    <mat-form-field appearance="fill" class="custom-selectt d-block mb-2 bg-white">
                                        <mat-select formControlName="companyId">
                                            <mat-option [value]='null'>
                                                --- Seçin ---
                                            </mat-option>
                                            <mat-option *ngFor="let x of company" value="{{x.id}}">
                                                {{x.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </th>

                    </ng-container>


                    <!-- Name Column -->
                    <ng-container matColumnDef="apply">
                        <th mat-header-cell *matHeaderCellDef class=" py-3  ">
                            <span class="pb-2">Müraciət növü:</span>
                            <mat-form-field appearance="fill" class="custom-selectt d-block  mb-2 bg-white">
                                <mat-select formControlName="applyTypeId">
                                    <mat-option [value]='null'>
                                        --- Seçin ---
                                    </mat-option>
                                    <mat-option *ngFor="let x of applyType" value="{{x.id}}">
                                        {{x.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>

                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef class="p-3 ">
                            <span class="pb-2">Tarix:</span>
                            <mat-form-field appearance="fill" class="custom-date-picker w-75 mb-2 bg-white">
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate placeholder="Başlanğıc" formControlName="startDate">
                                    <input matEndDate placeholder="Son" formControlName="endDate">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </th>

                    </ng-container>
                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="p-3 ">
                            <span class="pb-2">Status:</span>
                            <mat-form-field appearance="fill" class="custom-selectt w-100 mb-2 bg-white">
                                <mat-select formControlName="statusId">
                                    <mat-option [value]='null'>
                                        --- Seçin ---
                                    </mat-option>
                                    <mat-option *ngFor="let x of status" value="{{x.id}}">
                                        {{x.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>

                    </ng-container>




                    <!-- operation Column -->
                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef class="m-0 p-0"></th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>



                </form>
            </table>
            <table mat-table [dataSource]="ELEMENT_DATA" class=" mt-3 w-100 table table-responsive">


                <ng-container matColumnDef="name">

                    <td mat-cell *matCellDef="let element">
                        <span id="main" class="">{{element.orgName}}</span>
                        <span class="mt-2 " id="sub">{{element.tin}} </span>


                    </td>
                </ng-container>


                <!-- Name Column -->
                <ng-container matColumnDef="apply">

                    <td mat-cell *matCellDef="let element" class="pl-0">
                        <span id="main2" class="text-start pl-4" (click)="goToApplyDetail(element.applyId)">{{element.applyType}}</span>
                        <span class="mt-2 pl-4 " id="sub">{{element.applyNumber}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date" class="px-0">

                    <td mat-cell *matCellDef="let element" class="pl-0">

                    </td>
                </ng-container>
                <!-- status Column -->
                <ng-container matColumnDef="status">

                    <td mat-cell *matCellDef="let element" class="px-0">

                        <span id="main" [style.color]="element.color">{{element.status  }}</span>

                        <span class="mt-2 " id="sub">{{element.createdOn |date: 'dd.MM.YYYY HH:mm'}}</span>
                    </td>
                </ng-container>

                <!-- operation Column     (mouseover)="hoverIn($event.target, element.buttoncolor)" (mouseout)="hoverOut($event.target, element.buttoncolor)" -->
                <ng-container matColumnDef="operation" class="butn">

                    <td mat-cell *matCellDef="let element"><button [style.border-color]="element.buttoncolor" type="button" (click)="openhistorically(element.applyId)" class="btn btn-outline-primary"><span [style.color]="element.buttoncolor">Tarixçə</span> </button></td>

                </ng-container>


                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator #paginatorServer [pageSizeOptions]="[8, 16,24]" [pageSize]="applicationForm.controls.limit.value" [length]="totalSize" [pageIndex]="applicationForm.controls.page.value-1" showFirstLastButtons (page)="pageChanged($event)">
            </mat-paginator>
        </div>
    </div>
</div>