import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { InfoDialogComponent } from 'src/app/cabinet/dialogs/info-dialog/info-dialog.component';
import { GppDialogComponent } from 'src/app/cabinet/dialogs/gpp-dialog/gpp-dialog.component';
import { AppConfig } from 'src/app.config';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-wait-document',
  templateUrl: './wait-document.component.html',
  styleUrls: ['./wait-document.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class WaitDocumentComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  waitDocumentForm: FormGroup;
  waitDocuments: any[];
  showSpinner: boolean;
  formData: FormData;
  hasRequest: boolean;
  acceptType = AppConfig.settings.fileSettings.acceptFiles;;
  acceptTypes = "";
  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    this.getWaitDoc();
    this.setForm();
    this.setAcceptTypes();  
  }

  setAcceptTypes() {
    for (let index = 0; index < this.acceptType.length; index++) {
      const item = this.acceptType[index];
      if (this.acceptType.length - 1 >= index)
        this.acceptTypes += ("." + item + ", ")
    }
  }

  setForm() {
    this.waitDocumentForm = this.fb.group({
      key: [, Validators.required],
      file: [, Validators.required]
    });
  }

  getWaitDoc() {
    if (this.id) {
      this.showSpinner = true;
      this.cabinetService.getWaitDoc(this.id).subscribe(
        res => {
          this.waitDocuments = res.response.docs;          
          this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }
 
  sendWaitDoc() {
    if (this.id) {
      this.hasRequest = true;
      this.cabinetService.sendWaitDoc(this.formData, this.id).subscribe(
        res => {
          this.hasRequest = false;
          this.cabinetService.sendEmail(this.id, false).subscribe(
            res => { });
          window.location.reload();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.hasRequest = false;
        })
    }
  }

  setApplyWaitDoc(file, key) {
    if (!file) { this.clearDocument(); return; }
    if (!this.cabinetService.checkFile(file)) 
    { this.clearDocument(); return; }

    var selectedItem =  this.waitDocuments.filter(obj => obj.key === key);
    if(selectedItem.length > 0){
      var extHover = selectedItem[0].extHover;
      var extHoverMessage = selectedItem[0].extHoverMessage;
    if (extHover && !this.cabinetService.checkAdoc(file, extHover, extHoverMessage))  { this.clearDocument(); return; }
    }
   
    let data = {
      key: key,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      fileExt: file.name.split('.').pop()
    }
    let formData = new FormData();
    formData.append(`doc.key`, data.key);
    formData.append(`doc.file`, data.file);
    formData.append(`doc.fileName`, data.fileName);
    formData.append(`doc.fileSize`, data.fileSize);
    formData.append(`doc.fileExt`, data.fileExt);
    this.formData = formData;
  }

  clearDocument()
  {
    this.waitDocumentForm.controls.file.setValue('');
  }
}
