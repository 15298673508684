<div class="container-fluid border shadow-sm bg-white my-3 px-4">
    <div class="row my-4">
        <div class="col-12">
            <h1 class="font-weight-bold cabinet-title">{{ title }}</h1>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-md-12 bg-white pb-5 ">
            <mat-spinner style="margin: auto!important;" *ngIf="showSpinner" [diameter]="70">
            </mat-spinner>
        </div>
        <div *ngIf="!showSpinner" class="col-md-6">
            <div class="profile-box">
                <h2 class="profil-title">İstifadəçi məlumatları</h2>
                <form [formGroup]="applicationForm" class="customForm">

                    <div class="pt-3 pb-5">

                        <div class="d-flex">
                            <div class="col-6">
                                <label for="organization">İstifadəçi adı:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)"  type="text" matInput id="name" formControlName="name" />
                            </div>
                            <!-- <div class="col-6" [ngClass]="{'red':email.invalid && (email.dirty || email.touched)}"> -->
                            <div class="col-6 ">
                                <label for="profile-email">Şəxsiyyət vəsiqəsi FİN-i:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)"  type="text" matInput id="profile-email" formControlName="pin" />
                            </div>

                        </div>

                        <div class="d-flex">
                            <div class="col-6 mt-2">
                                <label for="profile-email">Qeydiyyat ünvanı:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)"  type="text" matInput id="profile-email" formControlName="regAddress" />
                            </div>
                            <div class="col-6 mt-2">
                                <label for="profile-email">Şəhər:<sup class="text-danger star">*</sup></label>

                                <mat-select formControlName="cityId" (selectionChange)="getRegionBYid($event.value)">
                                    <mat-option [value]="0">--- Seçin ---</mat-option>
                                    <mat-option *ngFor="let x of City" [value]="x.id">{{x.name}}</mat-option>
                                </mat-select>

                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-6 mt-2">
                                <label for="email">E-poçt ünvanı:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)" disabled  type="text" matInput id="email" name="email" formControlName="email" />
                                <div class="form-row justify-content-start" *ngIf="email.dirty && email.invalid">
                                    <div class="text-danger" *ngIf="email.errors?.required">
                                        E-poçt ünvanı boş ola bilməz.
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 mt-2">
                                <label for="profile-email">Rayon:<sup class="text-danger star">*</sup></label>
                                <mat-select formControlName="regionId" [disabled]=" Region.length==0 ">
                                    <mat-option [value]="0">--- Seçin ---</mat-option>
                                    <mat-option *ngFor="let x of Region" [value]="x.id">{{x.name}}</mat-option>
                                </mat-select>

                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-6 mt-2">
                                <label for="username">Telefon nömrəsi:<sup class="text-danger star">*</sup></label>
                                <input type="text" prefix="+994" mask="(00) 000 00 00" matInput id="profile-email" formControlName="phone" />
                            </div>
                            <div class="col-6 mt-2">
                                <label for="username">Küçə və ev ünvanı:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="address" />
                            </div>

                        </div>

                        <div style="margin-top: 277px;">
                            <div class="d-flex justify-content-end pt-3 mr-2 ">
                                <mat-spinner *ngIf="saving" class="m-3" [diameter]="25" class="my-auto">
                                </mat-spinner>
                                <button mat-button class="custom-btn m-2" (click)="save()" [disabled]="!saving && applicationForm.dirty && applicationForm.valid
                              ?
                              null : true">Yadda
                                saxla</button>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <div *ngIf="!showSpinner" class="col-md-6">
            <div class="profile-box">
                <h2 class="profil-title">Emitent məlumatları</h2>
                <form [formGroup]="EapplicationForm" class="customForm">

                    <div class="pt-3 pb-5">

                        <div class="d-flex">
                            <div class="col-6">
                                <label for="organization">Emitent adı:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)"  type="text" matInput id="name" formControlName="name" />
                            </div>
                            <!-- <div class="col-6" [ngClass]="{'red':email.invalid && (email.dirty || email.touched)}"> -->
                            <div class="col-6 ">
                                <label for="profile-email">Vöen:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="pin" />
                            </div>

                        </div>

                        <div class="d-flex">
                            <div class="col-6 mt-2">
                                <label for="profile-email">Qeydiyyat ünvanı:<sup class="text-danger star">*</sup></label>
                                <input  (click)="onKeyUp($event)"  type="text" matInput id="profile-email" formControlName="regAddress" />
                            </div>
                            <div class="col-6 mt-2">
                                <label for="profile-email">Şəhər:<sup class="text-danger star">*</sup></label>
                                <!--(selectionChange)="getERegionBYid($event.value)"-->
                                <mat-select formControlName="cityId" (selectionChange)="getERegionBYid($event.value)">
                                    <mat-option [value]="0">--- Seçin ---</mat-option>
                                    <mat-option *ngFor="let x of City" [value]="x.id">{{x.name}}</mat-option>
                                </mat-select>

                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-6 mt-2">
                                <label for="email">E-poçt ünvanı:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="email" name="email" formControlName="email" />
                                <div class="form-row justify-content-start" *ngIf="email.dirty && email.invalid">
                                    <div class="text-danger" *ngIf="email.errors?.required">
                                        E-poçt ünvanı boş ola bilməz.
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 mt-2">
                                <label for="profile-email">Rayon:<sup class="text-danger star">*</sup></label>
                                <mat-select formControlName="regionId" [disabled]=" ERegion.length==0 ">
                                    <mat-option [value]="0">--- Seçin ---</mat-option>
                                    <mat-option *ngFor="let x of ERegion" [value]="x.id">{{x.name}}</mat-option>
                                </mat-select>

                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-6 mt-2">
                                <label for="username">Telefon nömrəsi:<sup class="text-danger star">*</sup></label>
                                <input prefix="+994" mask="(00) 000 00 00" type="text" matInput id="profile-email" formControlName="phone" />
                            </div>
                            <div class="col-6 mt-2">
                                <label for="username">Küçə və ev ünvanı:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="address" />
                            </div>

                        </div>
                        <div class="d-flex">

                            <div class="col-6 mt-2">
                                <label for="username">Poçt-indeksi:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="postIndex" />
                            </div>

                            <div class="col-6 mt-2">
                                <label for="username">Müxbir hesab:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="correspondentAccount" />
                            </div>


                        </div>

                        <div class="d-flex">

                            <div class="col-6 mt-2" style="position: relative;">
                                <label for="username">Bank Vöeni:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" class="w-100 pin-search" formControlName="bankTin" />
                                <i *ngIf="!showAvisSpinner" (click)="getByTin()" class="fas fa-search text-dark s-icon"></i>
                                <i *ngIf="showAvisSpinner" class="fas fa-circle-notch fa-spin s-icon"></i>

                                <mat-error class="pt-1" *ngIf="!EapplicationForm.controls.bank.valid && EapplicationForm.controls.bank.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                                <mat-error *ngIf="tinInValid">
                                    Vöen düzgün deyil
                                </mat-error>
                                <mat-error *ngIf="hasAvisError">
                                    {{avisMessage}}
                                </mat-error>
                            </div>

                            <div class="col-6 mt-2">
                                <label for="username">Bank Adı:<sup class="text-danger star">*</sup></label>
                                <input   (click)="onKeyUp($event)" type="text" matInput id="profile-email" formControlName="bank" />
                            </div>


                        </div>

                        <div class="d-flex">

                            <div class="col-6 mt-2">
                                <label for="username">H/H:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="settlementAccount" />
                            </div>

                            <div class="col-6 mt-2">
                                <label for="username">Bankın kodu:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="bankCode" />
                            </div>


                        </div>

                        <div class="d-flex">

                            <div class="col-6 mt-2">
                                <label for="username">S.W.I.F.T:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="swift" />
                            </div>

                            <div class="col-6 mt-2">
                                <label for="username">Emitent kodu:<sup class="text-danger star">*</sup></label>
                                <input type="text" matInput id="profile-email" formControlName="code"/>
                            </div>

                        </div>

                        <div>
                            <div class="d-flex justify-content-end pt-4 mr-2 ">
                                <mat-spinner *ngIf="Esaving" class="m-3" [diameter]="25" class="my-auto">
                                </mat-spinner>

                                <button mat-button class="custom-btn m-2" (click)="AvisSave()" [disabled]="Esaving">AVIS-dən məlumatları yenilə</button>

                                <button mat-button class="custom-btn m-2" (click)="Esave()" [disabled]="!Esaving && EapplicationForm.dirty && EapplicationForm.valid ? null : true">Yadda
                                saxla</button>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>