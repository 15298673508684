import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AppConfig } from 'src/app.config';
import { HomeService } from 'src/app/home/components/home/home.service';
import { ColumnType } from 'src/app/models/constants';
import { ExcellService } from 'src/app/services/excell.service';

export interface PeriodicElement {
  id: number;
  reg_date: number;
  name: string;
  isin_code: number;
  place_type: string;
  reg_cost: number;
  reg_quantity: number;
  release_status: string
}
const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, reg_date: 2000, name: 'Techpro DC', isin_code: 741341367145737, place_type: 'Kütləvi təklif', reg_cost: 2500, reg_quantity: 14, release_status: 'Qeydiyyata alınmışdır' },
]
@Component({
  selector: 'app-bonds',
  templateUrl: './bonds.component.html',
  styleUrls: ['./bonds.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('expandArrowIcon', [
      state('right', style({ transform: 'rotate(0)' })),
      state('down', style({ transform: 'rotate(90deg)' })),
      transition(
        'right <=> down',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class BondsComponent implements OnInit {
  displayedColumns: string[] = ['arrow', 'reg_date', 'name', 'reg_num', 'reg_quantity', 'reg_cost'];
  displayedColumnsExpand = [...this.displayedColumns, 'expand'];
  expandedElement: PeriodicElement | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = ELEMENT_DATA;
  stockForm;
  public pageSize = 8;
  public currentPage = 0;
  public totalSize = 0;
  encum_length: number;
  pageEvent: PageEvent;
  showSpinner = false;
  color = '#674b47';
  limit: any = 5;
  pageIndex: any = 0;
  skip: any = 0;
  selectedColumns = [];
  currency = [];
  typeKey: any;
  colFilter;
  ELEMENT_DATA: any;
  exportDataList: any;
  disableExport = false;

  constructor(
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private TitleService: Title,
    private homeService: HomeService, private fb: FormBuilder, private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef, private excellService: ExcellService, public router: Router,
    private datePipe: DatePipe
  ) {
    this.homeService.clearStorage();
    this.route.queryParams.subscribe(params => {
      this.typeKey = params['type'];
    })
  }

  @Input() searchForm;
  @Input() columnTypeKey

  filter() {
    this.stockForm = this.searchForm;
    this.getBonds();
  }

  ngOnInit(): void {
    this.setColumn();
    this.setTitle();
    this.filter();
  }

  setColumn() {
    if (this.searchForm.columnTypeKey == ColumnType.CERTIFICATES ||
      this.searchForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS) {
      this.displayedColumns = this.displayedColumns.filter(x => x != 'reg_cost');
    }
    else if (!this.displayedColumns.some(x => x == 'reg_cost')) {
      this.displayedColumns.push('reg_cost');
    }
  }

  pageChanged(event: any) {
    let pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.stockForm.skip = pageIndex;
    this.stockForm.limit = this.pageSize;
    this.getBonds();
  }

  getBonds() {
    this.showSpinner = true;
    this.homeService.getBonds(this.stockForm, false).subscribe(res => {
      this.ELEMENT_DATA = res.response.data;
      this.totalSize = res.response.total;
      this.showSpinner = false;
    })
  }
  setTitle() {
    this.TitleService.setTitle("SƏHMLƏR" + " | mb-securities.e-cbar.az");
    const breadcrumb = { title: "Səhmlər" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }

  goToUpdate(id) {
    this.router.navigate(['/new-stock'], { queryParams: { id: `${id}` } });
  }
  export() {
    this.getExportStock();
  }
  getExportStock() {
    this.showSpinner = true
    this.disableExport = true;

    this.cdRef.detectChanges();
    this.homeService.getBonds(this.stockForm, true).subscribe(x => {
      if (this.ELEMENT_DATA != null) {
        this.exportDataList = x.response.data
        this.exportAsXLSX();
        this.showSpinner = false
        this.disableExport = false;
      }
    })
  }

  exportAsXLSX(): void {
    let column_data = [];

    column_data.push({ header: 'Buraxılışın qeydiyyat tarixi', key: 'registrationDate', width: 40, height: 30 });
    column_data.push({ header: 'Emitentin adı', key: 'name', width: 40, height: 30 });
    column_data.push({ header: 'Qiymətli kağızların beynəlxalq eyniləşdirmə nömrəsi (ISIN)', key: 'registrationNum', width: 30, height: 30 });

    if (!(this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS ||
      this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)) {

      if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
        this.stockForm.columnTypeKey == ColumnType.MUN_BONDS)
        column_data.push({ header: 'Qeydiyyata alınmış istiqrazların nominal dəyəri', key: 'regCost', width: 40, height: 30 });

      if (this.stockForm.columnTypeKey == ColumnType.GOV_BONDS)
        column_data.push({ header: 'İstiqrazların nominal dəyəri', key: 'regCost', width: 40, height: 30 });

      if (this.stockForm.columnTypeKey == ColumnType.BANK_NOTES)
        column_data.push({ header: 'Notların nominal dəyəri', key: 'regCost', width: 40, height: 30 });
    }

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.MUN_BONDS)
      column_data.push({ header: 'Qeydiyyata alınmış istiqrazların sayı', key: 'regQuantity', width: 30, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.GOV_BONDS)
      column_data.push({ header: 'İstiqrazların sayı', key: 'regQuantity', width: 30, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.BANK_NOTES)
      column_data.push({ header: 'Buraxılmış notların sayı', key: 'regQuantity', width: 30, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS)
      column_data.push({ header: 'Depozitar qəbzlərinin sayı', key: 'regQuantity', width: 30, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)
      column_data.push({ header: 'Daşınmaz əmlak sertifikatının sayı', key: 'regQuantity', width: 30, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS ||
      this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)
      column_data.push({ header: 'Vergi ödəyicisinin eyniləşdirmə nömrəsi (VÖEN)', key: 'tin', width: 40, height: 30 });
 
    column_data.push({ header: 'Emitentin hüquqi ünvanı', key: 'address', width: 40, height: 30 });
    column_data.push({ header: 'Qiymətli kağızın növü', key: 'securitiesType', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.BANK_NOTES ||
      this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS ||
      this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)
      column_data.push({ header: 'Qiymətli kağızın forması', key: 'securitiesForm', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.MUN_BONDS)
      column_data.push({ header: 'Təminat barədə məlumat', key: 'provideId', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.GOV_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.MUN_BONDS)
      column_data.push({ header: 'İstiqrazlarının dövriyyə müddəti', key: 'circulation', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.GOV_BONDS)
      column_data.push({ header: 'İstiqrazlarının buraxılacağı təqvim ili', key: 'releaseYear', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey != ColumnType.CERTIFICATES)
      column_data.push({ header: 'Buraxılışın valyutası', key: 'currency', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.MUN_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS ||
      this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)
      column_data.push({ header: 'Emissiyanın yekunları haqqında hesabatın təsdiq edilmə tarixi', key: 'approveDate', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.MUN_BONDS)
      column_data.push({ header: 'Yerləşdirilmiş istiqrazların sayı', key: 'placedQuantity', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS)
      column_data.push({ header: 'Yerləşdirilmiş depozitar qəbzlərinin sayı', key: 'placedQuantity', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)
      column_data.push({ header: 'Yerləşdirilmiş daşınmaz əmlak sertifikatlarının sayı', key: 'placedQuantity', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.CORP_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.GOV_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.MUN_BONDS ||
      this.stockForm.columnTypeKey == ColumnType.BANK_NOTES)
      column_data.push({ header: 'Tədavül müddəti', key: 'turnoverDate', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.GOV_BONDS)
      column_data.push({ header: 'İstiqrazların yerləşdirilmə üsulu', key: 'placeMethod', width: 40, height: 30 });

    if (this.stockForm.columnTypeKey == ColumnType.DEPOSITORY_RECEIPTS) {
      column_data.push({ header: 'Xarici emitentin adı', key: 'foreignIssuer', width: 40, height: 30 });
      column_data.push({ header: 'Xarici depozitarın adı', key: 'foreignDebtor', width: 40, height: 30 });
    }

    if (this.stockForm.columnTypeKey == ColumnType.CERTIFICATES)
    column_data.push({ header: 'Ödəniş tarixi', key: 'paymentDate', width: 40, height: 30 });

    let data = [];
    this.exportDataList.forEach(element => {
      data.push({
        registrationDate: this.datePipe.transform(element.registrationDate, 'dd.MM.yyyy'),
        name: element.name,
        registrationNum: element.registrationNum,
        regCost: element.regCost,
        regQuantity: element.regQuantity,

        tin: element.expands.tin,
        address: element.expands.address,
        securitiesType: element.expands.securitiesType,
        securitiesForm: element.expands.securitiesForm,
        provideId: element.expands.provideId,
        circulation: element.expands.circulation,
        releaseYear: element.expands.releaseYear,
        currency: element.expands.currency,
        approveDate: this.datePipe.transform(element.expands.approveDate, 'dd.MM.yyyy'),
        placedQuantity: element.expands.placedQuantity,
        turnoverDate: element.expands.turnoverDate,
        placeMethod: element.expands.placeMethod,
        foreignIssuer: element.expands.foreignIssuer,
        foreignDebtor: element.expands.foreignDebtor,
        paymentDate: this.datePipe.transform(element.expands.paymentDate, 'dd.MM.yyyy')
      });
    });
    this.excellService.exportAsXLSX(column_data, data, this.stockForm.columnTypeName);
  }

  public get columnType(): typeof ColumnType {
    return ColumnType;
  }
}
