import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { AsanDialog1Component } from 'src/app/cabinet/dialogs/asan-dialog-1/asan-dialog-1.component';
import { AsanDialog5Component } from 'src/app/cabinet/dialogs/asan-dialog-5/asan-dialog-5.component';
import { AppConfig } from 'src/app.config';

@Component({
  selector: 'app-required-contract',
  templateUrl: './required-contract.component.html',
  styleUrls: ['./required-contract.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class RequiredContractComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  contractDocumentForm: FormGroup;
  contractDocuments: any[];
  showSpinner: boolean;
  formData: FormData = new FormData;
  hasRequest: boolean;
  activePortal: any;
  nowDate: Date = new Date();
  currentDocs: any[] = [];
  isInValid: boolean = true;
  downloadSpinner: boolean;
  clickedKey: any;
  acceptTypes = "";
  acceptType = AppConfig.settings.fileSettings.acceptFiles;;

  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    this.getContractDoc();
    this.setForm();
    this.setAcceptTypes();
  }

  setAcceptTypes() {
    for (let index = 0; index < this.acceptType.length; index++) {
      const item = this.acceptType[index];
      if (this.acceptType.length - 1 >= index)
        this.acceptTypes += ("." + item + ", ")
    }
  }

  setForm() {
    this.contractDocumentForm = this.fb.group({
      file: [, Validators.required]
    });
  }

  signDoc(item) {
    console.log(item)
    this.dialog.open(AsanDialog1Component, {
      width: '1400px',
      position: {
        top: '20px'
      },
      data: item,
      autoFocus: false,
    });
  }

  formIsValid() {
    this.isInValid = false;
    console.log(this.contractDocuments.some(y => !y.signed && y.key != 'doc_in_20'))
    this.contractDocuments.forEach(x => {
      if (x.key != 'doc_in_20') {
        if (this.contractDocuments.some(y => !y.signed && y.key != 'doc_in_20')) {
          this.isInValid = true;
        }
      }
    })
  }

  getContractDoc() {
    if (this.id) {
      this.showSpinner = true;
      this.contractDocuments = [];
      this.cabinetService.getContractDoc(this.id).subscribe(
        res => {
          this.cabinetService.getTempFiles(this.id).subscribe(
            res_temp => {
              this.contractDocuments = res.response.docs;
              var i = 0;
              res.response.docs.forEach(res_data => {

                var has_signed = res_temp.data.find(x => x.key == res_data.key);
                if (has_signed) {
                  let blob_file = this.cabinetService.base64ToBlob(has_signed.file, "text/asciidoc", 512);
                  //console.log(blob_file)
                  var myFile = this.cabinetService.blobToFile(blob_file, has_signed.fileName);

                  this.contractDocuments[i].realBase64 = has_signed.file;
                  this.contractDocuments[i].signed = true;
                  this.contractDocuments[i].file = myFile;
                } else {
                  this.contractDocuments[i].signed = false;

                }
                i++;

              });
              console.log(this.contractDocuments)
              this.contractDocuments.push({
                'docName': 'Etibarnamə',
                'key': 'doc_in_20',
                'index': 1,
                'signed': false
              })
              this.formIsValid();
              this.activePortal = res.response.activePortal;
              this.showSpinner = false;
            },
          );



        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }

  getDocByKeyIndex(key, index) {
    return this.currentDocs.find(x => (x.key == 'doc_in_9' && x.key == key && x.index == index) || (x.key != 'doc_in_9' && x.key == key))?.file;
  }

  sendContractDoc() {
    if (this.isInValid) return;
    if (this.id) {
      this.hasRequest = true;
      this.cabinetService.sendContractDoc(this.formData, this.id).subscribe(
        res => {
          this.cabinetService.sendEmail(this.id, false).subscribe(
            res => { });
          window.location.reload();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.hasRequest = false;
        })
    }
  }

  downloadFile(path, key) {
    this.clickedKey = key;
    this.downloadSpinner = true;
    this.cabinetService.download(path).subscribe(
      res => {
        let file = this.cabinetService.base64ToBlob(res.data, res.contentType, 512);
        var fileURL = URL.createObjectURL(file);
        var savefile = document.createElement("a");
        savefile.download = res.name;
        savefile.href = fileURL;
        savefile.click();
        this.downloadSpinner = false;
      },
      err => {
        this.cabinetService.messageDialog(err.error.status.message, false);
        this.downloadSpinner = false;
      })
  }

  downloadSignedFile(file, docName) {
    var fileURL = URL.createObjectURL(file);
    var savefile = document.createElement("a");
    savefile.download = docName + ".adoc";
    savefile.href = fileURL;
    savefile.click();

  }

  getDetailSign(item) {
    this.dialog.open(AsanDialog5Component, {
      width: '650px',
      position: {
        top: '110px'
      },
      data: item,
      autoFocus: false,
    });

  }

  setApplyContractDoc(file, key) {
    if (!file) return;
    if (!this.cabinetService.checkFile(file)) return;
    let data = {
      key: key,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      fileExt: file.name.split('.').pop()
    };

    this.currentDocs = this.currentDocs.filter(x => x.key != key);
    this.currentDocs.push(data);

    this.currentDocs.forEach((val, i) => {
      this.formData.append(`docs[${i}].key`, val.key);
      this.formData.append(`docs[${i}].file`, val.file);
      this.formData.append(`docs[${i}].fileName`, val.fileName);
      this.formData.append(`docs[${i}].fileSize`, val.fileSize);
      this.formData.append(`docs[${i}].fileExt`, val.fileExt);
    });
    this.formIsValid();
  }
}

