<mat-spinner *ngIf="showSpinner" class="m-3" [diameter]="70" class="spinner">
</mat-spinner>
<div *ngIf="!showSpinner" class="container-fluid shadow-sm my-3">
  <div class="row">
    <div class="col-12 bg-white pt-4">
      <div class="table-responsive mt-3">
        <table mat-table [dataSource]="dataSource" mat-table class='custom-table border w-100' multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef class="text-center min-width-auto">
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <i class="fas fa-chevron-right fa-2x table-arrow cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element"
                [@expandArrowIcon]="expandedElement === element ? 'down' : 'right'">
              </i>
            </td>
          </ng-container>


          <!-- EXPAND -->
          <ng-container matColumnDef="expand">
            <td class="none" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsExpand.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                <div class="col-md-6 my-4">
                  <table class="mini-table w-100 h-100 border">
                    <tbody>
                      <tr>
                        <th class="bg-info text-white">Qiymətli kağızların sayı</th>
                        <td> <span>{{element.regQuantity}}</span> </td>
                      </tr>
                      <tr>
                        <th class="bg-info text-white">Qiymətli kağızların nominal dəyəri</th>
                        <td> <span>{{element.regCost}}</span> </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div class="col-md-6 my-4">
                  <table class="mini-table w-100 h-100 border">
                    <tbody>
                      <tr>
                        <th class="bg-info text-white">Qiymətli kağızların məcmu nominal dəyəri</th>
                        <td> <span>{{element.regTotalValue}}</span> </td>
                      </tr>
                      <tr>
                        <th class="bg-info text-white">Sənədin növü</th>
                        <td> <span>{{element.observe}}</span> </td>
                      </tr>

                    </tbody>
                  </table>


                </div>
              </div>
            </td>

          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Emitentin adı</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="tin">

            <th mat-header-cell *matHeaderCellDef>Vergi ödəyicisinin eyniləşdirmə nömrəsi (VÖEN)</th>
            <td mat-cell *matCellDef="let element"> {{element.tin}} </td>
          </ng-container>

          <ng-container matColumnDef="address">

            <th mat-header-cell *matHeaderCellDef>Emitentin hüquqi ünvanı</th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
          </ng-container>

          <ng-container matColumnDef="securitiesType">

            <th mat-header-cell *matHeaderCellDef>Qiymətli kağızın növü</th>
            <td mat-cell *matCellDef="let element"> {{element.securitiesType}} </td>
          </ng-container>

          <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>Buraxılışın valyutası</th>
            <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
          </ng-container>

          <ng-container matColumnDef="approveDate">
            <th mat-header-cell *matHeaderCellDef>Sənədin təsdiq edilmə tarixi</th>
            <td mat-cell *matCellDef="let element"> {{changeFormatDate(element.approveDate.toString())}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expand']" class="example-detail-row"></tr>
        </table>
        <mat-paginator #paginator [pageSize]="prospectusForm.limit" [pageSizeOptions]="[8, 16, 24]"
        [showFirstLastButtons]="true" [length]="totalSize" [pageIndex]="prospectusForm.skip - 1"
        (page)="pageChanged($event)">
        </mat-paginator>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end pb-3">
      <button (click)="export()" mat-button class="custom-btn">
        <i class="fas fa-file-export mr-1"></i>Export
      </button>
    </div>
  </div>
</div>
