import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/home/components/home/account-service';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-new-appeal',
  templateUrl: './new-appeal.component.html',
  styleUrls: ['./new-appeal.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppeal implements OnInit {
  showSpinner = false;
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  type = this.activatedRoute.snapshot.queryParamMap['params']['type'];
  status: any;
  constructor(
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private router : Router,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
  ) {
    this.cabinetService.clearStorage();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }
  }
  ngOnInit(): void {
    this.setTitle();
    this.getApplyStatus();
  }
  setTitle() {
    this.titleService.setTitle("QİYMƏTLİ KAĞIZLAR" + " | securities.e-cbar.az");
    const breadcrumb = { title: "Qi̇ymətli̇ Kağizlar", title2: "Yeni müraciət" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }



  getApplyStatus() {
    this.showSpinner = true;
    if (this.id) {
      this.cabinetService.getApplyStatus(this.id).subscribe(
        res => {
          this.status = res.response;
          if(this.status == 'cancel')
            this.router.navigate(['/appeals']);
          this.showSpinner = false;
          if(res.status.errorCode!=0){
            this.cabinetService.messageDialog(res.status.message, false);
          }
        },
        err => {
         if (err.error.status.errorCode != 0)
           this.cabinetService.messageDialog(err.error.status.message, false);
        })
    }else{
      this.showSpinner = false;
    }
  }
}
