import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-gpp-dialog',
  templateUrl: './gpp-dialog.component.html',
  styleUrls: ['./gpp-dialog.component.scss']
})
export class GppDialogComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  paymentgroup: FormGroup;
  nowDate = new Date();
  hasRequest: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public cabinetService: Cabinet,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
  }

  ngOnInit(): void {
    this.paymentgroup = this.fb.group({
      cardDigits: ['', [Validators.required]]
    });
  }
  pay() {
    if (this.paymentgroup.invalid) return;
    if (this.id) {
      this.hasRequest = true;
      this.cabinetService.sendPayment(this.paymentgroup.value, this.id).subscribe(
        res => {
          this.hasRequest = false;
          console.log(res)
          if(res.response!=null){
            window.location.href = res.response;
          }else{
            this.cabinetService.messageDialog("Sistemdə xəta baş verdi!", false);
          }
       
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.hasRequest = false;
        })
    }
  }
}
