<div class="container-fluid" style="margin-bottom: 20px;">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Müqaviləni imzala</h2>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button (click)="closeDialog()" class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>

    <mat-dialog-content class="mat-typography justify-center">
        <mat-spinner *ngIf="!src" style="margin: auto!important; transform: translateY(-120%)" [diameter]="70">
        </mat-spinner>
        <div class=" text-font center mx-auto   font-weight-bold">
            <div *ngIf="src" class="pdf-container">
                <iframe frameborder="0" style="width:100%!important; height:530px!important; " scrollbar="0" scrolling="auto" [src]="data_base64"></iframe>
            </div>

        </div>

        <div *ngIf="src" class="justify-center mx-auto   center font-weight-bold col-11">
            <mat-checkbox [(ngModel)]="checked">Müqavilə şərtlərini oxuduğumu, başa düşdüyümü və qəbul etdiyimi təsdiqləyirəm</mat-checkbox>
        </div>
        <!-- <mat-dialog-actions *ngIf="data_base64" align="end" class="mt-1 mr-2">
            <button mat-stroked-button class=" btn btn1-default my-2 " [disabled]="!checked" [mat-dialog-close]="true" (click)="confirm()"><span translate>Təsdiqlə</span></button>
        </mat-dialog-actions> -->

        <mat-dialog-actions *ngIf="data_base64" align="end" class="mt-2">
            <mat-spinner class="mr-3" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
            <button mat-stroked-button class="custom-btn my-2 mr-4" (click)="confirm()" [disabled]="!checked || hasRequest"><span translate>Təsdiqlə</span></button>
        </mat-dialog-actions>


    </mat-dialog-content>

</div>