<div *ngIf="elCounts>1" class="container-fluid">

    <p style="margin-bottom: 20px;">
        <i style="color: rgba(0, 0, 0, 0.54);">
            Zəhmət olmasa, sənədləri tək tək göndərərdiniz. Bir sənədi göndərdikdə, səhifə yenilənəcək və digər sənədi daha sonra yükləyə biləcəksiniz.
        </i>
    </p>
</div>


<!-- APPLY REQUIRED NEW DOC -->
<app-required-new-doc [status]="status" (addElCount)="addElCount($event)"></app-required-new-doc>
<!-- APPLY REQUIRED NEW DOC -->

<!-- APPLY WAITING DOCUMENT -->
<!-- <app-wait-document *ngIf="status=='checking'"> </app-wait-document> -->
<!-- APPLY WAITING DOCUMENT-->

<!-- APPLY MUST CORRECT -->
<app-must-correct *ngIf="status=='send_edition' || 'short_cancel'" (addElCount)="addElCount($event)"> </app-must-correct>
<!-- APPLY MUST CORRECT -->

<!-- APPLY REQUIRED DOCUMENT -->
<app-required-document *ngIf="status=='wait_for_doc'"> </app-required-document>
<!-- <app-required-document *ngIf="status=='send_edition'"> </app-required-document> -->
<!-- APPLY REQUIRED DOCUMENT -->

<!-- APPLY WAITING CONTRACT -->
<app-wait-contract *ngIf="status=='checking_contract'"> </app-wait-contract>
<!-- APPLY WAITING CONTRACT-->

<!-- APPLY REQUIRED CONTRACT -->
<app-required-contract *ngIf="status=='wait_sign'"> </app-required-contract>
<!-- APPLY REQUIRED CONTRACT -->

<!-- APPLY SUCCESS STEP -->
<app-success-step [status]="status" *ngIf="status=='accept_success' || 'short_cancel'"> </app-success-step>
<!-- APPLY SUCCESS STEP -->