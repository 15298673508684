<form style="padding: 0!important;">
  <h1 class="m-3">Məlumat</h1>
  <div>
    <div class="row mx-0">
      <div class="col-12" *ngIf="data.Text">
        <p class="m-2">{{data.Text}}</p>
      </div>
      <div class="m-3" class="col-12" *ngIf="data.obj_message">
        <div *ngFor="let i of getData(data.obj_message)">
          <span>{{i}}</span>
          <hr />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data.Text" align="end" class="m-3">
    <button mat-stroked-button class="custom-btn" [mat-dialog-close]="true" (click)="isRefresh(data.isRefresh)">
      <span>Bağla</span>
    </button>
  </div>
  <div *ngIf="data.obj_message" align="end" class="m-3">
    <button mat-stroked-button class="custom-btn" [mat-dialog-close]="true"
      (click)="reload()"><span>Bağla</span></button>
  </div>
</form>