import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-meas-dialog',
  templateUrl: './meas-dialog.component.html',
  styleUrls: ['./meas-dialog.component.scss']
})
export class MeasDialogComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public activatedRoute: ActivatedRoute, public dialog: MatDialog, public cabinetService: Cabinet) {
    this.cabinetService.clearStorage();
   }

  ngOnInit(): void {
  }
  getData(data){
    return data.split('&');
 }

 reload(){
     window.location.reload();
 }
 
 isRefresh(value: boolean)
 {
     if(value)
     {
         window.location.reload();
     }
 }
 confirm(){
  console.log(this.id)
  this.cabinetService.sendMeas(this.id, this.data.doc_key).subscribe(data => {
    if (data.status && data.status.errorCode == 0) {
      this.cabinetService.messageDialog(this.data.doc_name+" MEAS-da açıqlanmaq üçün təqdim edilmişdir.", true);
   }});
    
  
}
 cancel(){
  this.cabinetService.messageDialog("Müraciət dayandırıldı!", true);
}
}
