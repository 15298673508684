<div class="container-fluid border shadow-sm my-3 ">
    <div class="row">
        <div class="col-12 bg-white px-4 pt-4 pb-5 ">
            <!-- APPLY UPPER -->
            <app-appeal-sent-upper></app-appeal-sent-upper>
            <!-- APPLY UPPER -->

            <!-- APPLY BOTTOM -->
            <app-appeal-sent-bottom *ngIf="status!='refused'" [status]="status"> </app-appeal-sent-bottom>
            <!-- APPLY BOTTOM -->
            <div class="text-box p-3 mb-5" *ngIf="status=='refused'">
                <span class="d-inline-block">Müraciət dayandırılıb.</span>
            </div>
        </div>
    </div>


</div>