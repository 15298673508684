<div class="container" *ngIf="!qkr_home_page">
    <div class="col-md-12 bg-white pb-5 pt-5 home_container">
        <mat-spinner style="margin:auto!important;" [diameter]="70">
        </mat-spinner>
    </div>
</div>
<div class="container" *ngIf="qkr_home_page">
    <div class="row card mt-4">
        <div class="title col-md-12">{{qkr_home_page.title}}</div>
        <div class="col-md-12 col-xs-12 text">
            <p [innerHTML]="qkr_home_page.description" class="p"></p>

        </div>
    </div>

    <div class="row card mt-4">
        <div class="d-flex justify-content-between">
            <a (click)="searchByEmitentName()" style="color: #111111;text-decoration:none; margin-left: 70px;cursor:pointer" class="card-title mt-2 pl-5"><img height="20" style="margin-top:2px; margin-right: 5px;" src="../../../../../assets/images/search_logo.png">Emİtent adına ƏsasƏn Axtarış</a>
            <a (click)="searchByTIN()" style="color: #111111;text-decoration:none; margin-right: 70px; cursor:pointer" class="card-title mt-2 pr-5"><img height="20" style="margin-top:2px; margin-right: 5px;" src="../../../../../assets/images/search_logo.png">vöen - Ə ƏsasƏn Axtarış</a>
        </div>

    </div>
</div>