<div class="conainer-fluid">
    <div class="row">
        <div class="col-12" *ngFor="let item of corectDocuments; let i = index">
            <form [formGroup]="waitDocumentForm" *ngIf="waitDocumentForm">
                <div class="row mt-3">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-2">
                                <img *ngIf="i == 0" src="./assets/images/timeline-red.svg" alt="">
                            </div>
                            <div class="col-10">
                                <h6 *ngIf="i == 0">{{activePortal}} <span class="text-info">{{item.date | date: 'dd.MM.yyyy'}}</span></h6>
                                <p [innerHTML]="item.comment"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="w-100">{{item.docName}}</label>
                        <div class="custom-file">
                            <input type="file" [accept]="acceptTypes" class="custom-file-input" #file formControlName="file" role='button' id="customFileLangHTML" (change)="setApplyCorrectDoc($event.target.files[0], item.key, item.isSystem)">
                            <span class="custom-file-label" *ngIf="!file.value else elseBlockShow">Faylı seçin  və ya buraya
                                    sürükləyib buraxın
                                </span>
                            <ng-template #elseBlockShow>
                                <span class="custom-file-label check">Faylı seçin  və ya buraya
                                        sürükləyib buraxın</span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex c-mt">
                        <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="file.value && hasRequest && item.key == sentKey"></mat-spinner>
                        <div>
                            <button (click)="sendCorrectDoc(item.key)" mat-button class="custom-btn" [disabled]="(hasRequest && item.key == sentKey) || !file.value">
                                <span>Göndər</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>