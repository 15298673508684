import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from '../shared/base.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from 'src/app.config';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HomeService extends BaseService {
  myAppUrl: any;
  constructor(private http: HttpClient,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog) {
    super();
  }

  //start lookup

  getIssuers(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/get-issuers';
    return this.http.get<any>(url);
  }

  getColumnTypes(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/lookup/get-column-types';
    return this.http.get<any>(url);
  }

  getProspectusList(isExport:boolean,model:any): Observable<any> {

    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + '/Prospectus/get-prospectuses?isExport='+isExport;
    return this.http.post<any>(url,model);
  }

  changeFormatDate(date: string | number | Date | any) {
    if(date.toString().includes(".")){
      date = date.toString().split("/").join(".").split(".").reverse().join("-");
      let new_date = new Date(date);
      return this.datePipe.transform(new_date, 'dd.MM.yyyy')
    }else{
      let new_date = date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3");
      return this.datePipe.transform(new_date, 'dd.MM.yyyy')
    }
  }
}
