import { Component, OnInit, Injectable, ViewChild, Input, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { placementMethodsConst, qkObservesConst } from 'src/app/models/constants';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-disable-apply-1',
  templateUrl: './disable-apply-1.component.html',
  styleUrls: ['./disable-apply-1.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class DisableApply1Component implements OnInit {
  disableApply: any;
  paymentTypes: any;
  applyForm: FormGroup;
  @Input("disableApply") set applySet(value: any) {
    this.disableApply = value;
    this.setApplyForm();
    this.getPaymentTypes();

  }

  constructor(
    public fb: FormBuilder,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();

  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
      if (e.key === 'Tab') {
          return false;
      }

      return true;
  }
  onKeyUp (event) {
   event.target.disabled=true;
   
     event.preventDefault()
   
 }
 
  ngOnInit(): void {

  }


  setApplyForm() {

    this.applyForm = this.fb.group({
      applyType: [this.disableApply?.applyType],
      qkType: [this.disableApply?.qkType],
      qkObserve: [this.disableApply?.qkObserve],
      baseEmission: [this.disableApply?.baseEmission],
      qkAnderrayter: [this.disableApply?.qkAnderrayter],
      qkPlacement: [this.disableApply?.qkPlacement],
      purposeApply: [this.disableApply?.purposeApply],
      paymentTypeId: [this.disableApply?.paymentTypeId],
      issueAmount: [this.disableApply?.issueAmount]
    });

    this.applyForm.get("applyType").disable();
    this.applyForm.get("qkType").disable();
    this.applyForm.get("qkAnderrayter").disable();
    this.applyForm.get("qkPlacement").disable();
    this.applyForm.get("purposeApply").disable();
    this.applyForm.get("paymentTypeId").disable();
    this.applyForm.get("qkObserve").disable();
    this.applyForm.get("issueAmount").disable();
    this.applyForm.get("baseEmission").disable();
  }


  getPaymentTypes() {
    this.cabinetService.getPaymentTypes().subscribe((data => {
      this.paymentTypes = data.result;
    }));
  }
}
