<mat-spinner *ngIf="showSpinner" class="m-3" [diameter]="70" class="spinner">
</mat-spinner>
<div *ngIf="!showSpinner" class="container-fluid shadow-sm my-3">
    <div class="row">
        <div class="col-12 bg-white pt-4">
            <div class="table-responsive mt-3">
                <table mat-table [dataSource]="ELEMENT_DATA" mat-table class='custom-table border w-100'
                    multiTemplateDataRows>
                    <ng-container matColumnDef="arrow">
                        <th mat-header-cell *matHeaderCellDef class="text-center min-width-auto">
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center">
                            <i class="fas fa-chevron-right fa-2x table-arrow cursor-pointer" (click)="
                    expandedElement = expandedElement === element ? null : element
                  " [@expandArrowIcon]="
                    expandedElement === element ? 'down' : 'right'
                  "></i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                        <td class="none" mat-cell *matCellDef="let element"
                            [attr.colspan]="displayedColumnsExpand.length">
                            <div class="example-element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="col-md-6 my-4">
                                    <table [dataSource]="dataSource2" class="mini-table w-100 h-100 border">
                                        <tbody>
                                            <!-- <tr>
                                                <th class="bg-info text-white">Emitentin təşkilati-hüquqi forması</th>
                                                <td> <span>{{element.expands.structure}}</span> </td>
                                            </tr> -->
                                            <tr *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                       columnTypeKey == columnType.DEPOSITORY_RECEIPTS ||
                                                       columnTypeKey == columnType.CERTIFICATES">
                                                <th class="bg-info text-white">Vergi ödəyicisinin eyniləşdirmə nömrəsi
                                                    (VÖEN)</th>
                                                <td> <span>{{element.expands.tin}}</span> </td>
                                            </tr>
                                            <tr>
                                                <th class="bg-info text-white">Emitentin hüquqi ünvanı</th>
                                                <td> <span>{{element.expands.address}}</span> </td>
                                            </tr>

                                            <!-- <tr>
                                                <th class="bg-info text-white">Buraxılış haqqında qərarın tarixi</th>
                                                <td> <span>{{element.expands.decisionDate | date: 'dd.MM.yyyy'}}</span> </td>
                                            </tr> -->
                                            <!-- <tr>
                                                <th class="bg-info text-white">İlkin buraxılışın səbəbi</th>
                                                <td> <span>{{element.expands.initialReason}}</span> </td>
                                            </tr> -->
                                            <!-- <tr>
                                                <th class="bg-info text-white">Dəyişikliklə bağlı buraxılışın səbəbi
                                                </th>
                                                <td> <span>{{element.expands.purposeIssue}}</span> </td>
                                            </tr> -->
                                            <!-- <tr>
                                                <th class="bg-info text-white">Səhmin növü</th>
                                                <td> <span>{{element.expands.stockType}}</span> </td>
                                            </tr> -->
                                            <tr>
                                                <th *ngIf="columnTypeKey == columnType.CORP_BONDS"
                                                    class="bg-info text-white">Qiymətli kağızın növü</th>
                                                <th *ngIf="columnTypeKey == columnType.GOV_BONDS || columnTypeKey == columnType.MUN_BONDS"
                                                    class="bg-info text-white">İstiqrazların növü</th>
                                                <th *ngIf="columnTypeKey == columnType.BANK_NOTES"
                                                    class="bg-info text-white">Qiymətli kağızın adı</th>
                                                <th *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS"
                                                    class="bg-info text-white">Baza aktivi</th>
                                                <td *ngIf="columnTypeKey != columnType.CERTIFICATES">
                                                    <span>{{element.expands.securitiesType}}</span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                       columnTypeKey == columnType.BANK_NOTES ||
                                                       columnTypeKey == columnType.DEPOSITORY_RECEIPTS ||
                                                       columnTypeKey == columnType.CERTIFICATES">
                                                <th *ngIf="columnTypeKey == columnType.CORP_BONDS || columnTypeKey == columnType.CERTIFICATES"
                                                    class="bg-info text-white">Qiymətli kağızın forması</th>
                                                <th *ngIf="columnTypeKey == columnType.BANK_NOTES"
                                                    class="bg-info text-white">Notların növü</th>
                                                <th *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS"
                                                    class="bg-info text-white">Depozitar qəbzlərinin forması</th>
                                                <td> <span>{{element.expands.securitiesForm}}</span> </td>
                                            </tr>
                                            <tr *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                       columnTypeKey == columnType.MUN_BONDS">
                                                <th class="bg-info text-white">Təminat barədə məlumat</th>
                                                <td> <span>{{element.expands.provideId}}</span> </td>
                                            </tr>
                                            <tr *ngIf="columnTypeKey == columnType.GOV_BONDS ||
                                                       columnTypeKey == columnType.MUN_BONDS">
                                                <th class="bg-info text-white">İstiqrazlarının dövriyyə müddəti</th>
                                                <td> <span>{{element.expands.circulation}}</span> </td>
                                            </tr>
                                            <tr *ngIf="columnTypeKey == columnType.GOV_BONDS">
                                                <th class="bg-info text-white">İstiqrazlarının buraxılacağı təqvim ili
                                                </th>
                                                <td> <span>{{element.expands.releaseYear | date: 'dd.MM.yyyy'}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6 my-4">
                                    <table [dataSource]="dataSource2" class="mini-table w-100 h-100 border">
                                        <tbody>
                                            <tr *ngIf="columnTypeKey != columnType.CERTIFICATES">
                                                <th *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                           columnTypeKey == columnType.BANK_NOTES"
                                                    class="bg-info text-white">Buraxılışın valyutası</th>

                                                <th *ngIf="columnTypeKey == columnType.GOV_BONDS ||
                                                           columnTypeKey == columnType.MUN_BONDS"
                                                    class="bg-info text-white">İstiqrazların valyutası</th>

                                                <th *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS"
                                                    class="bg-info text-white">Depozitar qəbzlərinin valyutası</th>

                                                <td> <span>{{element.expands.currency}}</span> </td>
                                            </tr>

                                            <!-- <tr>
                                                <th class="bg-info text-white">Qeydiyyata alınmış səhmlərin məcmu
                                                    nominal dəyəri</th>
                                                <td> <span>{{element.expands.regTotalValue}}</span> </td>
                                            </tr> -->

                                            <tr *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                       columnTypeKey == columnType.MUN_BONDS ||
                                                       columnTypeKey == columnType.DEPOSITORY_RECEIPTS ||
                                                       columnTypeKey == columnType.CERTIFICATES">
                                                <th class="bg-info text-white">Emissiyanın yekunları haqqında hesabatın
                                                    təsdiq edilmə tarixi</th>
                                                <td> <span>{{element.expands.approveDate | date: 'dd.MM.yyyy'}}</span>
                                                </td>
                                            </tr>

                                            <tr *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                       columnTypeKey == columnType.MUN_BONDS ||
                                                       columnTypeKey == columnType.DEPOSITORY_RECEIPTS ||
                                                       columnTypeKey == columnType.CERTIFICATES">

                                                <th *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                           columnTypeKey == columnType.MUN_BONDS"
                                                    class="bg-info text-white">
                                                    Yerləşdirilmiş istiqrazların sayı</th>

                                                <th *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS"
                                                    class="bg-info text-white"> Yerləşdirilmiş depozitar qəbzlərinin
                                                    sayı</th>

                                                <th *ngIf="columnTypeKey == columnType.CERTIFICATES"
                                                    class="bg-info text-white"> Yerləşdirilmiş daşınmaz əmlak
                                                    sertifikatlarının sayı</th>
                                                <td> <span>{{element.expands.placedQuantity}}</span> </td>
                                            </tr>

                                            <!-- <tr>
                                                <th class="bg-info text-white">Yerləşdirilmiş səhmlərin məcmu nominal
                                                    dəyəri</th>
                                                <td> <span>{{element.expands.placedTotalValue}}</span> </td>
                                            </tr> -->

                                            <!-- <tr>
                                                <th class="bg-info text-white">Yerləşdirmənin başlanma tarixi</th>
                                                <td> <span>{{element.expands.startDate | date: 'dd.MM.yyyy'}}</span> </td>
                                            </tr>

                                            <tr>
                                                <th class="bg-info text-white">Yerləşdirmənin başa çatma tarixi</th>
                                                <td> <span>{{element.expands.endDate | date: 'dd.MM.yyyy'}}</span> </td>
                                            </tr> -->

                                            <!-- <tr>
                                                <th class="bg-info text-white">Çek hərraclarında dəyişiklik</th>
                                                <td> <span>{{element.expands.checkChange}}</span> </td>
                                            </tr> -->

                                            <tr *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                                       columnTypeKey == columnType.GOV_BONDS ||
                                                       columnTypeKey == columnType.MUN_BONDS||
                                                       columnTypeKey == columnType.BANK_NOTES">
                                                <th class="bg-info text-white">Tədavül müddəti</th>
                                                <td> <span>{{element.expands.turnoverDate}}&nbsp;{{element.expands.timeType}}</span>
                                                </td>
                                            </tr>

                                            <tr *ngIf="columnTypeKey == columnType.GOV_BONDS">
                                                <th class="bg-info text-white">İstiqrazların yerləşdirilmə üsulu</th>
                                                <td> <span>{{element.placeMethod}}</span> </td>
                                            </tr>

                                            <tr *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS">
                                                <th class="bg-info text-white">Xarici emitentin adı</th>
                                                <td> <span>{{element.expands.foreignIssuer}}</span> </td>
                                            </tr>
                                            <tr *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS">
                                                <th class="bg-info text-white">Xarici depozitarın adı</th>
                                                <td> <span>{{element.expands.foreignDebtor}}</span> </td>
                                            </tr>
                                            <tr *ngIf="columnTypeKey == columnType.CERTIFICATES">
                                                <th class="bg-info text-white">Ödəniş tarixi</th>
                                                <td> <span>{{element.expands.paymentDate | date: 'dd.MM.yyyy'}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="reg_date">
                        <th mat-header-cell *matHeaderCellDef>
                            <span
                                *ngIf="!(columnTypeKey == columnType.DEPOSITORY_RECEIPTS || columnTypeKey == columnType.CERTIFICATES)">Buraxılışın
                                qeydiyyat tarixi</span>
                            <span
                                *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS || columnTypeKey == columnType.CERTIFICATES">Qeydiyyat
                                tarixi</span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.registrationDate | date: 'dd.MM.yyyy'}} </td>
                    </ng-container>


                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Emitentin adı</th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>


                    <ng-container matColumnDef="reg_num">
                        <th mat-header-cell *matHeaderCellDef>Qiymətli kağızların beynəlxalq eyniləşdirmə nömrəsi (ISIN)
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.registrationNum}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="place_method">
                        <th mat-header-cell *matHeaderCellDef>Yerləşdirmə üsulu</th>
                        <td mat-cell *matCellDef="let element"> {{element.placeMethod}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="reg_cost" *ngIf="!(columnTypeKey == columnType.DEPOSITORY_RECEIPTS ||
                    columnTypeKey == columnType.CERTIFICATES)">
                        <th mat-header-cell *matHeaderCellDef>
                            <span *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                         columnTypeKey == columnType.MUN_BONDS">
                                Qeydiyyata alınmış istiqrazların nominal dəyəri
                            </span>
                            <span *ngIf="columnTypeKey == columnType.GOV_BONDS">
                                İstiqrazların nominal dəyəri
                            </span>
                            <span *ngIf="columnTypeKey == columnType.BANK_NOTES">
                                Notların nominal dəyəri
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.regCost}} </td>
                    </ng-container>

                    <ng-container matColumnDef="reg_quantity">
                        <th mat-header-cell *matHeaderCellDef>
                            <span *ngIf="columnTypeKey == columnType.CORP_BONDS ||
                                         columnTypeKey == columnType.MUN_BONDS">
                                Qeydiyyata alınmış istiqrazların sayı
                            </span>
                            <span *ngIf="columnTypeKey == columnType.GOV_BONDS">
                                İstiqrazların sayı
                            </span>
                            <span *ngIf="columnTypeKey == columnType.BANK_NOTES">
                                Buraxılmış notların sayı
                            </span>
                            <span *ngIf="columnTypeKey == columnType.DEPOSITORY_RECEIPTS">
                                Depozitar qəbzlərinin sayı
                            </span>
                            <span *ngIf="columnTypeKey == columnType.CERTIFICATES">
                                Daşınmaz əmlak sertifikatının sayı
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.regQuantity}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="release_status">
                        <th mat-header-cell *matHeaderCellDef>Buraxılışın statusu</th>
                        <td mat-cell *matCellDef="let element"> {{element.releaseStatus}} </td>
                    </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expand']" class="example-detail-row"></tr>
                </table>
                <mat-paginator #paginator [pageSize]="stockForm.limit" [pageSizeOptions]="[8, 16, 24]"
                    [showFirstLastButtons]="true" [length]="totalSize" [pageIndex]="stockForm.skip - 1"
                    (page)="pageChanged($event)">
                </mat-paginator>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-end pb-3">
            <button (click)="export()" mat-button class="custom-btn">
                <i class="fas fa-file-export mr-1"></i>Export
            </button>
        </div>
    </div>
</div>

<div class="spinner" *ngIf="!dataSource" align="center">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
</div>
