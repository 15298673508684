import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ColumnType } from 'src/app/models/constants';
import { HomeService } from '../../../home/home.service';
import { BondsComponent } from '../../components/registry/bonds/bonds.component';
import { StocksComponent } from '../../components/registry/stocks/stocks.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  title: "Qiymətli Kağızların Dövlət Reyestri Sistemi";
  title2: "Axtarış";
  columnTypeModel;
  prospectusModel;
  searchForm: FormGroup

  emitentInfos = [
    {
      'key': 'TIN',
      'name': 'VÖEN-ə görə'
    },
    {
      'key': 'NAME',
      'name': 'Emitentin adına görə'
    }
  ];

  issuers = [];
  columnTypes = [];
  seachPetition: any;
  columnTypeKey: any;
  isSearch: boolean;
  @ViewChild(StocksComponent) childStock: StocksComponent;
  @ViewChild(BondsComponent) childBond: BondsComponent;
  filteredIssuers: any[];
  constructor(private titleService: Title,
    private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.setSearchForm();
    this.setTitleAndBredcrumb();
    this.getIssuers();
    this.getColumnTypes();
    this.searchForm.valueChanges.subscribe(x => {
      this.isSearch = false;
    })
  }

  private setTitleAndBredcrumb(): void {
    this.titleService.setTitle('Axtarış' + ' | securities.e-cbar.az');
    const breadcrumb = { title: this.title, title2: this.title2 };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }

  setSearchForm() {
    let by = this.activatedRoute.snapshot.queryParamMap['params']['by'];
    let searchBy = by == 'EMITENT' ? 'NAME' : 'TIN';
    this.searchForm = this.fb.group({
      issuerInformation: [searchBy, Validators.required],
      issuerId: [""],
      tin: [,],
      columnTypeId: ["", Validators.required],
      columnTypeKey: [, Validators.required],
      columnTypeName: [, Validators.required],
      skip: [0,],
      limit: [8,],
    });
    this.setValidatorsTinName(searchBy);
  }

  someStupidFunction() {
    console.log('yeeey');
  }
  setValidatorsTinName(searchBy) {
    if (searchBy == 'NAME')
    {
      this.searchForm.get('issuerId').setValidators(Validators.required);
      this.searchForm.get('tin').clearValidators();
      this.searchForm.get('tin').updateValueAndValidity();
    }
    else if (searchBy == 'TIN')
    {
      this.searchForm.get('tin').setValidators(Validators.required);
      this.searchForm.get('issuerId').clearValidators();
      this.searchForm.get('issuerId').updateValueAndValidity();
    }
  }

  getIssuers() {
    this.homeService.getIssuers().subscribe(result => {
      this.issuers = result.result;
      this.filteredIssuers = this.issuers;
    })
  }

  getColumnTypes() {
    this.homeService.getColumnTypes().subscribe(result => {
      this.columnTypes = result.result;
    })
  }

  setColumnTypeKey(e) {
    this.isSearch = false;
    let id = e.value;
    this.columnTypeKey = this.columnTypes.find(x => x.id == id)?.key;
    let columnTypeName = this.columnTypes.find(x => x.id == id)?.name;
    console.log(this.columnTypeKey);

    this.searchForm.get('columnTypeKey').setValue(this.columnTypeKey);
    this.searchForm.get('columnTypeName').setValue(columnTypeName);
  }

  setIssuerInformation(e) {
    let key = e.value;
    this.setValidatorsTinName(key);
  }

  search() {
    this.isSearch = true;
    if (this.columnTypeKey == this.columnType.STOCK)
      this.childStock?.filter();
    else if (
      this.columnTypeKey == this.columnType.CORP_BONDS ||
      this.columnTypeKey == this.columnType.GOV_BONDS ||
      this.columnTypeKey == this.columnType.MUN_BONDS ||
      this.columnTypeKey == this.columnType.BANK_NOTES ||
      this.columnTypeKey == this.columnType.DEPOSITORY_RECEIPTS ||
      this.columnTypeKey == this.columnType.CERTIFICATES) {
      this.childBond?.setColumn();
      this.childBond?.filter();
    }
  }

  public get columnType(): typeof ColumnType {
    return ColumnType;
  }
}
