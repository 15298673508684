<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">

            <h2>Müqaviləni imzala</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button (click)="closeDialog()" class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="rel_div">

        <img class="asan-pic" src="../../../../assets/images/asanSign.png" />
        <div class=" text-font font-weight-bold">
            <div style="font-size: 20px; padding-left: 38px;" class="col-md-12  pt-3  font-weight-bold">
                <p>Asan İmzada qeydiyyatda olan nömrə və istifadəçinin id məlumatını daxil edin:</p>

            </div>

            <form action="" [formGroup]="asanForm" class="pl-4">
                <div class="pt-2">

                    <div class=" col-12  col-md-12 col-xs-12 " [ngClass]="{'invalid-input': asanForm.controls.phoneNumber.touched && asanForm.controls.phoneNumber.invalid }">
                        <label class="gpp-head">Telefon nömrəsi: </label>
                        <mat-form-field style="width: 380px">
                            <input prefix="+994" mask="(00) 000 00 00" matInput type="text" formControlName="phoneNumber">
                        </mat-form-field>
                        <div style="margin-left: 160px;" class="text-danger pr-4" *ngIf="asanForm.controls.phoneNumber.invalid && (asanForm.controls.phoneNumber.dirty || asanForm.controls.phoneNumber.touched) && asanForm.controls.phoneNumber.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>

                    <div class=" col-12  col-md-12 col-xs-12  " [ngClass]="{'invalid-input': asanForm.controls.userId.touched && asanForm.controls.userId.invalid }">
                        <label class="gpp-head">İstifadəçi ID-si: </label>
                        <mat-form-field style="width: 380px">
                            <input minlength="6" maxlength="6" matInput type="text" formControlName="userId">
                        </mat-form-field>
                        <div style="margin-left: 160px;" class="text-danger pr-4" *ngIf="asanForm.controls.userId.invalid && (asanForm.controls.userId.dirty || asanForm.controls.userId.touched) && asanForm.controls.userId.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>

                <mat-dialog-actions align="end" class="mt-2">
                    <mat-spinner class="mr-3" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
                    <button mat-stroked-button class="custom-btn my-2 mr-4" (click)="asanSign()" [disabled]="asanForm.invalid || hasRequest"><span translate>İmzala</span></button>
                </mat-dialog-actions>
            </form>



        </div>


    </mat-dialog-content>

</div>