import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from 'src/app/shell/shell.service';
import { RedirectComponent } from './redirect/redirect.component';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { EmissionHomeComponent } from './emission-home/emission-home.component';
import { UserManualComponent } from 'src/app/cabinet/components/user-manual/user-manual.component';
import { Appeals } from 'src/app/cabinet/components/appeals/appeals.component';
import { NewAppeal } from 'src/app/cabinet/components/new-appeal/new-appeal.component';
import { NewAppealList } from 'src/app/cabinet/components/new-appeal-list/new-appeal-list.component';
import { ProfileComponent } from 'src/app/cabinet/components/profile/profile.component';
import { AppealDetail } from 'src/app/cabinet/components/appeal-detail/appeal-detail.component';
import { RegisterCompleteComponent } from './register-complete/register-complete.component';
import { AccessListComponent } from 'src/app/cabinet/components/access-list/access-list.component';
import { NewAccessComponent } from 'src/app/cabinet/components/new-access/new-access.component';
import { NewAppealDefault } from 'src/app/cabinet/components/new-appeal-default/new-appeal-default.component';
import { ContactDetailsComponent } from 'src/app/cabinet/components/contact-details/contact-details.component';
import { HomeComponent } from './home/home/home.component';
import { AppealsUnderwriter } from 'src/app/cabinet/components/appeals-underwriter/appeals-underwriter.component';
import { ProfileUnderwriterComponent } from 'src/app/cabinet/components/profile-underwriter/profile-underwriter.component';
import { RegisterCompleteUnderwriterComponent } from './register-complete-underwriter/register-complete-underwriter.component';
import { SearchComponent } from '../../search/search/search.component';
import { ReyestrHomeComponent } from '../registry/reyestr-home/reyestr-home/reyestr-home.component';
import { OauthRedirectComponent } from './oauth-redirect/oauth-redirect.component';



const routes: Routes = [
  Shell.childRoutes([
    {path: '', redirectTo: '/home', pathMatch: 'full' },
    {path: 'oauth-redirect', component: OauthRedirectComponent },
    {
      path: 'home',
      component: HomeComponent,
      data: {
        breadcrumb: []
      }
    },
    {
      path: 'emissionHome',
      component: EmissionHomeComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/emissionHome',
          }
        ],
      },
    },
    {
      path: 'reyestrHome',
      component: ReyestrHomeComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/reyestrHome',
          }
        ],
      },
    },
    {
      path: 'redirect',
      component: RedirectComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/redirect',
          },
          {
            label: '{{title2}}',
            url: '/redirect',
          },
        ],
      },
    },
    {
      path: 'login',
      component: LoginComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title2}}',
            url: '/login',
          },
        ],
      },
    },
    {
      path: 'register-complete',
      component: RegisterCompleteComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/emissionHome',
          },
          {
            label: '{{title2}}',
            url: '/register-complete',
          },
        ],
      },
    },
    {
      path: 'register-complete-underwriter',
      component: RegisterCompleteUnderwriterComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/emissionHome',
          },
          {
            label: '{{title2}}',
            url: '/register-complete-underwriter',
          },
        ],
      },
    },
    {
      path: 'search',
      component: SearchComponent,
      data: {
        breadcrumb: [
          {
            label: 'Qiymətli Kağızların Dövlət Reyestri Sistemi',
            url: '/reyestrHome',
          },
          {
            label: 'Axtarış',
            url: '/search',
          },
        ],
      },
    },
  ]),

  Cabinet.childRoutes([
    { path: '', redirectTo: '/appeals', pathMatch: 'full' },
    {
      path: 'appeals',
      component: Appeals,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/appeals',
          },
        ],
      },
    },
    {
      path: 'appeals-underwriter',
      component: AppealsUnderwriter,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/appeals-underwriter',
          },
        ],
      },
    },
    {
      path: 'new-appeal',
      component: NewAppeal,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/new-appeal-list ',
          },
          {
            label: '{{title2}}',
            url: '/new-appeal/:type',
          },
        ],
      },
    },
    {
      path: 'appeal-detail',
      component: AppealDetail,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/appeals',
          },
          {
            label: '{{title2}}',
            url: '/appeal-detail',
          },
        ],
      },
    },
    {
      path: 'appeal-detail',
      component: AppealDetail,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/appeals',
          },
          {
            label: '{{title2}}',
            url: '/appeal-detail',
          },
        ],
      },
    },
    {
      path: 'access-list',
      component: AccessListComponent,
      data: {
        breadcrumb: [
          {
            label: 'Səlahiyyətlərin ötürülməsi',
            url: '/access-list',
          },
        ],
      },
    },
    {
      path: 'new-access',
      component: NewAccessComponent,
      data: {
        breadcrumb: [
          {
            label: 'Səlahiyyətlərin ötürülməsi',
            url: '/access-list',
          },
          {
            label: 'Yeni səlahiyyət',
            url: '/new-access',
          },
        ],
      },
    },
    {
      path: 'new-appeal-list',
      component: NewAppealList,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/new-appeal-list',
          },
          {
            label: '{{title2}}',
            url: '/new-appeal-list',
          },
        ],
      },
    },

    {
      path: 'user-manual',
      component: UserManualComponent,
      //  canActivate: [AuthGuardService],
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/user-manual',
          },
        ],
      },
    },

    {
      path: 'contact-details',
      component: ContactDetailsComponent,
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/contact-details',
          },
        ],
      },
    },
    {
      path: 'profile',
      component: ProfileComponent,
      //  canActivate: [AuthGuardService],
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/profile',
          },
        ],
      },
    },
    {
      path: 'profile-underwriter',
      component: ProfileUnderwriterComponent,
      //  canActivate: [AuthGuardService],
      data: {
        breadcrumb: [
          {
            label: '{{title}}',
            url: '/profile-underwriter',
          },
        ],
      },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  // providers: [HomeService]
})
export class HomeRoutingModule { }
