<footer>
    <div class="container">
        <div class="row">
            <!-- <h2 class="separator"></h2> -->
            <div class="col-12">
                <p class="p_foot text-center" >&copy;
                  <span>{{date| date: 'yyyy' }}. Bütün hüquqlar qorunur.</span>
                </p>
            </div>
        </div>
    </div>
</footer>
