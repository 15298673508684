<div class="container overflow-hidden">
    <div class="row mx-auto card mt-4">
        <p class="title col-md-12 ">{{title2}}</p>
        <div class="col-md-12 col-xs-12 ">
            <form style="padding-left: 260px; padding-top: 20px" action="" class="customForm form-row" [formGroup]="registerForm">
                <div style="height: 50px;" class=" mb-2 col-12 row   " [ngClass]="{'invalid-input': registerForm.controls.orgId.touched && registerForm.controls.orgId.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">Təşkilat:<sup
                        class="text-danger star">*</sup></label>
                    </div>
                    <mat-select class=" col-4   " formControlName="orgId" required>
                        <mat-option value="">--- Seçin ---</mat-option>
                        <mat-option *ngFor="let x of Org" [value]="x.id">{{x.name}}</mat-option>
                    </mat-select>

                    <div style="margin-top: -13px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.orgId.invalid && (registerForm.controls.orgId.dirty || registerForm.controls.orgId.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.orgId.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2  " [ngClass]="{'invalid-input': registerForm.controls.email.touched && registerForm.controls.email.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">E-poçt ünvanı:<sup
                        class="text-danger start ">*</sup></label></div>
                    <input style="height: 35px;" class=" col-4    " type="text" id="application-form-email" formControlName="email">

                    <div style="margin-top: -7px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.email.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2  " [ngClass]="{'invalid-input': registerForm.controls.name.touched && registerForm.controls.name.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">Ad:<sup
                        class="text-danger start ">*</sup></label></div>
                    <input style="height: 35px;" class=" col-4    " type="text" id="application-form-email" formControlName="name" disabled>

                    <div style="margin-top: -7px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.name.invalid && (registerForm.controls.name.dirty || registerForm.controls.name.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.name.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2  " [ngClass]="{'invalid-input': registerForm.controls.surName.touched && registerForm.controls.surName.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">Soyad:<sup
                        class="text-danger start ">*</sup></label></div>
                    <input style="height: 35px;" class=" col-4    " type="text" id="application-form-email" formControlName="surName" disabled>

                    <div style="margin-top: -7px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.surName.invalid && (registerForm.controls.surName.dirty || registerForm.controls.surName.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.surName.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2  " [ngClass]="{'invalid-input': registerForm.controls.fatherName.touched && registerForm.controls.fatherName.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">Ata adı:<sup
                        class="text-danger start ">*</sup></label></div>
                    <input style="height: 35px;" class=" col-4    " type="text" id="application-form-email" formControlName="fatherName" disabled>

                    <div style="margin-top: -7px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.fatherName.invalid && (registerForm.controls.fatherName.dirty || registerForm.controls.fatherName.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.fatherName.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2  " [ngClass]="{'invalid-input': registerForm.controls.docPin.touched && registerForm.controls.docPin.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">Fin kod:<sup
                        class="text-danger start ">*</sup></label></div>
                    <input style="height: 35px;" class=" col-4    " type="text" id="application-form-email" formControlName="docPin" disabled>

                    <div style="margin-top: -7px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.docPin.invalid && (registerForm.controls.docPin.dirty || registerForm.controls.docPin.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.docPin.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2 px-2 " [ngClass]="{'invalid-input': registerForm.controls.position.touched && registerForm.controls.position.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email">Vəzifə:<sup
                        class="text-danger start ">*</sup></label></div>
                    <input style="height: 35px; " class=" col-4    " type="text" id="application-form-email" formControlName="position">

                    <div style="margin-top: -7px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.position.invalid && (registerForm.controls.position.dirty || registerForm.controls.position.touched)">
                        <div class="col-4"></div>
                        <div class="text-danger red-cl" *ngIf="registerForm.controls.position.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <div style="height: 50px;" class="row  col-12  mb-2 px-2 " [ngClass]="{'invalid-input': registerForm.controls.position.touched && registerForm.controls.position.invalid }">
                    <div class="col-4 text-right mr-4 "></div>
                    <div class="col-4 pt-2 ">
                        <button style="margin-left: -15px;" class="custom-btn btn btn1-default mrc" type="button" [disabled]=" showSpinner || registerForm.invalid " (click)="register()">
                        Qeydiyyat
                    </button>
                        <mat-spinner class="m-2" *ngIf="showSpinner" style="display: inline; ; " [diameter]="20 ">
                        </mat-spinner>
                    </div>

                </div>


            </form>
        </div>
    </div>
</div>