<div class="conainer-fluid">
    <div class="row">
        <div class="col-12 mt-1" *ngFor="let item of outputDocuments; let i = index">
            <form class="mb-0" [formGroup]="waitDocumentForm" *ngIf="waitDocumentForm">
                <div class="row">
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-1">
                                <img class="position-absolute" *ngIf="i == 0 && status=='accept_success'" src="./assets/images/timeline-green.svg" alt="">
                                <img class="position-absolute" *ngIf="i == 0 && status=='short_cancel'" src="./assets/images/timeline-red.svg" alt="">
                            </div>
                            <div class="col-11">
                                <h6 *ngIf="i == 0">{{activePortal}} <span class="text-info">{{nowDate | date:
                                        'dd.MM.yyyy HH:mm'}}</span></h6>
                                <p *ngIf="item.comment" [innerHTML]="item.comment"></p>
                                <div class="d-flex" *ngIf="item.color=='light_blue'" [style.color]="'#03A9D9'">
                                    <div class="cursor-pointer">
                                        <img (click)="downloadFile(item.path)" *ngIf="!(downloadSpinner && clickedPath == item.path)" src="../../../../assets/images/download_light_blue.svg" alt="" style="width: 24px">
                                        <mat-spinner class="d-inline mr-4" *ngIf="clickedPath == item.path && downloadSpinner" [diameter]="20">
                                        </mat-spinner>
                                        <span (click)="downloadFile(item.path)" style="margin-left:10px" [ngStyle]="item.measCompleted == false && {'padding-top': '5px'} ">
                                            {{item.docName}}</span>
                                        <span *ngIf="item.measCompleted == true && item.measDate" style="padding-left: 5px; color: #03A9D9;">
                                                (MEAS-da açıqlanmaq üçün təqdim edilmişdir-{{item.measDate| date: 'dd.MM.yyyy'}})
                                            </span>
                                        <button *ngIf="item.measCompleted == false" (click)="sendMeas(item.key, item.docName)" mat-button class="ml-3 custom-btn meas_btn" [disabled]="hasRequest">
                                                <span>MEAS-da açıqla</span>
                                            </button>
                                    </div>


                                </div>

                                <div *ngIf="item.color=='dark_blue'" [style.color]="'#1C3651'">
                                    <div class="d-flex cursor-pointer">
                                        <img (click)="downloadFile(item.path)" *ngIf="!(downloadSpinner && clickedPath == item.path)" src="../../../../assets/images/download_dark_blue.svg" alt="" style="width: 27px">
                                        <mat-spinner class="d-inline mr-1" *ngIf="clickedPath == item.path && downloadSpinner" [diameter]="20">
                                        </mat-spinner>
                                        <span (click)="downloadFile(item.path)" style="margin-left:10px" [ngStyle]="item.measCompleted == false && {'padding-top': '5px'} ">
                                            {{item.docName}}</span>
                                        <span *ngIf="item.measCompleted == true && item.measDate" style="padding-left: 5px; color: #03A9D9;">
                                                (MEAS-da açıqlanmaq üçün təqdim edilmişdir-{{item.measDate| date: 'dd.MM.yyyy'}})
                                            </span>

                                        <button *ngIf="item.measCompleted == false" (click)="sendMeas(item.key, item.docName)" mat-button class="ml-3 custom-btn meas_btn" [disabled]="hasRequest">
                                                <span>MEAS-da açıqla</span>
                                            </button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="status=='accept_success'" class="col-12 bg-white d-flex justify-content-end">
            <mat-spinner class="mr-2 mt-2" [diameter]="20" *ngIf="hasRequest">
            </mat-spinner>
            <button *ngIf="!showSpinner && nextPortal" (click)="goNext()" mat-button class="custom-btn" [disabled]="hasRequest" type="submit">
                <span>{{nextPortal}}-{{nextPortal == 'MB' || nextPortal == 'BFB' ? 'yə' : 'ə'}} müraciət</span>
            </button>
        </div>
    </div>
</div>