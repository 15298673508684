<div class="row p-3 title">
    <div class="col-10">
        <h2>Müraciət sənədləri</h2>
    </div>
    <div class="col-2">
        <mat-dialog-actions align="end">
            <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
        </mat-dialog-actions>
    </div>
</div>
<mat-dialog-content>
    <mat-tab-group mat-stretch-tabs mat-align-tabs="center">
        <mat-tab label="Göndərilənlər">
            <div class="p-3" *ngIf="!showSpinner">
                <div class="row mt-3" *ngFor="let item of appealDoc?.sentDocs">
                    <div class="col-7 d-flex">
                        <span class="material-symbols-outlined mr-2">
              description
            </span>
                        <span>{{item.docName}}</span>
                        <span *ngIf="item.measCompleted && item.measDate" style="padding-left: 15px; width: 280px; color: #03A9D9;">
              (MEAS-da açıqlanmaq üçün təqdim edilmişdir-{{item.measDate| date: 'dd.MM.yyyy'}})
          </span>

                        <button *ngIf="item.measCompleted==false" (click)="sendMeas(item.docKey, item.docName) " class="custom-btn cursor-pointer col-3 p-0 ml-3 " mat-button>
                      <span>MEAS-da açıqla</span>
                  </button>
                    </div>
                    <div class="col-4" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                        <span title="{{item.fileName}}">{{item.fileName}}</span>
                    </div>
                    <div class="col-1">
                        <mat-spinner *ngIf="clickedPath == item.path && downloadSpinner" [diameter]="20">
                        </mat-spinner>
                        <span *ngIf="!(clickedPath == item.path && downloadSpinner)" (click)="downloadFile(item.path)" class="material-symbols-outlined cursor-pointer">
              file_download
            </span>
                    </div>
                </div>
            </div>
            <div *ngIf="showSpinner" class="py-5">
                <mat-spinner style="margin: auto!important;" [diameter]="70">
                </mat-spinner>
            </div>
        </mat-tab>
        <mat-tab label="Daxil olanlar">
            <mat-tab-group mat-stretch-tabs mat-align-tabs="center">
                <mat-tab *ngFor="let portal of appealDoc?.portals" [label]="portal | uppercase">
                    <div class="p-3" *ngIf="!showSpinner">
                        <div class="row mt-3" *ngFor="let item of getDocsByPortal(portal)">
                            <div class="col-7 d-flex">
                                <span class="material-symbols-outlined mr-2">
                  description
                </span>
                                <span>{{item.docName}}</span>
                                <span *ngIf="item.measCompleted && item.measDate" style="padding-left: 15px; width: 280px; color: #03A9D9;">
                                  (MEAS-da açıqlanmaq üçün təqdim edilmişdir-{{item.measDate| date: 'dd.MM.yyyy'}})
                              </span>

                                <button *ngIf="item.measCompleted==false" (click)="sendMeas(item.docKey, item.docName) " class="custom-btn cursor-pointer col-3 p-0 ml-3 " mat-button>
                                <span>MEAS-da açıqla</span>
                            </button>
                            </div>
                            <div class="col-4" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                                <span title="{{item.fileName}}">{{item.fileName}}</span>
                            </div>
                            <div class="col-1">
                                <mat-spinner *ngIf="clickedPath == item.path && downloadSpinner" [diameter]="20">
                                </mat-spinner>
                                <span *ngIf="!(clickedPath == item.path && downloadSpinner)" (click)="downloadFile(item.path)" class="material-symbols-outlined cursor-pointer">
                  file_download
                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showSpinner" class="py-5">
                        <mat-spinner style="margin: auto!important;" [diameter]="70">
                        </mat-spinner>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div *ngIf="!showSpinner && !appealDoc?.portals?.length">
                <p class="text-center pt-4">Sənəd mövcud deyil</p>
            </div>
        </mat-tab>
        <mat-tab label="Sistem sənədləri">
            <div class="p-3" *ngIf="!showSpinner">
                <div class="row mt-3" *ngFor="let item of appealDoc?.systemDocs">
                    <div class="col-7 d-flex">
                        <span class="material-symbols-outlined mr-2">
              description
            </span>
                        <span>{{item.docName}}</span>
                    </div>
                    <div class="col-4" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                        <span title="{{item.fileName}}">{{item.fileName}}</span>
                    </div>
                    <div class="col-1">
                        <mat-spinner *ngIf="clickedPath == item.path && downloadSpinner" [diameter]="20">
                        </mat-spinner>
                        <span *ngIf="!(clickedPath == item.path && downloadSpinner)" (click)="downloadFile(item.path)" class="material-symbols-outlined cursor-pointer">
              file_download
            </span>
                    </div>
                </div>
            </div>
            <div *ngIf="showSpinner" class="py-5">
                <mat-spinner style="margin: auto!important;" [diameter]="70">
                </mat-spinner>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>