import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeRoutingModule } from './home-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { SubFieldsComponent } from './sub-fields/sub-fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Capcha
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RedirectComponent } from './redirect/redirect.component';
import { ShellModule } from 'src/app/shell/shell.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { Appeals } from 'src/app/cabinet/components/appeals/appeals.component';
import { NewAppeal } from 'src/app/cabinet/components/new-appeal/new-appeal.component';
import { NewAppealList } from 'src/app/cabinet/components/new-appeal-list/new-appeal-list.component';
import { EmissionHomeComponent } from './emission-home/emission-home.component';
import { HomeComponent } from './home/home/home.component';
import { RegisterCompleteComponent } from './register-complete/register-complete.component';
import { MessageDialog } from 'src/app/cabinet/dialogs/messageDialog/message-dialog';
import { RegisterCompleteUnderwriterComponent } from './register-complete-underwriter/register-complete-underwriter.component';
import { SearchComponent } from '../../search/search/search.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToTopBeforeComponent } from '../../scroll-to-top-before/scroll-to-top-before.component';
import { NgxMaskModule } from 'ngx-mask';
import { ReyestrHomeComponent } from '../registry/reyestr-home/reyestr-home/reyestr-home.component';
import { StocksComponent } from '../registry/stocks/stocks.component';
import { BondsComponent } from '../registry/bonds/bonds.component';
import { ProspectusComponent } from '../registry/prospectus/prospectus.component';
import { OauthRedirectComponent } from './oauth-redirect/oauth-redirect.component';
import {BreadcrumbModule} from "angular-crumbs";
//import * as data from '../../assets/config/config.json';
//let json: any = (data as any).default;
//declare var require: any;
//const config = require('../../assets/config/config.json');

@NgModule({
  declarations: [
    LoginComponent,
    RedirectComponent,
    EmissionHomeComponent,
    HomeComponent,
    RegisterCompleteComponent,
    RegisterCompleteUnderwriterComponent,
    ScrollToTopBeforeComponent,
    ReyestrHomeComponent,
    SearchComponent,
    ProspectusComponent,
    StocksComponent,
    BondsComponent,
    OauthRedirectComponent
  ],
    imports: [
        NgxMaskModule.forRoot(),
        RecaptchaV3Module,
        RecaptchaModule,
        ShellModule,
        FormsModule,
        ReactiveFormsModule,
        NoopAnimationsModule,
        NgbModule,
        CommonModule,
        RouterModule,
        HomeRoutingModule,
        HttpClientModule,
        MaterialModule,
        BreadcrumbModule
    ],
  providers: [
    // AuthGuardService,
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: json.keys.reCapcha_site_key },
  ],
})
export class HomeModule { }
