<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <!-- <h4>{{data.applyTypeName.split(".")[1]}} üçün müraciət </h4> -->
            <h6>{{data.applyTypeName.split(".")[1]}} üçün müraciət </h6>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content>
        <mat-dialog-actions align="end" class="mt-2 text-left">
            <div class="row">
                <div class="col-6">
                    <div *ngIf="data.data.apply.qkTypeName">
                        <strong>Qiymətli kağızın növü: </strong>
                        <span>
                            {{data.data.apply.qkTypeName}}
                        </span>
                    </div>
                    <div class="" *ngIf="data.data.apply.ReasonReorganizationName">
                        <strong>Yenidən təşkilin səbəbi : </strong> <span>{{data.data.apply.ReasonReorganizationName}}</span>
                    </div>
                    <div class="" *ngIf="data.data.apply.PurposeIssueName">
                        <strong>Emissiyanın məqsədi : </strong> <span>{{data.data.apply.PurposeIssueName}}</span>
                    </div>
                    <div *ngIf="data.data.apply.qkAnderrayterName" class="mt-3">
                        <strong>Anderrayter: </strong> <span>{{data.data.apply.qkAnderrayterName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.apply.issueAmount">
                        <strong>Emissiya məbləği: </strong> <span>{{data.data.apply.issueAmount}}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div *ngIf="data.data.apply.qkPlacementName">
                        <strong>Yerləşdirmə üsulu: </strong> <span>{{data.data.apply.qkPlacementName}}</span>
                    </div>
                    <div *ngIf="data.data.apply.isListingName">
                        <strong>Hazırda listinqdədirmi? :    </strong> <span>{{data.data.apply.isListingName}}</span>
                    </div>
                    <div *ngIf="data.data.apply.changeDocName">
                        <strong>Dəyişiklik ediləcək sənədin adı: </strong> <span>{{data.data.apply.changeDocName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.apply.qkObserveName">
                        <strong>Müşaiyət olunur: </strong> <span>{{data.data.apply.qkObserveName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.apply.paymentTypeName">
                        <strong>Ödənişin növü: </strong> <span>{{data.data.apply.paymentTypeName}}</span>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div *ngIf="data.data.petition.reorganizeOrgName">
                        <strong>Yenidən təşkil ilə bağlı qərarı qəbul etmiş orqanın adı: </strong> <span>{{data.data.petition.reorganizeOrgName}}</span>
                    </div>
                    <div *ngIf="data.data.petition.protocolOrgName">
                        <strong>Qərarı qəbul etmiş orqanın adı: </strong> <span>{{data.data.petition.protocolOrgName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.protocolNumber">
                        <strong>Qərarın (protokolun) nömrəsi: </strong> <span>{{data.data.petition.protocolNumber}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.protocolDate">
                        <strong>Qərarın (protokolun) tarixi: </strong> <span>{{data.data.petition.protocolDate | date:
                            'dd.MM.yyyy'}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.releaseOrgName">
                        <strong>Buraxılış ilə bağlı qərarı qəbul etmiş orqanın adı: </strong> <span>{{data.data.petition.releaseOrgName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.qkCost">
                        <strong *ngIf="!data.data.petition.reasonQkWithdrawal">Qiymətli kağızın nominal dəyəri: </strong>
                        <strong *ngIf="data.data.petition.reasonQkWithdrawal">Dövriyyədən çıxarılacaq qiymətli kağızın nominal dəyəri: </strong>
                         <span>{{data.data.petition.qkCost}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.qkQuantity">
                        <strong *ngIf="!data.data.petition.reasonQkWithdrawal" >Qiymətli kağızın miqdarı: </strong>
                        <strong *ngIf="data.data.petition.reasonQkWithdrawal" >Dövriyyədən çıxarılacaq qiymətli kağızın miqdarı: </strong> 
                         <span>{{data.data.petition.qkQuantity}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.reasonQkWithdrawal">
                        <strong>Qiymətli kağızların dövriyyədən çıxarılmasının səbəbi: </strong> <span>{{data.data.petition.reasonQkWithdrawal}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.provideInfoName">
                        <strong>Təminat barədə məlumat: </strong> <span>{{data.data.petition.provideInfoName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.circulationPeriod && data.data.petition.timeTypeName">
                        <strong>Tədavül müddəti: </strong>
                        <span>{{data.data.petition.circulationPeriod}} </span>
                        <span>{{data.data.petition.timeTypeName}}</span>
                    </div>
                    <div class="mt-3" *ngIf="data.data.petition.qkTypeName">
                        <strong>Qiymətli kağızın növü: </strong>
                        <span>
                            {{data.data.petition.qkTypeName}}
                        </span>
                    </div>
                </div>
                <div class="col-6">
                    <div>
                        <strong>Rəhbər şəxsin Ş/V FIN kodu/Passport nömrəsi: </strong> <span>{{data.data.petition.ownerPin |
                            uppercase}}</span>
                    </div>
                    <div class="font mt-3">
                        <strong>Rəhbər şəxsin doğum tarixi: </strong> <span>{{data.data.petition.ownerBirthdate}}</span>
                    </div>
                    <div class="font mt-3">
                        <strong>Rəhbər şəxsin adı: </strong> <span>{{data.data.petition.ownerName}}</span>
                    </div>
                    <div class="font mt-3">
                        <strong>Rəhbər şəxsin soyadı: </strong> <span>{{data.data.petition.ownerSurname}}</span>
                    </div>
                    <div class="font mt-3">
                        <strong>Rəhbər şəxsin ata adı: </strong> <span>{{data.data.petition.ownerMiddlename}}</span>
                    </div>
                    <div class="font mt-3">
                        <strong>Rəhbər şəxsin telefon nömrəsi: </strong> <span>{{data.data.petition.ownerTelephone}}</span>
                    </div>
                    <div class="font mt-3">
                        <strong>Rəhbər şəxsin vəzifəsi: </strong> <span>{{data.data.petition.ownerPosition}}</span>
                    </div>
                </div>
            </div>
            <div class="row py-4">
                <div class="col-12">
                    <div class="info px-3 py-2 shadow d-flex align-items-center">
                        <div class="icon mr-3">
                            <span class="material-icons-outlined">info</span>
                        </div>
                        <div class="text-box">
                            <p class="mb-0">Yuxarıda təqdim edilən məlumatların doğruluğunu təsdiq edirəm. Təmsilçisi olduğum hüquqi şəxsin müraciətinə baxılmasını xahiş edirəm.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-right d-flex justify-content-end align-items-center">
                    <mat-spinner class="mr-3" *ngIf="showSpinner" [diameter]="20">
                    </mat-spinner>
                    <button [disabled]="showSpinner" mat-stroked-button class="custom-btn btn btn1-default" (click)="accept()">Qəbul</button>
                </div>
            </div>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>