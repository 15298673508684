<div class="container-fluid border shadow-sm my-3 ">
    <div class="row" *ngIf="!showSpinner">
        <div class="col-12 bg-white px-4 pt-4 pb-5 ">
            <h1 class="font-weight-bold cabinet-title">Yeni müraciət</h1>
            <span class="cabinet-sub-title pl-1">{{subTitle}}</span>
            <button *ngIf="id" mat-button class="custom-btn ex-button" style="position: absolute; top: 40px; right: 40px; font-size: 12px; float: right; background-color: #D90303!important; min-width: auto;" (click)="cancelDialog()">Müraciəti ləğv et</button>
            <form [formGroup]="applyForm" *ngIf="applyForm" class="pt-5">
                <div class="row pt-1">
                    <div class="col-sm-6 col-md-4">
                        <label class="w-100">Yenidən təşkilin səbəbi
                            <sup class="text-danger star">*</sup></label>
                        <mat-form-field class="w-100">
                            <mat-select formControlName="reasonReorganizationId" (selectionChange)="addControlsForBond($event.value)">
                                <mat-option [value]="">--- Seçin ---</mat-option>
                                <mat-option *ngFor="let item of reasonReorganizations" [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="pt-1" *ngIf="!applyForm.controls.reasonReorganizationId.valid && applyForm.controls.reasonReorganizationId.touched">
                            Xana mütləq doldurulmalıdır
                        </mat-error>
                    </div>
                    <div class="offset-md-1 col-sm-6 col-md-4 mt-3 mt-sm-0">
                        <label class="w-100">Qiymətli kağızlarınız hazırda birjada listinqdədirmi?
                            <sup class="text-danger star">*</sup></label>
                        <mat-form-field class="w-100">
                            <mat-select formControlName="isListingId" (selectionChange)="addControlsForBond($event.value)">
                                <mat-option [value]="">--- Seçin ---</mat-option>
                                <mat-option *ngFor="let item of isListing" [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="pt-1" *ngIf="!applyForm.controls.isListingId.valid && applyForm.controls.isListingId.touched">
                            Xana mütləq doldurulmalıdır
                        </mat-error>
                    </div>
                    <div class="col-md-4 col-sm-6 mt-3">
                        <label class="w-100">Qiymətli kağızın növü
                            <sup class="text-danger star">*</sup></label>
                        <mat-form-field class="w-100">
                            <mat-select formControlName="qkTypeId" (selectionChange)="addControlsForBond($event.value)">
                                <mat-option [value]="">--- Seçin ---</mat-option>
                                <mat-option *ngFor="let item of qkTypes" [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="pt-1" *ngIf="!applyForm.controls.qkTypeId.valid && applyForm.controls.qkTypeId.touched">
                            Xana mütləq doldurulmalıdır
                        </mat-error>
                    </div>

                    <!-- <div class="offset-md-1 col-sm-6 col-md-4 mt-3" *ngIf="getRealApplyType()">
                        <label class="w-100">Müraciətin məqsədi <sup class="text-danger star">*</sup> </label>
                        <mat-form-field class="w-100">
                            <mat-select [formControlName]="'purposeApplyId'">
                                <mat-option [value]="">--- Seçin ---</mat-option>
                                <mat-option *ngFor="let item of purposeApply" [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="pt-1" *ngIf="!applyForm.controls.purposeApplyId.valid && applyForm.controls.purposeApplyId.touched">
                            Xana mütləq doldurulmalıdır
                        </mat-error>
                    </div> -->

                    <div *ngIf="issue_prospectus" class="col-md-4 col-sm-6 mt-3">
                        <label class="w-100">Emissiya məbləği
                            <sup class="text-danger star">*</sup>
                        </label>
                        <mat-form-field class="w-100">
                            <input matInput type="number" formControlName="issueAmount">
                        </mat-form-field>
                        <mat-error class="pt-1" *ngIf="!applyForm.controls.issueAmount.valid && applyForm.controls.issueAmount.touched">
                            Xana mütləq doldurulmalıdır
                        </mat-error>
                    </div>
                    <div *ngIf="issue_prospectus" class="offset-md-1 col-sm-6 col-md-4 mt-3">
                        <label class="w-100" id="example-radio-group-label">Ödəniş növü <sup
                                class="text-danger star">*</sup> </label>
                        <mat-radio-group [formControlName]="'paymentTypeId'" aria-labelledby="example-radio-group-label" class="example-radio-group">
                            <mat-radio-button class="w-100 example-radio-button" *ngFor="let item of paymentTypes" [value]="item.id">
                                {{item.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-error class="pt-1" *ngIf="!applyForm.controls.paymentTypeId.valid && applyForm.controls.paymentTypeId.touched">
                            Xana mütləq doldurulmalıdır
                        </mat-error>
                    </div>
                </div>
            </form>

            <hr class="mt-5">

            <form [formGroup]="petitionForm" *ngIf="petitionForm" class="pt-5">
                <div class="row">
                    <div class="col-sm-6 col-md-4">
                        <div>
                            <label class="w-100">Qərarı qəbul etmiş orqanın adı
                                <sup class="text-danger star">*</sup>
                            </label>
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="protocolOrgName">
                            </mat-form-field>
                            <mat-error class="pt-1" *ngIf="!petitionForm.controls.protocolOrgName.valid && petitionForm.controls.protocolOrgName.touched">
                                Xana mütləq doldurulmalıdır
                            </mat-error>
                        </div>
                        <div class="mt-3">
                            <label class="w-100">Qərarın (protokolun) nömrəsi
                                <sup class="text-danger star">*</sup>
                            </label>
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="protocolNumber">
                            </mat-form-field>
                            <mat-error class="pt-1" *ngIf="!petitionForm.controls.protocolNumber.valid && petitionForm.controls.protocolNumber.touched">
                                Xana mütləq doldurulmalıdır
                            </mat-error>
                        </div>
                        <div class="mt-3">
                            <label class="w-100">Qərarın (protokolun) tarixi
                                <sup class="text-danger star">*</sup>
                            </label>
                            <mat-form-field class="w-100">
                                <input matInput type="text" formControlName="protocolDate" [matDatepicker]="pickerProtocolDate">
                                <mat-datepicker-toggle style=" position: absolute !important;
                                right: 1% !important;
                                top: 15% !important;" matSuffix [for]="pickerProtocolDate"></mat-datepicker-toggle>
                                <mat-datepicker class="datepicker-appeal" #pickerProtocolDate></mat-datepicker>
                            </mat-form-field>
                            <mat-error class="pt-1" *ngIf="!petitionForm.controls.protocolDate.valid && petitionForm.controls.protocolDate.touched">
                                Xana mütləq doldurulmalıdır
                            </mat-error>
                        </div>
                        <div class="mt-3">
                            <label class="w-100">Qiymətli kağızın nominal dəyəri
                                <sup class="text-danger star">*</sup>
                            </label>
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="qkCost">
                            </mat-form-field>
                            <mat-error class="pt-1" *ngIf="!petitionForm.controls.qkCost.valid && petitionForm.controls.qkCost.touched">
                                Xana mütləq doldurulmalıdır
                            </mat-error>
                        </div>
                        <div class="mt-3">
                            <label class="w-100">Qiymətli kağızın miqdarı
                                <sup class="text-danger star">*</sup>
                            </label>
                            <mat-form-field class="w-100">
                                <input matInput type="number" formControlName="qkQuantity">
                            </mat-form-field>
                            <mat-error class="pt-1" *ngIf="!petitionForm.controls.qkQuantity.valid && petitionForm.controls.qkQuantity.touched">
                                Xana mütləq doldurulmalıdır
                            </mat-error>
                        </div>
                        <div class="mt-3" *ngIf="isBond">
                            <label class="w-100">Təminat barədə məlumat</label>
                            <mat-form-field class="w-100">
                                <mat-select formControlName="provideInfoId">
                                    <mat-option [value]="">--- Seçin ---</mat-option>
                                    <mat-option *ngFor="let item of provideInfos" [value]="item.id">{{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="pt-1" *ngIf="!petitionForm.controls.provideInfoId.valid && petitionForm.controls.provideInfoId.touched">
                                Xana mütləq doldurulmalıdır
                            </mat-error>
                        </div>
                        <div class="mt-3" *ngIf="isBond">
                            <div class="row">
                                <div class="col-6">
                                    <label class="w-100">Tədavül müddəti
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="number" formControlName="circulationPeriod">
                                    </mat-form-field>
                                    <mat-error class="pt-1" *ngIf="!petitionForm.controls.circulationPeriod.valid && petitionForm.controls.circulationPeriod.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="col-6">
                                    <label class="w-100">Dövr</label>
                                    <mat-form-field class="w-100">
                                        <mat-select formControlName="timeTypeId">
                                            <mat-option [value]="">--- Seçin ---</mat-option>
                                            <mat-option *ngFor="let item of timeTypes" [value]="item.id">{{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error class="pt-1" *ngIf="!petitionForm.controls.timeTypeId.valid && petitionForm.controls.timeTypeId.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 mt-4 d-flex justify-content-between">
                        <button (click)="gonewappeallist()" mat-button class="text-center">
                            <img src="../../../../assets/images/backspace.svg">
                            <span style="color:#03A9D9; margin-left:5px">Geri qayıt</span>
                        </button>
                        <div class="d-flex">
                            <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
                            <button (click)="save()" mat-button class="custom-btn mr-3" [disabled]="applyForm.invalid  || petitionForm.invalid" type="submit">
                                <span>Yadda saxla</span>
                            </button>
                            <button (click)="confirm()" mat-button class="custom-btn" [disabled]="applyForm.invalid || petitionForm.invalid" type="submit">
                                <span>Təsdiqlə</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="row">
        <div class="col-md-12 py-3 pt-5 pb-5 bg-white" *ngIf="showSpinner">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
    </div>
</div>