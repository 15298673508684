import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-notfication',
  templateUrl: './notfication.component.html',
  styleUrls: ['./notfication.component.scss']
})
export class NotficationComponent implements OnInit {

  panelOpenState = false;
  showSpinner: boolean;
  notifications: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public cabinetService: Cabinet,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    if (this.data.id) {
      this.showSpinner = true;
      this.cabinetService.getNotifications(this.data.id).subscribe(
        res => {
          this.notifications = res.data;
          this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }
  readNotification(notifyId) {
    if (notifyId && this.data.id) {
      this.cabinetService.readNotification(this.data.id, notifyId).subscribe(
        res => {
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
        })
    }
  }

  refreshPage(){
    location.reload();
  }
}
