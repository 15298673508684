<div *ngIf="asanSpinner" class=" common-spinner col-md-12 bg-white pb-5">
    <mat-spinner style="margin: auto!important;  " [diameter]="70">
    </mat-spinner>
</div>

<aside id="sidebar" class="text-white">
<div class="d-flex align-items-center justify-content-center" style="padding: 20px 10px 0 10px;">
  <span  style="text-transform: uppercase;font-size: 16px; padding-bottom: 10px;width: 100%;text-align: center;border-bottom: 1px solid white;">
    Qİymətlİ Kağızların Emİssİyası Sİstemİ
  </span>
</div>
    <!-- Qiymətli kağızlar -->
    <mat-list role="list">
        <mat-list-item role="listitem">
            <a class="text-white text-uppercase font-weight-bold" [routerLink]="['/appeals']" routerLinkActive="router-link-active">
                <span class="ml-2 ml-xl-0 mr-1 mb-1" style="font-size: 17px; margin-right: 4px;  " class="material-icons-outlined">
            list_alt
            </span>
                <span>MÜRACİƏTLƏR</span>
            </a>
        </mat-list-item>
    </mat-list>

    <!-- Şirkətlər -->
    <mat-list role="list" *ngIf="accountService.is_emitent()">
        <mat-list-item role="listitem">
            <a (click)="myAccount()" routerLinkActive="router-link-active" class="text-white text-uppercase font-weight-bold">
                <span style="font-size: 17px;  " class=" mb-1  mr-1 material-icons-outlined">
                    business_center
                    </span>
                <span>Təşkİlatlarım</span>
            </a>
        </mat-list-item>
    </mat-list>
    <mat-list role="list" *ngIf="is_admin">
        <mat-list-item role="listitem">
            <a [routerLink]="['/access-list']" routerLinkActive="router-link-active" class="text-white text-uppercase font-weight-bold">
                <span id="selah" style="font-size: 19px;  " class=" material-icons-outlined">
                    group
                    </span>
                <span style="width: 150px;">SƏlahİyyƏtlƏrİn
                    ötürülmƏsİ</span>
            </a>
        </mat-list-item>
    </mat-list>
    <!-- Məlumatlar -->
    <mat-list role="list">
        <mat-list-item role="listitem">
            <a [routerLink]="['/user-manual']" routerLinkActive="router-link-active" class="text-white text-uppercase font-weight-bold">
                <span style="font-size: 19px;   margin-bottom: 2px;  margin-right: 3px;" class="   material-icons-outlined">
                    library_books
                        </span>

                <span>İstİfadəçİ təlİmatları</span>
            </a>
        </mat-list-item>
    </mat-list>
    <mat-list role="list">
        <mat-list-item role="listitem">
            <a [routerLink]="['/contact-details']" routerLinkActive="router-link-active" class="text-white text-uppercase font-weight-bold">
                <span style="font-size: 19px;   margin-bottom: 2px;  margin-right: 3px;" class="   material-icons-outlined">
                      help_outline
                      </span>
                <span>Əlaqə məlumatları</span>
            </a>
        </mat-list-item>
    </mat-list>
</aside>
