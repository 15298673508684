import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CabinetComponent} from './cabinet.component';
import {Ng7DynamicBreadcrumbModule} from 'ng7-dynamic-breadcrumb';
import {BreadcrumbModule} from 'angular-crumbs';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarComponent} from './fixed-part/sidebar/sidebar.component';
import {FooterComponent} from './fixed-part/footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../shared/material.module';
import {Breadcrumbcomponent} from './fixed-part/breadcrumbs-cabinet/breadcrumb.component';
import {HomeService} from '../home/components/home/home.service';
import {Appeals} from './components/appeals/appeals.component';
import {AppealDetail} from './components/appeal-detail/appeal-detail.component';
import {NewAppeal} from './components/new-appeal/new-appeal.component';
import {NewAppealList} from './components/new-appeal-list/new-appeal-list.component';
import {SelectCompanyDialogComponent} from './dialogs/selectCompany/select-company-dialog.component';
import {NewCompanyDialogComponent} from './dialogs/newCompany/new-company-dialog.component';
import {ScrollToTopComponent} from './components/scroll-to-top/scroll-to-top.component';
import {UserManualComponent} from './components/user-manual/user-manual.component';
import {ApplyDocumentDialogComponent} from './dialogs/applyDocument/apply-document-dialog.component';
import {MessageDialog} from './dialogs/messageDialog/message-dialog';
import {ProfileComponent} from './components/profile/profile.component';
import {InfoDialogComponent} from './dialogs/info-dialog/info-dialog.component';
import {MeasDialogComponent} from './dialogs/meas-dialog/meas-dialog.component';
import {GppDialogComponent} from './dialogs/gpp-dialog/gpp-dialog.component';
import {NotficationComponent} from './dialogs/notfication/notfication.component';
import {AccessListComponent} from './components/access-list/access-list.component';
import {NewAccessComponent} from './components/new-access/new-access.component';
import {
  StateRegistrationDialogComponent
} from './dialogs/state-registration-dialog/state-registration-dialog.component';
import {NewAppealDefault} from './components/new-appeal-default/new-appeal-default.component';
import {NewAppealNew} from './components/new-appeal-new/new-appeal-new.component';
import {IConfig, NgxMaskModule} from 'ngx-mask'
import {DisableApplyComponent} from './components/new-appeal-new/disable-apply/disable-apply.component';
import {PaymentReceiptComponent} from './components/new-appeal-new/paymentReceipt/payment-receipt.component';
import {NewAppealSentComponent} from './components/new-appeal-sent/new-appeal-sent.component';
import {AppealSentUpperComponent} from './components/new-appeal-sent/appeal-sent-upper/appeal-sent-upper.component';
import {AppealSentBottomComponent} from './components/new-appeal-sent/appeal-sent-bottom/appeal-sent-bottom.component';
import {
  WaitContractComponent
} from './components/new-appeal-sent/appeal-sent-bottom/wait-contract/wait-contract.component';
import {
  WaitDocumentComponent
} from './components/new-appeal-sent/appeal-sent-bottom/wait-document/wait-document.component';
import {
  SuccessStepComponent
} from './components/new-appeal-sent/appeal-sent-bottom/success-step/success-step.component';
import {
  MustCorrectComponent
} from './components/new-appeal-sent/appeal-sent-bottom/must-correct/must-correct.component';
import {
  RequiredContractComponent
} from './components/new-appeal-sent/appeal-sent-bottom/required-contract/required-contract.component';
import {
  RequiredDocumentComponent
} from './components/new-appeal-sent/appeal-sent-bottom/required-document/required-document.component';
import {
  RequiredNewDocComponent
} from './components/new-appeal-sent/appeal-sent-bottom/required-new-doc/required-new-doc.component';
import {ReceiptDialog} from './dialogs/receipt/receipt-dialog';
import {
  DisableApply1Component
} from './components/new-appeal-new/disable-apply/disable-apply-1/disable-apply-1.component';
import {
  NewAppealDefault2Component
} from './components/new-appeal-default/new-appeal-default-2/new-appeal-default-2.component';
import {
  NewAppealDefault1Component
} from './components/new-appeal-default/new-appeal-default-1/new-appeal-default-1.component';
import {ContactDetailsComponent} from './components/contact-details/contact-details.component';
import {MessageComponent} from './dialogs/message/message/message.component';
import {CancelDialogComponent} from './dialogs/cancel-dialog/cancel-dialog.component';
import {HistoricallyDialogComponent} from './dialogs/historically-dialog/historically-dialog.component';
import {AppealsUnderwriter} from './components/appeals-underwriter/appeals-underwriter.component';
import {ProfileUnderwriterComponent} from './components/profile-underwriter/profile-underwriter.component';
import {AsanDialog1Component} from './dialogs/asan-dialog-1/asan-dialog-1.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AsanDialog2Component} from './dialogs/asan-dialog-2/asan-dialog-2.component';
import {AsanDialog3Component} from './dialogs/asan-dialog-3/asan-dialog-3.component';
import {AsanDialog4Component} from './dialogs/asan-dialog-4/asan-dialog-4.component';
import {AsanDialog5Component} from './dialogs/asan-dialog-5/asan-dialog-5.component';
import {ApplyMessageDialogComponent} from './dialogs/applyMessage/apply-message-dialog.component';
import {DeactiveApplyDialogComponent} from './dialogs/deactive-apply-dialog/deactive-apply-dialog.component';
import {SharedModule} from "../shared/shared.module";


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    MaterialModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    Ng7DynamicBreadcrumbModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    SharedModule
    // ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),

  ],
  providers: [HomeService,CurrencyPipe,DatePipe],

  declarations: [
    CabinetComponent,
    SidebarComponent,
    Appeals,
    AppealsUnderwriter,
    AppealDetail,
    NewAppeal,
    NewAppealDefault2Component,
    NewAppealDefault1Component,
    NewAppealNew,
    NewAppealSentComponent,
    NewAppealList,
    MessageDialog,
    FooterComponent,
    Breadcrumbcomponent,
    ScrollToTopComponent,
    ProfileComponent,
    UserManualComponent,
    ApplyDocumentDialogComponent,
    ApplyMessageDialogComponent,
    SelectCompanyDialogComponent,
    NewCompanyDialogComponent,
    InfoDialogComponent,
    MeasDialogComponent,
    AsanDialog1Component,
    AsanDialog2Component,
    AsanDialog3Component,
    AsanDialog4Component,
    AsanDialog5Component,
    CancelDialogComponent,
    GppDialogComponent,
    NotficationComponent,
    AccessListComponent,
    NewAccessComponent,
    StateRegistrationDialogComponent,
    ReceiptDialog,
    DisableApplyComponent,
    DisableApply1Component,
    AppealSentUpperComponent,
    AppealSentBottomComponent,
    WaitContractComponent,
    WaitDocumentComponent,
    SuccessStepComponent,
    ProfileUnderwriterComponent,
    MustCorrectComponent,
    RequiredContractComponent,
    RequiredDocumentComponent,
    RequiredNewDocComponent,
    PaymentReceiptComponent,
    NewAppealDefault,
    ContactDetailsComponent,
    MessageComponent,
    HistoricallyDialogComponent,
    DeactiveApplyDialogComponent
  ],
  entryComponents: [MessageDialog],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CabinetModule { }
