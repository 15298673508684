export enum Static {
  ACCR_HOME_PAGE = "QK_HOME_PAGE",
  ACCR_USER_MANUAL = "QK_USER_MANUAL",
  QK_HOME_PAGE = "QK_HOME_PAGE",
  QK_HOME_PAGE_2 = "QK_HOME_PAGE_2",
  HOME_PAGE = "HOME_PAGE",
  HOME_PAGE_CARDTITLE="HOME_PAGE_CARDTITLE",
  QK_USER_MANUAL = "QK_USER_MANUAL",
  ANDERWRITER_USER_MANUAL = "ANDERWRITER_USER_MANUAL",
  ANDERWRITER_CONTACT_DETAILS = "ANDERWRITER_CONTACT_DETAILS",
  QK_CONTACT_DETAILS = "QK_CONTACT_DETAILS",
  QKR_HOME_PAGE = "QKR_HOME_PAGE"
}

export enum ApplyType {
  TYPE1 = "process_1",
  TYPE2 = "process_2",
  TYPE3 = "process_3",
  TYPE4 = "process_4",
  TYPE5 = "process_5",
  TYPE6 = "process_6",
  TYPE7 = "process_7"
}
export enum PortalType {
  MB = "MB",
  MDM = "MDM",
  BFB = "BFB",
}

export enum qkTypesConst
{
  stock = 'stock',
  bond = 'bond'
}

export enum placementMethodsConst
{
  fb_public = 'fb_public',
  fb_closed = 'fb_closed',
  b_over = 'b_over'
}

export enum qkObservesConst
{
  issue_prospectus = 'issue_prospectus',
  info_memorandum = 'info_memorandum',
  base_issue_prospectus = 'base_issue_prospectus',
  terms_base_issue_prospectus = 'terms_base_issue_prospectus'
}

export enum UserRoles {
  LEADER = "leader",
  FOUNDER = "founder",
  ALL_APPLY = "all_apply",
  NEW_APPLY = "new_apply",
  ACCESS_APPLY = "access_apply"
}

export enum ColumnType {
  STOCK = "STOCK",
  CORP_BONDS = "CORP_BONDS",
  GOV_BONDS = "GOV_BONDS",
  MUN_BONDS = "MUN_BONDS",
  BANK_NOTES = "BANK_NOTES",
  DEPOSITORY_RECEIPTS = "DEPOSITORY_RECEIPTS",
  CERTIFICATES = "CERTIFICATES",
  BILLS = "BILLS",
  PROSPECTUS = "PROSPECTUS"
}
