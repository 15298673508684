<div class="container-fluid border shadow-sm my-3">
    <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between bg-white px-4 pt-4">
            <h1 class="font-weight-bold cabinet-title ">Yeni səlahiyyət </h1>
            <div>
                <mat-spinner class="ml-2 mt-2" [diameter]="20" style="float: right;" *ngIf="false">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 bg-white px-4 pb-4  pt-4">
            <div style="width: 35%;" class="mx-2   ">
                <form action="" class=" form-r  customForm form-row center" [formGroup]="iamasForm">
                    <div style="height: 70px; " class=" col-12  input-date mt-2 " [ngClass]="{'invalid-input': iamasForm.controls.birthDate.touched && iamasForm.controls.birthDate.invalid }">
                        <label for="application-form-email" class="mb-2">Doğum tarixi: </label>
                        <input placeholder="28.02.1998" class="w-100 customForm" mask="00.00.0000" matInput formControlName="birthDate">
                        <!-- <mat-datepicker-toggle style=" position: absolute !important;
                        right: 1% !important;
                        top: 32% !important;" class="mb-5" matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker class="datepicker-access" #picker></mat-datepicker> -->
                        <div class="row  col-12 justify-content-start pl-4" *ngIf="iamasForm.controls.birthDate.invalid && (iamasForm.controls.birthDate.dirty || iamasForm.controls.birthDate.touched)">
                            <div style="margin-top: -15px; margin-left: -10px;" class="text-danger" *ngIf="iamasForm.controls.birthDate.errors?.required">
                                Xana mütləq doldurulmalıdır
                            </div>
                            <div style="margin-top: -15px; margin-left: -10px;" class="text-danger" *ngIf="iamasForm.controls.birthDate.errors?.pattern">
                                Tarixi dd.MM.yyyy formatda daxil edin.
                            </div>
                        </div>
                        <!-- <input class="w-100 customForm invisible" [matDatepicker]="picker" formControlName="birth"> -->
                    </div>
                    <!-- EMAIL  -->
                    <div style="height: 70px;" class=" col-12 input-date   mt-2 input" [ngClass]="{'invalid-input': iamasForm.controls.pinCode.touched && iamasForm.controls.pinCode.invalid }">
                        <label for="email">Fin kod: </label>
                        <input class="w-100" matInput type="text" id="email" formControlName="pinCode" />
                        <div class="row col-12 justify-content-start pl-4" *ngIf="iamasForm.controls.pinCode.invalid && (iamasForm.controls.pinCode.dirty || iamasForm.controls.pinCode.touched)">
                            <div style="margin-top: -15px; margin-left: -10px;" class="text-danger" *ngIf="iamasForm.controls.pinCode.errors?.required">
                                Xana mütləq doldurulmalıdır
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-xs-12 center pt-2 input-date  d-flex justify-content-end">
                        <mat-spinner *ngIf="iamasbuton" style="margin:7px; " [diameter]="20">
                        </mat-spinner>
                        <button (click)="checkUser()" class="btn btn1-default mrc" type="button" *ngIf="!showSpinner" [disabled]="iamasbuton || accessbuton || !iamasForm.valid ">
                            Yoxla
                        </button>
                    </div>
                </form>
                <form *ngIf="response" action="" class="form-r  customForm form-row center " [formGroup]="accessForm">
                    <div class="row mt-4">
                        <span style="font-size: 18px!important;" class="text-uppercase mx-2 span col-12">{{response.fullName}}</span>
                        <span style="font-size: 18px!important;" class="text-uppercase mx-2 span col-12 mt-2">{{changeFormatDate(response.birthDate)}}</span>
                        <span class="   col-12 mt-2">
                            <section class="example-section">
                                <label class="example-margin"></label>
                                <mat-radio-group formControlName="roleKey">
                                    <mat-radio-button *ngFor="let item of emitentRole" class=" col-12 mb-2  check"
                                        [value]="item.key"><span class="span">{{item.name}}</span> </mat-radio-button>

                        </mat-radio-group>
                        </section>
                        </span>
                        <div style="height: 70px;" mb-2 class=" col-12 input-date pr-0  pl-4 " [ngClass]="{'invalid-input': accessForm.controls.position.touched && accessForm.controls.position.invalid }">
                            <label for="email">Vəzifə: <sup class="text-danger star">*</sup> </label>
                            <input class="w-100" matInput type="text" id="email" formControlName="position" />
                            <div class="row col-12 justify-content-start pl-4" *ngIf="accessForm.controls.position.invalid && (accessForm.controls.position.dirty || accessForm.controls.position.touched)">
                                <div style="margin-top: -15px; margin-left: -10px;" class="text-danger" *ngIf="accessForm.controls.position.errors?.required">
                                    Xana mütləq doldurulmalıdır
                                </div>
                            </div>
                        </div>
                        <div class="col-12 input-date pr-0  pl-4   pt-2  d-flex justify-content-end">
                            <mat-spinner *ngIf="accessbuton" style="margin:7px; " [diameter]="20">
                            </mat-spinner>
                            <button (click)=createAccess() class="btn btn1-default px-2 " type="button" *ngIf="!showSpinner" [disabled]=" iamasbuton || accessbuton  || !accessForm.valid  ">
                                Səlahiyyəti ötür
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>