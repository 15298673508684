import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { placementMethodsConst, qkObservesConst } from 'src/app/models/constants';
import { ApplyDocumentDialogComponent } from 'src/app/cabinet/dialogs/applyDocument/apply-document-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CancelDialogComponent } from 'src/app/cabinet/dialogs/cancel-dialog/cancel-dialog.component';
import { ApplyMessageDialogComponent } from 'src/app/cabinet/dialogs/applyMessage/apply-message-dialog.component';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-appeal-sent-upper',
  templateUrl: './appeal-sent-upper.component.html',
  styleUrls: ['./appeal-sent-upper.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class AppealSentUpperComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  applyUpper: any;
  paymentTypes: any;
  showSpinner = true;
  applyForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cabinetService: Cabinet,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
    this.getApplyUpper();

  }
  ngOnInit(): void {

  }

  getApplyUpper() {
    if (!this.id) return;
    this.cabinetService.getApplyUpper(this.id).subscribe(
      res => {
        console.log(res);

        this.applyUpper = res.response;
        this.showSpinner = false;
      },
      err => {
        if (err.error.status.errorCode != 0)
          this.cabinetService.messageDialog(err.error.status.message, false);
        this.showSpinner = false;
      })
  }

  openDialog() {
    this.dialog.open(ApplyDocumentDialogComponent,{
      position:{
        top:'110px'
      },
    });
  }

  openMessageDialog() {
    this.dialog.open(ApplyMessageDialogComponent,{
      position:{
        top:'110px'
      },
    });
  }

  cancelDialog(){
    this.dialog.open(CancelDialogComponent, {
      width: '400px',
      position: {
        top: '110px'
      },
    data: {
      id: this.id 
    }});
  }
}
