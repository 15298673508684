<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">

            <h2>Ödəniş</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content>
        <div class=" text-font center mx-auto   font-weight-bold">
            <table class="table-gpp mt-4">
                <tr>
                    <td>
                        <div class="gpp-head"> Qaimənin №</div>
                        <div class="gpp-td applyNumber">{{data.applyNumber}}</div>
                    </td>
                    <td>
                        <div class="gpp-head"> Ödəniş tarixi</div>
                        <div class="gpp-td"> {{nowDate | date: 'dd.MM.yyyy'}}</div>
                    </td>
                    <td>
                        <div class="gpp-head"> Dövlət rüsumu</div>
                        <div class="gpp-td"> {{data.amountStateDuty}} AZN</div>
                    </td>

                </tr>

            </table>

        </div>
        <form action="" [formGroup]="paymentgroup">
            <div class="pt-2">

                <div style="height: 70px;" mb-2 class=" col-12  col-md-8 col-xs-6 px-4 " [ngClass]="{'invalid-input': paymentgroup.controls.cardDigits.touched && paymentgroup.controls.cardDigits.invalid }">
                    <label>Ödəniş kartının ilk 6 rəqəmini daxil edin </label>
                    <!-- <input minlength="6" maxlength="6" matInput type="text" formControlName="cardDigits" /> -->
                    <mat-form-field class="w-100">
                        <input minlength="6" maxlength="6" matInput type="text" formControlName="cardDigits">
                    </mat-form-field>
                    <div class="row col-12 justify-content-start mx-0 px-0 " *ngIf="paymentgroup.controls.cardDigits.invalid && (paymentgroup.controls.cardDigits.dirty || paymentgroup.controls.cardDigits.touched)">
                        <div class="text-danger mx-0 px-0" *ngIf="paymentgroup.controls.cardDigits.errors?.required"> Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
            </div>

            <mat-dialog-actions align="end" class="mt-2">
                <mat-spinner class="mr-3" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
                <button mat-stroked-button class="custom-btn my-2 mr-4" (click)="pay()" [disabled]="paymentgroup.invalid || hasRequest"><span translate>Ödəniş et</span></button>
            </mat-dialog-actions>
        </form>

    </mat-dialog-content>

</div>