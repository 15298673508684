import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-deactive-apply-dialog',
  templateUrl: './deactive-apply-dialog.component.html',
  styleUrls: ['./deactive-apply-dialog.component.scss']
})
export class DeactiveApplyDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeactiveApplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cabinet: Cabinet,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

}
