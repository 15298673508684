import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplyType, placementMethodsConst, qkObservesConst, qkTypesConst } from 'src/app/models/constants';
import { MatDialog } from '@angular/material/dialog';
import { CancelDialogComponent } from '../../dialogs/cancel-dialog/cancel-dialog.component';
import { AccountService } from 'src/app/home/components/home/account-service';
import { MeasDialogComponent } from '../../dialogs/meas-dialog/meas-dialog.component';
import { AppConfig } from 'src/app.config';
import { DeactiveApplyDialogComponent } from '../../dialogs/deactive-apply-dialog/deactive-apply-dialog.component';

@Component({
  selector: 'app-new-appeal-new',
  templateUrl: './new-appeal-new.component.html',
  styleUrls: ['./new-appeal-new.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppealNew implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  transactionId = this.activatedRoute.snapshot.queryParamMap['params']['transactionId'];

  dynamicForm: FormGroup;
  applySendDocForm: FormGroup;
  documents: any;
  showSpinner: boolean;
  selectedFile: any;
  uploadError: boolean;
  clickDoc: any;
  hasRequest: boolean;
  applyPetition: any;
  currentDocs: any[] = [];
  index: number = 1;
  requiredDocs: any[];
  isInValid: boolean;
  applyNewReload: any;
  commonSpinner: boolean;
  downloadSpinner: boolean;
  acceptType = AppConfig.settings.fileSettings.acceptFiles;
  acceptTypes = "";
  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private accountService: AccountService,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
    if (!this.accountService.is_emitent()) {
      this.router.navigate(["appeals-underwriter"]);
    }
  }
  ngOnInit(): void {
    this.setTitle();
    this.getApplyNew();
    this.setAcceptTypes();
  }

  setAcceptTypes() {
    for (let index = 0; index < this.acceptType.length; index++) {
      const item = this.acceptType[index];
      if (this.acceptType.length - 1 >= index)
        this.acceptTypes += ("." + item + ", ")
    }
  }

  ngOnDestroy() {
    if (this.applyNewReload) {
      clearInterval(this.applyNewReload);
    }
  }

  setApplySendDocForm() {
    this.applySendDocForm = this.fb.group({
      previousDocs: [[],],
      outputDocs: [[],],
      currentDocs: [[],],
    })

  }

  setApplySendDoc(file, key, index, hasMeas, extHover, extHoverMessage) {
    if (!file) return;
    if (!this.cabinetService.checkFile(file)) return;
    if (extHover && !this.cabinetService.checkAdoc(file, extHover, extHoverMessage)) return;
    this.index++;
    let data = {
      key: key,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      fileExt: file.name.split('.').pop(),
      index: index ? index : this.index,
      hasMeas: hasMeas
    }
    //console.log(data.file)
    let formData = new FormData();
    formData.append(`key`, data.key);
    formData.append(`file`, data.file);
    formData.append(`index`, data.index);
    formData.append(`fileName`, data.fileName);
    formData.append(`fileSize`, data.fileSize);
    formData.append(`fileExt`, data.fileExt);
    formData.append(`hasMeas`, data.hasMeas);
    this.cabinetService.sendTempFile(formData, this.id).subscribe(
      res => {

        console.log(res);
      },
    );
    this.currentDocs = this.currentDocs.filter(x => !(x.key == key && x.index == (index ? index : this.index)));
    this.currentDocs.push(data);
    this.applySendDocForm.controls.currentDocs.setValue(this.currentDocs);
    this.formIsValid();
  }

  otherDocs() {
    this.applyPetition.docs.push({ docName: 'Digər', key: 'doc_in_9', type: 'current', index: this.index, hasMeas: false })
  }

  getDocByKeyIndex(key, index) {
    return this.currentDocs.find(x => (x.key == 'doc_in_9' && x.key == key && x.index == index) || (x.key != 'doc_in_9' && x.key == key))?.file;
  }

  formIsValid() {
    this.isInValid = false;
    this.requiredDocs.forEach(x => {
      if (!this.currentDocs.some(y => y.key == x.key)) {
        this.isInValid = true;
      }
    })
  }

  sendApply() {
   
    this.cabinetService.checkApplyCreateDate(this.id).subscribe(
        res => {
          console.log(res);
          if(res.response)
          {
            const dialogRef = this.dialog.open(DeactiveApplyDialogComponent, {
              width: '650px',
              position: {
                top: '60px'
              },
              data: {},
              autoFocus: false
            })
          }
          else{
              if (this.isInValid) return;
                  let obj = {
                    'modifications': {
                    }
                  }
                  let formData = new FormData();
                  let currentDocs: any[] = this.applySendDocForm.controls.currentDocs.value;
                  let outputDocs: any[] = this.applySendDocForm.controls.outputDocs.value;
                  let previousDocs: any[] = this.applySendDocForm.controls.previousDocs.value;

                  currentDocs.forEach((val, i) => {
                    formData.append(`currentDocs[${i}].key`, val.key);
                    formData.append(`currentDocs[${i}].file`, val.file);
                    formData.append(`currentDocs[${i}].fileName`, val.fileName);
                    formData.append(`currentDocs[${i}].fileSize`, val.fileSize);
                    formData.append(`currentDocs[${i}].fileExt`, val.fileExt);
                    formData.append(`currentDocs[${i}].hasMeas`, val.hasMeas);
                  });

                  formData.append(`variablesStr`, JSON.stringify(obj));

                  outputDocs.forEach((val, i) => formData.append(`outputDocs[${i}]`, val));
                  previousDocs.forEach((val, i) => formData.append(`previousDocs[${i}]`, val));
                  this.hasRequest = true;
                  this.cabinetService.sendApply(formData, this.id).subscribe(
                    res => {
                      this.cabinetService.sendEmail(this.id, true).subscribe(
                        res => { });
                      window.location.reload();
                    },
                    err => {
                      if (err.error.status.errorCode != 0)
                        this.cabinetService.messageDialog(err.error.status.message, false);
                      this.hasRequest = false;
                    })
          }
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.hasRequest = false;
        })
   
  }
  getApplyNew(isReload = false) {
    if (this.id) {
      if (!isReload) this.showSpinner = true
      this.cabinetService.getApplyNew(this.id).subscribe(
        res => {
          this.showSpinner = false;
          if (res.status.errorCode != 0) {
            this.cabinetService.messageDialog(res.status.message, false);
            this.router.navigate([""]);
          } else {
            this.applyPetition = res.response;
            // this.applyPetition.docs[2].type = 'output';
            // this.applyPetition.docs[3].type = 'previous';
            this.setPreviousAndOutputDocs(this.applyPetition.docs);
            if (!this.applyPetition?.paymentReceipt?.status && this.transactionId) {
              this.commonSpinner = true;
              this.reloadLoopApplyNew();
            }
            else if (this.transactionId) {
              this.commonSpinner = false;
              this.cabinetService.messageDialog("Ödənişiniz uğurla təsdiqlənmişdir.", false);
              this.router.navigate(['new-appeal'], { queryParams: { id: this.id } });
            }
          }

        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }

  downloadFile(path) {
    this.downloadSpinner = true;
    this.cabinetService.download(path).subscribe(
      res => {
        let file = this.cabinetService.base64ToBlob(res.data, res.contentType, 512);
        var fileURL = URL.createObjectURL(file);
        var savefile = document.createElement("a");
        savefile.download = res.name;
        savefile.href = fileURL;
        savefile.click();
        this.downloadSpinner = false;
      },
      err => {
        this.cabinetService.messageDialog(err.error.status.message, false);
        this.downloadSpinner = false;
      })
  }

  reloadLoopApplyNew() {
    this.applyNewReload = setTimeout(() => {
      this.getApplyNew(true);
    }, 1000)
  }

  sendMeas(key, docName) {
    console.log(key);
    this.dialog.open(MeasDialogComponent, {
      width: '550px',
      position: {
        top: '110px'
      },
      data: { isMeas: true, doc_name: docName, doc_key: key },
      autoFocus: false,
    });
  }


  setPreviousAndOutputDocs(docs) {
    this.setApplySendDocForm();
    let previousDocs: any[] = docs.filter(x => x.type == 'previous');
    let outputDocs: any[] = docs.filter(x => x.type == 'output');
    this.requiredDocs = docs.filter(x => x.isRequired);

    this.applySendDocForm.controls.previousDocs.setValue(previousDocs.map(x => x.key));
    this.applySendDocForm.controls.outputDocs.setValue(outputDocs.map(x => x.key));
    this.formIsValid();

    this.cabinetService.getTempFiles(this.id).subscribe(
      res => {
        //console.log(res);
        res.data.forEach(data => {
          if (this.requiredDocs.find(x => x.key == data.key)) {
            //console.log(data)
            let blob_file = this.cabinetService.base64ToBlob(data.file, res.contentType, 512);
            //console.log(blob_file)
            var myFile = this.cabinetService.blobToFile(blob_file, data.fileName);
            //console.log(myFile)

            let formData = new FormData();
            formData.append(`key`, data.key);
            formData.append(`file`, myFile);
            formData.append(`index`, data.index);
            formData.append(`fileName`, data.fileName);
            formData.append(`fileSize`, data.fileSize);
            formData.append(`fileExt`, data.fileExt);
            formData.append(`hasMeas`, data.hasMeas);

            let c_data = {
              key: data.key,
              file: myFile,
              fileName: data.fileName,
              fileSize: data.fileSize,
              fileExt: data.fileExt,
              hasMeas: data.hasMeas,
              index: data.index ? data.index : this.index
            }

            this.currentDocs = this.currentDocs.filter(x => !(x.key == data.key && x.index == (data.index ? data.index : this.index)));
            this.currentDocs.push(c_data);
            this.applySendDocForm.controls.currentDocs.setValue(this.currentDocs);

          }

        });
        this.formIsValid();
      },
    );
  }

  setTitle() {
    this.TitleService.setTitle("QİYMƏTLİ KAĞIZLAR" + " | securities.e-cbar.az");
    const breadcrumb = { title: "Qi̇ymətli̇ Kağizlar", title2: "Yeni müraciət" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }

  cancelDialog() {
    this.dialog.open(CancelDialogComponent, {
      width: '400px',
      position: {
        top: '110px'
      },
      data: {
        id: this.id
      }
    });
  }

  // dyanamicDocumentForm() {
  //   console.log(this.documents.length);
  //   const numberOfdocument = this.documents.length || 0;
  //   if (this.t.length < numberOfdocument) {
  //     for (let i = this.t.length; i < numberOfdocument; i++) {
  //       this.t.push(this.fb.group({
  //         documentName: ['', Validators.required],
  //         fileSize: ['', Validators.required],
  //         fileName: ['', Validators.required]
  //       }));
  //     }
  //   } else {
  //     for (let i = this.t.length; i >= numberOfdocument; i--) {
  //       this.t.removeAt(i);
  //     }
  //   }
  // }
}
