import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { delay } from 'rxjs/operators';
import { AccountService } from 'src/app/home/components/home/account-service';
import { HomeService } from 'src/app/home/components/home/home.service';
import { UserRoles } from 'src/app/models/constants';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-access-list',
  templateUrl: './access-list.component.html',
  styleUrls: ['./access-list.component.scss']
})
export class AccessListComponent implements OnInit {
  saveButton =false
  showSpinner=false
  isChecked = true;
  width1 = 0;
  width = 100;
  displayedColumns: string[] = ['name', 'role', 'position',  'operation'];
  dataSource = []
  message=''
  Role=[]
  applicationForm: FormGroup;
  constructor(public router: Router,
    private cabinetService: Cabinet,
    private _sanitizer: DomSanitizer,
    private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, 
    private TitleService: Title,
    private fb: FormBuilder,
    private accountService: AccountService
    
  ) {
    this.cabinetService.clearStorage();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }
    this.accoountPermission();
  }
  ngOnInit(): void {
    this.applicationForm = this.fb.group({
      roleId: ['', Validators.required],
     });
    this.setTitle();
    this.getRoles();
    this.getdata();
    
  }

  accoountPermission(){
    this.accountService.accountPermission().subscribe(data => {
     
      if (data.status && data.status.errorCode == 0) {
        if(!(data.response==UserRoles.LEADER || data.response==UserRoles.FOUNDER||UserRoles.ACCESS_APPLY)){
            this.router.navigate(["/"]);
        }
      }

    });   
  }

  //set Title
  setTitle() {
      this.TitleService.setTitle("Müraciətlər" + " | securities.e-cbar.az");
      const breadcrumb = { title: "Müraciətlər" };
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
   
  }

getdata()
{
  //this.showSpinner=true
  
  this.accountService.getAccessUser(this.isChecked).subscribe(data => {
    if (data.status && data.status.errorCode == 0) {
      this.dataSource=data.data
    //  this.showSpinner=false
    }});
   
}

  async activeDeaktive(id,status){
    
  this.accountService.changeStatus(id,status).subscribe(data => {
    if (data.status && data.status.errorCode == 0) {
      this.message=data.status.message;
      this.getdata()
      
    }});
    
   
    await new Promise(f => setTimeout(f, 2500));
   this.message=''
}
getRoles(){
  this.cabinetService.emitentRole().subscribe(result => {
 this.Role=result.result;

  })

 }
 getselect(element){
  this.applicationForm.get("roleId").setValue(+element.roleId);
  document.getElementById('n'+element.id).style.display='none'
  document.getElementById('ye'+element.id).style.display='none'
  document.getElementById('s'+element.id).style.display='block'
  document.getElementById('y'+element.id).style.display='inline'
 }
 async changeRole(element){
  //this.showSpinner=true
  this.accountService.changeRole(element.id,this.applicationForm.getRawValue().roleId).subscribe(data => {
    if (data.status && data.status.errorCode == 0) {
      this.message=data.status.message;
      document.getElementById('n'+element.id).style.display='block'
      document.getElementById('ye'+element.id).style.display='inline'
      document.getElementById('s'+element.id).style.display='none'
      document.getElementById('y'+element.id).style.display='none'
      this.getdata()
      
    }});
    //this.showSpinner=false
    
    await new Promise(f => setTimeout(f, 2500));
    this.message=''
 }
}
