import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/home/components/home/account-service';
import { Cabinet } from '../../cabinet.service';

export interface ApplyLog {
  id: number;
  applyId: number;
  text: string;
  date: Date;
}

@Component({
  selector: 'app-historically-dialog',
  templateUrl: './historically-dialog.component.html',
  styleUrls: ['./historically-dialog.component.scss']
})
export class HistoricallyDialogComponent implements OnInit {
  id: number;
  showSpinner = false;
  applyLogs:ApplyLog[]=[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public cabinetService: Cabinet,
    public accountService: AccountService,
    public router: ActivatedRoute,
    public dialog: MatDialog) {

    this.id = data.id;

    this.showSpinner = true;


    this.cabinetService.u_getApplyLogs(this.id).subscribe(data => {
      for(let element of data.response.data){
        this.applyLogs.push(element);
      }
      this.showSpinner=false;
    });
  }
  ngOnInit(): void {
  }
}
