<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Bildiriş - {{data.applyNumber}}</h2>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close (click)="refreshPage()" class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="mat-typography justify-center">
        <div *ngIf="showSpinner" class="py-5">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
        <div *ngIf="!showSpinner" class=" text-font center mx-auto   font-weight-bold">
            <mat-accordion>
                <mat-expansion-panel (click)="readNotification(item.id)" *ngFor="let item of notifications" [style.background-color]="item.isRead ? 'lightGrey' : 'white'" class="mb-3">
                    <mat-expansion-panel-header [style.background-color]="item.isRead ? 'lightGrey' : 'white'">
                        <mat-panel-title>
                            <span class="mr-2 "> {{item.portalFull}}</span>
                            <span style="color:  #03A9D9;;">{{item.date | date: 'dd.MM.yyyy  HH:mm'}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p> {{item.text}}</p>
                </mat-expansion-panel>
                <ng-container *ngIf="notifications.length == 0">
                  <div class="d-flex justify-content-center">
                    <p [style.fontSize]="'16px'" [style.fontStyle]="'normal'">Boşdur.</p>
                  </div>
                </ng-container>
            </mat-accordion>
        </div>
    </mat-dialog-content>
</div>
