import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/home/components/home/home.service';
import { Cabinet } from '../../cabinet.service';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Static } from 'src/app/models/constants';
import { AccountService } from 'src/app/home/components/home/account-service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactDetailsComponent implements OnInit {
  title = 'Əlaqə məlumatları';
  constructor(
    private accountService: AccountService,
    public router: Router,
    private cabinetService: Cabinet,
    private _sanitizer: DomSanitizer,
    private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title
  ) {
    this.cabinetService.clearStorage();
  }

  ngOnInit(): void {
    this.setTitle(this.title);
    this.get_data();
  }
  qk_contact_details: any;
  get_data() {
    var key = Static.QK_CONTACT_DETAILS;
    if(!this.accountService.is_emitent()){
       key = Static.ANDERWRITER_CONTACT_DETAILS;
    }
    this.homeService.static(key).subscribe((data) => {
      //console.log(data);
      console.log(data);
      this.qk_contact_details = data.result;
      if (data.result.isPdf==false)
       {
        this.qk_contact_details['description'] = this.decodeReceipt(
          this.qk_contact_details['description']
        );
      }
      else{
              let file = this.Base64ToBlob(this.qk_contact_details["description"], 'application/pdf', 512);
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');
                this.router.navigate(["/"]);
       }
   
      console.log(this.qk_contact_details);

      this.qk_contact_details['description'] = this.qk_contact_details['description'].replaceAll("&lt;", "<")
      .replaceAll("&quot;", '"').replaceAll("&gt;", ">").replaceAll("&nbsp;", "");
      console.log(this.qk_contact_details['description']);

    });
  }

  public decodeReceipt(data) {
    return decodeURIComponent(
      atob(atob(data))
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  setTitle(title: string) {
    if (title) {
      this.TitleService.setTitle(title + ' | securities.e-cbar.az');
      const breadcrumb = { title: title };
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
    }
  }
  Base64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
}
