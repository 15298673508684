<form [formGroup]="applyForm" *ngIf="disableApply" class="pt-5">
    <div class="row pt-1">
        <div class="col-sm-6 col-md-4" *ngIf="disableApply.qkType">
            <label class="w-100">Qiymətli kağızın növü
                <sup class="text-danger star">*</sup></label>
            <mat-form-field class="w-100">
                <input  (click)="onKeyUp($event)"  matInput formControlName="qkType">
            </mat-form-field>

        </div>
        <div class="offset-md-1 col-sm-6 col-md-4 mt-3 mt-sm-0" *ngIf="disableApply.qkAnderrayter">
            <label class="w-100">Anderrayter <sup class="text-danger star">*</sup> </label>
            <mat-form-field class="w-100">
                <input  (click)="onKeyUp($event)"  matInput formControlName="qkAnderrayter">
            </mat-form-field>

        </div>
        <div class="col-md-4 col-sm-6 mt-3" *ngIf="disableApply.qkPlacement">
            <label class="w-100">Yerləşdirmə üsulu <sup class="text-danger star">*</sup> </label>
            <mat-form-field class="w-100">
                <input  (click)="onKeyUp($event)"  matInput formControlName="qkPlacement">
            </mat-form-field>

        </div>
        <!-- <div class="offset-md-1 col-sm-6 col-md-4 mt-3" *ngIf="disableApply.purposeApply">
            <label class="w-100">Müraciətin məqsədi <sup class="text-danger star">*</sup> </label>
            <mat-form-field class="w-100">
                <input matInput formControlName="purposeApply">
            </mat-form-field>
        </div> -->
        <div class="offset-md-1 col-sm-6 col-md-4 mt-3" *ngIf="disableApply.qkObserve">
            <label class="w-100">Müşayiət olunur <sup class="text-danger star">*</sup> </label>
            <mat-form-field class="w-100">
                <input  (click)="onKeyUp($event)"  matInput formControlName="qkObserve">
            </mat-form-field>
        </div>

        <div class="col-md-4 col-sm-6 mt-3" *ngIf="disableApply.baseEmission">
            <label class="w-100">Baza emissiya prospekti <sup class="text-danger star">*</sup> </label>
            <mat-form-field class="w-100">
                <input  (click)="onKeyUp($event)"  matInput formControlName="baseEmission">
            </mat-form-field>
        </div>
        <div *ngIf="disableApply.issueAmount" class="col-md-4 col-sm-6 mt-3">
            <label class="w-100">Emissiya məbləği
                <sup class="text-danger star">*</sup>
            </label>
            <mat-form-field class="w-100">
                <input  (click)="onKeyUp($event)"  matInput type="number" formControlName="issueAmount">
            </mat-form-field>

        </div>
        <div *ngIf="disableApply.issueAmount" class="offset-md-1 col-sm-6 col-md-4 mt-3">
            <label class="w-100" id="example-radio-group-label">Ödəniş növü <sup
                    class="text-danger star">*</sup> </label>
            <mat-radio-group  (click)="onKeyUp($event)"  [formControlName]="'paymentTypeId'" aria-labelledby="example-radio-group-label" class="example-radio-group">
                <mat-radio-button  class="w-100 example-radio-button" *ngFor="let item of paymentTypes" [value]="item.id">
                    {{item.name}}
                </mat-radio-button>
            </mat-radio-group>

        </div>
    </div>
</form>