<div class="conainer-fluid">
    <div class="row">
        <div class="col-12" *ngFor="let item of contractDocuments; let i = index">
            <form [formGroup]="contractDocumentForm" *ngIf="contractDocumentForm">
                <div class="row mt-3">

                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-2">
                                <img *ngIf="i == 0" src="./assets/images/timeline-green.svg" alt="">
                            </div>
                            <div class="col-10">
                                <h6 *ngIf="i == 0">{{activePortal}} <span class="text-info">{{nowDate | date:
                                        'dd.MM.yyyy'}}</span></h6>
                                <p class="mb-0">
                                    <span *ngIf="item.key != 'doc_in_20' && !item.signed">Göndərilmiş müqaviləni imzalayın.</span>
                                    <span *ngIf="item.key == 'doc_in_20'">Müqavilə nümayəndə tərəfindən imzalandıqda
                                        etibarnamə təqdim edilməlidir.</span>
                                </p>
                                <p  class="text-info " *ngIf="item.key != 'doc_in_20' && !item.signed">
                                    <img (click)="downloadFile(item.templatePath, item.key)" *ngIf="!(downloadSpinner && clickedKey == item.key)" src="./assets/images/DownloadSimple.png" alt="" class="cursor-pointer mr-2">
                                    <mat-spinner class="d-inline mr-4" *ngIf="clickedKey == item.key && downloadSpinner" [diameter]="20">
                                    </mat-spinner>
                                    <span (click)="downloadFile(item.templatePath, item.key)" class=" cursor-pointer">{{item.docName}}</span>
                                </p>

                                <p *ngIf="item.key != 'doc_in_20' && item.signed" class="text-info cursor-pointer contract_container">
                                    <img (click)="downloadSignedFile(item.file, item.docName)" *ngIf="!(downloadSpinner && clickedKey == item.key)" src="./assets/images/DownloadSimple.png" alt="" class="mr-2 cursor-pointer">
                                    <mat-spinner class="d-inline mr-4" *ngIf="clickedKey == item.key && downloadSpinner" [diameter]="20">
                                    </mat-spinner>
                                    <span class=" cursor-pointer"  (click)="downloadSignedFile(item.file, item.docName)">{{item.docName}} (adoc)</span>
                                    <img class=" cursor-pointer"  (click)="getDetailSign(item)" class="inf_ic" *ngIf="!(downloadSpinner && clickedKey == item.key)" src="./assets/images/info_icon.png" style="width: 20px; height: 20px;" alt="">


                                </p>



                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center" *ngIf="item.key != 'doc_in_20' && !item.signed">
                        <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
                        <div>
                            <button (click)="signDoc(item)" mat-button class="custom-btn">
                                <span>Sənədi imzala</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="item.key == 'doc_in_20'" class="col-md-4">
                        <label class="w-100">{{item.docName}}</label>
                        <div class="custom-file">
                            <input type="file" [accept]="acceptTypes" class="custom-file-input" #file formControlName="file" role='button' id="customFileLangHTML" (change)="setApplyContractDoc($event.target.files[0], item.key)">
                            <span class="custom-file-label" [ngClass]="{'check': file.value}">
                                Faylı seçin  və ya buraya sürükləyib buraxın
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 lbl-gray-txt mt-4 pt-1" *ngIf="item.key == 'doc_in_20' && getDocByKeyIndex(item.key, item.index)">
                        <p class="pl-4 pt-2 pb-2"> <b class="float-left">{{getDocByKeyIndex(item.key,
                                item.index).name}}</b> <b class="float-right">
                                [{{cabinetService.sizeToBytes(getDocByKeyIndex(item.key, item.index).size)}}]</b>
                        </p>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 d-flex justify-content-end" *ngIf="!showSpinner">
            <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
            <div>
                <button (click)="sendContractDoc()" mat-button class="custom-btn" [disabled]="hasRequest || isInValid">
                    <span>Göndər</span>
                </button>
            </div>
        </div>
    </div>
</div>