import { Component, OnInit, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Cabinet } from '../../cabinet.service';
import { AppConfig } from 'src/app.config';
import { AccountService } from 'src/app/home/components/home/account-service';
import { UserRoles } from 'src/app/models/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class SidebarComponent implements OnInit {
  isclicked: boolean
  isAdmin: boolean;
  asanSpinner: boolean;
  isCentralBank: boolean;
  measUrl: string;
  bfbUrl: string;
  is_admin: boolean = false;
  constructor(private cabinet: Cabinet,public accountService: AccountService,) {
    this.measUrl = AppConfig.settings.urls.measUrl;
    this.bfbUrl = AppConfig.settings.urls.bfbUrl;
    this.cabinet.clearStorage();
    if(this.accountService.is_emitent())
    this.accoountPermission();
  }
  ngOnInit() {
  }
  openMeas(){
    var URL1 = this.measUrl;
    window.open(URL1);
  }
  openBfb(){
    var URL2 = this.bfbUrl;
    window.open(URL2);
  }
  myAccount(){
    if (this.isclicked) { return; }
    this.asanSpinner = true;
    this.isclicked=true
    this.accountService.myAccounts().subscribe(data => {
      console.log(data);
      this.asanSpinner = false;
      this.isclicked = false;
      localStorage.setItem("email", this.accountService.getUserEmail().email);
      if (data.status && data.status.errorCode == 0) {
        this.accountService.openDialogAccounts(data.response.accounts);
      } else {
        this.cabinet.messageDialog(data.status.message, false);
      }

    },
      err => {
        this.cabinet.messageDialog('Serverdə xəta baş verdi', false);
      });   
  }
  opendialog(){
    this.accountService.openDialogNotfication();
    //this.accountService.openDialogGpp("test");
   // this.accountService.openDialogMeasOrCancel("test",true);
    //this.accountService.openDialogMeasOrCancel("test",false);
   // this.accountService.openDialogInfo()
  }

  accoountPermission(){
    this.accountService.accountPermission().subscribe(data => {
      console.log(data);
      if (data.status && data.status.errorCode == 0) {
        if(data.response==UserRoles.LEADER || data.response==UserRoles.FOUNDER  || data.response==UserRoles.ACCESS_APPLY){
          this.is_admin = true;
        }
      }

    });   
  }
}
