import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';
import { AsanDialog3Component } from '../asan-dialog-3/asan-dialog-3.component';

@Component({
  selector: 'app-asan-dialog-5',
  templateUrl: './asan-dialog-5.component.html',
  styleUrls: ['./asan-dialog-5.component.scss']
})
export class AsanDialog5Component implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  hasRequest: boolean;
  file_data;
  signature_data;
  downloadSpinner: boolean;
  clickedPath: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public cabinetService: Cabinet,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
    this.getDetailSign();
  }

  closeDialog() {
    window.location.reload();
  }
  
  ngOnInit(): void {
 
  }

  getDetailSign() {

    var send_obj = { containerBase64: this.data.realBase64 };
    this.cabinetService.getFiles(send_obj, this.id).subscribe(
      res => {
       this.file_data = res.response.files;
      });

    this.cabinetService.getSignatures(send_obj, this.id).subscribe(
      res => {
        // console.log(res);
        this.signature_data = res.response.signatures;
      });
  }

  downloadFile(base64, docName) {
    this.clickedPath = base64;
    this.downloadSpinner = true;
    let file = this.cabinetService.base64ToBlob(base64, "application/pdf", 512);
    var fileURL = URL.createObjectURL(file);
    var savefile = document.createElement("a");
    savefile.download = docName;
    savefile.href = fileURL;
    savefile.click();
    this.downloadSpinner = false;
  }

}
