<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2 *ngIf="data.isMeas">MEAS-da açıqla</h2>
            <h2 *ngIf="!data.isMeas">Bildiriş</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="mat-typography justify-center">
        <div class=" text-font center mx-auto   font-weight-bold">
            <p *ngIf="data.isMeas">{{data.doc_name}} MEAS-da açıqlanmaq üçün Mərkəzi Banka göndəriləcəkdir.</p>
            <p *ngIf="!data.isMeas">Müraciətə baxılmanın dayandırılmasına əminsinizmi?</p>

        </div>

        <mat-dialog-actions *ngIf="data.isMeas" align="end" class="mt-2">
            <button mat-stroked-button class=" btn btn1-default my-2 " [mat-dialog-close]="true" (click)="confirm()"><span translate>Göndər</span></button>
        </mat-dialog-actions>
        <mat-dialog-actions *ngIf="!data.isMeas" align="end" class="mt-2">
            <button mat-stroked-button class=" btn btn1-default my-2 " [mat-dialog-close]="true" (click)="cancel()"><span translate>Təsdiqlə</span></button>
        </mat-dialog-actions>

    </mat-dialog-content>

</div>