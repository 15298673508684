<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2 class="text-upper">Məlumat</h2>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>

    <mat-dialog-content class="mat-typography justify-center">
        <div style="font-size: 16px; line-height: 30px; width: 95%; text-align: justify ; "
            class=" text-font center mx-auto">
            <p  class="p">
                
            </p>
        </div>
    </mat-dialog-content>
</div>