<div *ngFor="let item of newRequiredDocs; let i = index">
    <form class="mb-0" [formGroup]="newRequiredForm" *ngIf="newRequiredForm">
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-2">
                        <img class="position-absolute" *ngIf="i == 0" src="./assets/images/timeline-orange.svg" alt="">
                    </div>
                    <div class="col-10">
                        <h6 *ngIf="i == 0">{{activePortal}} <span class="text-info">{{item.date | date:
                                'dd.MM.yyyy'}}</span></h6>
                        <i [style.fontSize]="'14px'" [style.color]="'rgba(0, 0, 0, 0.54)'">Tələb olunan sənədi sistemə yükləyin.</i>
                        <p [innerHTML]="item.comment" [style.marginTop]="'8px'"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label class="w-100">{{item.docName}}</label>
                <div class="custom-file">
                    <input type="file" [accept]="acceptTypes" class="custom-file-input" #file formControlName="file" role='button' id="customFileLangHTML" (change)="setApplyNewRequiredDoc($event.target.files[0], item.key, item.isSystem)">
                    <span class="custom-file-label" *ngIf="!file.value else elseBlockShow">Faylı seçin  və ya buraya
                        sürükləyib buraxın
                    </span>
                    <ng-template #elseBlockShow>
                        <span class="custom-file-label check">Faylı seçin  və ya buraya
                            sürükləyib buraxın</span>
                    </ng-template>
                </div>
            </div>
            <div class="col-md-4 d-flex c-mt">
                <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="file.value && hasRequest && item.index == clickedIndex"></mat-spinner>
                <div>
                    <button (click)="sendNewRequiredDoc(item.index)" mat-button class="custom-btn" [disabled]="item.index == clickedIndex && hasRequest || !file.value">
                        <span>Göndər</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- APPLY WAITING DOCUMENT -->
<app-wait-document *ngIf="status=='checking' && newRequiredDocs?.length == 0"> </app-wait-document>
<!-- APPLY WAITING DOCUMENT-->