import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/home/components/home/account-service';
import { TokenModel } from 'src/app/models/TokenModel';
import { Cabinet } from '../../cabinet.service';
import { NewCompanyDialogComponent } from '../newCompany/new-company-dialog.component';

@Component({
  selector: 'app-select-company-dialog',
  templateUrl: './select-company-dialog.component.html',
  styleUrls: ['./select-company-dialog.component.scss']
})
export class SelectCompanyDialogComponent implements OnInit {

  hasProcess: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public cabinetService: Cabinet, public accountService: AccountService, public router: Router, public dialog: MatDialog) {
   
    //console.log(data)
    if(!data.accounts){
      this.router.navigate(["/"]);
    }
    
   }

  ngOnInit(): void {
  }
  loginAccount(tin, companyId, type){
    this.hasProcess = true;
    let model = new TokenModel();
    model.companyId = companyId;
    model.tin = tin;
    model.type = type;
    if(localStorage.getItem("email")){
      model.isNormalLogin = true;
      model.email = localStorage.getItem("email");
    }
    
    model.userToken = localStorage.getItem("asanToken");
    this.accountService.generateToken(model).subscribe(data => {
      if (data.status && data.status.errorCode == 1) {
        this.cabinetService.messageDialog(data.status.message, false);
      } else {
        this.accountService.createJWT(data.response, "appeals");
        localStorage.removeItem("asanToken");
        localStorage.removeItem("email");
        this.hasProcess = false;
        this.dialog.closeAll();
        this.router.navigate(['/'])
      }
    },
      err => {
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }

  openNewCompany(){
    this.accountService.openDialogNewCompany();
  }

}