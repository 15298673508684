<div class="row p-3 title">
    <div class="col-10">
        <h2>Mesaj tarixçəsi</h2>
    </div>
    <div class="col-2">
        <mat-dialog-actions align="end">
            <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
        </mat-dialog-actions>
    </div>
</div>
<mat-dialog-content class="m-0">
    <mat-tab-group mat-stretch-tabs mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">
        <mat-tab *ngFor="let portal of appealMessage?.portals" [label]="portal" (click)="readMessage(portal)">
            <div class="px-3" *ngIf="!showSpinner">
                <div class="row" *ngFor="let item of getDocsByPortal(portal)">
                    <div class="col-12 py-3" [ngClass]="{'bg-light':item.isRead}">
                        <span style="color: #03A9D9;">{{item.date | date: 'dd.MM.yyyy hh:mm'}}</span> - <span>
                            {{item.text}}</span>
                    </div>
                    <hr class="w-100">
                </div>
            </div>
            <div *ngIf="showSpinner" class="py-5">
                <mat-spinner style="margin: auto!important;" [diameter]="70">
                </mat-spinner>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div *ngIf="!showSpinner && !appealMessage?.portals?.length">
        <p class="text-center pt-4">Mesaj mövcud deyil</p>
    </div>
</mat-dialog-content>