<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">

            <h2>Müqavilə detalları</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button (click)="closeDialog()" class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="pt-4">
        <mat-spinner *ngIf="!(file_data && signature_data)" class="my_spinner" [diameter]="70">
        </mat-spinner>

        <ng-container *ngIf="signature_data && file_data">
            <div class="row" *ngFor="let el of file_data">
                <h1 class="col-4 gpp-head">Sənəd: </h1>
                <div style="padding: 4px 5px 4px 0">
                    <mat-spinner *ngIf="clickedPath == el.fileBodyBase64 && downloadSpinner" [diameter]="20">
                    </mat-spinner>
                    <span *ngIf="!(clickedPath == el.fileBodyBase64 && downloadSpinner)" (click)="downloadFile(el.fileBodyBase64, el.fileName)" class="material-symbols-outlined cursor-pointer">
                      file_download
                    </span>
                </div>
                <p style="cursor: pointer;" class="col-7 gpp-body" (click)="downloadFile(el.fileBodyBase64, el.fileName)">{{el.fileName}}

                </p>


            </div>
            <hr>
        </ng-container>

        <ng-container *ngIf="signature_data && file_data">

            <ng-container *ngFor="let el of signature_data">
                <div class="row">
                    <h1 class="col-12 company-title">{{el.signerCompany}}</h1>
                </div>
                <div class="row">
                    <h1 class="col-4 gpp-head">İmzalayan şəxs: </h1>
                    <p class="col-8 gpp-body">{{el.signerModel}}</p>
                </div>

                <div class="row">
                    <h1 class="col-4 gpp-head">İmzalanma tarixi: </h1>
                    <p class="col-8 gpp-body">{{el.signingTime | date: 'dd.MM.yyyy'}}
                    </p>
                </div>
            </ng-container>
        </ng-container>



    </mat-dialog-content>

</div>