import { Component, OnInit, Injectable, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-disable-apply',
  templateUrl: './disable-apply.component.html',
  styleUrls: ['./disable-apply.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class DisableApplyComponent implements OnInit {
  disableApply: any;
  @Input("disableApply") set applySet(value: any) {
    this.disableApply = value;
  }
  constructor(
    public fb: FormBuilder,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
  }
}
