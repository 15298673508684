import { Component, OnInit, Injectable, ViewChild } from '@angular/core';

import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/home/components/home/home.service';
import { AccountService } from 'src/app/home/components/home/account-service';


@Component({
  selector: 'app-cabinet-home',
  templateUrl: './new-appeal-list.component.html',
  styleUrls: ['./new-appeal-list.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppealList implements OnInit {
  applyTypes: any;
  showSpinner: boolean;
  constructor(public router: Router,
    private cabinetService: Cabinet,
    private _sanitizer: DomSanitizer,
    private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private accountService: AccountService

  ) {
    this.cabinetService.clearStorage();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }
    this.getApplyTypes();
  }
  ngOnInit(): void {
    this.setTitle();
  }
  //set Title
  setTitle() {
    this.TitleService.setTitle("QİYMƏTLİ KAĞIZLAR" + " | securities.e-cbar.az");
    const breadcrumb = { title: "Qi̇ymətli̇ Kağizlar", title2: "Yeni müraciət" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }
  getApplyTypes() {
    this.showSpinner = true;
    this.cabinetService.getApplyTypes().subscribe((data => {
      this.applyTypes = data.result;
      this.showSpinner = false;
      console.log(data.result)
    }));
  }
  goToNewApply(type) {
    this.router.navigate(['new-appeal'], { queryParams: { type: `${type}` } });
  }
}
