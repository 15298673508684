import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { InfoDialogComponent } from 'src/app/cabinet/dialogs/info-dialog/info-dialog.component';
import { GppDialogComponent } from 'src/app/cabinet/dialogs/gpp-dialog/gpp-dialog.component';
import { MeasDialogComponent } from 'src/app/cabinet/dialogs/meas-dialog/meas-dialog.component';
import { AppConfig } from 'src/app.config';


@Component({
  selector: 'app-required-document',
  templateUrl: './required-document.component.html',
  styleUrls: ['./required-document.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  hasRequest: boolean;
  currentDocs: any[] = [];
  index: number = 1;
  showSpinner: boolean;
  applySendDocForm: FormGroup;
  accountForm: FormGroup;
  selectAtribute: any;
  nowDate = new Date();
  accountOptions = [
    {
      'name': 'Bəli',
      'key': 1
    },
    {
      'name': 'Xeyr',
      'key': 0
    }
  ]
  requiredDoc: any;
  isInValid: boolean;
  requiredDocs: any[];
  downloadSpinner: boolean;
  clickedPath: any;
  acceptType = AppConfig.settings.fileSettings.acceptFiles;
  acceptTypes = "";

  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }

  ngOnInit(): void {
    // this.getApplyNew();
    this.getAtributeSelect();
    this.setAccountForm();
    this.setAcceptTypes();
  }

  setAcceptTypes() {
    for (let index = 0; index < this.acceptType.length; index++) {
      const item = this.acceptType[index];
      if (this.acceptType.length - 1 >= index)
        this.acceptTypes += ("." + item + ", ")
    }
  }

  setApplySendDocForm() {
    this.applySendDocForm = this.fb.group({
      previousDocs: [[],],
      outputDocs: [[],],
      currentDocs: [[],],
    })
  }

  goNext() {
    if (this.accountForm.invalid) return;
    if (this.id) {
      let obj = {
        'modifications': {
          "isNext": {
            "type": "Long",
            "value": 0
          },
          "has_account": {
            "type": "Long",
            "value": this.accountForm.controls.has_account.value
          }
        }
      }
      this.showSpinner = true;
      this.cabinetService.goNext(obj, this.id).subscribe(
        res => {
          this.showSpinner = false;
          this.getRequiredDocs();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }

  formIsValid() {
    this.isInValid = false;
    this.requiredDocs.forEach(x => {
      if (!this.currentDocs.some(y => y.key == x.key)) {
        this.isInValid = true;
      }
    })
  }

  downloadFile(path) {
    this.clickedPath = path;
    this.downloadSpinner = true;
    this.cabinetService.download(path).subscribe(
      res => {
        let file = this.cabinetService.base64ToBlob(res.data, res.contentType, 512);
        var fileURL = URL.createObjectURL(file);
        var savefile = document.createElement("a");
        savefile.download = res.name;
        savefile.href = fileURL;
        savefile.click();
        this.downloadSpinner = false;
      },
      err => {
        this.cabinetService.messageDialog(err.error.status.message, false);
        this.downloadSpinner = false;
      })
  }

  setAccountForm() {
    this.accountForm = this.fb.group({
      has_account: [, [Validators.required]],
      isNext: [0, [Validators.required]]
    })
  }

  setApplySendDoc(file, key, index, hasMeas, extHover, extHoverMessage) {
    if (!file) return;
    if (!this.cabinetService.checkFile(file)) return;
    if (extHover && !this.cabinetService.checkAdoc(file, extHover, extHoverMessage)) return;
    this.index++;
    let data = {
      key: key,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      fileExt: file.name.split('.').pop(),
      hasMeas: hasMeas,
      index: index ? index : this.index
    }

    let formData = new FormData();
    formData.append(`key`, data.key);
    formData.append(`file`, data.file);
    formData.append(`index`, data.index);
    formData.append(`fileName`, data.fileName);
    formData.append(`fileSize`, data.fileSize);
    formData.append(`fileExt`, data.fileExt);
    formData.append(`hasMeas`, data.hasMeas);
    this.cabinetService.sendTempFile(formData, this.id).subscribe(
      res => {

        console.log(res);
      },
    );

    this.currentDocs = this.currentDocs.filter(x => !(x.key == key && x.index == (index ? index : this.index)));
    this.currentDocs.push(data);
    this.applySendDocForm.controls.currentDocs.setValue(this.currentDocs);
    this.formIsValid();
  }

  otherDocs() {
    this.requiredDoc.docs.push({ docName: 'Digər', key: 'doc_in_9', type: 'current', index: this.index, hasMeas: false  })
  }

  getDocByKeyIndex(key, index) {
    return this.currentDocs.find(x => (x.key == 'doc_in_9' && x.key == key && x.index == index) || (x.key != 'doc_in_9' && x.key == key))?.file;
  }

  sendApply() {
    if (this.isInValid) return;
    let obj = {
      'modifications': {
        "isNext": {
          "type": "Long",
          "value": 1
        },
        "has_account": {
          "type": "Long",
          "value": this.accountForm.controls.has_account.value
        }
      }
    }

    let formData = new FormData();
    let currentDocs: any[] = this.applySendDocForm.controls.currentDocs.value;
    let outputDocs: any[] = this.applySendDocForm.controls.outputDocs.value;
    let previousDocs: any[] = this.applySendDocForm.controls.previousDocs.value;

    currentDocs.forEach((val, i) => {
      formData.append(`currentDocs[${i}].key`, val.key);
      formData.append(`currentDocs[${i}].file`, val.file);
      formData.append(`currentDocs[${i}].fileName`, val.fileName);
      formData.append(`currentDocs[${i}].fileSize`, val.fileSize);
      formData.append(`currentDocs[${i}].fileExt`, val.fileExt);
      formData.append(`currentDocs[${i}].hasMeas`, val.hasMeas);
    });
    formData.append(`variablesStr`, JSON.stringify(obj));

    outputDocs.forEach((val, i) => formData.append(`outputDocs[${i}]`, val));
    previousDocs.forEach((val, i) => formData.append(`previousDocs[${i}]`, val));
    this.hasRequest = true;
    this.cabinetService.sendApply(formData, this.id).subscribe(
      res => {
        this.cabinetService.sendEmail(this.id, true).subscribe(
          ress => {
           });        
        window.location.reload();
      },
      err => {
        if (err.error.status.errorCode != 0)
          this.cabinetService.messageDialog(err.error.status.message, false);
        this.hasRequest = false;
      })
  }


  sendMeas(key, docName){
    console.log(key);
    this.dialog.open(MeasDialogComponent, {
      width: '550px',
      position:{
        top:'110px'
      },
      data: { isMeas: true, doc_name: docName, doc_key: key },
      autoFocus: false,
    });
  }
  
  getAtributeSelect() {
    if (this.id) {
      // this.showSpinner = true;
      this.cabinetService.getAtributeSelect(this.id).subscribe(
        res => {
          this.selectAtribute = res.response;
          if (!this.selectAtribute)
            this.getRequiredDocs();
            
          // this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          // this.showSpinner = false;
        })
    }
  }

  getRequiredDocs() {
    this.setApplySendDocForm();
    if (this.id) {
      this.showSpinner = true;
      this.cabinetService.getRequiredDocs(this.id).subscribe(
        res => {
          this.requiredDoc = res.response;
          this.setPreviousAndOutputDocs(this.requiredDoc.docs);
          this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }

  setPreviousAndOutputDocs(docs) {
    let previousDocs: any[] = docs.filter(x => x.type == 'previous');
    let outputDocs: any[] = docs.filter(x => x.type == 'output');
    this.requiredDocs = docs.filter(x => x.isRequired);

    this.applySendDocForm.controls.previousDocs.setValue(previousDocs.map(x => x.key));
    this.applySendDocForm.controls.outputDocs.setValue(outputDocs.map(x => x.key));
    this.formIsValid();

    this.cabinetService.getTempFiles(this.id).subscribe(
      res => {
        //console.log(res);
        res.data.forEach(data => {
          if (this.requiredDocs.find(x => x.key == data.key)) {
            //console.log(data)
            let blob_file = this.cabinetService.base64ToBlob(data.file, res.contentType, 512);
            //console.log(blob_file)
            var myFile = this.cabinetService.blobToFile(blob_file, data.fileName);
            //console.log(myFile)

            let formData = new FormData();
            formData.append(`key`, data.key);
            formData.append(`file`, myFile);
            formData.append(`index`, data.index);
            formData.append(`fileName`, data.fileName);
            formData.append(`fileSize`, data.fileSize);
            formData.append(`fileExt`, data.fileExt);
            formData.append(`hasMeas`, data.hasMeas);

            let c_data = {
              key: data.key,
              file: myFile,
              fileName: data.fileName,
              fileSize: data.fileSize,
              fileExt: data.fileExt,
              hasMeas: data.hasMeas,
              index: data.index ? data.index : this.index
            }

            this.currentDocs = this.currentDocs.filter(x => !(x.key == data.key && x.index == (data.index ? data.index : this.index)));
            this.currentDocs.push(c_data);
            this.applySendDocForm.controls.currentDocs.setValue(this.currentDocs);
            this.formIsValid();

          }

        });
      },
    );
   

  }
}
