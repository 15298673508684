import { Component, OnInit, Injectable, ViewChild, AfterViewInit } from '@angular/core';

import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/home/components/home/home.service';
import { FormArray, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { PortalType } from 'src/app/models/constants';
import { MatDialog } from '@angular/material/dialog';
import { ApplyDocumentDialogComponent } from '../../dialogs/applyDocument/apply-document-dialog.component';
import { AccountService } from 'src/app/home/components/home/account-service';

@Component({
  selector: 'app-cabinet-home',
  templateUrl: './appeal-detail.component.html',
  styleUrls: ['./appeal-detail.component.scss']
})
@Injectable({
  providedIn: 'root'
})

export class AppealDetail implements OnInit {
  stepLabel:any;
  testData =
    {
    "id": "2e59d947-0c80-11ec-b91a-00059a3c7a00",
    "isCompleted": false,
	   "activeStepKey": "MB",
	   "activeStepNum": 1,
	   "steps": [
	   	{
	   		"num" : 1,
	   		"key": "MB"
	   	},
	   	{
	   		"num" : 2,
	   		"key": "MDM"
	   	},
	   	{
	   		"num" : 3,
	   		"key": "BFB"
	   	}
	   ]}
     ;
     steps =  [
      {
        "num" : 1,
        "key": "MB"
      },
      {
        "num" : 2,
        "key": "MDM"
      },
      {
        "num" : 3,
        "key": "BFB"
      }
    ]
   documents = [
     {name:"Qiymətli kağızın çıxarışı",fileName:"abc_chixarish.pdf",fileSize:"154 KB"},
     {name:"EM və İM təsdiqi barədə bildirişı",fileName:"abc_chixarish.pdf",fileSize:"154 KB"},
     {name:"Qiymətli kağız mülkiyyətçilərinin reyestri sənədi  ",fileName:"abc_chixarish.pdf",fileSize:"154 KB"},
     {name:"Milli Depozit Mərkəzi ilə müqavilə",fileName:"abc_chixarish.pdf",fileSize:"154 KB"},
   ]
    @ViewChild('stepper') stepper: MatStepper;
  constructor(public router: Router,
    private cabinetService: Cabinet,
    private _sanitizer: DomSanitizer,
    private homeService: HomeService,
    public dialog: MatDialog,
    private accountService: AccountService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, 
    private TitleService: Title

  ) {
    this.cabinetService.clearStorage();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }
  }
  ngOnInit(): void {
    this.setTitle();
  }
  public get portalType(): typeof PortalType {
    return PortalType;
  }
  //set Title
  setTitle() {
      this.TitleService.setTitle("Müraciətlər" + " | securities.e-cbar.az");
      const breadcrumb = { title: "Müraciətlər", title2: "Müraciət"};
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

  }
  openDialog() {
    const dialogRef = this.dialog.open(ApplyDocumentDialogComponent,{
      position:{
        top:'110px'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
