import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { HomeService } from 'src/app/home/components/home/home.service';
import { Cabinet } from '../../cabinet.service';
import { Static } from 'src/app/models/constants';
import { AccountService } from 'src/app/home/components/home/account-service';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent implements OnInit {
  title="İstifadəçi təlimatı";
  constructor(private accountService: AccountService, public router: Router,private cabinetService: Cabinet, private _sanitizer: DomSanitizer, private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private TitleService: Title) {
      this.cabinetService.clearStorage();
     }

  ngOnInit(): void {
    this.setTitle(this.title);
    this.get_data();
  }

  qk_user_manual:any;
  get_data() {
    var key = Static.QK_USER_MANUAL;
    if(!this.accountService.is_emitent()){
       key = Static.ANDERWRITER_USER_MANUAL;
    }
    this.homeService.static(key).subscribe(
      ((data) => {
        //console.log(data);
        this.qk_user_manual = data.result;
        if (data.result.isPdf==false) {
            
          this.qk_user_manual["description"] = this.decodeReceipt(this.qk_user_manual["description"])
        
        }
        else{
                let file = this.Base64ToBlob(this.qk_user_manual["description"], 'application/pdf', 512);
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL, '_blank');
                  this.router.navigate(["/"]);
         }
        this.qk_user_manual["description"] = this.decodeReceipt(this.qk_user_manual["description"])
      }
      ));
    }
  
    public decodeReceipt(data) {
      return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }


  setTitle(title: string) {
    if (title) {
      this.TitleService.setTitle(title + " | securities.e-cbar.az");
      const breadcrumb = { title: title };
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
    }
  }
  Base64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
}
