<form [formGroup]="accountForm" *ngIf="selectAtribute == 'has_account'">
    <div class="row mt-3">
        <div class="col-md-4">
            <label class="w-100">MDM-də emitent hesabınız var ?</label>
            <mat-form-field class="w-100">
                <mat-select formControlName="has_account" (selectionChange)="goNext()">
                    <mat-option [value]="">--- Seçin ---</mat-option>
                    <mat-option *ngFor="let item of accountOptions" [value]="item.key">{{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error class="pt-1"
                *ngIf="!accountForm.controls.has_account.valid && accountForm.controls.has_account.touched">
                Seçilməlidir
            </mat-error>
        </div>
    </div>
</form>

<div class="div" *ngIf="!showSpinner">
    <div class="col-12" *ngFor="let doc of requiredDoc?.docs; let i = index">
        <form class="mb-0" [formGroup]="applySendDocForm" *ngIf="applySendDocForm">
            <div class="row">
                <div style="width: 15px;" *ngIf="i == 0">
                    <img class="position-absolute" src="./assets/images/timeline-orange.svg" alt="">
                </div>
                <div
                    [ngClass]="i == 0 ? 'col-11 pl-3' : requiredDoc.activePortal == 'Baki Fond Birjası' ? 'col-11 ml-3' : 'col-11 ml-4'">
                    <h6 class="ml-2" *ngIf="i == 0">{{requiredDoc.activePortal}} <span class="text-info">{{nowDate |
                            date:
                            'dd.MM.yyyy HH:mm'}}</span></h6>
                    <div class="row" *ngIf="doc.isView">
                        <div class=" col-12" *ngIf="doc.color=='light_blue'" style="color:#03A9D9"
                            [ngClass]="i == 0 ? 'pl-1 ml-3' : ''">
                            <img class="cursor-pointer" (click)="downloadFile(doc.path)"
                                *ngIf="!(downloadSpinner && clickedPath == doc.path)"
                                src="../../../../assets/images/download_light_blue.svg" alt="" style="width: 24px">
                            <mat-spinner class="d-inline mr-4" *ngIf="clickedPath == doc.path && downloadSpinner"
                                [diameter]="20">
                            </mat-spinner>
                            <span class="cursor-pointer" (click)="downloadFile(doc.path)" style="margin-left:10px">
                                {{doc.docName}}</span>
                        </div>

                        <div class=" col-12" *ngIf="doc.color=='dark_blue'" [style.color]="'#1C3651'"
                            [ngClass]="i == 0 ? '' : 'pl-1 ml-2'">
                            <img class="cursor-pointer" (click)="downloadFile(doc.path)"
                                *ngIf="!(downloadSpinner && clickedPath == doc.path)"
                                src="../../../../assets/images/download_dark_blue.svg" alt="" style="width: 24px">
                            <mat-spinner class=" d-inline mr-4" *ngIf="downloadSpinner && clickedPath == doc.path"
                                [diameter]="20">
                            </mat-spinner>
                            <span class="cursor-pointer" (click)="downloadFile(doc.path)" style="margin-left:10px">
                                {{doc.docName}}</span>
                        </div>

                        <div class="cursor-pointer col-12" *ngIf="doc.color=='middle_blue'" [style.color]="'#337AB7'"
                            [ngClass]="i == 0 ? 'pl-1 ml-3' : ''">
                            <img (click)="downloadFile(doc.path)" *ngIf="!(downloadSpinner && clickedPath == doc.path)"
                                src="../../../../assets/images/download_middle_blue.svg" alt="" style="width: 24px">
                            <mat-spinner class="d-inline mr-4" *ngIf="downloadSpinner && clickedPath == doc.path"
                                [diameter]="20">
                            </mat-spinner>
                            <span (click)="downloadFile(doc.path)" style="margin-left:10px"
                                [ngStyle]="doc.hasMeas && !doc.measDate && {'padding-top': '5px'} ">
                                {{doc.docName}}</span>
                            <span *ngIf="doc.hasMeas && doc.measDate" style="padding-left: 5px; color: #03A9D9;">
                                (MEAS-da açıqlanmaq üçün təqdim edilmişdir-{{doc.measDate| date: 'dd.MM.yyyy'}})
                            </span>
                            <button *ngIf="doc.hasMeas && !doc.measDate" (click)="sendMeas(doc.key, doc.docName)"
                                class="cursor-pointer col-6 p-0 m-2" mat-button class="custom-btn">
                                <span>MEAS-da açıqla</span>
                            </button>

                        </div>



                    </div>
                    <div class="row" *ngIf="!doc.isView">
                        <div class="custom-file col-md-4 col-sm-6 ml-3 p-0">
                            <input type="file" [accept]="acceptTypes" class="custom-file-input" #file role='button'
                                id="customFileLangHTML"
                                (change)="setApplySendDoc($event.target.files[0], doc.key, doc.index, doc.hasMeas, doc.extHover, doc.extHoverMessage)"
                                title="{{ doc?.docHover && (doc?.docHover.trim() != '') ? doc?.docName +': '+doc?.docHover : doc?.docName }}">
                            <span class="custom-file-label" *ngIf="!file.value else elseBlockShow">
                                <span style="margin-left:10px"
                                    *ngIf="getDocByKeyIndex(doc.key, doc.index)?.name && doc.hasTemplate else elseBlockShow">
                                    {{getDocByKeyIndex(doc.key, doc.index).name}}
                                </span>
                                <ng-template #elseBlockShow>
                                    <span style="margin-left:10px"> {{doc.docName.substring(0, 49)}}</span>
                                </ng-template>
                                <sup *ngIf="doc.isRequired" class="text-danger star">*</sup>
                            </span>
                            <ng-template #elseBlockShow>
                                <span class="custom-file-label check">
                                    <span style="margin-left:10px"
                                        *ngIf="getDocByKeyIndex(doc.key, doc.index)?.name && doc.hasTemplate else elseBlockShow">
                                        {{getDocByKeyIndex(doc.key, doc.index).name}}
                                    </span>
                                    <ng-template #elseBlockShow>
                                        <span style="margin-left:10px"> {{doc.docName}}</span>
                                    </ng-template>
                                    <sup *ngIf="doc.isRequired" class="text-danger star">*</sup>
                                </span>
                            </ng-template>
                        </div>

                        <div class="d-flex ext-hover">
                            <i *ngIf="doc.extHover" class="fa-solid fa-circle-info my-auto cursor-pointer"
                                [title]="doc.extHoverMessage" style="color: #03a9d9;"></i>
                        </div>

                        <div style="position: relative;" class="  col-md-4 col-sm-6 p-0 mt-2" *ngIf="doc.hasTemplate"
                            [style.color]="'#03A9D9'">
                            <span
                                style="margin-left:10px; position:absolute; top: -30px;left: 27px; color: #847c7c;">Nümunəvi
                                formanı yükləyin.</span>
                            <img class="cursor-pointer" (click)="downloadFile(doc.templatePath)"
                                *ngIf="!(downloadSpinner && clickedPath == doc.templatePath)"
                                src="../../../../assets/images/download_light_blue.svg" alt="" style="width: 24px">
                            <mat-spinner class="d-inline mr-4"
                                *ngIf="downloadSpinner && clickedPath == doc.templatePath" [diameter]="20">
                            </mat-spinner>
                            <span class="cursor-pointer" (click)="downloadFile(doc.templatePath)"
                                style="margin-left:10px"> {{doc.docName}}</span>

                        </div>
                        <div class="col-md-7 col-sm-6 lbl-gray-txt"
                            *ngIf="getDocByKeyIndex(doc.key, doc.index) && !doc.hasTemplate">
                            <p class="pl-4 pt-2 pb-2"> <b class="float-left">{{getDocByKeyIndex(doc.key,
                                    doc.index).name}}</b> <b class="float-right">
                                    [{{cabinetService.sizeToBytes(getDocByKeyIndex(doc.key, doc.index).size)}}]</b>
                            </p>
                        </div>
                    </div>
                    <div class="row mt-3 pl-3" style="position:relative">
                        <img *ngIf="requiredDoc && !showSpinner && i == requiredDoc?.docs.length - 1"
                            (click)="otherDocs()" src="../../../../assets/images/add_circle_fill.svg"
                            class="add-circle-fill cursor-pointer mt-2" alt="">
                        <p *ngIf="requiredDoc && !showSpinner && i == requiredDoc?.docs.length - 1"
                            style="position:absolute; top: 10px; left: 50px;">
                            <i *ngIf="requiredDoc && !showSpinner && i == requiredDoc?.docs.length - 1"
                                style="color: rgba(0, 0, 0, 0.54);">Müraciət üçün əlavə sənədin daxil edilməsinə ehtiyac
                                varsa, "+" düyməsi üzərinə klik edərək daxil olunacaq sənəd(lər) üçün əlavə bölmə(lər)
                                yarada bilərsiniz. Qeyd etmək lazımdır ki, yuxarıda müraciət üçün tələb olunan əsas
                                sənədlərdən fərqli olaraq daxil etdiyiniz əlavə sənədləri yüklədikdən sonra səhifəni
                                yeniləsəniz sənədlər yadda saxlanılmayacaqdır.</i>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="row" *ngIf="showSpinner">
    <div class="col-md-12 bg-white">
        <mat-spinner style="margin: auto!important;" [diameter]="70">
        </mat-spinner>
    </div>
</div>
<div class="d-flex justify-content-end">
    <mat-spinner class="mr-2 mt-2" [diameter]="20" *ngIf="hasRequest">
    </mat-spinner>
    <button (click)="sendApply()" mat-button class="custom-btn"
        [disabled]="!requiredDoc || hasRequest || isInValid || showSpinner" type="submit">
        <span>Müraciət göndər</span>
    </button>
</div>