import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { InfoDialogComponent } from 'src/app/cabinet/dialogs/info-dialog/info-dialog.component';
import { GppDialogComponent } from 'src/app/cabinet/dialogs/gpp-dialog/gpp-dialog.component';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-wait-contract',
  templateUrl: './wait-contract.component.html',
  styleUrls: ['./wait-contract.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class WaitContractComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];


  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
   
  }





}
