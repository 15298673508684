<div *ngIf="commonSpinner" class="common-spinner col-md-12 bg-white py-5">
    <div class="inner-spinner vh-100 d-flex">
        <mat-spinner style="margin: auto!important; transform: translateY(-120%)" [diameter]="70">
        </mat-spinner>
    </div>
</div>
<div class="container-fluid border shadow-sm my-3 ">
    <div class="row" *ngIf="!showSpinner && applyPetition">
        <div class="col-12 bg-white px-4 pt-4 pb-5 ">
            <h1 class="font-weight-bold cabinet-title">Yeni müraciət</h1>
            <span
                class="cabinet-sub-title pl-1 ">{{applyPetition.disableApply?.applyTypeId+'.'+applyPetition.disableApply?.applyType}}</span>
            <button mat-button class="custom-btn ex-button"
                style="position: absolute; top: 40px; right: 40px; font-size: 12px; float: right; background-color: #D90303!important; min-width: auto;"
                (click)="cancelDialog()">Müraciəti ləğv et</button>

            <!-- DISABLE APPLY START -->
            <app-disable-apply [disableApply]="applyPetition.disableApply"></app-disable-apply>
            <!-- DISABLE APPLY START -->

            <!-- PAYMENT START -->
            <app-payment-receipt *ngIf="applyPetition.disableApply?.issueAmount"
                [paymentReceipt]="applyPetition.paymentReceipt" [issueAmount]="applyPetition.disableApply?.issueAmount"
                [applyNumber]="applyPetition.disableApply?.applyNumber">
            </app-payment-receipt>
            <!-- PAYMENT END -->

            <!-- DOCS START -->
            <h4 class="title-gray mt-3">Tələb olunan sənədlər - {{applyPetition.activePortal}}</h4>
            <div class="container-fluid" *ngFor="let doc of applyPetition.docs">
                <div class="row" *ngIf="doc.isView">
                    <div (click)="downloadFile(doc.path)" class="cursor-pointer col-3 p-0 m-2"
                        *ngIf="doc.color=='light_blue'" style="color:#03A9D9">
                        <img *ngIf="!downloadSpinner" src="../../../../assets/images/download_light_blue.svg" alt=""
                            style="width: 24px">
                        <mat-spinner class="d-inline mr-4" *ngIf="downloadSpinner" [diameter]="20">
                        </mat-spinner>
                        <span style="margin-left:10px"> {{doc.docName}}</span>
                    </div>

                    <div (click)="downloadFile(doc.path)" class="cursor-pointer col-3 p-0 m-2"
                        *ngIf="doc.color=='dark_blue'" [style.color]="'#1C3651'">
                        <img *ngIf="!downloadSpinner" src="../../../../assets/images/download_dark_blue.svg" alt=""
                            style="width: 24px">
                        <mat-spinner class="d-inline mr-4" *ngIf="downloadSpinner" [diameter]="20">
                        </mat-spinner>
                        <span style="margin-left:10px"> {{doc.docName}}</span>
                    </div>

                    <div class="cursor-pointer col-3 p-0 m-2" *ngIf="doc.color=='middle_blue'"
                        [style.color]="'#337AB7'">
                        <img (click)="downloadFile(doc.path)" *ngIf="!downloadSpinner"
                            src="../../../../assets/images/download_middle_blue.svg" alt="" style="width: 24px">
                        <mat-spinner class="d-inline mr-4" *ngIf="downloadSpinner" [diameter]="20">
                        </mat-spinner>
                        <span (click)="downloadFile(doc.path)" style="margin-left:10px"
                            [ngStyle]="doc.hasMeas && !doc.measDate && {'padding-top': '5px'} "> {{doc.docName}}</span>
                        <span *ngIf="doc.hasMeas && doc.measDate" style="padding-left: 5px; color: #03A9D9;">
                            (MEAS-da açıqlanmaq üçün təqdim edilmişdir-{{doc.measDate| date: 'dd.MM.yyyy'}})
                        </span>

                        <button *ngIf=" doc.hasMeas && !doc.measDate " (click)="sendMeas(doc.key, doc.docName) "
                            class="cursor-pointer col-6 p-0 m-2 " mat-button class="custom-btn ">
                            <span>MEAS-da açıqla</span>
                        </button>
                    </div>




                </div>
                <div class="row" *ngIf="!doc.isView">
                    <div class="custom-file col-md-4 col-sm-6 m-2 p-0">

                        <input
                            title="{{ doc?.docHover && (doc?.docHover.trim() != '') ? doc?.docName +': '+doc?.docHover : doc?.docName }}"
                            type="file" [accept]="acceptTypes" class="custom-file-input" #file
                            [required]="doc.isRequired" role='button'
                            [disabled]="(applyPetition?.paymentReceipt?.isOnline == false && !applyPetition?.paymentReceipt?.hasReceipt) || (applyPetition?.paymentReceipt?.isOnline && applyPetition?.paymentReceipt?.status == false)"
                            id="customFileLangHTML"
                            (change)="setApplySendDoc($event.target.files[0], doc.key, doc.index, doc.hasMeas, doc.extHover, doc.extHoverMessage)">
                        <span class="custom-file-label w-100"
                            [ngClass]="{'check': getDocByKeyIndex(doc.key, doc.index)}"
                            *ngIf="!doc?.hasTemplate; else elseBlockShow">
                            <div class="d-flex align-items-center" style="width: 90%;">
                                <span style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
                                    {{doc?.docName}}
                                </span>
                                <sup *ngIf="doc.isRequired" class="text-danger star">*</sup>
                            </div>
                        </span>

                        <ng-template #elseBlockShow>
                            <span class="custom-file-label" [ngClass]="{'check': getDocByKeyIndex(doc.key, doc.index)}">
                                Faylı seçin və ya buraya sürükləyib buraxın
                            </span>
                        </ng-template>
                    </div>

                    <i *ngIf="doc.extHover" class="fa-solid fa-circle-info my-auto cursor-pointer" [title]="doc.extHoverMessage" style="color: #03a9d9;"></i>

                    <div (click)="downloadFile(doc.path)" style="position: relative;"
                        class="cursor-pointer col-md-4 col-sm-6 p-0 m-3" *ngIf="doc.hasTemplate"
                        [style.color]="'#03A9D9'">
                        <span
                            style="margin-left:10px; position:absolute; top: -30px;left: 27px; color: #847c7c;">Nümunəvi
                            formanı yükləyin.</span>

                        <img *ngIf="!downloadSpinner" src="../../../../assets/images/download_light_blue_false.svg"
                            alt="" style="width: 24px">
                        <mat-spinner class="d-inline mr-4" *ngIf="downloadSpinner" [diameter]="20">
                        </mat-spinner>
                        <span style="margin-left:10px"> {{doc.docName}}</span>


                    </div>
                    <div class="col-md-7 col-sm-6 lbl-gray-txt" *ngIf="getDocByKeyIndex(doc.key, doc.index)">
                        <p class="pl-4 pt-2 pb-2"> <b class="float-left">{{getDocByKeyIndex(doc.key,
                                doc.index).name}}</b> <b class="float-right">
                                [{{cabinetService.sizeToBytes(getDocByKeyIndex(doc.key, doc.index).size)}}]</b></p>
                    </div>
                </div>
            </div>
            <div style="position:relative"
                *ngIf="!((applyPetition?.paymentReceipt?.isOnline == false && !applyPetition?.paymentReceipt?.hasReceipt) || (applyPetition?.paymentReceipt?.isOnline && applyPetition?.paymentReceipt?.status == false))">
                <img (click)="otherDocs()" src="../../../../assets/images/add_circle_fill.svg"
                    class="add-circle-fill cursor-pointer ml-1 mt-2" alt="">

                <p style="position:absolute; top: 10px; left: 40px;">
                    <i style="color: rgba(0, 0, 0, 0.54);">
                        Müraciət üçün əlavə sənədin daxil edilməsinə ehtiyac varsa, "+" düyməsi üzərinə klik edərək
                        daxil olunacaq sənəd(lər) üçün əlavə bölmə(lər) yarada bilərsiniz. Qeyd etmək lazımdır ki,
                        yuxarıda müraciət üçün tələb olunan əsas sənədlərdən fərqli olaraq daxil etdiyiniz əlavə
                        sənədləri yüklədikdən sonra səhifəni yeniləsəniz sənədlər yadda saxlanılmayacaqdır.
                    </i>
                </p>
            </div>

            <!-- DOCS END -->
        </div>
        <div class="col-12 pb-4 bg-white d-flex justify-content-end">
            <mat-spinner class="mr-2 mt-2" [diameter]="20" *ngIf="hasRequest">
            </mat-spinner>
            <button (click)="sendApply()" mat-button class="custom-btn"
                [disabled]="hasRequest || isInValid || (applyPetition?.paymentReceipt?.isOnline == false && !applyPetition?.paymentReceipt?.hasReceipt) || (applyPetition?.paymentReceipt?.isOnline && applyPetition?.paymentReceipt?.status == false)"
                type="submit">
                <span>Müraciət göndər</span>
            </button>
        </div>
    </div>

    <!-- SPINNER -->
    <div class="row">
        <div class="col-md-12 py-3 pt-5 pb-5 bg-white" *ngIf="showSpinner">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
    </div>
    <!-- SPINNER -->
</div>