<div class="container-fluid border shadow-sm my-3">
    <div class="row" >
        <div class="col-12 d-flex align-items-center justify-content-between bg-white px-4 pt-4">
            <h1 class="font-weight-bold cabinet-title">İnvestisiya qiymətli kağızlarının buraxılışının dövlət qeydiyyatına alınması</h1>
        </div>
        <div  class="col-12 bg-white px-4 py-4">
            <!-- table 1 -->
            <table class="table1 w-100"  >
              <tr>
                <th>
                  <span id="main">Muraciet nomresi</span>
                  <span id="sub">#72952-492</span>
                </th>
                <th>
                  <span id="main">Müraciət tarixi</span>
                  <span id="sub">08.07.2021</span>
                </th>
                <th>
                  <span id="main">Status</span>
                  <span id="sub">Baxılmaqdadır</span>
                </th>
                <th>
                  <span id="main">Göndərilən sənədlər</span>
                  <span id="sub" (click)="openDialog()">5 sənəd <i class="fa fa-external-link pl-1 pt-1" aria-hidden="true"></i></span>
                </th>
                <th>
                  <span id="main"> MDM müqavilə nümunər</span>
                  <span id="sub">MDM_Müqavilə nümunəsi <i class="fa fa-download pl-1" aria-hidden="true"></i></span>
                </th>
              </tr>
            </table> 
             <!-- table 2 -->
            <h6 class="sub-title pt-5 pl-1">Sənədlər kataloqu</h6>
            <table class="table2 w-100">
                <tr *ngFor="let doc of documents ; let i = index">
                  <th>
                    <span id="main"><i class="fa fa-file-text-o pl-2" aria-hidden="true"></i>{{doc.name}}</span>
                  </th>
                  <th>
                    <span id="main">{{doc.fileName}}</span>
                  </th>
                  <th>
                    <span id="main">{{doc.fileSize}}</span>
                  </th>
                </tr>
            </table> 
            <div class="col-xl-12  bg-white pt-5">
              <mat-horizontal-stepper linear #stepper [selectedIndex]="testData.activeStepNum" labelPosition="bottom">
                  <mat-step *ngFor="let step of steps; let i = index" label="step.key">
                      <!-- <mat-spinner style="margin: auto!important;" *ngIf="!checkLoad" [diameter]="70">
                      </mat-spinner> -->
                      <div class="col-xl-12 pt-1" >
                          <!-- <hr class=" pb-5"> -->
                           <ng-container >
                            <ng-template *ngIf="step.key == portalType.MB" matStepLabel>Mərkəzi Bank</ng-template>
                            <ng-template style="max-width:90px!important ;" *ngIf="step.key == portalType.MDM" matStepLabel>Milli Depozit Mərkəzi</ng-template>
                            <ng-template *ngIf="step.key == portalType.BFB" matStepLabel>Bakı Fond Brijası</ng-template>
                            <app-step-detail-detail [isCompleted] = "testData.isCompleted" ></app-step-detail-detail>
                           </ng-container>
                      </div>
                  </mat-step>
              </mat-horizontal-stepper>
          </div>
        </div>
    </div>
</div>