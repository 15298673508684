<div class="container-fluid border shadow-sm my-3">
    <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between bg-white px-4 pt-4">
            <h1 class="font-weight-bold cabinet-title ">Səlahiyyətlərin ötürülməsi</h1>
            <div>
                <a [routerLink]="['/new-access']" mat-flat-button color="primary" style="text-decoration: none;" class="custom-btn">
                    <i class="fas fa-user-plus"></i> Yeni Səlahiyyət
                </a>
                <mat-spinner class="ml-2 mt-2" [diameter]="20" style="float: right;" *ngIf="false">
                </mat-spinner>
            </div>

        </div>

    </div>
    <div class="col-md-12 bg-white pb-5 ">
        <mat-spinner style="margin: auto!important;" *ngIf="showSpinner" [diameter]="70">
        </mat-spinner>
    </div>
    <div *ngIf="!showSpinner" class="row  pt-3 bg-white">

        <span class="ml-4 mr-2 slide-title">  Passivlər  </span>
        <mat-slide-toggle [(ngModel)]="isChecked" (change)="getdata()"></mat-slide-toggle><span class="ml-2 slide-title"> Aktivlər </span>
    </div>
    <div *ngIf="!showSpinner " class="row">
        <!-- <div class="col-md-12 bg-white pb-5" *ngIf="false">
              <mat-spinner style="margin: auto!important;" [diameter]="70">
              </mat-spinner>
          </div> -->
        <div class="col-12 bg-white px-4 pb-4  pt-4">
            <div class="col-md-12 bg-white pb-5 text-center" *ngIf="dataSource.length ==0">
                <span style="margin: auto!important;">Mövcud deyil </span>
            </div>
            <div class="over" *ngIf="dataSource.length !=0">
                <form action="" [formGroup]="applicationForm">
                    <table mat-table [dataSource]="dataSource" class="w-100  custom-table ">

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> İstifadəçi adı</th>
                            <td mat-cell *matCellDef="let element">
                                <span class="sub">{{element.fullName}}</span>

                            </td>
                        </ng-container>

                        <!-- status Column -->
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef>İstifadəçi rolu </th>
                            <td mat-cell *matCellDef="let element">

                                <span style="display: block;" id="{{'n'+element.id}}" class="sub">{{element.role}}</span>
                                <span id="{{'s'+element.id}}" style="display: none;">
                <mat-select   style="text-align: left;" class="py-2 border rounded bg-white text-start pl-1 "   formControlName="roleId"  >
                <mat-option *ngFor="let x of Role" [value]="x.id">{{x.name}}</mat-option>
                </mat-select>
              </span>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> Vəzifə</th>
                            <td mat-cell *matCellDef="let element">
                                <span class="sub text-capitalize">{{element.position}}</span>
                            </td>
                        </ng-container>

                        <!-- operation Column -->
                        <ng-container matColumnDef="operation">
                            <th mat-header-cell *matHeaderCellDef>Əməliyyat </th>
                            <td mat-cell *matCellDef="let element" class="px-1">
                                <div style="width: 200px;  " class="">
                                    <button (click)="activeDeaktive(element.id,false)" mat-stroked-button *ngIf="element.roleId !=3 && element.roleId !=4 && element.status " style="position: relative;" class="operation p-1 ">
                  <i class="fas fa-times"></i>
                   <span  class="d-inline p-1  ml-1">Deaktiv et</span> </button>
                                    <button id="{{'ye'+element.id}}" (click)="getselect(element)" mat-stroked-button *ngIf="element.roleId !=3 && element.roleId !=4 && element.status " class="operation p-1 ml-1" style="display: inline;">Yenilə</button>
                                    <button id="{{'y'+element.id}}" (click)="changeRole(element)" mat-stroked-button style="display: none;" class="operation p-1 ml-1">Yadda Saxla</button>
                                    <button (click)="activeDeaktive(element.id,true)" mat-stroked-button *ngIf="element.roleId !=3 && element.roleId !=4 && !element.status" class="operation p-1 ml-1">Aktiv et</button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </form>
            </div>
            <div style="height: 70px;" class=" w-100 my-3  ">
                <span *ngIf="message" class="my-4 ml-2 message d-block">{{message}}</span>
            </div>
        </div>
    </div>
</div>