<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Müraciət</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content style="margin:0!important" class="mat-typography justify-center">
        <div class=" text-font text-center center mx-auto   font-weight-bold">
            <p>Müraciətə baxılmanı dayandırmağa əminsinizmi?</p>

        </div>



    </mat-dialog-content>
    <mat-dialog-actions style="padding-bottom: 30px!important; padding-right: 10px!important;" align="end">
        <div *ngIf="showSpinner" class="py-5">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
        <button [disabled]="showSpinner" mat-stroked-button class=" btn btn1-default my-2 " [mat-dialog-close]="true" (click)="cancel()"><span translate>Təsdiqlə</span></button>
    </mat-dialog-actions>

</div>