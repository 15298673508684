import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AccountService } from 'src/app/home/components/home/account-service';
import { HomeService } from 'src/app/home/components/home/home.service';
import { Cabinet } from '../../cabinet.service';




@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  title = " Profil";
  
  saving = false;
  currentCityId :number
  City=[];
  ECity=[];
  Region=[];
  ERegion=[];
  data=[]
  showSpinner=false
  applicationForm: FormGroup;
  EapplicationForm: FormGroup;
  Esaving: boolean;
  showAvisSpinner: boolean;
  tinInValid: boolean;
  hasAvisError: boolean;
  avisMessage: "";
  constructor(public router: Router,   private accountService: AccountService, private fb: FormBuilder, private cabinetService: Cabinet, private _sanitizer: DomSanitizer, private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private TitleService: Title) {

  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
      if (e.key === 'Tab') {
          return false;
      }
 return true;
  }

  onKeyUp(event) {
    event.target.disabled = true;
    event.preventDefault();
  }

  ngOnInit(): void {    
    this.setTitle(this.title);
    this.getCity();
    this.cabinetService.clearStorage();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }else{
      this.getData();
      this.getEmitentData();
  
     this.applicationForm = this.fb.group({
       email: ['', [Validators.required, Validators.email]],
       pin: ['', Validators.required],
       phone: ['', Validators.required],
       name: ['', Validators.required],
       address: ['', Validators.required],
       regAddress: ['', Validators.required],
       cityId: ['', Validators.required],
       regionId: ['', Validators.required]
       
     });
     this.EapplicationForm = this.fb.group({
       email: ['', [Validators.required, Validators.email]],
       pin: ['', Validators.required],
       phone: ['', Validators.required],
       name: ['', Validators.required],
       address: ['', Validators.required],
       regAddress: ['', Validators.required],
       cityId: ['', Validators.required],
       regionId: ['', Validators.required],
       postIndex: ['', Validators.required],
       bank: ['', Validators.required],
       bankTin: ['', Validators.required],
       correspondentAccount: ['', Validators.required],
       settlementAccount: ['', Validators.required],
       code: [''],
       swift: ['', Validators.required],
       bankCode: [''],
     });  
    }
    
  }
  setTitle(title: string) {
    if (title) {
      this.TitleService.setTitle(title + " | securities.e-cbar.az");
      const breadcrumb = { title: title };
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
    }
  }
 getCity(){
  this.cabinetService.city().subscribe(result => {
 this.City=result.result;

  })

 }
 getRegionBYid(id :number){
  if(id ==0){
    this.applicationForm.get("regionId").setValue(0);
    return;
  }
   
  this.cabinetService.regionById(id).subscribe(result => {
 this.Region=result.result;
 //console.log(this.Region)
 if(this.Region.length ==0){
  this.applicationForm.get("regionId").setValue(0);
  return;
}
  })

 }
 getERegionBYid(id){
  if(id ==null){
    this.EapplicationForm.get("regionId").setValue(0);
    return;
  }
  
 this.cabinetService.regionById(id).subscribe(result => {
this.ERegion=result.result;
if(this.ERegion.length ==0){
  this.EapplicationForm.get("regionId").setValue(0);
  return;
}

 })

}
  getData() {
    this.showSpinner=true;
    this.cabinetService.profile().subscribe(result => {
      
      if (!(result.Status && result.Status.errorCode == 1)) {
        this.applicationForm.get("name").setValue(result.response.userName);
        this.applicationForm.get("regionId").setValue(+result.response.regionId);
        this.applicationForm.get("email").setValue(result.response.email);
        this.applicationForm.get("phone").setValue(result.response.phone);
        this.applicationForm.get("pin").setValue(result.response.pin);
        this.applicationForm.get("cityId").setValue(result.response.cityId==null ? 0 : +result.response.cityId);
        this.applicationForm.get("regAddress").setValue(result.response.regAddress);
        this.applicationForm.get("address").setValue(result.response.address);
        this.applicationForm.get("name").disable();
        this.applicationForm.get("regAddress").disable();
        this.applicationForm.get("pin").disable();
        this.applicationForm.get("email").disable();
        this.getRegionBYid(result.response.cityId);
        this.showSpinner=false;
      }
  
    });
  }
  getEmitentData() {
    this.showSpinner=true;
    this.cabinetService.currentcompany().subscribe(result => {
     // console.log(result)
      if (!(result.Status && result.Status.errorCode == 1)) {
        this.EapplicationForm.get("name").setValue(result.response.name);
        this.EapplicationForm.get("regionId").setValue(result.response.regionId==null ? 0 : +result.response.regionId);
        this.EapplicationForm.get("email").setValue(result.response.email);
        this.EapplicationForm.get("phone").setValue(result.response.phone);
        this.EapplicationForm.get("pin").setValue(result.response.tin);
        this.EapplicationForm.get("cityId").setValue(result.response.cityId==null ? 0 : +result.response.cityId);
        this.EapplicationForm.get("regAddress").setValue(result.response.regAddress);
        this.EapplicationForm.get("address").setValue(result.response.address);
        this.EapplicationForm.get("postIndex").setValue(result.response.postIndex);
       
        this.EapplicationForm.get("bank").setValue(result.response.bank);
        this.EapplicationForm.get("bankTin").setValue(result.response.bankTin);
        this.EapplicationForm.get("correspondentAccount").setValue(result.response.correspondentAccount);
        this.EapplicationForm.get("settlementAccount").setValue(result.response.settlementAccount);
        this.EapplicationForm.get("code").setValue(result.response.code);
        this.EapplicationForm.get("code").disable();
        this.EapplicationForm.get("bankCode").setValue(result.response.bankCode);
        this.EapplicationForm.get("swift").setValue(result.response.swift);
       
        this.EapplicationForm.get("bank").disable();
        this.EapplicationForm.get("name").disable();
        this.EapplicationForm.get("regAddress").disable();
        this.EapplicationForm.get("pin").disable();
         this.getERegionBYid(result.response.cityId);
         this.showSpinner=false;
      }
     
    });
  }

  save() {
    this.saving = true;
    if (this.applicationForm.invalid) {
      return;
    }
    // this.showSpinner = true;
    
    this.cabinetService.updateProfile(this.applicationForm.getRawValue()).subscribe(data => {
    this.saving = false;
    this.cabinetService.messageDialog(data.status.message, false);
    this.router.navigate(["/profile"]);
    this.saving = false;
      // this.showSpinner = false;
    },
      err => {
        this.saving = false;
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }

  Esave() {
    this.Esaving = true;
    if (this.EapplicationForm.invalid) {
      return;
    }
    // this.showSpinner = true;
    
    this.cabinetService.updateProfileCompany(this.EapplicationForm.getRawValue()).subscribe(data => {
    this.saving = false;
    this.cabinetService.messageDialog(data.status.message, false);
    this.router.navigate(["/profile"]);
    this.Esaving = false;
      // this.showSpinner = false;
    },
      err => {
        this.Esaving = false;
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }

  AvisSave() {
    this.Esaving = true;
    
    this.cabinetService.updateAvis().subscribe(data => {
    this.saving = false;
    this.cabinetService.messageDialog(data.status.message, false);
    this.router.navigate(["/profile"]);
    this.Esaving = false;
      // this.showSpinner = false;
    },
      err => {
        this.Esaving = false;
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }

  getByTin() {
   
    let tin = this.EapplicationForm.controls.bankTin.value;
    this.showAvisSpinner = true;
    this.hasAvisError = false;
   
  
    this.cabinetService.getByTin(tin).subscribe(
      res => {
        if (res.status.errorCode == 0) {
          this.EapplicationForm.controls.bank.setValue(res.response.legalPerson?.fullName);
   
        }
        else {
          this.hasAvisError = true;
          this.avisMessage = res.status.message;
        }
        this.showAvisSpinner = false;
      }
    );
  }

  get email(): AbstractControl { return this.applicationForm.controls.email; }
  get pin(): AbstractControl { return this.applicationForm.controls.pin; }
  get phone(): AbstractControl { return this.applicationForm.controls.phone; }

}
