<div class="container emission_home" *ngIf="!qk_home_page">
    <div class="col-md-12 bg-white pb-5 pt-5 home_container">
        <mat-spinner style="margin:auto!important;" [diameter]="70">
        </mat-spinner>
    </div>
</div>
<div class="container emission_home" *ngIf="qk_home_page">
    <div *ngIf="asanSpinner" class=" common-spinner col-md-12 bg-white pb-5">
        <mat-spinner style="margin: auto!important;  " [diameter]="70">
        </mat-spinner>
    </div>
    <div class="card mt-4">
        <div class="row">
            <div class="title col-md-12">{{qk_home_page.title}}</div>
            <div class="col-md-12 col-xs-12 text">
                <p [innerHTML]="qk_home_page.description" class="p"></p>

            </div>
        </div>


        <div class="row">
            <div class="col-lg-6 p-0 pr-3 pl-3">
                <div class="col card mt-4 p-4">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                        <div class="mt-1 mr-3 ml-3">
                            <img height="80px" style="float: right;" src="../../../../../assets/images/EMITENT_LOGO.png">
                        </div>
                        <div class="mt-1">
                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center">
                                <a style="cursor:pointer;text-decoration:none; color: #333333; font-size: 25;" class="card-title" (click)="asanv2(0)">EMİTENTLƏR</a>

                                <br>

                            </ng-container>


                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center">
                                <br>
                                <a  (click)="asanv2(0)" style="display:inline-block;cursor: pointer;">
                                    <div class="digital_login">
                                        <img class="digital_login--default"
                                             src="../assets/images/digital_login_third_s.png"
                                             height="47" alt="digital_login">
                                        <img class="digital_login--hover d-none"
                                             src="../assets/images/digital_login_third_s_hover.png"
                                             height="47" alt="digital_login">
                                    </div>
                                </a>

                            </ng-container>

                        </div>
                    </div>





                </div>
            </div>

            <div class="col-lg-6 p-0 pr-3 pl-3">
                <div class="col card mt-4 p-4">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                        <div class="mt-1 mr-3 ml-3">
                            <img height="80px" style="float: right;" src="../../../../../assets/images/UNDERWRITER_LOGO.png">
                        </div>
                        <div class="mt-1">
                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center">
                                <a style="cursor:pointer;text-decoration:none; color: #333333; font-size: 25;" class="card-title" (click)="asanv2(1)"> ANDERRAYTERLƏR</a>
                                <br>
                            </ng-container>


                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center">
                                <br>
                                <a (click)="asanv2(1)" style="display:inline-block;cursor: pointer;">
                                    <div class="digital_login">
                                        <img class="digital_login--default"
                                             src="../assets/images/digital_login_third_s.png"
                                             height="47" alt="digital_login">
                                        <img class="digital_login--hover d-none"
                                             src="../assets/images/digital_login_third_s_hover.png"
                                             height="47" alt="digital_login">
                                    </div>
                                </a>

                            </ng-container>

                        </div>
                    </div>





                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 p-0 pr-3 pl-3">
                <div class="col card mt-4 p-4">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                        <div class="mt-1 mr-3 ml-3">
                            <img height="85px" style="float: right; margin-right: -15px;" src="../../../../../assets/images/BFB_LOGO.png">
                        </div>
                        <div class="mt-1">
                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center">
                                <span style="color: #333333; font-size: 25; margin-bottom: 5px; display: inline-block;" class="card-title ">BAKI FOND BİRJASI</span><br>
                                <a (click)="certRequest(1, 'bfb')" class="main_a" style=" cursor: pointer; ">Yeni istifadəçi qeydiyyatı üçün müraciət</a><br>
                                <a (click)="certRequest(2, 'bfb') " class="main_a" style="cursor: pointer; ">Sertifikatı yeniləmək üçün müraciət</a><br>
                            </ng-container>


                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center ">

                                <ng-container *ngIf="testLogin ">
                                    <a class="btn-text " (click)="gotoPortal( 'login', 'bfb') "><button  mat-button class="btn btn1-default new_btn" type="button " >Giriş</button></a><br>
                                </ng-container>
                                <ng-container *ngIf="!testLogin ">
                                    <a class="btn-text "><button  mat-button class="btn btn1-default new_btn" type="button "  (click)="gotoBsxm( 'home', 'bfb') " >E-imza ilə giriş</button></a><br>
                                </ng-container>

                            </ng-container>

                        </div>
                    </div>





                </div>
            </div>

            <div class="col-lg-6 p-0 pr-3 pl-3">
                <div class="col card mt-4 p-4">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
                        <div class="mt-1 mr-3 ml-3">
                            <img height="95px" style="float: right; margin-right: -10px;" src="../../../../../assets/images/MDM_LOGO.png">
                        </div>
                        <div class="mt-1">
                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center">
                                <span style="color: #333333; font-size: 25; margin-bottom: 5px; display: inline-block;" class="card-title ">MİLLİ DEPOZİT MƏRKƏZİ</span><br>
                                <a (click)="certRequest(1, 'mdm')" class="main_a" style=" cursor: pointer; ">Yeni istifadəçi qeydiyyatı üçün müraciət</a><br>
                                <a (click)="certRequest(2, 'mdm') " class="main_a" style="cursor: pointer; ">Sertifikatı yeniləmək üçün müraciət</a><br>
                            </ng-container>


                            <ng-container class="col-md-12 mt-2 d-flex justify-content-center ">

                                <ng-container *ngIf="testLogin ">
                                    <a class="btn-text " (click)="gotoPortal( 'login', 'mdm') "><button  mat-button class="btn btn1-default new_btn" type="button " >Giriş</button></a><br>
                                </ng-container>
                                <ng-container *ngIf="!testLogin ">
                                    <a class="btn-text "><button  mat-button class="btn btn1-default new_btn" type="button "  (click)="gotoBsxm( 'home', 'mdm') " >E-imza ilə giriş</button></a><br>
                                </ng-container>

                            </ng-container>

                        </div>
                    </div>





                </div>
            </div>
        </div>


    </div>

</div>
