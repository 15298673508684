import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-asan-dialog-4',
  templateUrl: './asan-dialog-4.component.html',
  styleUrls: ['./asan-dialog-4.component.scss']
})
export class AsanDialog4Component implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  nowDate = new Date();
  hasStatus: boolean;
  hasRequest: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public cabinetService: Cabinet,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.cabinetService.clearStorage();
    console.log(data)
    this.checkStatus();
  }

  ngOnInit(): void {

  }

  closeDialog() {
    window.location.reload();
  }

  checkStatus(){
    this.cabinetService.getSignStatus(this.data.postModel, this.id).subscribe(
      res => {
        console.log(res)
        if(res.response && res.response.status=='OUTSTANDING_TRANSACTION'){
          setTimeout(() => 
          {
             this.checkStatus();
          },
          1200);
        }else if(res.response && res.response.status=='SIGNATURE_CREATED'){
          this.hasStatus = true;
          this.dialog.closeAll();
          this.cabinetService.messageDialog("Müqavilə imzalanması uğurla başa çatmışdır.", true);
        }else{
          this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
        }
      
       
      
      

     
      },
      err => {
        if (err.error.status.errorCode != 0)
        this.cabinetService.messageDialog("Asan İmza prosesində xəta baş verdi, yenidən cəhd edin.", true);
        this.hasRequest = false;
      })
  }

}
