<div>
    <div class="bred-margin">
        <div class="breadcrumbs-container">
            <div class="container-fluied">
                <div class="row mr-0">
                    <div class="col-md-7 bread">
                        <ul class="breadcrumbs">
                            <li> <a href="/home"><i class="fa fa-home" aria-hidden="true"></i></a></li>
                            <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li>
                                <app-ng7-dynamic-breadcrumb [bgColor]="breadcrumbConfig['bgColor']" [fontSize]="breadcrumbConfig['fontSize']" [fontColor]="breadcrumbConfig['fontColor']" [lastLinkColor]="breadcrumbConfig['lastLinkColor']" [symbol]="breadcrumbConfig['symbol']">
                                </app-ng7-dynamic-breadcrumb>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5 loginButton">
                        <div class="d-flex justify-content-end ">
                            <div class="login-menu">
                                <div>
                                    <button mat-button (click)="toggleMenu()" class="text-dark limited-text">

                                        <span > İstifadəçi : </span> <strong>{{getUserName() | uppercase}}</strong></button>
                                </div>
                                <div [ngClass]="{'activeLoginMenu':toggleLoginMenu}" style="z-index: 49;" (click)="toggleMenu()" class="overlay-menu">


                                </div>
                                <ul [ngClass]="{'activeLoginMenu':toggleLoginMenu}" style="z-index: 50;">
                                    <li>
                                        <a *ngIf="accountService.is_emitent()" [routerLink]=" [ '/profile'] ">
                                            <i class="fas fa-user mr-2 "></i>
                                            <span>Profil</span>

                                        </a>

                                        <a *ngIf="!accountService.is_emitent()" [routerLink]=" [ '/profile-underwriter'] ">
                                            <i class="fas fa-user mr-2 "></i>
                                            <span>Profil</span>

                                        </a>
                                    </li>
                                    <li>
                                        <a (click)="logout() ">
                                            <i class="fas fa-power-off mr-2 "></i>
                                            <span>Çıxış</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
