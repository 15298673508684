<div class="container overflow-hidden">
    <div class="row mx-auto card mt-4">
        <p class="title col-md-12 ">{{title2}}</p>
        <div class="col-md-12 col-xs-12 ">
            <form style="padding-left: 260px; padding-top: 20px" action="" class="customForm form-row" [formGroup]="registerForm">
                <div style="height: 50px;" class="mb-2 col-12 row " [ngClass]="{'invalid-input': registerForm.controls.cityId.touched && registerForm.controls.cityId.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email" class="m-0">Şəhər:<sup
                        class="text-danger star">*</sup></label>
                    </div>
                    <mat-select class="col-4" formControlName="cityId" (selectionChange)="getRegionBYid($event.value)" required>
                        <mat-option [value]="0">--- Seçin ---</mat-option>
                        <mat-option *ngFor="let x of City" [value]="x.id">{{x.name}}</mat-option>
                    </mat-select>
                    <div style="margin-top: -13px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.cityId.invalid && (registerForm.controls.cityId.dirty || registerForm.controls.cityId.touched)">
                        <div class="col-4 mr-5"></div>
                        <div class="text-danger" *ngIf="registerForm.controls.cityId.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>

                <div style="height: 50px;" class="mb-2 col-12 row " [ngClass]="{'invalid-input': registerForm.controls.regionId.touched && registerForm.controls.regionId.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email" class="m-0">Rayon:
                      </label>
                    </div>
                    <mat-select class="col-4" [disabled]=" Region.length==0 " formControlName="regionId">
                        <mat-option [value]="0">--- Seçin ---</mat-option>
                        <mat-option *ngFor="let x of Region" [value]="x.id">{{x.name}}</mat-option>
                    </mat-select>
                    <div style="margin-top: -13px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.regionId.invalid && (registerForm.controls.regionId.dirty || registerForm.controls.regionId.touched)">
                        <div class="col-4 mr-5"></div>
                        <div class="text-danger" *ngIf="registerForm.controls.regionId.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>

                <div style="height: 50px;" class="mb-2 col-12 row " [ngClass]="{'invalid-input': registerForm.controls.address.touched && registerForm.controls.address.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email" class="m-0">Küçə və ev ünvanı:<sup
                        class="text-danger star">*</sup>
                      </label>
                    </div>
                    <input class="col-4" type="text" id="application-form-email" formControlName="address">
                    <div style="margin-top: -13px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.address.invalid && (registerForm.controls.address.dirty || registerForm.controls.address.touched)">
                        <div class="col-4 mr-5"></div>
                        <div class="text-danger" *ngIf="registerForm.controls.address.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>

                <!-- PHONE  -->
                <div style="height: 50px;" class="mb-2 col-12 row " [ngClass]="{'invalid-input': registerForm.controls.phone.touched && registerForm.controls.phone.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="application-form-email" class="m-0">Əlaqə telefonu:<sup
                        class="text-danger star">*</sup>
                      </label>
                    </div>
                    <input class="col-4" type="text" id="application-form-email" matInput prefix="+994" mask="(00) 000 00 00" formControlName="phone">
                    <div style="margin-top: -13px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.phone.invalid && (registerForm.controls.phone.dirty || registerForm.controls.phone.touched)">
                        <div class="col-4 mr-5"></div>
                        <div class="text-danger" *ngIf="registerForm.controls.phone.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <!-- EMAIL  -->
                <div style="height: 50px;" class="mb-2 col-12 row " [ngClass]="{'invalid-input': registerForm.controls.email.touched && registerForm.controls.email.invalid }">
                    <div class="col-4 text-right mr-4">
                        <label for="email">Elektron poçt: <sup
                        class="text-danger star">*</sup>
                      </label>
                    </div>
                    <input class="col-4" matInput type="text" id="email" formControlName="email" />
                    <div style="margin-top: -13px;" class="row col-12 justify-content-start pl-4" *ngIf="registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched)">
                        <div class="col-4 mr-5"></div>
                        <div class="text-danger" *ngIf="registerForm.controls.email.errors?.required">
                            Xana mütləq doldurulmalıdır
                        </div>
                    </div>
                </div>
                <!-- CHECKBOX -->
                <!-- <div class=" col-md-4 col-xs-4 center">
                    <mat-checkbox [(ngModel)]="checked">Sistemin <b>İstifadə qaydaları</b> ilə razıyam</mat-checkbox>
                </div> -->
                <!-- BUTTON  -->
                <div style="height: 50px;" class="mb-2 col-12 row ">
                    <div class="col-4 text-right mr-4 "></div>
                    <div class="col-4 pt-2 ">
                        <button style="margin-left: -15px;" class="btn btn1-default mrc" type="button" [disabled]=" showSpinner || registerForm.invalid " (click)="register()">
                        Qeydiyyat
                    </button>
                        <mat-spinner class="m-2" *ngIf="showSpinner" style="right: 100; position: absolute;display: inline; " [diameter]="20 ">
                        </mat-spinner>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>