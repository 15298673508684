import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
//breadcrumbs: MenuItem[];
export class ShellComponent implements OnInit {

  constructor(private analytics:AnalyticsService) { }

  ngOnInit() {
    this.analytics.init();
  
  }
  
  //private toPrimeNgMenuItem(crumb: Breadcrumb) {
  //  return <MenuItem>{ label: crumb.displayName, url: `#${crumb.url}`}
//}

}
