import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Component, OnInit } from '@angular/core';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { AccountService } from '../account-service';
import { delay, delayWhen } from 'rxjs/operators';
import { waitForAsync } from '@angular/core/testing';
import {v4 as uuidv4} from 'uuid';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  title2 = 'Giriş'
  loginForm: FormGroup;
  bsxmUrl: string;
  showSpinner: boolean;
  testLogin: boolean;
  asanSpinner: boolean;
  type = this.activatedRoute.snapshot.queryParamMap['params']['type'];
  asanloginAuthURI: string | undefined;
  asan_client_id: string | undefined;
  asan_redirect_uri: string | undefined;
  AsanV2Model: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private cabinetService: Cabinet,
    private accountService: AccountService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.asanloginAuthURI = AppConfig.settings.asan.asanloginAuthUrl;
    this.asan_client_id = AppConfig.settings.asan.asan_client_id;
    this.asan_redirect_uri = AppConfig.settings.asan.asan_redirect_url;
    this.testLogin = AppConfig.settings.other.testLogin;
    if (!this.type) {
      this.type = 0;
    }
    var auth = this.accountService.getPortal();
    if (auth.errorCode == 0 || !this.testLogin) {
      this.router.navigate(["/" + auth.path]);
    }
    let params = this.route.snapshot.queryParamMap["params"];
    this.is_come_token(false);
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.AsanV2Model = this.accountService.getData();
    if( this.AsanV2Model != null || undefined){
      this.asanLogin();
    }
  }

  ngOnInit(): void {
    this.setTitleAndBredcrumb();
  }
  normal_login() {
    if (this.asanSpinner || this.showSpinner) { return }
    if (this.loginForm.invalid) {
      return;
    }
    this.asanSpinner = true;

    localStorage.setItem("portal", this.type==0? "EMITENT": "UNDER_WRITER");

    // this.showSpinner = true;
    this.accountService.normalLogin(this.loginForm.value, this.type).subscribe(data => {
      console.log(data);
      this.asanSpinner = false;
      if (data.status && data.status.errorCode == 0) {
        localStorage.setItem("email", this.loginForm.get("email").value);
        if(this.type==0){
          this.accountService.openDialogAccounts(data.response.accounts);
        }else{
          this.accountService.createJWT(data.response, "appeals-underwriter");

        }

      } else {
        this.cabinetService.messageDialog(data.status.message, false);
      }

    },
      err => {
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }

  private setTitleAndBredcrumb(): void {
    this.titleService.setTitle(this.title2 + ' | securities.e-cbar.az');
    const breadcrumb = { title2: this.title2 };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }

  is_come_token(secondLogin: boolean) {
    if (this.asanSpinner || this.showSpinner) { return }


    if (secondLogin || localStorage.getItem("login") == "ok") {
      this.asanSpinner = true;
      
        this.accountService.getMyAccounts({AuthorizationCode: this.AsanV2Model.code, State: this.AsanV2Model.state}).subscribe((data: any) => {
          localStorage.setItem("asanToken", data.response.asanToken)
          if (data.status.errorCode == 0 && data.response.isNew) {
            if(localStorage.getItem("portal")=="EMITENT"){
            this.router.navigate(["/register-complete"]);
            }else{
              this.router.navigate(["/register-complete-underwriter"]);
            }
          } else {

            localStorage.removeItem("login");
            if(this.type==0){
              this.accountService.openDialogAccounts(data.response.accounts);
            }else{
              this.accountService.createJWT(data.response, "appeals-underwriter");

            }

            this.asanSpinner = false
          }
        },
          err => {
            if (secondLogin) {
              localStorage.setItem("login", "ok");
              window.location.href = this.accountService.getAsanURLLogin() + this.router.url.toString().substr(1);

            }
            this.asanSpinner=false;
          });
      
    }
  }
  asanLogin() {
    localStorage.setItem("portal", this.type==0? "EMITENT": "UNDER_WRITER");

    this.is_come_token(true);
  }

  asanv2() {
    this.accountService.getStateId().subscribe((res: any) => {
    let client_id = this.asan_client_id;
    let redirect_uri = this.asan_redirect_uri;
    let authUrl = this.asanloginAuthURI!;
    let state_id = res;
    let url = authUrl + '?client_id=' + client_id + '&' + 'redirect_uri=' + redirect_uri + '&' + 'response_type=code' + '&' + 'state=' + state_id + '&' + 'scope=openid certificate';
    window.location.href = url;
  });
}

}
