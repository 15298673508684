<div class="container-fluid">
    <div class="row pb-4 bg-white">
        <div class="col-9 d-flex align-items-center justify-content-start bg-white pt-4">
            <h1 style="max-width:80%" class="font-weight-bold cabinet-title" *ngIf="!showSpinner && applyUpper">
                {{applyUpper.type}}</h1>
        </div>
        <div class="col-3 d-flex  align-items-center justify-content-end bg-white px-4 pt-4">
            <button mat-button class="custom-btn ex-button mr-2" *ngIf="!showSpinner"
                style="font-size: 12px; min-width: auto;" (click)="openDialog()">Sənədlərə baxış</button>

            <button mat-button class="custom-btn ex-button"
                *ngIf="applyUpper && applyUpper.statusKey!=='refused' && applyUpper.statusKey!=='executed' && applyUpper.backgroundStatusKey!=='accept_success' && !showSpinner"
                style="font-size: 12px; background-color: #D90303!important; min-width: auto;"
                (click)="cancelDialog()">Müraciətə baxılmanı dayandır</button>

            <button mat-button class="custom-btn ex-button ml-2" *ngIf="!showSpinner"
                style="font-size: 12px; min-width: auto;" (click)="openMessageDialog()">Mesaj tarixçəsi</button>
        </div>
    </div>
</div>



<table class="table1 w-100 mt-4" *ngIf="!showSpinner && applyUpper">
    <tr>
        <th>
            <span id="main">Müraciət nömrəsi</span>
            <span id="sub">{{applyUpper.applyNumber}}</span>
        </th>
        <th>
            <span id="main">Müraciət tarixi</span>
            <span id="sub">{{applyUpper.createdOn | date: 'dd.MM.yyyy HH:mm'}}</span>
        </th>
        <th>
            <span id="main">Status</span>
            <span id="sub">{{applyUpper.status}}</span>
        </th>

    </tr>
</table>

<mat-horizontal-stepper *ngIf="!showSpinner && applyUpper && applyUpper.stepData" [linear]="true" #stepper
    [style.paddingRight]="applyUpper.stepData?.steps?.length == 3 ? '20%' : 'calc(50% + 76px)'"
    [ngClass]="applyUpper.stepData?.direction == 'end' ? ('end last-edited-step-' + (applyUpper.stepData?.activeIndex-1)) : ('start last-edited-step-' + (applyUpper.stepData?.activeIndex-1))"
    [selectedIndex]="applyUpper.stepData?.activeIndex-1" labelPosition="bottom">
    <mat-step *ngFor="let step of applyUpper.stepData?.steps; let i = index" label="{{step.portalFull}}">
    </mat-step>
</mat-horizontal-stepper>

<div class="text-box p-3 mb-4"
    *ngIf="applyUpper && applyUpper.statusKey!=='refused' && applyUpper.statusKey!=='executed' && !showSpinner && applyUpper.mainText">
    <span class="d-inline-block">{{applyUpper.mainText}}</span>
</div>

<div class="green-box p-3 mb-4"
    *ngIf="applyUpper && applyUpper.observeKey!='base_issue_prospectus' && applyUpper.statusKey!=='refused' && !showSpinner  && applyUpper.statusKey=='executed'">
    <span class="d-inline-block">{{applyUpper.type}} prosesi başa çatmışdır.</span>
</div>

<div class="green-box p-3 mb-4"
    *ngIf="applyUpper && applyUpper.observeKey=='base_issue_prospectus' && applyUpper.statusKey!=='refused' && !showSpinner  && applyUpper.statusKey=='executed'">
    <span class="d-inline-block"> Baza emissiya prospekti üzrə olan müraciət başa çatmışdır.</span>
</div>

<div class="text-box p-3 mb-5"
    *ngIf="applyUpper && applyUpper.statusKey!=='refused' && !showSpinner && applyUpper.noteText">
    <span class="icon text-danger material-icons-outlined">info</span>
    <span class="pl-1">{{applyUpper.noteText}}</span>
</div>

<div class="text-box p-3 mb-4" *ngIf="applyUpper && !showSpinner && applyUpper.message">
    <span class="d-inline-block">{{applyUpper.message}}</span>
    <span class="cursor-pointer" style="color: #03A9D9;" (click)="openMessageDialog()"> Mesajlara bax</span>
</div>

<!-- SPINNER -->
<div class="row" *ngIf="showSpinner">
    <div class="col-md-12 py-3 pt-5 pb-5 bg-white">
        <mat-spinner style="margin: auto!important;" [diameter]="70">
        </mat-spinner>
    </div>
</div>
<!-- SPINNER -->