import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Component, OnInit } from '@angular/core';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { AccountService } from '../account-service';
import { MatDialog } from '@angular/material/dialog';
import { SelectCompanyDialogComponent } from 'src/app/cabinet/dialogs/selectCompany/select-company-dialog.component';
import { NewCompanyDialogComponent } from 'src/app/cabinet/dialogs/newCompany/new-company-dialog.component';
import { LoginASANVM } from 'src/app/models/LoginASANVM';


@Component({
  selector: 'app-register-complete',
  templateUrl: './register-complete.component.html',
  styleUrls: ['./register-complete.component.scss']
})
export class RegisterCompleteComponent implements OnInit {
  title = 'Qiymətli kağızları emissiyası sistemi';
  title2 = 'İstifadəçi qeydiyyatı';
  registerForm: FormGroup;
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  showSpinner: boolean;
  Region=[]
  City=[]
  constructor(
    public dialog: MatDialog,
    private cabinetService: Cabinet,
    private accountService: AccountService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  
  ) {
    localStorage.removeItem("login");
    if (!localStorage.getItem("asanToken") || this.accountService.getUserName().errorCode == 0
    || localStorage.getItem("portal")!="EMITENT"){
      this.router.navigate(["/"]);
    }
    this.registerForm = this.fb.group({
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      cityId: [0, Validators.required],
      regionId: [0],
      userToken: [localStorage.getItem("asanToken"), Validators.required]
    });

  }

  ngOnInit(): void {
    this.setTitleAndBredcrumb();
    this.getCity();
    this.getRegionBYid(0)
  }

  // get email(): AbstractControl { return this.registerForm.controls.email; }
  // get phone(): AbstractControl { return this.registerForm.controls.phone; }

  private setTitleAndBredcrumb(): void {
    this.titleService.setTitle(this.title + ' | securities.e-cbar.az');
    const breadcrumb = { title: this.title, title2: this.title2 };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }
  getRegionBYid(id){
    if(id ==0){
      this.registerForm.get("regionId").setValue(0);
      return;
    }
     console.log(id)
    this.cabinetService.regionById(id).subscribe(result => {
   this.Region=result.result;
   console.log(this.Region)
   if(this.Region.length ==0){
    this.registerForm.get("regionId").setValue(0);
    return;
  }
    })
  
   }
  getCity(){
    this.cabinetService.city().subscribe(result => {
   this.City=result.result;
   console.log(this.City)
    })
  
   }
  
  register() {
    if (this.registerForm.invalid) {
      
      return;
    }
    this.accountService.registerComplete(this.registerForm.value).subscribe(data => {
    
      
      if (data.status.errorCode == 0) {
        let lg_model = new LoginASANVM();
        lg_model.checkCode = "200";
        lg_model.generalToken = localStorage.getItem("asanToken");
        console.log(localStorage.getItem("asanToken"))
        this.accountService.getMyAccounts(lg_model).subscribe((data: any) => {
          //console.log(data);
          if (data.status.errorCode == 0 && !data.response.isNew) {
            localStorage.removeItem("portal");
            this.accountService.openDialogAccounts(data.response.accounts);
           
           
          } else {
            this.cabinetService.messageDialog(data.status.message, true);
            this.router.navigate(["/"])
          }
        });
      } else {
        this.cabinetService.messageDialog(data.status.message, false);
        this.router.navigate(["/"])
      }
    },
      err => {
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }
}
