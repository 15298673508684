<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">

            <h2>ASAN İMZA - PIN2</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button (click)="closeDialog()" class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content>

        <div *ngIf="data" class=" text-font font-weight-bold">
            <div style="font-size: 18px; padding-left: 38px;" class="col-md-12  pt-3  font-weight-bold">
                <p>PİN1 kodu uğurla daxil edildi. İndi isə, yoxlama kodunun telefonun ekranındakı koda uyğun olduğundan əmin olun və ASAN İMZA PIN2 kodunu daxil edin.</p>

            </div>

            <div class="pt-2">

                <div style="height: 50px;" mb-2 class=" col-12  col-md-12 col-xs-12 d-flex justify-content-center">
                    <label class="gpp-head">Yoxlama kodu: </label>
                    <h1 class="ver_code">{{data.getModel.verificationCode}}</h1>


                </div>


            </div>


            <mat-spinner *ngIf="!hasStatus" class="my_spinner" [diameter]="70">
            </mat-spinner>


        </div>


    </mat-dialog-content>

</div>