<div class="container">
  <form [formGroup]="searchForm" class="row card mt-4" (submit)="search()">
    <div class="middle-text col-md-12">QİYMƏTLİ KAĞIZLARIN DÖVLƏT REYESTRİ SİSTEMİ</div>
    <div class="title col-md-12">Axtarış</div>
    <div class="row mt-3">
      <div class="col-sm-6 col-md-4">
        <label class="w-100">Axtarış kriteriyası
          <sup class="text-danger star">*</sup></label>
        <mat-form-field class="w-100">
          <mat-select formControlName="issuerInformation" #issuerInformation
            (selectionChange)="setIssuerInformation($event)">
            <mat-option value="">--- Seçin ---</mat-option>
            <mat-option *ngFor="let emitentInfo of emitentInfos" [value]="emitentInfo.key">
              {{emitentInfo.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="pt-1"
          *ngIf="!searchForm.controls.issuerInformation.valid && searchForm.controls.issuerInformation.touched">
          Xana mütləq doldurulmalıdır
        </mat-error>
      </div>
      <div *ngIf="issuerInformation.value ==='NAME'" class=" col-sm-6 col-md-4 mt-sm-0">
        <label class="w-100">Emitentin adı
          <sup class="text-danger star">*</sup> </label>
        <mat-form-field class="w-100">
          <mat-select formControlName="issuerId" #issuerId>
            <mat-select-filter [displayMember]="'name'" *ngIf="issuerId.focused" [placeholder]="'Axtar...'"
              [noResultsMessage]="'Nəticə tapılmadı'" [array]="issuers" (filteredReturn)="filteredIssuers = $event">
            </mat-select-filter>
            <mat-option value="">--- Seçin ---</mat-option>
            <!-- <mat-option *ngFor="let issuer of issuers" [value]="issuer.id">{{issuer.name}}</mat-option> -->
            <mat-option [value]="issuer.id" *ngFor="let issuer of filteredIssuers">
              {{issuer.name}}
          </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="pt-1" *ngIf="!searchForm.controls.issuerId.valid && searchForm.controls.issuerId.touched">
          Xana mütləq doldurulmalıdır
        </mat-error>
      </div>
      <div *ngIf="issuerInformation.value ==='TIN'" class=" col-sm-6 col-md-4 mt-sm-0">
        <label class="w-100">VÖEN
          <sup class="text-danger star">*</sup> </label>
        <mat-form-field class="w-100">
          <input formControlName="tin" matInput type="text" style="height:100%">
        </mat-form-field>
        <mat-error class="pt-1" *ngIf="!searchForm.controls.tin.valid && searchForm.controls.tin.touched">
          Xana mütləq doldurulmalıdır
        </mat-error>
      </div>
      <div class="col-sm-6 col-md-4 ">
        <label class="w-100">Qiymətli kağızın növü<sup class="text-danger star">*</sup> </label>
        <mat-form-field class="w-100">
          <mat-select formControlName="columnTypeId" (selectionChange)="setColumnTypeKey($event); isSearch=false">
            <mat-option value="">--- Seçin ---</mat-option>
            <mat-option *ngFor="let columnType of columnTypes" [value]="columnType.id">{{columnType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="pt-1"
          *ngIf="!searchForm.controls.columnTypeId.valid && searchForm.controls.columnTypeId.touched">
          Xana mütləq doldurulmalıdır
        </mat-error>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-end">
      <button [disabled]="searchForm.invalid" mat-button class="btn custom-btn">Axtar</button>
    </div>
    <div class="w-100">
      <app-stocks *ngIf="columnTypeKey == columnType.STOCK && isSearch" [searchForm]="searchForm.value"></app-stocks>
      <app-bonds *ngIf="isSearch && (
            columnTypeKey == columnType.CORP_BONDS ||
            columnTypeKey == columnType.GOV_BONDS ||
            columnTypeKey == columnType.MUN_BONDS ||
            columnTypeKey == columnType.BANK_NOTES ||
            columnTypeKey == columnType.DEPOSITORY_RECEIPTS ||
            columnTypeKey == columnType.CERTIFICATES)" [searchForm]="searchForm.value"
        [columnTypeKey]="columnTypeKey"></app-bonds>
      <app-prospectus *ngIf="columnTypeKey == columnType.PROSPECTUS && isSearch"
        [searchForm]="searchForm.value"></app-prospectus>
    </div>
  </form>
</div>
