import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Cabinet } from '../../cabinet.service';
import { AccountService } from 'src/app/home/components/home/account-service';

@Component({
  selector: 'app-new-appeal-default',
  templateUrl: './new-appeal-default.component.html',
  styleUrls: ['./new-appeal-default.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppealDefault implements OnInit {
  type = this.activatedRoute.snapshot.queryParamMap['params']['type'];
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  applyPetition: any;
  apply_active: boolean = false;
  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private accountService: AccountService,
    private cabinetService: Cabinet
  ) {
    this.getApplyDefault();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }
  }
  ngOnInit(): void {
  }

  getApplyDefault() {
    if (this.id) {
      this.cabinetService.getApplyDefault(this.id).subscribe(
        res => {
          if (res.status.errorCode != 0) {
            this.cabinetService.messageDialog(res.status.message, false);
            this.router.navigate([""]);
          }
          else {
            this.applyPetition = res.response;
            this.apply_active = this.applyPetition?.apply?.applyType?.isActive;
          }
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
        })
    } else {
      this.cabinetService.getApplyTypeItem(this.type).subscribe((data => {
        this.apply_active = data.result?.isActive;
      }));
    }
  }

}
