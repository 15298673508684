<div class="container-fluid">
    <div class="row pt-3 title" >
        <div class="col-10 pb-3">
            <h4>Məlumat</h4>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content>
        <mat-dialog-actions align="end" class="mt-2 text-left">
            <div class = "row">
                <div class="col-12 pb-3">
                <h6>
                    İlkin müraciətdən 24 saat keçdiyi üçün yeni müraciət yaratmaq lazımdır.
                </h6>
                </div>
            </div>
         
        </mat-dialog-actions>
    </mat-dialog-content>
</div>
