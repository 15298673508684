import { Component, OnInit, Injectable, ViewChild, Input } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { InfoDialogComponent } from 'src/app/cabinet/dialogs/info-dialog/info-dialog.component';
import { GppDialogComponent } from 'src/app/cabinet/dialogs/gpp-dialog/gpp-dialog.component';
import { MeasDialogComponent } from 'src/app/cabinet/dialogs/meas-dialog/meas-dialog.component';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-success-step',
  templateUrl: './success-step.component.html',
  styleUrls: ['./success-step.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class SuccessStepComponent implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  status: any;
  @Input("status") set statusSet(value: any) {
    this.status = value;
  }
  waitDocumentForm: FormGroup;
  outputDocuments: any[];
  showSpinner: boolean;
  formData: FormData;
  hasRequest: boolean;
  activePortal: any;
  nowDate: Date = new Date();
  nextPortal: any;
  clickedPath: any;
  downloadSpinner: boolean;
  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {
    this.cabinetService.clearStorage();
  }
  ngOnInit(): void {
    this.getOutputDoc();
    this.setForm();
  }

  setForm() {
    this.waitDocumentForm = this.fb.group({
      file: [, Validators.required]
    });
  }

  meas() {

  }

  downloadFile(path) {
    this.clickedPath = path;
    this.downloadSpinner = true;
    this.cabinetService.download(path).subscribe(
      res => {
        let file = this.cabinetService.base64ToBlob(res.data, res.contentType, 512);
        var fileURL = URL.createObjectURL(file);
        var savefile = document.createElement("a");
        savefile.download = res.name;
        savefile.href = fileURL;
        savefile.click();
        this.downloadSpinner = false;
      },
      err =>
      {
        this.cabinetService.messageDialog(err.error.status.message, false);
        this.downloadSpinner = false;
      })
  }

  sendMeas(key, docName){
    console.log(key);
    this.dialog.open(MeasDialogComponent, {
      width: '550px',
      position:{
        top:'110px'
      },
      data: { isMeas: true, doc_name: docName, doc_key: key },
      autoFocus: false,
    });
  }

  getOutputDoc() {
    if (this.id) {
      this.showSpinner = true;
      this.cabinetService.getOutputDoc(this.id).subscribe(
        res => {
          this.outputDocuments = res.response.docs;
          this.activePortal = res.response.activePortal;
          this.nextPortal = res.response.nextPortal;
          this.showSpinner = false;
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.showSpinner = false;
        })
    }
  }
  goNext() {
    if (this.id) {
      let obj = {
        'modifications': {
         
        }
      }
      this.hasRequest = true;
      this.cabinetService.goNext(obj, this.id).subscribe(
        res => {
          window.location.reload();
        },
        err => {
          if (err.error.status.errorCode != 0)
            this.cabinetService.messageDialog(err.error.status.message, false);
          this.hasRequest = false;
        })
    }
  }

  setApplyCorrectDoc(file, key, isSystem) {
    if (!file) return;
    let data = {
      key: key,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      fileExt: file.name.split('.').pop(),
      isSystem: isSystem
    }
    let formData = new FormData();
    formData.append(`isSystem`, data.isSystem);
    formData.append(`doc.key`, data.key);
    formData.append(`doc.file`, data.file);
    formData.append(`doc.fileName`, data.fileName);
    formData.append(`doc.fileSize`, data.fileSize);
    formData.append(`doc.fileExt`, data.fileExt);
    this.formData = formData;
  }
}