import { Component, OnInit, Injectable, ViewChild } from '@angular/core';

import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/home/components/home/home.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/home/components/home/account-service';


@Component({
  selector: 'app-appeals-underwriter',
  templateUrl: './appeals-underwriter.component.html',
  styleUrls: ['./appeals-underwriter.component.scss']
})
@Injectable({
  providedIn: 'root'
})

export class AppealsUnderwriter implements OnInit {
  applicationForm: FormGroup;
  miniShowSpinner = false
  showSpinner = false;
  width1 = 0;
  width = 100;
  applyType = [];
  status = [];
  ELEMENT_DATA = [];
  displayedColumns: string[] = ['name', 'apply', 'date', 'status', 'operation'];
  dataSource = this.ELEMENT_DATA;
  company = [];
  pageSize: any;
  totalSize: number;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private accountService: AccountService,
    private cabinetService: Cabinet,
    private _sanitizer: DomSanitizer,
    private homeService: HomeService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title

  ) {

    this.cabinetService.clearStorage();
    if (this.accountService.is_emitent()) {
      this.router.navigate(["/appeals"]);
    } else {


      this.applicationForm = this.fb.group({
        companyId: [null],
        applyTypeId: [null],
        startDate: [null],
        endDate: [null],
        statusId: [null],
        page: [0],
        limit: [8],
      });
      this.getApply();
      this.getApllyType();
      this.getStatus();
      this.getCompany();
    }
  }
  ngOnInit(): void {
    this.setTitle();

  }
  //set Title
  setTitle() {
    this.TitleService.setTitle("Müraciətlər" + " | securities.e-cbar.az.az");
    const breadcrumb = { title: "Müraciətlər" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

  }


  getApllyType() {
    this.cabinetService.getApplyTypes().subscribe(res => {

      this.applyType = res.result
    })
  }
  getStatus() {
    this.cabinetService.u_getStatus().subscribe(res => {

      this.status = res.result
    })
  }
  getCompany() {
    this.cabinetService.u_getCompany().subscribe(res => {

      this.company = res.result
    })
  }
  getApply() {
    this.showSpinner = true;
    this.cabinetService.u_getApply(this.applicationForm.getRawValue()).subscribe(res => {
      this.ELEMENT_DATA = res.response.data;
      this.totalSize = res.response.total;
      console.log(res.response.data)
      this.showSpinner = false;
    })
  }

  filter() {

    this.getApply()
    console.log(this.applicationForm.value)
  }
  pageChanged(event: any) {
    let pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.applicationForm.get("page").setValue(pageIndex);
    this.applicationForm.get("limit").setValue(this.pageSize);
    this.getApply();
  }
  openhistorically(ID) {
    console.log(ID);
    this.cabinetService.openDialogHistoricallys(ID);
  }
}
