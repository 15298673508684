import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-new-company-dialog',
  templateUrl: './new-company-dialog.component.html',
  styleUrls: ['./new-company-dialog.component.scss']
})
export class NewCompanyDialogComponent implements OnInit {
  newForm: FormGroup;
  newAlready = false;
  showSpinner: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog,
  private cabinetService: Cabinet,
  private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
  private titleService: Title,
  private fb: FormBuilder,
  private router: Router,
  private route: ActivatedRoute
  ) { 
    this.newForm = this.fb.group({
      tin: ['', Validators.required],
      attorneyNumber: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  addCompany(){
    if(this.newForm.invalid){
      return;
    }
  }
}
