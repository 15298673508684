import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AppConfig } from 'src/app.config';
import { AccountService } from '../account-service';
import { HomeService } from '../home.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { NewCompanyDialogComponent } from 'src/app/cabinet/dialogs/newCompany/new-company-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectCompanyDialogComponent } from 'src/app/cabinet/dialogs/selectCompany/select-company-dialog.component';
import { Static } from 'src/app/models/constants';
import { v4 as uuidv4 } from 'uuid';
import { TokenModel } from 'src/app/models/TokenModel';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-home',
  templateUrl: './emission-home.component.html',
  styleUrls: ['./emission-home.component.scss']
})
export class EmissionHomeComponent implements OnInit {
  title = "Qiymətli Kağızların Emissiyası Sistemi";
  bsxmUrl: string;
  showSpinner: boolean;
  testLogin: boolean;
  qk_home_page: any;
  asanSpinner;
  bfbUrl: any;
  mdmUrl: any;
  asanloginAuthURI: string | undefined;
  asan_client_id: string | undefined;
  asan_redirect_uri: string | undefined;
  AsanV2Model: any;
  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private cabinetService: Cabinet,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private homeService: HomeService,
    private route: ActivatedRoute) {
    this.asanloginAuthURI = AppConfig.settings.asan.asanloginAuthUrl;
    this.asan_client_id = AppConfig.settings.asan.asan_client_id;
    this.asan_redirect_uri = AppConfig.settings.asan.asan_redirect_url;
    this.is_come_token(false);
    this.bsxmUrl = AppConfig.settings.other.bsxmLogin;
    this.mdmUrl = AppConfig.settings.urls.mdmUrl;
    this.bfbUrl = AppConfig.settings.urls.bfbUrl;
    var auth = this.accountService.getPortal();
    if (auth.errorCode == 0) {
      this.router.navigate(["/" + auth.path]);
    }
    this.testLogin = AppConfig.settings.other.testLogin;
    this.AsanV2Model = this.accountService.getData();
    if (this.AsanV2Model != null || undefined) {
      this.is_come_token(true);
    }
  }

  ngOnInit(): void {
    this.setTitleAndBredcrumb();
    this.get_data();
  }

  private setTitleAndBredcrumb(): void {
    this.titleService.setTitle(this.title + ' | securities.e-cbar.az');
    const breadcrumb = { title: this.title };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }

  gotoLogin(id) {
    localStorage.setItem("portal", id == 0 ? "EMITENT" : "UNDER_WRITER");
    this.router.navigate(['login'], { queryParams: { type: `${id}` } });
  }

  gotoUserManual(id) {
    localStorage.setItem("portal", id == 0 ? "EMITENT" : "UNDERWRITER");
    this.router.navigate(['user-manual-default'], { queryParams: { type: `${id}` } });
  }

  get_data() {
    this.homeService.static(Static.QK_HOME_PAGE_2).subscribe(
      ((data) => {
        this.qk_home_page = data.result;
        this.qk_home_page["description"] = this.decodeReceipt(this.qk_home_page["description"])
      }
      ));
  }

  public decodeReceipt(data) {
    return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  login(id) {
    this.asanSpinner = true;
    localStorage.setItem("portal", id == 0 ? "EMITENT" : "UNDER_WRITER");
    this.is_come_token_old(true);
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  certRequest(num, portal) {
    if (portal == "bfb") {
      window.location.href = this.bsxmUrl + this.bfbUrl + '/cert-request?type=' + num + "&";
    } else {
      window.location.href = this.bsxmUrl + this.mdmUrl + '/cert-request?type=' + num + "&";
    }
  }

  gotoBsxm(path: string, portal): void {
    if (portal == "bfb") {
      window.location.href = this.bsxmUrl + this.bfbUrl + "/" + path + "?";
    } else {
      window.location.href = this.bsxmUrl + this.mdmUrl + "/" + path + "?";

    }
  }

  gotoPortal(path: string, portal): void {
    if (portal == "bfb") {
      window.location.href = this.bfbUrl + "/" + path;
    } else {
      window.location.href = this.mdmUrl + "/" + path;

    }
  }

  is_come_token_old(secondLogin: boolean) {
    if (secondLogin || localStorage.getItem("login") == "ok") {
      this.accountService.getAsanTokeTestMain().subscribe((data1: any) => {
        data1.checkCode = "200";
        //console.log('data1',data1);
        this.accountService.getMyAccounts(data1).subscribe((data: any) => {
          //console.log(data);
          localStorage.setItem("asanToken", data1.generalToken)
          if (data.status.errorCode == 0 && data.response.isNew) {
            var route = "register-complete";
            if (localStorage.getItem("portal") == "UNDER_WRITER") {
              route += "-underwriter";
            }
            this.router.navigate(["/" + route]);
          } else {
            localStorage.removeItem("login");
            if (localStorage.getItem("portal") == "UNDER_WRITER") {
              localStorage.removeItem("portal");
              this.accountService.createJWT(data.response, "appeals-underwriter");
            } else {
              localStorage.removeItem("portal");
              this.accountService.openDialogAccounts(data.response.accounts);
            }

          }
        },
          err => {
            if (secondLogin) {
              localStorage.setItem("login", "ok");
              window.location.href = this.accountService.getAsanURLLogin() + this.router.url.toString().substr(1);

            }

          });
      },
        err => {
          if (secondLogin) {
            localStorage.setItem("login", "ok");
            window.location.href = this.accountService.getAsanURLLogin() + this.router.url.toString().substr(1);

          }
        });
    }
  }

  is_come_token(secondLogin: boolean) {
    if (secondLogin || localStorage.getItem("login") == "ok") {
      this.asanSpinner = true;
      this.accountService.getMyAccounts({ AuthorizationCode: this.AsanV2Model.code, State: this.AsanV2Model.state }).subscribe((data: any) => {
          localStorage.setItem("asanToken", data.response.asanToken)
      
        if (data.status.errorCode == 0 && data.response.isNew && localStorage.getItem("portal") == "EMITENT" ) {
         // if (localStorage.getItem("portal") == "EMITENT") {
            this.router.navigate(["/register-complete"]);
         // }
          //  else {
          //   this.router.navigate(["/register-complete-underwriter"]);
          // }
        } else {

          localStorage.removeItem("login");
          if (localStorage.getItem("portal") == "EMITENT") {
            this.asanSpinner = false;
            this.accountService.openDialogAccounts(data.response.accounts);
          } else {
            let model = new TokenModel();
            //model.companyId = data.response.accounts[0].companyId;
            //model.tin = data.response.accounts[0].tin;
            //model.type = data.response.accounts[0].type;

            model.userToken = data.response.asanToken;
            if (data.response.asanToken) {
              this.accountService.generateTokenUnderwriter(model).subscribe(gdata => {
                if (gdata.status && gdata.status.errorCode == 1) {
                  this.asanSpinner = false;
                  this.cabinetService.messageDialog(gdata.status.message, false);
                } else {
                  data.response.token = gdata.response.token;
                  this.accountService.createJWT(gdata.response, "appeals-underwriter");
                  this.asanSpinner = false;
                  // localStorage.removeItem("asanToken");
                  // localStorage.removeItem("email");
                  // this.dialog.closeAll();
                  //this.router.navigate(['/'])
                }
              },
                err => {
                  this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
                  this.asanSpinner = false;
                });
            }
            else {
              this.cabinetService.messageDialog(data.status.message, false);
              this.asanSpinner = false;
            }
          }
        }
      },
        err => {
          if (secondLogin) {
            localStorage.setItem("login", "ok");
            this.asanSpinner = false;
            window.location.href = this.accountService.getAsanURLLogin() + this.router.url.toString().substr(1);
          }
        });
    }
  }

  asanv2(id) {
    localStorage.setItem("portal", id == 0 ? "EMITENT" : "UNDER_WRITER");
    this.accountService.getStateId().subscribe((res: any) => {
      let client_id = this.asan_client_id;
      let redirect_uri = this.asan_redirect_uri;
      let authUrl = this.asanloginAuthURI!;
      let state_id = res;
      let url = authUrl + '?client_id=' + client_id + '&' + 'redirect_uri=' + redirect_uri + '&' + 'response_type=code' + '&' + 'state=' + state_id + '&' + 'scope=openid certificate';
      window.location.href = url;
    });
  }
}
