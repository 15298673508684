import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from 'src/loader.service';
import {AppConfig} from 'src/app.config';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  name: string;
  cbarUrl: string;
  homeUrl: string;
  contactUrl: string;
  onlineQueueUrl: string;
  showProgress: boolean = true;
  visible: boolean = false;
  // encapsulation: ViewEncapsulation.None
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  //navbardropdown mobile menu
  hasToken = false

  constructor(
    public loaderService: LoaderService,
  ) {
    this.homeUrl = AppConfig.settings.urls.homeUrl;
    this.cbarUrl = AppConfig.settings.urls.cbarUrl;
    this.contactUrl = AppConfig.settings.urls.contactUrl;
    this.onlineQueueUrl = AppConfig.settings.urls.onlineQueueUrl;
    this.loaderService.isLoading.subscribe((v) => {
      this.showProgress = v;
    });
    this.hasToken = !!localStorage.getItem('token')
  }

  ngOnInit() {

  }

  public showToolbar() {
    this.visible = !this.visible;
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    // this.subscription.unsubscribe();
  }
}
