<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Bildiriş</h2>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="mat-typography justify-center">
      
      <div style="font-weight: 400; font-size:16px">Emissiya məbləği ilə Qiymətli kağızın miqdarı uyğun olmalıdır</div> 
    </mat-dialog-content>
</div>