import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class TranslateMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
      this.firstPageLabel = 'İlk səhifə'
      this.previousPageLabel = 'Əvvəlki səhifə';
      this.nextPageLabel = 'Növbəti səhifə';
      this.lastPageLabel = 'Son səhifə';
      this.itemsPerPageLabel = 'Səhifədəki say';
  }
}