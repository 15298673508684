import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AccountService } from 'src/app/home/components/home/account-service';
import { UserRoles } from 'src/app/models/constants';
import { Cabinet } from '../../cabinet.service';



@Component({
  selector: 'app-new-access',
  templateUrl: './new-access.component.html',
  styleUrls: ['./new-access.component.scss'],

})
export class NewAccessComponent implements OnInit {

  title = 'Qiymətli kağızları emissiyası sistemi';
  title2 = 'İstifadəçi qeydiyyatı';
  iamasForm: FormGroup;
  accessForm: FormGroup;
  checked = false;
  indeterminate = false;
  iamasbuton = false;
  accessbuton = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  showSpinner: boolean;
  response
  emitentRole = []

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private cabinetService: Cabinet,
    private accountService: AccountService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute

  ) {
    this.cabinetService.clearStorage();
    if(!this.accountService.is_emitent()){
      this.router.navigate(["appeals-underwriter"]);
    }
    
    this.accoountPermission();

    this.iamasForm = this.fb.group({
      birthDate: ['', [Validators.required,Validators.pattern('^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])(19|20)\\d\\d$')]],
      pinCode: ['', Validators.required],

    });
    this.accessForm = this.fb.group({
      roleKey: ['', Validators.required],
      position: ['', Validators.required],
      pin: ['', Validators.required],


    });
  }
  ngOnInit() {
    this.getEmitentRole()
    // this.iamasForm.controls['birth'].valueChanges.subscribe(change => {
    //   let a: string = change.toString()
    //   console.log(a);
    //   if (a.substring(2, 3) == '.') {

    //   } else {
    //     this.iamasForm.get("birthDate").setValue(this.datepipe.transform(change, 'dd.MM.yyyy'))
    //   }
    // });
  }

  accoountPermission() {
    this.accountService.accountPermission().subscribe(data => {
      console.log(data);
      if (data.status && data.status.errorCode == 0) {
        if (!(data.response == UserRoles.LEADER || data.response == UserRoles.FOUNDER ||UserRoles.ACCESS_APPLY)) {
          this.router.navigate(["/"]);
        }
      }

    });
  }
  getEmitentRole() {
    this.cabinetService.emitentRole().subscribe(res => {
      this.emitentRole = res.result
    })
  }
  checkUser() {
    let birthdate: string = this.iamasForm.controls.birthDate.value;

    if (birthdate.indexOf(".") == -1) {
      birthdate = birthdate.substring(0, 2) +
        "." +
        birthdate.substring(2, 4) +
        "." +
        birthdate.substring(4, 8);
    }
    this.iamasbuton = true;
    this.cabinetService.checkUser(this.iamasForm.controls.pinCode.value, birthdate).subscribe(res => {
      if (res.status.errorCode == 0) {
        console.log(res);

        this.response = res.response
        this.accessForm.get("pin").setValue(res.response.pin)
      }
      else {
        this.cabinetService.messageDialog(res.status.message, false)
      }
      this.iamasbuton = false;
    })
  }
  createAccess() {
    this.accessbuton = true;
    this.cabinetService.createAccess(this.accessForm.getRawValue()).subscribe(res => {
      this.cabinetService.messageDialog(res.status.message, false)
      this.accessbuton = false;
      this.router.navigate(["/access-list"]);
    })

  }

  changeFormatDate(date: string | number | Date) {
    return this.cabinetService.changeFormatDate(date)
  }
}
