import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AsanFileModel } from 'src/app/models/asan-file-model';
import { Cabinet } from '../../cabinet.service';
import { AsanDialog2Component } from '../asan-dialog-2/asan-dialog-2.component';

@Component({
  selector: 'app-asan-dialog-1',
  templateUrl: './asan-dialog-1.component.html',
  styleUrls: ['./asan-dialog-1.component.scss']
})
export class AsanDialog1Component implements OnInit {
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  src = undefined;
  data_base64;
  checked = false;
  file_base_real;
  hasRequest: boolean;
  files: AsanFileModel[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute, public dialog: MatDialog, public cabinetService: Cabinet) {
    this.cabinetService.clearStorage();
    this.downloadFile(data.templatePath.replace("docx", "pdf"), data.key);
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    console.log(bytes)

    return bytes.buffer;
  }


  ngOnInit(): void {
  }

  closeDialog() {
    window.location.reload();
  }
  
  getData(data) {
    return data.split('&');
  }


  downloadFile(path, key) {
    this.cabinetService.download(path).subscribe(
      res => {
        this.src = this._base64ToArrayBuffer(res.data);
        this.file_base_real = res.data;
        this.data_base64 = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64," + res.data);

        console.log(this.src);
        //this.downloadSpinner = false;
      },
      err => {
        this.cabinetService.messageDialog(err.error.status.message, false);
        // this.downloadSpinner = false;
      })
  }



  confirm() {
    this.hasRequest = true;

    var file = new AsanFileModel();
    file.mimeType = "application/pdf";
    file.fileBodyBase64 = this.file_base_real;
    file.fileName = "Müqavilə.pdf";

    this.files.push(file);

    console.log(this.id)
    this.dialog.closeAll();
    this.dialog.open(AsanDialog2Component, {
      width: '650px',
      position: {
        top: '110px'
      },
      data: {files: this.files, key: this.data.key},
      autoFocus: false,
    });


  }

}
