import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ExcellService } from 'src/app/services/excell.service';
import { HomeService } from '../../../home.service';

export interface Prospectus {
  id: number,
  name: string,
  tin: string,
  address: string,
  securitiesType: string,
  currency: string,
  regCost: number,
  observe: string,
  regQuantity: number,
  regTotalValue: number,
  approveDate: Date
}

@Component({
  selector: 'app-prospectus',
  templateUrl: './prospectus.component.html',
  styleUrls: ['./prospectus.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('expandArrowIcon', [
      state('right', style({ transform: 'rotate(0)' })),
      state('down', style({ transform: 'rotate(90deg)' })),
      transition(
        'right <=> down',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ProspectusComponent implements OnInit {
  prospectusForm;
  public pageSize = 8;
  public currentPage = 0;
  public totalSize = 0;
  limit: any = 5;
  pageIndex: any = 0;
  skip: any = 0;
  dataSource: any;
  displayedColumns: string[] = ['arrow', 'name', 'tin', 'address', 'securitiesType', 'currency', 'approveDate'];
  displayedColumnsExpand = [...this.displayedColumns, 'expand'];
  disableExport = false;

  showSpinner = false;
  ELEMENT_DATA: any;
  exportDataList: any;

  constructor(private homeService: HomeService,
    private cdRef: ChangeDetectorRef,
    private excellService: ExcellService) { }

  @Input() searchForm;

  ngOnInit(): void {
    console.log(this.searchForm);
    this.filter();
  }

  filter() {
    this.prospectusForm = this.searchForm;
    this.getProspectusList();
  }

  pageChanged(event: any) {
    let pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.prospectusForm.skip = pageIndex;
    this.prospectusForm.limit = this.pageSize;
    this.getProspectusList();
  }

  getProspectusList() {

    this.showSpinner = true;
    this.homeService.getProspectusList(false, this.prospectusForm).subscribe(res => {

      this.totalSize = res.response.total;
      let tempDataSource: Prospectus[] = [];
      console.log(res.response.data);
      for (let element of res.response.data) {
        let prospData: Prospectus = {
          id: element?.id,
          name: element?.name,
          tin: element?.tin,
          address: element?.address,
          securitiesType: element?.securitiesType,
          currency: element?.currency,
          regCost: element?.expand?.regCost,
          observe: element?.expand?.observe,
          regQuantity: element?.expand?.regQuantity,
          regTotalValue: element?.expand?.regTotalValue,
          approveDate: element?.approveDate
        };
        tempDataSource.push(prospData);
      }
      this.dataSource = new MatTableDataSource<Prospectus>(tempDataSource);
      this.showSpinner = false;
    })
  }

  changeFormatDate(date: any) {
    return this.homeService.changeFormatDate(date);
  }

  exportAsXLSX(): void {
    let column_data = []
    column_data.push({ header: 'Emitentin adı ', key: 'name', width: 40, height: 30 });
    column_data.push({ header: 'Vergi ödəyicisinin eyniləşdirmə nömrəsi (VÖEN)', key: 'tin', width: 40, height: 30 });
    column_data.push({ header: 'Emitentin hüquqi ünvanı', key: 'address', width: 30, height: 30 });
    column_data.push({ header: 'Qiymətli kağızın növü', key: 'securitiesType', width: 30, height: 30 });
    column_data.push({ header: 'Buraxılışın valyutası', key: 'currency', width: 30, height: 30 });
    column_data.push({ header: 'Sənədin təsdiq edilmə tarixi', key: 'approveDate', width: 30, height: 30 });
    column_data.push({ header: 'Qiymətli kağızların sayı', key: 'regQuantity', width: 30, height: 30 });
    column_data.push({ header: 'Qiymətli kağızların nominal dəyəri', key: 'regCost', width: 30, height: 30 });
    column_data.push({ header: 'Qiymətli kağızların məcmu nominal dəyəri', key: 'regTotalValue', width: 30, height: 30 });
    column_data.push({ header: 'Sənədin növü', key: 'observe', width: 30, height: 30 });


    console.log(column_data);

    let data = [];
    this.exportDataList.forEach(element => {
      data.push({
        name: element?.name,
        tin: element?.tin,
        address: element?.address,
        securitiesType: element?.securitiesType,
        currency: element?.currency,
        regCost: element?.regCost,
        observe: element?.observe,
        regQuantity: element?.regQuantity,
        regTotalValue: element?.regTotalValue,
        approveDate: this.changeFormatDate(element?.approveDate)
      });
    });
    this.excellService.exportAsXLSX(column_data, data, "EMISSIYA PROSPEKTI");
  }

  export() {
    this.getExportProspectus();
  }

  getExportProspectus() {
    this.showSpinner = true
    this.disableExport = true;
    this.cdRef.detectChanges();
    this.homeService.getProspectusList(true, this.searchForm).subscribe(res => {

      let tempDataSource: Prospectus[] = [];
      if (this.dataSource != null) {
        for (let element of res.response.data) {
          let prospData: Prospectus = {
            id: element?.id,
            name: element?.name,
            tin: element?.tin,
            address: element?.address,
            securitiesType: element?.securitiesType,
            currency: element?.currency,
            regCost: element?.expand?.regCost,
            observe: element?.expand?.observe,
            regQuantity: element?.expand?.regQuantity,
            regTotalValue: element?.expand?.regTotalValue,
            approveDate: element?.approveDate
          };
          tempDataSource.push(prospData);
        }
        this.dataSource = new MatTableDataSource<Prospectus>(tempDataSource);
        this.showSpinner = false;

        this.exportDataList = tempDataSource;
        console.log(this.exportDataList)
        this.exportAsXLSX();
        this.showSpinner = false
        this.disableExport = false;
      }
    })
  }
}
