<div class="container-fluid border shadow-sm my-3 ">
    <div class="row" *ngIf="!showSpinner">
        <div class="col-12 bg-white px-4 pt-4 pb-5 ">
            <h1 class="font-weight-bold cabinet-title">Yeni müraciət</h1>
            <span class="cabinet-sub-title pl-1">Müraciət göndərmək üçün aşağıdaki proseslərdən birini seçin.</span>
            <div class="content col-12 row pt-5">
                <div class="card shadow mb-3 px-2 py-3 bg-white rounded grey_style" [class.grey_style]="!type.isActive" *ngFor=" let type of applyTypes; let i = index; " (click)="type.isActive && goToNewApply(type.key)">

                    <ng-container>
                        <div class="d-flex align-items-center pr-3">
                            <img src="../../../../assets/images/FilePlus.png" class="card-img-left  " alt="...">
                        </div>
                        <div class="card-body align-items-center d-flex">
                            <p class="card-text ">{{type.processNumber+'. '+type.name}}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 py-3 pt-5 pb-5 bg-white" *ngIf="showSpinner">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
    </div>
</div>