<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Qəbz</h2>

        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="receipt">
        <iframe #receipt class="iframe_dept content my-3" *ngIf="data_source" [src]="data_source">
      
      </iframe>

        <div *ngIf="!data_source" class="iframe_dept content"></div>
    </mat-dialog-content>

</div>
<div class="row">
    <div class="col-12 d-flex justify-content-end " *ngIf="data_source">
        <button mat-button type="button" class="btn mr-3" (click)="HTML_Dowload()">Yüklə</button>
        <!-- <button mat-button type="button" class="btn mr-2 " (click)="print()">Çap et</button> -->
    </div>
</div>