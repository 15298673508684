import { Injectable } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';
import { ExcellColumn } from '../models/excell.model';
@Injectable({
  providedIn: 'root'
})
export class ExcellService {
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  constructor() {
  }
  exportAsXLSX(column_data: ExcellColumn[], data: any, name: any): void {
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('My Sheet', {
      properties: {
        defaultRowHeight: 30,
      }
    }
    );
    let col_data = [];
    column_data.forEach(element => {
      col_data.push({
        header: element.header, key: element.key, width: element.width,
        outlineLevel: 0, hidden: false, style: null, values: null, letter: null,
        number: null, worksheet: worksheet, isCustomWidth: false, headers: null,
        isDefault: false, headerCount: 0, collapsed: false, eachCell: null,
        equivalentTo() { return false; }, defn: null
      })
    });
    worksheet.columns = col_data;
    worksheet.getRow(1).font = {
      name: '"BebasNeue", Arial, sans-serif;',
      family: 4,
      size: 10,
      bold: true
    };
    worksheet.getRow(1).alignment = { 
      vertical: 'middle',
      horizontal: 'center'
    };
    worksheet.properties.defaultRowHeight = 30;

    data.forEach(element => {
      worksheet.addRow(element);
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, name);
    });

  }




}
