<app-scroll-to-top></app-scroll-to-top>
<div class="container-fluid border shadow-sm my-3">
    <div class="row" *ngIf="!showSpinner">
        <div class="col-12 bg-white px-4 pt-4 pb-5">
            <h1 class="font-weight-bold cabinet-title">Yeni müraciət</h1>
            <span class="cabinet-sub-title pl-1">{{subTitle}}</span>
            <button *ngIf="id" mat-button class="custom-btn ex-button"
                style="position: absolute; top: 40px; right: 40px; font-size: 12px; float: right; background-color: #D90303!important; min-width: auto;"
                (click)="cancelDialog()">Müraciəti ləğv et</button>


            <mat-accordion multi>

                <mat-expansion-panel [expanded]="true" class="mb-2 mt-4">

                    <mat-expansion-panel-header class="profil-title">
                        <mat-panel-title class="profil-titl ">
                            <span style="font-weight:500 ;">Müraciət məlumatları</span>
                        </mat-panel-title>

                    </mat-expansion-panel-header>

                    <form [formGroup]="applyForm" *ngIf="applyForm" class="pt-3">
                        <div class="row">
                            <div [ngClass]="{'mt-3': type == applyType.TYPE4 || this.type == this.applyType.TYPE5}"
                                class="col-sm-6 col-md-4" *ngIf="type != applyType.TYPE3">
                                <label class="w-100">Qiymətli kağızın növü
                                    <sup class="text-danger star">*</sup></label>
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="qkTypeId"
                                        (selectionChange)="addControlsForBond($event.value)">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of qkTypes" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.qkTypeId.valid && applyForm.controls.qkTypeId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class="offset-md-1 col-sm-6 col-md-4" *ngIf="type == applyType.TYPE2">
                                <label class="w-100">Yenidən təşkilin səbəbi:
                                    <sup class="text-danger star">*</sup></label>
                                <mat-form-field class="w-100">
                                    <mat-select formControlName="ReasonReorganizationId">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of ReasonReorganization" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.ReasonReorganizationId.valid && applyForm.controls.ReasonReorganizationId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class="offset-md-1 col-sm-6 col-md-4 mt-3 mt-sm-0"
                                *ngIf="type == applyType.TYPE1 ||type == applyType.TYPE6 ||type == applyType.TYPE7">
                                <label class="w-100">Anderrayter <sup class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'qkAnderrayterId'">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of qkAnderrayters" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.qkAnderrayterId.valid && applyForm.controls.qkAnderrayterId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>


                            <div class="col-md-4 col-sm-6 mt-3" *ngIf="type == applyType.TYPE1">
                                <label class="w-100">Yerləşdirmə üsulu <sup class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'qkPlacementId'"
                                        (selectionChange)="addControlsForPlacement($event.value)">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of placementMethods" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.qkPlacementId.valid && applyForm.controls.qkPlacementId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class=" col-sm-6 col-md-4 mt-3" *ngIf="type == applyType.TYPE7">
                                <label class="w-100">Müraciətin məqsədi <sup class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'purposeApplyId'">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of purposeApply" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.purposeApplyId.valid && applyForm.controls.purposeApplyId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class="offset-md-1 col-sm-6 col-md-4 mt-3"
                                *ngIf="type == applyType.TYPE1 && isObserve">
                                <label class="w-100">Müşayiət olunur <sup class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'qkObserveId'"
                                        (selectionChange)="addControlsForObserve($event.value)">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of qkObserves" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.qkObserveId.valid && applyForm.controls.qkObserveId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class="col-sm-6 col-md-4 mt-3" *ngIf="type == applyType.TYPE1 && isTermsBaseIssue">
                                <label class="w-100">Baza emissiya prospektini seçin: <sup
                                        class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <!-- <mat-select [formControlName]="'baseEmissionId'" (selectionChange)="addControlsForObserve($event.value)"> -->
                                    <mat-select [formControlName]="'baseEmissionId'">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of baseEmissionProspects" [value]="item.id">{{
                                            item.applyNumber + " - "+ cabinetService.changeFormatDate(item.acceptDate) +
                                            " - " + item.issueAmount + " AZN" }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.qkObserveId.valid && applyForm.controls.qkObserveId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div *ngIf="issue_prospectus" class="col-md-4 col-sm-6 mt-3">
                                <label class="w-100">Emissiya məbləği
                                    <sup class="text-danger star">*</sup>
                                </label>
                                <mat-form-field class="w-100">
                                    <input matInput type="number" formControlName="issueAmount">
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.issueAmount.valid && applyForm.controls.issueAmount.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>

                                <mat-error class="pt-1" *ngIf="applyForm.controls.issueAmount.value<=0">
                                    Məbləğ 0-dan böyük olmalıdır
                                </mat-error>
                            </div>
                            <div *ngIf="issue_prospectus" class="offset-md-1 col-sm-6 col-md-4 mt-3">
                                <label class="w-100" id="example-radio-group-label">Ödəniş növü <sup
                                        class="text-danger star">*</sup> </label>
                                <mat-radio-group [formControlName]="'paymentTypeId'"
                                    aria-labelledby="example-radio-group-label" class="example-radio-group">
                                    <mat-radio-button class="w-100 example-radio-button"
                                        *ngFor="let item of paymentTypes" [value]="item.id">
                                        {{item.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.paymentTypeId.valid && applyForm.controls.paymentTypeId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>


                            <!-- applyType.TYPE6 -->
                            <div class="col-md-4 col-sm-6 mt-3" *ngIf="type == applyType.TYPE6 ">
                                <label class="w-100">Dəyişiklik ediləcək sənədin adı <sup
                                        class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'changeDocId'"
                                        (selectionChange)="checkControlsForChangeDoc($event.value)">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of documents" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.changeDocId.valid && applyForm.controls.changeDocId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class="col-md-4 col-sm-6 mt-3" *ngIf="type == applyType.TYPE3 ">
                                <label class="w-100">Emissiyanın məqsədi <sup class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'purposeIssueId'">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of PurposeIssue" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.purposeIssueId.valid && applyForm.controls.purposeIssueId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>

                            <div [ngClass]="{'offset-md-1':type == applyType.TYPE7 || type == applyType.TYPE3 || type == applyType.TYPE4 || type == applyType.TYPE6 }"
                                class=" col-md-4 col-sm-6 mt-3"
                                *ngIf="(type == applyType.TYPE2 || type == applyType.TYPE3 || type == applyType.TYPE6 && applyForm.controls.qkTypeId.valid || type == applyType.TYPE4 && applyForm.controls.qkTypeId.valid) || (type == applyType.TYPE7 && applyForm.controls.qkTypeId.valid)">
                                <label class="w-100">{{!isBond ? type != applyType.TYPE2 ? 'Səhmləriniz' :'' :
                                    'İstiqrazlarınız '}}{{type == applyType.TYPE2 ? 'Qiymətli kağızlarınız':''}} hazırda
                                    {{type == applyType.TYPE2 ? 'birjada' :''}} listinqdədirmi? <sup
                                        class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'isListingId'"
                                        (selectionChange)="addControlsForListing($event.value)">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let item of listing" [value]="item.id">{{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.isListingId.valid && applyForm.controls.isListingId.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>

                            <div class="col-md-4 col-sm-6 mt-3" *ngIf="type == applyType.TYPE4 && isBond">
                                <label class="w-100">Ödəniş agenti <sup class="text-danger star">*</sup> </label>
                                <mat-form-field class="w-100">
                                    <mat-select [formControlName]="'payingAgent'">
                                        <mat-option [value]="">--- Seçin ---</mat-option>
                                        <mat-option [value]="'mdm'">MDM</mat-option>
                                        <mat-option [value]="'other'">Digər</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.payingAgent.valid && applyForm.controls.payingAgent.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                            <div class=" col-sm-6 col-md-4 mt-3" *ngIf="type == applyType.TYPE6 && isListing">
                                <div>
                                    <label class="w-100">Qiymətli kağızların beynəlxalq eyniləşdirmə nömrəsi (ISIN)
                                        <sup *ngIf="type == applyType.TYPE6 && !isBaseProspectus"
                                            class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="registerNumber">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!applyForm.controls.registerNumber.valid && applyForm.controls.registerNumber.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                            </div>
                            <div [ngClass]="{'offset-md-1':type == applyType.TYPE6}" class=" col-sm-6 col-md-4 mt-3"
                                *ngIf="isListing">
                                <div *ngIf="type == applyType.TYPE3 || type == applyType.TYPE6">
                                    <label class="w-100">Listinq müqaviləsinin nömrəsi
                                        <sup *ngIf="type == applyType.TYPE6 && !isBaseProspectus"
                                            class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="contractNumber">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!applyForm.controls.contractNumber.valid && applyForm.controls.contractNumber.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                            </div>
                            <div [ngClass]="{'offset-md-1':type == applyType.TYPE3 }" class="col-md-4 col-sm-6 mt-3"
                                *ngIf="(type == applyType.TYPE3 || type == applyType.TYPE6) && isListing">
                                <label class="w-100">Listinq müqaviləsinin tarixi
                                    <sup *ngIf="type == applyType.TYPE6 && !isBaseProspectus"
                                        class="text-danger star">*</sup>
                                </label>
                                <mat-form-field class="w-100">
                                    <input matInput type="text" formControlName="contractDate"
                                        [matDatepicker]="pickerContractDate">
                                    <mat-datepicker-toggle style=" position: absolute !important;
                                        right: 1% !important;
                                        top: 15% !important;" matSuffix [for]="pickerContractDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker class="datepicker-appeal" #pickerContractDate></mat-datepicker>
                                </mat-form-field>
                                <mat-error class="pt-1"
                                    *ngIf="!applyForm.controls.contractDate.valid && applyForm.controls.contractDate.touched">
                                    Xana mütləq doldurulmalıdır
                                </mat-error>
                            </div>
                        </div>
                    </form>

                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" class="mb-2">

                    <mat-expansion-panel-header class="profil-title">
                        <mat-panel-title class="profil-titl">
                            <span style="font-weight:500 ;"> Ərizə və Rəhbər şəxs məlumatları</span>
                        </mat-panel-title>

                    </mat-expansion-panel-header>

                    <form [formGroup]="petitionForm" *ngIf="petitionForm" class="pt-3">
                        <div class="row">
                            <div class="col-sm-6 col-md-4">
                                <div *ngIf="type != applyType.TYPE7 && type != applyType.TYPE2">
                                    <label class="w-100">Qərarı qəbul etmiş orqanın adı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="protocolOrgName">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.protocolOrgName.valid && petitionForm.controls.protocolOrgName.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div *ngIf="type == applyType.TYPE2">
                                    <label class="w-100">Buraxılış ilə bağlı qərarı qəbul etmiş orqanın adı:
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="releaseOrgName">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.releaseOrgName.valid && petitionForm.controls.releaseOrgName.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <!-- <div *ngIf="type == applyType.TYPE2">
                                    <label class="w-100">Yenidən təşkil ilə bağlı qərarı qəbul etmiş orqanın adı:
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="reorganizeOrgName">
                                    </mat-form-field>
                                    <mat-error class="pt-1" *ngIf="!petitionForm.controls.reorganizeOrgName.valid && petitionForm.controls.reorganizeOrgName.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div> -->
                                <div [ngClass]="{'mt-3': type != applyType.TYPE7}">

                                    <label class="w-100">
                                        <span *ngIf="type != applyType.TYPE7">Qərarın (protokolun) nömrəsi</span>
                                        <span *ngIf="type == applyType.TYPE7">Dövlət qeydiyyat nömrəsi (ISIN)</span>
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="protocolNumber">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.protocolNumber.valid && petitionForm.controls.protocolNumber.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3">
                                    <label class="w-100">
                                        <span *ngIf="type != applyType.TYPE7">Qərarın (protokolun) tarixi</span>
                                        <span *ngIf="type == applyType.TYPE7">Dövlət qeydiyyat tarixi</span>
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="protocolDate"
                                            [matDatepicker]="pickerProtocolDate">
                                        <mat-datepicker-toggle style=" position: absolute !important;
                                            right: 1% !important;
                                            top: 15% !important;" matSuffix [for]="pickerProtocolDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker class="datepicker-appeal" #pickerProtocolDate></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.protocolDate.valid && petitionForm.controls.protocolDate.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <!-- <div class="mt-3" *ngIf="type == applyType.TYPE2">
                                    <label class="w-100">Buraxılış ilə bağlı qərarı qəbul etmiş orqanın adı:
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="releaseOrgName">
                                    </mat-form-field>
                                    <mat-error class="pt-1" *ngIf="!petitionForm.controls.releaseOrgName.valid && petitionForm.controls.releaseOrgName.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div> -->
                                <div class="mt-3" *ngIf="type == applyType.TYPE2">
                                    <label class="w-100">Yenidən təşkil ilə bağlı qərarı qəbul etmiş orqanın adı:
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="reorganizeOrgName">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.reorganizeOrgName.valid && petitionForm.controls.reorganizeOrgName.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3"
                                    *ngIf="type == applyType.TYPE1 || type == applyType.TYPE2 || type == applyType.TYPE3 || type == applyType.TYPE6 || type == applyType.TYPE4 || type == applyType.TYPE5 ">
                                    <label *ngIf="type != applyType.TYPE4" class="w-100">Qiymətli kağızın nominal dəyəri
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <label *ngIf="type == applyType.TYPE4" class="w-100">Dövriyyədən çıxarılacaq
                                        qiymətli kağızın nominal dəyəri
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="number" (input)="calculate()" formControlName="qkCost">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.qkCost.valid && petitionForm.controls.qkCost.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3"
                                    *ngIf="type == applyType.TYPE1 || type == applyType.TYPE2 || type == applyType.TYPE3 || type == applyType.TYPE6 || type == applyType.TYPE4 || type == applyType.TYPE5">
                                    <label *ngIf="type != applyType.TYPE4" class="w-100">Qiymətli kağızın miqdarı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <label *ngIf="type == applyType.TYPE4" class="w-100">Dövriyyədən çıxarılacaq
                                        qiymətli kağızın miqdarı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="number" (change)="changingQuantity()"
                                            formControlName="qkQuantity">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.qkQuantity.valid && petitionForm.controls.qkQuantity.touched">
                                        Xana mütləq doldurulmalıdır

                                    </mat-error>
                                    <mat-error
                                        *ngIf="issue_prospectus && petitionForm.value.qkQuantity != applyForm.value.issueAmount / petitionForm.value.qkCost">
                                        Emissiya məbləği ilə Qiymətli kağızın miqdarı uyğun olmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3" *ngIf="type == applyType.TYPE4">

                                    <label class="w-100">Qiymətli kağızların dövriyyədən çıxarılmasının səbəbi
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="reasonQkWithdrawal">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.reasonQkWithdrawal.valid && petitionForm.controls.reasonQkWithdrawal.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3" *ngIf="isBond">
                                    <label class="w-100">Təminat barədə məlumat</label>
                                    <mat-form-field class="w-100">
                                        <mat-select formControlName="provideInfoId">
                                            <mat-option [value]="">--- Seçin ---</mat-option>
                                            <mat-option *ngFor="let item of provideInfos" [value]="item.id">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.provideInfoId.valid && petitionForm.controls.provideInfoId.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3" *ngIf="isBond  ">
                                    <div class="row">
                                        <div class="col-6">
                                            <label class="w-100">Tədavül müddəti
                                                <sup class="text-danger star">*</sup>
                                            </label>
                                            <mat-form-field class="w-100">
                                                <input matInput type="number" formControlName="circulationPeriod">
                                            </mat-form-field>
                                            <mat-error class="pt-1"
                                                *ngIf="!petitionForm.controls.circulationPeriod.valid && petitionForm.controls.circulationPeriod.touched">
                                                Xana mütləq doldurulmalıdır
                                            </mat-error>
                                        </div>
                                        <div class="col-6">
                                            <label class="w-100">Dövr</label>
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="timeTypeId">
                                                    <mat-option [value]="">--- Seçin ---</mat-option>
                                                    <mat-option *ngFor="let item of timeTypes" [value]="item.id">
                                                        {{item.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-error class="pt-1"
                                                *ngIf="!petitionForm.controls.timeTypeId.valid && petitionForm.controls.timeTypeId.touched">
                                                Xana mütləq doldurulmalıdır
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="offset-md-1 col-sm-6 col-md-4">

                                <div>
                                    <label class="w-100" id="example-radio-group-label">Rəhbər şəxsin vətəndaşlığı <sup
                                            class="text-danger star">*</sup> </label>
                                    <mat-radio-group [formControlName]="'ownerIsForeign'"
                                        (change)="addControlsForIsForeign($event)"
                                        aria-labelledby="example-radio-group-label" class="example-radio-group">
                                        <mat-radio-button class="w-100 example-radio-button" [value]="false">
                                            Azərbaycan Respublikasının vətəndaşı
                                        </mat-radio-button>
                                        <mat-radio-button class="w-100 example-radio-button" [value]="true">
                                            Əcnəbi
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.ownerIsForeign.valid && petitionForm.controls.ownerIsForeign.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div *ngIf="isForeign">
                                    <label class=" w-100">Rəhbər şəxsin sənəd növü <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <mat-select [formControlName]="'ownerDocType'"
                                            (selectionChange)="addControlsForDocType($event.value)">
                                            <mat-option value="">--- Seçin ---</mat-option>
                                            <mat-option value="id_card">Şəxsiyyət vəsiqəsi</mat-option>
                                            <mat-option value="passport">Passport</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.ownerDocType.valid && petitionForm.controls.ownerDocType.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div *ngIf="petitionForm.controls.ownerDocType.value" class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin doğum tarixi
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <ng-container class="w-100">
                                        <input class="custom-input-text-control" placeholder="28.02.1998"
                                            mask="00.00.0000" type="text" (keyup)="pinEnableDisable($event.value)"
                                            formControlName="ownerBirthdate">
                                    </ng-container>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.ownerBirthdate.valid && petitionForm.controls.ownerBirthdate.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div *ngIf="petitionForm.controls.ownerDocType.value" class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin Ş/V Fin kodu/Passport nömrəsi
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100 pin-search">
                                        <input   matInput type="text"
                                            formControlName="ownerPin">
                                        <i *ngIf="!showIamasSpinner && petitionForm.controls.ownerDocType.value=='id_card' && !petitionForm.controls.ownerPin.disabled"
                                            (click)="getByPin()" class="fas fa-search text-dark"></i>
                                        <i *ngIf="showIamasSpinner && petitionForm.controls.ownerDocType.value=='id_card'"
                                            class="fas fa-circle-notch fa-spin" style="top: 30% !important;"></i>
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.ownerPin.valid && petitionForm.controls.ownerPin.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                    <mat-error *ngIf="pinInValid">
                                        Fin düzgün deyil
                                    </mat-error>
                                    <mat-error
                                        *ngIf="petitionForm.controls.ownerPin.value && petitionForm.controls.ownerPin.value.length!=7 && !isForeign">
                                        Fin kod 7 simvol olmalıdır
                                    </mat-error>
                                    <mat-error *ngIf="petitionForm.controls.ownerDocType.value && petitionForm.controls.ownerDocType.value=='id_card' &&
                                            petitionForm.controls.ownerPin.value && (petitionForm.controls.ownerPin.value.length>6 ||
                                            petitionForm.controls.ownerPin.value.length<5) && isForeign">
                                        Fin kod 5 və ya 6 simvol olmalıdır
                                    </mat-error>
                                    <mat-error *ngIf="hasIamasError">
                                        {{iamasMessage}}
                                    </mat-error>
                                </div>
                                <div *ngIf="petitionForm.controls.ownerDocType.value" class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin adı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input (click)="onKeyUp($event)" matInput type="text"
                                            formControlName="ownerName">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="petitionForm.controls.ownerDocType.value" class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin soyadı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input (click)="onKeyUp($event)" matInput type="text"
                                            formControlName="ownerSurname">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="petitionForm.controls.ownerDocType.value" class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin ata adı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input (click)="onKeyUp($event)" matInput type="text"
                                            formControlName="ownerMiddlename">
                                    </mat-form-field>
                                </div>
                                <div class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin telefon nömrəsi
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="tel" prefix="+994" mask="(00) 000 00 00"
                                            formControlName="ownerTelephone">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.ownerTelephone.valid && petitionForm.controls.ownerTelephone.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                                <div class="mt-3">
                                    <label class="w-100">Rəhbər şəxsin vəzifəsi
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="ownerPosition">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!petitionForm.controls.ownerPosition.valid && petitionForm.controls.ownerPosition.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>
                            </div>

                        </div>


                    </form>

                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" class="mb-2">
                    <mat-expansion-panel-header class="profil-title">
                        <mat-panel-title class="profil-titl">
                            <span style="font-weight:500 ;">Bank rekvizitləri</span>
                        </mat-panel-title>

                    </mat-expansion-panel-header>

                    <form [formGroup]="requisiteForm" class="pt-3">
                        <div class="row">

                            <div class="col-sm-6 col-md-4">
                                <div>
                                    <label class="w-100">Bank Vöeni:
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100 pin-search">
                                        <input matInput type="text" formControlName="bankTin">
                                        <i *ngIf="!showAvisSpinner" (click)="getByTin()"
                                            class="fas fa-search text-dark"></i>
                                        <i *ngIf="showAvisSpinner" class="fas fa-circle-notch fa-spin"
                                            style="top: 30% !important;"></i>
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!requisiteForm.controls.bankTin.valid && requisiteForm.controls.bankTin.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                    <mat-error *ngIf="tinInValid">
                                        Vöen düzgün deyil
                                    </mat-error>

                                    <mat-error *ngIf="hasAvisError">
                                        {{avisMessage}}
                                    </mat-error>
                                </div>
                                <div class="mt-3">
                                    <label class="w-100">Bankın adı
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input (click)="onKeyUp($event)" matInput type="text" formControlName="bank">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!requisiteForm.controls.bank.valid && requisiteForm.controls.bank.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>

                                <div class="mt-3">
                                    <label class="w-100">H/H
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="settlementAccount">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!requisiteForm.controls.settlementAccount.valid && requisiteForm.controls.settlementAccount.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>

                                <div class="mt-3">
                                    <label class="w-100">Bankın kodu
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="code">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!requisiteForm.controls.code.valid && requisiteForm.controls.code.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>

                                <div class="mt-3">
                                    <label class="w-100">S.W.I.F.T
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="swift">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!requisiteForm.controls.swift.valid && requisiteForm.controls.swift.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>

                                <div class="mt-3">
                                    <label class="w-100">Müxbir hesab
                                        <sup class="text-danger star">*</sup>
                                    </label>
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" formControlName="correspondentAccount">
                                    </mat-form-field>
                                    <mat-error class="pt-1"
                                        *ngIf="!requisiteForm.controls.correspondentAccount.valid && requisiteForm.controls.correspondentAccount.touched">
                                        Xana mütləq doldurulmalıdır
                                    </mat-error>
                                </div>



                            </div>



                        </div>


                    </form>

                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" class="mb-2">
                    <mat-expansion-panel-header class="profil-title">
                        <mat-panel-title class="profil-titl">
                            <span style="font-weight:500 ;">Ünvan və əlaqə məlumatları</span>
                        </mat-panel-title>

                    </mat-expansion-panel-header>

                    <form [formGroup]="requisiteForm" class="pt-3">

                        <div class="row">

                            <div class="col-sm-6 col-md-4">
                                
                           
                                <div class=" mt-3">
                                    <label for="profile-email">Qeydiyyat ünvanı:<sup class="text-danger star">*</sup></label>
                                    <mat-form-field class="w-100">
                                    <input    type="text" matInput id="profile-email" formControlName="regAddress" />
                                </mat-form-field>
                                </div>
                                <div class=" mt-3">
                                    <label for="email">E-poçt ünvanı:<sup class="text-danger star">*</sup></label>
                                    <mat-form-field class="w-100">
                                    <input     type="text" matInput id="email" name="email" formControlName="email" />
                                </mat-form-field>
                                    <div class="form-row justify-content-start" *ngIf="requisiteForm.controls.email.dirty && requisiteForm.controls.email.invalid">
                                        <div class="text-danger" *ngIf="requisiteForm.controls.email.errors?.required">
                                            E-poçt ünvanı boş ola bilməz.
                                        </div>
                                    </div>
                                </div>
                                <div class=" mt-3">
                                    <label for="profile-email">Şəhər:<sup class="text-danger star">*</sup></label>
                                    <mat-form-field class="w-100">
                                    <mat-select formControlName="cityId" (selectionChange)="getRegionBYid($event.value)">
                                        <mat-option value="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let x of City" [value]="x.id">{{x.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                               
                            </div>
                            
                           
                                <div class=" mt-3">
                                    <label for="profile-email">Rayon:<sup class="text-danger star">*</sup></label>
                                    <mat-form-field class="w-100">
                                    <mat-select formControlName="regionId" [disabled]=" !Region">
                                        <mat-option value="">--- Seçin ---</mat-option>
                                        <mat-option *ngFor="let x of Region" [value]="x.id">{{x.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                           
                                <div class=" mt-3">
                                    <label for="username">Telefon nömrəsi:<sup class="text-danger star">*</sup></label>
                                    <mat-form-field class="w-100">
                                    <input type="text" prefix="+994" mask="(00) 000 00 00" matInput id="profile-email" formControlName="phone" />
                                </mat-form-field>
                                </div>
                                <div class=" mt-3">
                                    <label for="username">Küçə və ev ünvanı:<sup class="text-danger star">*</sup></label>
                                    <mat-form-field class="w-100">
                                    <input type="text" matInput id="profile-email" formControlName="address" />
                                </mat-form-field>
                                </div>
    
                            </div>
    
                       
                        </div>
    
                    </form>

                </mat-expansion-panel>
            </mat-accordion>












            <div class="col-12 mt-4 d-flex justify-content-between">
                <button (click)="gonewappeallist()" mat-button class="text-center">
                    <img src="../../../../assets/images/backspace.svg">
                    <span style="color:#03A9D9; margin-left:5px">Geri qayıt</span>
                </button>
                <div class="d-flex" *ngIf="applyPetition || type">
                    <mat-spinner class="mr-3 mt-2" [diameter]="20" *ngIf="hasRequest"></mat-spinner>
                    <!-- <button *ngIf="!hasRequisite" (click)="addRequisite()" mat-button class="custom-btn mr-3" type="submit">
                        <span>Rekvizit məlumatlarını yenilə</span>
                    </button> -->
                    <button (click)="save()" mat-button class="custom-btn mr-3"
                        [disabled]="hasIamasError || hasAvisError || hasRequest || applyForm.invalid || petitionForm.invalid || requisiteForm.invalid"
                        type="submit">
                        <span>Yadda saxla</span>
                    </button>
                    <button (click)="confirm()" mat-button class="custom-btn"
                        [disabled]="hasIamasError || hasAvisError || hasRequest || applyForm.invalid || petitionForm.invalid || requisiteForm.invalid"
                        type="submit">
                        <span>Təsdiqlə</span>
                    </button>
                </div>
            </div>


        </div>
    </div>
    <div class="row">
        <div class="col-md-12 py-3 pt-5 pb-5 bg-white" *ngIf="showSpinner">
            <mat-spinner style="margin: auto!important;" [diameter]="70">
            </mat-spinner>
        </div>
    </div>
</div>