<div *ngIf="asanSpinner" class=" common-spinner col-md-12 bg-white pb-5">
    <mat-spinner style="margin: auto!important;  " [diameter]="70">
    </mat-spinner>
</div>
<section class="sub-fields">

    <div class="container">

        <div class="card-section">

            <div class="row justify-content-center">

                <div class="col-lg-6 col-sm-8 col-xs-12">


                    <h1 class="title">{{ title2 }}</h1>

                     <!-- <ng-container *ngIf="testLogin">
                        <form action="" class="customForm p-3" [formGroup]="loginForm">
                            <div class="form-row align-items-center mb-3" [ngClass]="{
                'invalid-input':
                  loginForm.controls.email.touched &&
                  loginForm.controls.email.invalid
              }">
                                <label class="col-4 label-col" for="email">İstifadəçi adı </label>
                                <div class="col">
                                    <input matInput type="text" class="width-fix" id="email"
                                        [formControl]="loginForm.controls.email" />
                                </div>
                            </div>
                            <div class="form-row align-items-center mb-3" [ngClass]="{
                'invalid-input':
                  loginForm.controls.password.touched &&
                  loginForm.controls.password.invalid
              }">
                                <label class="col-4 label-col" for="password">Parol </label>
                                <div class="col">
                                    <input matInput type="password" class="width-fix" id="password"
                                        [formControl]="loginForm.controls.password" />
                                </div>
                            </div>
                            <div class="form-row mb-3">
                                <div class="col-4 label-col"></div>
                                <div class="col">
                                    <button [disabled]="asanSpinner || showSpinner" *ngIf="!showSpinner"
                                        class="btn btn1-default" type="submit" (click)="normal_login()">
                                        Daxil ol
                                    </button>
                                    <div class="d-flex justify-content-start" *ngIf="showSpinner">
                                        <button [disabled]="true" mat-button class="btn btn1-default" type="button">
                                            Daxil ol
                                        </button>
                                        <mat-spinner style="margin:7px;" [diameter]="20">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-4 label-col mr-2"></div>

                                <div class="col row" style="margin-left: -22px; margin-right:-15px;">
                                    <a (click)="asanv2()"
                                        style="display:inline-block;cursor:pointer;margin-left: 15px;">

                                        <div class="bt_cl ideas-bt-cl ">
                                            <img height="34" src="../assets/images/asanlogin-btn.svg">

                                        </div>

                                    </a>

                                </div>

                            </div>
                        </form>
                    </ng-container>  -->
                    <ng-container >
                        <div class="form-row mt-5">
                            <div class="col d-flex justify-content-center">
                                <a (click)="asanv2()" style="display:inline-block;">
                                    <div class="digital_login">
                                        <img class="digital_login--default"
                                             src="../assets/images/digital_login_third_s.png"
                                             height="47" alt="digital_login">
                                        <img class="digital_login--hover d-none"
                                             src="../assets/images/digital_login_third_s_hover.png"
                                             height="47" alt="digital_login">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>
