import { Component, OnInit, Injectable, ViewChild, Input, HostListener } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplyType, placementMethodsConst, qkObservesConst, qkTypesConst } from 'src/app/models/constants';
import { MatDialog } from '@angular/material/dialog';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { StateRegistrationDialogComponent } from 'src/app/cabinet/dialogs/state-registration-dialog/state-registration-dialog.component';
import { DatePipe } from '@angular/common';
import { MessageComponent } from 'src/app/cabinet/dialogs/message/message/message.component';
import { CancelDialogComponent } from 'src/app/cabinet/dialogs/cancel-dialog/cancel-dialog.component';
import { AppConfig } from 'src/app.config';

declare var require: any;
const jsZip = require('jszip');

@Component({
  selector: 'app-new-appeal-default-1',
  templateUrl: './new-appeal-default-1.component.html',
  styleUrls: ['./new-appeal-default-1.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewAppealDefault1Component implements OnInit {
  istestLogin =AppConfig.settings.other.testLogin
  id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
  type = this.activatedRoute.snapshot.queryParamMap['params']['type'];
  applyForm: FormGroup;
  petitionForm: FormGroup;
  requisiteForm: FormGroup;
  dynamicForm: FormGroup;
  subTitle: any;
  hasMB: Boolean;
  hasMDM: Boolean;
  hasBFB: Boolean;
  documents: any;
  showSpinner: boolean;
  portals: any;
  selectedFile: any;
  uploadError: boolean;
  clickDoc: any;
  qkTypes: any;
  placementMethods: any;
  baseEmissionProspects: any;
  showIamasSpinner: boolean;
  pinInValid: boolean;
  hasIamasError: boolean;
  iamasMessage: any;
  qkAnderrayters: any;
  provideInfos: any;
  qkObserves: any;
  isBond: boolean;
  isForeign: boolean;
  isIdCard: boolean;
  isObserve: boolean;
  isTermsBaseIssue: boolean;
  issue_prospectus: boolean;
  base_issue_prospectus: boolean;
  paymentTypes: any;
  timeTypes: any;
  hasRequest: boolean;
  purposeApply: any;
  hasRequisite: boolean = false;

  has_requisite: boolean = false;
  showAvisSpinner: boolean;
  tinInValid: boolean;
  hasAvisError: boolean;
  avisMessage: "";
  applyPetition: any;
  listing: any;
  qktypename: any;
  ReasonReorganization: any;
  PurposeIssue: any;
  isListing: any;
  isBaseProspectus =false;
  City: any;
  Region: [];
  @Input("applyPetition") set applyPetitionSet(value: any) {
    if (value) {
      this.applyPetition = value;
      this.isForeign = this.applyPetition.petition.ownerIsForeign;
      this.type = 'process_' + this.applyPetition.apply.applyTypeId;
      console.log(this.applyPetition.apply.applyTypeId);


    }
    this.methodsCall();
  }

  constructor(public router: Router,
    private cabinetService: Cabinet,
    public activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    public fb: FormBuilder,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title,
    private dialog: MatDialog
  ) {

    // if ((
    //   this.type !=this.applyType.TYPE2 &&
    //    this.type !=this.applyType.TYPE1 &&
    //     this.type !=this.applyType.TYPE6 &&
    //      this.type !=this.applyType.TYPE7 &&
    //      this.istestLogin==false
    //      )) {
    //   router.navigate(['/'])
    // }

    this.cabinetService.clearStorage();
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
      if (e.key === 'Tab') {
          return false;
      }
 return true;
  }
  onKeyUp (event) {
    console.log(this.isIdCard);
    
    if (this.isIdCard || this.isIdCard == undefined) {
      
      
      event.target.disabled=true;
   
      event.preventDefault()
    }
 
 }
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
    this.setTitle();
    // this.confirm();
    // this.getPortal();
  }
  //set Title
  setTitle() {
    this.TitleService.setTitle("QİYMƏTLİ KAĞIZLAR" + " | securities.e-cbar.az/");
    const breadcrumb = { title: "Qi̇ymətli̇ Kağizlar", title2: "Yeni müraciət" };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }


  addRequisite() {
    this.hasRequisite = true;
  }
  methodsCall() {
    this.getPurposeIssue()
    this.getDocuments()
    this.getListing();
    this.getAppliesByObserve();
    this.setApplyForm();
    this.setPetitionForm();
    this.setRequisiteForm();
    this.setDisable();
    this.getQkType();
    this.getPlacementMethods();
    this.getQkAnderrayter();
    this.getProvideInfo();
    this.getQkObserve();
    this.getPaymentTypes();
    this.getTimeTypes();
    this.getCity()
    this.getLeader();
    this.getCompany();
    this.addControlsForProcces6();
    this.getPurposeApply();
    this.getReasonReorganization()
  }
  setApplyForm() {
    this.getSubTitle();
    this.applyForm = this.fb.group({
      id: [this.id],
      applyTypeId: [this.type ? this.type.split('_')[1] : 0, [Validators.required]],
      qkTypeId: [this.applyPetition?.apply?.qkTypeId, [Validators.required]],
      qkAnderrayterId: [this.applyPetition?.apply?.qkAnderrayterId, [Validators.required]],
      qkPlacementId: [this.applyPetition?.apply?.qkPlacementId, [Validators.required]],
      purposeApplyId: [this.applyPetition?.apply?.purposeApplyId, [Validators.required]],

      applyTypeName: [,],
      qkTypeName: [,],
      qkAnderrayterName: [,],
      qkPlacementName: [,],
      purposeApplyName: [,]
    });
  }

  setPetitionForm() {
    this.petitionForm = this.fb.group({
      protocolOrgName: [this.applyPetition?.petition?.protocolOrgName, [Validators.required]],
      protocolNumber: [this.applyPetition?.petition?.protocolNumber, [Validators.required]],
      protocolDate: [this.datepipe.transform(this.applyPetition?.petition?.protocolDate, 'yyyy-MM-dd'), [Validators.required]],
      qkCost: [this.applyPetition?.petition?.qkCost, [Validators.required]],
      reasonQkWithdrawal: [this.applyPetition?.petition?.reasonQkWithdrawal, [Validators.required]],
      qkQuantity: [this.applyPetition?.petition?.qkQuantity, [Validators.required]],
      ownerIsForeign: [this.applyPetition?.petition?.ownerIsForeign, [Validators.required]],
      ownerDocType: [this.applyPetition?.petition?.ownerDocType, [Validators.required]],
      ownerPin: [this.applyPetition?.petition?.ownerPin, [Validators.required]],
      // ownerBirthdate: [this.applyPetition?.petition?.ownerBirthdate, [Validators.required,Validators.pattern('^(0[1-9]|[12][0-9]|3[01])(\-)(0[1-9]|1[012])(\-)(19|20)\\d\\d$')]],
      ownerBirthdate: [this.applyPetition?.petition?.ownerBirthdate, Validators.required],
      ownerName: [this.applyPetition?.petition?.ownerName, [Validators.required]],
      ownerSurname: [this.applyPetition?.petition?.ownerSurname, [Validators.required]],
      ownerMiddlename: [this.applyPetition?.petition?.ownerMiddlename, [Validators.required]],
      ownerTelephone: [this.applyPetition?.petition?.ownerTelephone, [Validators.required]],
      ownerPosition: [this.applyPetition?.petition?.ownerPosition, [Validators.required]],
    });


    this.petitionForm.get("ownerPin").disable();
  }


  setRequisiteForm() {
    if (this.applyPetition?.requisite) {
      this.hasRequisite = true;
    }
    this.requisiteForm = this.fb.group({
      bank: [this.applyPetition?.requisite?.bank, [Validators.required]],
      bankTin: [this.applyPetition?.requisite?.bankTin, [Validators.required]],
      correspondentAccount: [this.applyPetition?.requisite?.correspondentAccount, Validators.required],
      settlementAccount: [this.applyPetition?.requisite?.settlementAccount, Validators.required],
      code: [this.applyPetition?.requisite?.code, Validators.required],
      swift: [this.applyPetition?.requisite?.swift, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      regAddress: ['', Validators.required],
      cityId: ['', Validators.required],
      regionId: ['' , Validators.required]
    });

    this.requisiteForm.get("bank").disable();
  }



  calculate(): any {
    this.petitionForm.controls.qkQuantity?.setValue(this.applyForm.value.issueAmount / this.petitionForm.value.qkCost);

  }

  changingQuantity() {
    if (this.petitionForm.value.qkQuantity != this.applyForm.value.issueAmount / this.petitionForm.value.qkCost) {


    }
  }

  setDisable() {
    this.petitionForm.get("ownerPin").disable();
    this.petitionForm.get("ownerName").disable();
    this.petitionForm.get("ownerSurname").disable();
    this.petitionForm.get("ownerMiddlename").disable();
  }
  addControlsForIsForeign(isForeign) {
    console.log(isForeign)
    console.log(this.petitionForm.get("ownerBirthdate"));

    this.isForeign = isForeign.value;
    this.petitionForm.get("ownerBirthdate").setValue(null);
    this.petitionForm.get("ownerPin").setValue(null);
    this.petitionForm.get("ownerName").setValue(null);
    this.petitionForm.get("ownerSurname").setValue(null);
    this.petitionForm.get("ownerMiddlename").setValue(null);

    if (isForeign.value) {
      this.petitionForm.get("ownerDocType").setValue(null);

    } else {
      this.petitionForm.get("ownerDocType").setValue("id_card");
      this.petitionForm.get("ownerPin").disable();
      this.petitionForm.get("ownerName").disable();
      this.petitionForm.get("ownerSurname").disable();
      this.petitionForm.get("ownerMiddlename").disable();
    }

  }

  addControlsForDocType(isIdCard) {
    console.log(isIdCard)
    this.petitionForm.get("ownerBirthdate").setValue(null);
    this.petitionForm.get("ownerPin").setValue(null);
    this.petitionForm.get("ownerName").setValue(null);
    this.petitionForm.get("ownerSurname").setValue(null);
    this.petitionForm.get("ownerMiddlename").setValue(null);
    if (isIdCard == "id_card") {
      this.isIdCard = true;
      this.petitionForm.get("ownerPin").disable();
      this.petitionForm.get("ownerName").disable();
      this.petitionForm.get("ownerSurname").disable();
      this.petitionForm.get("ownerMiddlename").disable();
    } else {
      this.isIdCard = false;
      this.petitionForm.get("ownerPin").enable();
      this.petitionForm.get("ownerName").enable();
      this.petitionForm.get("ownerSurname").enable();
      this.petitionForm.get("ownerMiddlename").enable();
    }

  }

  addControlsForBond(qkTypeId) {
    if (this.type == this.applyType.TYPE2) {
      this.applyForm.get("qkTypeId").setValue(qkTypeId);
    }
    if (this.qkTypes.find(x => x.id == qkTypeId)?.key == qkTypesConst.bond) {
      this.petitionForm.addControl('provideInfoId', new FormControl(this.applyPetition?.petition?.provideInfoId, Validators.required));
      this.petitionForm.addControl('circulationPeriod', new FormControl(this.applyPetition?.petition?.circulationPeriod, Validators.required));
      this.petitionForm.addControl('timeTypeId', new FormControl(this.applyPetition?.petition?.timeTypeId, Validators.required));

      this.petitionForm.addControl('provideInfoName', new FormControl(this.applyPetition?.petition?.provideInfoName));
      this.petitionForm.addControl('timeTypeName', new FormControl(this.applyPetition?.petition?.timeTypeName));

      this.isBond = true;
      if(this.type == this.applyType.TYPE4) this.applyForm.addControl('payingAgent', new FormControl(this.applyPetition?.apply?.purposeIssueId, Validators.required));
    }
    else {
      this.petitionForm.removeControl('provideInfoId');
      this.petitionForm.removeControl('circulationPeriod');
      this.petitionForm.removeControl('timeTypeId');
      this.applyForm.removeControl('payingAgent');
      this.petitionForm.removeControl('provideInfoName');
      this.petitionForm.removeControl('timeTypeName');
      this.isBond = false;
    }
    console.log(this.applyForm);
    
    if (this.type == this.applyType.TYPE7) this.getPurposeApply();
  }
  addControlsForProcces6() {

    if (this.type == this.applyType.TYPE3) {
      this.applyForm.addControl('isListingId', new FormControl(this.applyPetition?.apply?.isListingId, Validators.required));
      this.applyForm.addControl('purposeIssueId', new FormControl(this.applyPetition?.apply?.purposeIssueId, Validators.required));
      this.applyForm.addControl('isListingName', new FormControl(''));
      this.applyForm.addControl('PurposeIssueName', new FormControl(''));
      this.applyForm.addControl('qkTypeName', new FormControl(''));
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementName');
      this.applyForm.removeControl('qkAnderrayterName');
      this.applyForm.removeControl('qkAnderrayterId');


      this.isBond == true;



    }
    else {


    }

    if (this.type == this.applyType.TYPE4) {
      this.applyForm.addControl('isListingId', new FormControl(this.applyPetition?.apply?.isListingId, Validators.required));
      this.applyForm.addControl('purposeIssueId', new FormControl(this.applyPetition?.apply?.purposeIssueId, Validators.required));
      this.applyForm.addControl('payingAgent', new FormControl(this.applyPetition?.apply?.purposeIssueId, Validators.required));
      this.applyForm.addControl('isListingName', new FormControl(''));
      this.applyForm.addControl('PurposeIssueName', new FormControl(''));
      this.applyForm.addControl('qkTypeName', new FormControl(''));
      this.petitionForm.addControl('reasonQkWithdrawal', new FormControl(''));
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementName');
      this.applyForm.removeControl('qkAnderrayterName');
      this.applyForm.removeControl('qkAnderrayterId');
      this.applyForm.removeControl('purposeIssueId');
      this.applyForm.removeControl('PurposeIssueName');
     
      
 }
    else {
      this.petitionForm.removeControl('reasonQkWithdrawal');
      this.petitionForm.removeControl('payingAgent');

    }
    if (this.type == this.applyType.TYPE5) {
    
      this.applyForm.addControl('qkTypeName', new FormControl(''));
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementName');
      this.applyForm.removeControl('qkAnderrayterName');
      this.applyForm.removeControl('qkAnderrayterId');
      this.applyForm.removeControl('purposeApplyName');
      this.applyForm.removeControl('purposeApplyId');
      console.log(this.applyForm);

      this.isBond == true;



    }
    else {


    }
    if (this.type == this.applyType.TYPE6) {
      this.applyForm.addControl('isListingId', new FormControl(this.applyPetition?.apply?.isListingId, Validators.required));
      this.applyForm.addControl('changeDocId', new FormControl(this.applyPetition?.apply?.changeDocId, Validators.required));
      this.applyForm.addControl('isListingName', new FormControl(''));
      this.applyForm.addControl('changeDocName', new FormControl(''));
      // this.applyForm.removeControl('purposeApplyId');
      // this.petitionForm.removeControl('qkCost');
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementName');
      

    }
    else {


    }

    if (this.type == this.applyType.TYPE7) {
      this.applyForm.addControl('isListingId', new FormControl(this.applyPetition?.apply?.isListingId, Validators.required));

      this.applyForm.addControl('isListingName', new FormControl(''));
      this.petitionForm.removeControl('qkQuantity');
      this.petitionForm.removeControl('qkCost');
      this.petitionForm.removeControl('protocolOrgName');
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementName');

    }
    else {


    }
    if (this.type == this.applyType.TYPE2) {
      this.applyForm.addControl('isListingId', new FormControl(this.applyPetition?.apply?.isListingId, Validators.required));

      this.applyForm.addControl('isListingName', new FormControl(''));
      this.applyForm.addControl('ReasonReorganizationId', new FormControl(this.applyPetition?.apply?.reasonReorganizationId, Validators.required));
      this.applyForm.addControl('ReasonReorganizationName', new FormControl('',));
      this.applyForm.addControl('qkTypeId', new FormControl(this.applyPetition?.petition?.qkTypeId, Validators.required));
      this.applyForm.addControl('qkTypeName', new FormControl('',));
      this.petitionForm.addControl('releaseOrgName', new FormControl(this.applyPetition?.petition?.releaseOrgName, Validators.required));
      this.petitionForm.addControl('reorganizeOrgName', new FormControl(this.applyPetition?.petition?.reorganizeOrgName, Validators.required));
      this.applyForm.removeControl('qkAnderrayterId');
      //this.applyForm.removeControl('qkTypeName');
      this.applyForm.removeControl('qkPlacementId');
      this.applyForm.removeControl('qkPlacementName');
      this.petitionForm.removeControl('protocolOrgName');
      console.log(this.applyForm);
      

    }
    else {

      this.applyForm.removeControl('ReasonReorganizationName');
      this.applyForm.removeControl('ReasonReorganizationId');

      this.petitionForm.removeControl('releaseOrgName');
      this.petitionForm.removeControl('reorganizeOrgName');
      this.petitionForm.removeControl('qkTypeName');
      this.petitionForm.removeControl('qkTypeId');
    }
    console.log(this.applyForm);
    console.log(this.petitionForm);
  }
  addControlsForPlacement(placementId) {
    if (this.placementMethods.find(x => x.id == placementId)?.key == placementMethodsConst.fb_public) {
      this.applyForm.addControl('qkObserveId', new FormControl(this.applyPetition?.apply?.qkObserveId, Validators.required));
      this.applyForm.addControl('qkObserveName', new FormControl(''));
      this.isObserve = true;
    }
    else {
      this.applyForm.removeControl('qkObserveId');
      this.applyForm.removeControl('qkObserveName');
      this.applyForm.removeControl('baseEmissionId');
      this.isObserve = false;
      this.issue_prospectus = false;
      this.isTermsBaseIssue = false;
    }
    console.log(this.applyForm.value);

  }



  addControlsForObserve(qkObserveId) {
    if (
      this.qkObserves.find(x => x.id == qkObserveId)?.key == qkObservesConst.issue_prospectus ||
      this.qkObserves.find(x => x.id == qkObserveId)?.key == qkObservesConst.base_issue_prospectus
    ) {

      this.applyForm.addControl('paymentTypeId', new FormControl(this.applyPetition?.apply?.paymentTypeId, Validators.required));
      this.applyForm.addControl('paymentTypeName', new FormControl(''));
      // this.applyForm.addControl('issueAmount', new FormControl(this.applyPetition?.apply?.issueAmount, Validators.required));
      this.applyForm.addControl('issueAmount', new FormControl(this.applyPetition?.apply?.issueAmount ? this.applyPetition?.apply?.issueAmount : 1, [Validators.min(1), Validators.required]));
      this.issue_prospectus = true;
      this.applyForm.removeControl('baseEmissionId');
      this.isTermsBaseIssue = false;
    } else if (this.qkObserves.find(x => x.id == qkObserveId)?.key == qkObservesConst.terms_base_issue_prospectus) {
      this.applyForm.addControl('baseEmissionId', new FormControl(this.applyPetition?.apply?.baseEmissionId, Validators.required));
      this.applyForm.removeControl('issueAmount');
      this.applyForm.removeControl('paymentTypeId');
      this.applyForm.removeControl('paymentTypeName');
      this.isTermsBaseIssue = true;
      this.issue_prospectus = false;
    } else {
      this.applyForm.removeControl('issueAmount');
      this.applyForm.removeControl('paymentTypeId');
      this.applyForm.removeControl('paymentTypeName');
      this.applyForm.removeControl('baseEmissionId');
      this.issue_prospectus = false;
      this.isTermsBaseIssue = false;
    }

    console.log(this.applyForm.value);

  }



  setNull() {
    this.petitionForm.get("ownerName").setValue(null);
    this.petitionForm.get("ownerSurname").setValue(null);
    this.petitionForm.get("ownerMiddlename").setValue(null);
  }

  getByTin() {
    this.requisiteForm.get("bank").setValue(null);
    let tin = this.requisiteForm.controls.bankTin.value;
    this.showAvisSpinner = true;
    this.hasAvisError = false;


    this.cabinetService.getByTin(tin).subscribe(
      res => {
        if (res.status.errorCode == 0) {
          this.requisiteForm.controls.bank.setValue(res.response.legalPerson?.fullName);

        }
        else {
          this.hasAvisError = true;
          this.avisMessage = res.status.message;
        }
        this.showAvisSpinner = false;
      }
    );
  }


  getByPin() {
    let pin = this.petitionForm.controls.ownerPin.value;
    // let birthdate = this.petitionForm.controls.ownerBirthdate.value;
    let birthdate = this.petitionForm.controls.ownerBirthdate.value;

    if (!birthdate.includes('.')) {
      birthdate = birthdate.substring(0, 2) + "." + birthdate.substring(2, 4) + "." + birthdate.substring(4, 8);
    }

    if (!birthdate || !pin) return;
    this.showIamasSpinner = true;
    this.pinInValid = false;
    this.hasIamasError = false;
    if (pin.length > 7 || pin.length < 5) {
      this.pinInValid = true;
      this.showIamasSpinner = false;
      return;
    }
    this.setNull();
    this.cabinetService.getByPin(pin, birthdate).subscribe(
      res => {
        if (res.status.errorCode == 0) {
          this.setIamasData(res.response.result);
        }
        else {
          this.hasIamasError = true;
          this.iamasMessage = res.status.message;
        }
        this.showIamasSpinner = false;
      }
    );
  }

  pinEnableDisable(data) {
    if (data) this.petitionForm.get("ownerPin").disable();
    else this.petitionForm.get("ownerPin").enable();
  }

  setIamasData(data) {
    this.petitionForm.controls.ownerName.setValue(data.nameAz);
    this.petitionForm.controls.ownerSurname.setValue(data.surnameAz);
    this.petitionForm.controls.ownerMiddlename.setValue(data.middleAz);
  }

  getLeader() {

    if (this.applyPetition?.petition) return;
    this.cabinetService.getLeader().subscribe((res => {
      if (res.response) {
        console.log(res.response);


        this.petitionForm.controls.ownerName.setValue(res.response.name);
        this.petitionForm.controls.ownerSurname.setValue(res.response.surname);
        this.petitionForm.controls.ownerMiddlename.setValue(res.response.fatherName);
        this.petitionForm.controls.ownerPin.setValue(res.response.pin);
        this.petitionForm.controls.ownerBirthdate.setValue(this.changeFormatDateDashed(res.response.birthDate));
        this.petitionForm.controls.ownerPosition.setValue(res.response.position);
        this.petitionForm.controls.ownerTelephone.setValue(res.response.phone);
        this.petitionForm.controls.ownerIsForeign.setValue(false);
        this.petitionForm.controls.ownerDocType.setValue("id_card");

      }
    }));
  }

  getCompany() {

    if (this.applyPetition?.petition) return;
    this.cabinetService.currentcompany().subscribe((res => {
      if (res.response) {
        this.requisiteForm.get("bank").setValue(res.response.bank);
        this.requisiteForm.get("bankTin").setValue(res.response.bankTin);
        this.requisiteForm.get("correspondentAccount").setValue(res.response.correspondentAccount);
        this.requisiteForm.get("settlementAccount").setValue(res.response.settlementAccount);
        this.requisiteForm.get("code").setValue(res.response.bankCode);
        this.requisiteForm.get("swift").setValue(res.response.swift);
        this.requisiteForm.get("regionId").setValue(+res.response.regionId);
        this.requisiteForm.get("email").setValue(res.response.email);
        this.requisiteForm.get("phone").setValue(res.response.phone);
        this.requisiteForm.get("cityId").setValue(res.response.cityId==null ? 0 : +res.response.cityId);
        this.requisiteForm.get("regAddress").setValue(res.response.regAddress);
        this.requisiteForm.get("address").setValue(res.response.address);
        this.getRegionBYid(res.response.cityId);
      }
    }));
  }


  getQkType() {
    this.cabinetService.getQkType().subscribe((data => {
      this.qkTypes = data.result;
      if (this.type == this.applyType.TYPE1) {
        this.addControlsForBond(this.applyPetition?.apply?.qkTypeId)
      }
      if (this.type == this.applyType.TYPE3) {
        this.applyForm.get("qkTypeId").setValue(this.qkTypes.filter(x => x.key == 'stock')[0].id)
      }
    }));
  }
  getReasonReorganization() {
    this.cabinetService.getReasonReorganization().subscribe((data => {
      this.ReasonReorganization = data.result;


    }));
  }
  getAppliesByObserve() {
    this.cabinetService.getAppliesByObserve(qkObservesConst.base_issue_prospectus).subscribe(data => {
      this.baseEmissionProspects = data.response.data;
      console.log(this.baseEmissionProspects);
    })
  }
  getPlacementMethods() {
    this.cabinetService.getPlacementMethods().subscribe((data => {
      this.placementMethods = data.result.filter(x=>x.key != '-');
      this.addControlsForPlacement(this.applyPetition?.apply?.qkPlacementId);
    }));
  }

  openDialog() {
    this.dialog.open(MessageComponent, {
      position: {
        top: '110px'
      },
    });
  }

  getQkAnderrayter() {
    this.cabinetService.getQkAnderrayter().subscribe((data => {
      this.qkAnderrayters = data.result;
    }));
  }
  getListing() {
    this.cabinetService.getListing().subscribe((data => {
      this.listing = this.cabinetService.sortByName(data.result);
      if (this.type == this.applyType.TYPE3 || this.type == this.applyType.TYPE6)
        this.addControlsForListing(this.applyPetition?.apply?.isListingId);
    }));
  }
  getProvideInfo() {
    this.cabinetService.getProvideInfo().subscribe((data => {
      this.provideInfos = data.result;
    }));
  }

  getQkObserve() {
    this.cabinetService.getQkObserve().subscribe((data => {
      this.qkObserves = data.result;
      this.addControlsForObserve(this.applyPetition?.apply?.qkObserveId);
    }));
  }
  getDocuments() {
    this.cabinetService.getDocument().subscribe((data => {
      this.documents = data.result.filter((x) => x.key == "doc_in_13" || x.key == 'doc_in_12' || x.key == 'doc_in_11' || x.key == 'doc_in_10' || x.key == 'doc_in_3');
    }));
  }
  getPaymentTypes() {
    this.cabinetService.getPaymentTypes().subscribe((data => {
      this.paymentTypes = data.result;
    }));
  }
  getPurposeIssue() {
    this.cabinetService.getPurposeIssue().subscribe((data => {
      this.PurposeIssue = data.result;
    }));
  }
  getTimeTypes() {
    this.cabinetService.getTimeTypes().subscribe((data => {
      this.timeTypes = data.result;
    }));
  }

  getPurposeApply() {
    this.cabinetService.getPurposeApply().subscribe((data => {
      this.purposeApply = data.result;
      if (this.type == this.applyType.TYPE1) {

        this.applyForm.get('purposeApplyId').setValue(this.purposeApply.find(x => x.key == 'key_4').id);
      }
      if (this.type == this.applyType.TYPE6) {

        this.applyForm.get('purposeApplyId').setValue(this.purposeApply.find(x => x.key == 'key_5').id);
      }
      if (this.type == this.applyType.TYPE7) {
        if (this.isBond==false) {
          this.purposeApply = this.purposeApply.filter(x => x.key == 'key_1' || x.key == 'key_2' || x.key == 'key_3');
        }else{
        this.purposeApply = this.purposeApply.filter(x => x.key == 'key_1');}
      }
      if (this.type == this.applyType.TYPE2 || this.type == this.applyType.TYPE3) {

        this.applyForm.get('purposeApplyId').setValue(this.purposeApply.find(x => x.key == 'key_6').id);
      }
      if (this.type == this.applyType.TYPE4 ) {

        this.applyForm.get('purposeApplyId').setValue(this.purposeApply.find(x => x.key == 'key_7').id);
      }
    }));
  }

  getSubTitle() {
    if (!this.id) {
      this.cabinetService.getApplyTypes().subscribe((data => {
        this.subTitle = this.type.split('_')[1] ? this.type.split('_')[1] + "." + data.result.filter(x => x.key == this.type)[0].name : "" + data.result.filter(x => x.key == this.type)[0].name;
      }));
    } else {
      this.subTitle = this.applyPetition?.apply?.applyType.key.split('_')[1] ? this.type.split('_')[1] + "." + this.applyPetition?.apply?.applyType.name : "" + this.applyPetition?.apply?.applyType.name;
    }
  }

  // dyanamicDocumentForm() {
  //   console.log(this.documents.length);
  //   const numberOfdocument = this.documents.length || 0;
  //   if (this.t.length < numberOfdocument) {
  //     for (let i = this.t.length; i < numberOfdocument; i++) {
  //       this.t.push(this.fb.group({
  //         documentName: ['', Validators.required],
  //         fileSize: ['', Validators.required],
  //         fileName: ['', Validators.required]
  //       }));
  //     }
  //   } else {
  //     for (let i = this.t.length; i >= numberOfdocument; i--) {
  //       this.t.removeAt(i);
  //     }
  //   }
  // }



  getPortal() {
    console.log(this.hasMB);
    this.cabinetService.getPortal().subscribe((data => {
      // this.portals = data.result;

      if (!this.hasMB) {
        this.portals = data.result.filter(x => x.key != "mb");
      }

    }));

  }

  public get applyType(): typeof ApplyType {
    return ApplyType;
  }

  public findInvalidControls() {
    const invalid = [];
    let controls = this.applyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    controls = this.petitionForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  checkControlsForChangeDoc(id) {
    if ( this.type == this.applyType.TYPE6) {
      this.isBaseProspectus = this.documents.some(x => x.id == id && x.key == 'doc_in_12');
    //  if (this.isListing) {
       
        
     
      if ( this.isBaseProspectus) {
        this.applyForm.get('contractNumber')?.clearValidators();
        this.applyForm.get('contractNumber')?.updateValueAndValidity();
        this.applyForm.get('contractDate')?.clearValidators();
        this.applyForm.get('contractDate')?.updateValueAndValidity();
         this.applyForm.get('registerNumber')?.clearValidators();
         this.applyForm.get('registerNumber')?.updateValueAndValidity();
         console.log(this.applyForm);
         
      }
      else {
        this.applyForm.get('contractNumber')?.setValidators([Validators.required]);
        this.applyForm.get('contractNumber')?.updateValueAndValidity();
        this.applyForm.get('contractDate')?.setValidators([Validators.required]);
        this.applyForm.get('contractDate')?.updateValueAndValidity();
         this.applyForm.get('registerNumber')?.setValidators([Validators.required]);
         this.applyForm.get('registerNumber')?.updateValueAndValidity();
      }
    //}
    }
  }
  addControlsForListing(id) {
    if (this.type == this.applyType.TYPE3 || this.type == this.applyType.TYPE6) {
      this.isListing = this.listing.some(x => x.id == id && x.key == 'yes');
      if (this.isListing) {
        this.applyForm.addControl('contractNumber', new FormControl(this.applyPetition?.apply?.contractNumber, Validators.required));
        this.applyForm.addControl('contractDate', new FormControl(this.applyPetition?.apply?.contractDate, Validators.required));

        if(this.type == this.applyType.TYPE6) { 
          this.applyForm.addControl('registerNumber', new FormControl(this.applyPetition?.apply?.registerNumber, Validators.required));
          if (this.isBaseProspectus) {
            this.applyForm.get('contractNumber').clearValidators();
            this.applyForm.get('contractNumber').updateValueAndValidity();
            this.applyForm.get('contractDate').clearValidators();
            this.applyForm.get('contractDate').updateValueAndValidity();
             this.applyForm.get('registerNumber').clearValidators();
             this.applyForm.get('registerNumber').updateValueAndValidity();
          }
          else{
            this.applyForm.get('contractNumber').setValidators([Validators.required]);
            this.applyForm.get('contractNumber').updateValueAndValidity();
            this.applyForm.get('contractDate').setValidators([Validators.required]);
            this.applyForm.get('contractDate').updateValueAndValidity();
             this.applyForm.get('registerNumber').setValidators([Validators.required]);
             this.applyForm.get('registerNumber').updateValueAndValidity();
          }
         } 
        }
      else {
        this.applyForm.removeControl('contractNumber');
        this.applyForm.removeControl('contractDate');
        this.applyForm.removeControl('registerNumber');
      }
    }
  }

  confirm() { 
    console.log(this.findInvalidControls());
    let apply = this.applyForm;
    let petition = this.petitionForm;
    if (apply.invalid || petition.invalid) return;
    this.id = this.activatedRoute.snapshot.queryParamMap['params']['id'];
    console.log(this.id)
    apply.get('id').setValue(this.id);

    if (this.type != this.applyType.TYPE2 && this.type != this.applyType.TYPE3 && this.type != this.applyType.TYPE4 && this.type != this.applyType.TYPE5) {
      apply.get('qkTypeName').setValue(this.qkTypes.find(x => x.id == apply
        .get('qkTypeId').value).name);
      apply.get('qkAnderrayterName').setValue(this.qkAnderrayters.find(x => x.id == apply
        .get('qkAnderrayterId').value).name);
    }

    if ( this.type != this.applyType.TYPE5) {
      apply.get('purposeApplyName').setValue(this.purposeApply.find(x => x.id == apply
        .get('purposeApplyId')?.value)?.name);
    }

    if (this.type == this.applyType.TYPE1) {

      apply.get('qkPlacementName').setValue(this.placementMethods.find(x => x.id == apply
        .get('qkPlacementId').value).name);
    }

    if (this.type == this.applyType.TYPE6) {
      apply.get('changeDocName').setValue(this.documents.find(x => x.id == apply
        .get('changeDocId').value).name);
      apply.get('isListingName').setValue(this.listing.find(x => x.id == apply
        .get('isListingId').value).name);
    }

    if (this.type == this.applyType.TYPE2) {
      apply.get('qkTypeName').setValue(this.qkTypes.find(x => x.id == apply
        .get('qkTypeId').value).name);
      apply.get('ReasonReorganizationName').setValue(this.ReasonReorganization.find(x => x.id == apply
        .get('ReasonReorganizationId').value).name);
      apply.get('isListingName').setValue(this.listing.find(x => x.id == apply
        .get('isListingId').value).name);
    }

    if (this.type == this.applyType.TYPE4 || this.type == this.applyType.TYPE5) {
      apply.get('qkTypeName').setValue(this.qkTypes.find(x => x.id == apply
        .get('qkTypeId').value).name);
      
     if (this.type == this.applyType.TYPE4) {
      apply.get('isListingName').setValue(this.listing.find(x => x.id == apply
        .get('isListingId').value).name);
     } 
    }
    if (this.type == this.applyType.TYPE3) {
      console.log(this.applyForm);
      apply.get('qkTypeName').setValue(this.qkTypes.find(x => x.id == apply
        .get('qkTypeId').value).name);
      apply.get('isListingName').setValue(this.listing.find(x => x.id == apply
        .get('isListingId').value).name);
      apply.get('PurposeIssueName').setValue(this.PurposeIssue.find(x => x.id == apply
        .get('purposeIssueId').value).name);
    }
    if (this.type == this.applyType.TYPE7) {
      apply.get('purposeApplyName').setValue(this.purposeApply.find(x => x.id == apply
        .get('purposeApplyId').value).name);
      apply.get('isListingName').setValue(this.listing.find(x => x.id == apply
        .get('isListingId').value).name);
    }

    if (apply.get('qkObserveId')) {
      apply.get('qkObserveName').setValue(this.qkObserves.find(x => x.id == apply
        .get('qkObserveId').value).name);
      if (apply.get('paymentTypeId')) {
        apply.get('paymentTypeName').setValue(this.paymentTypes.find(x => x.id == apply
          .get('paymentTypeId').value).name);
      }
    }

    if (petition.get('provideInfoId')) {
      petition.get('provideInfoName').setValue(this.provideInfos.find(x => x.id == petition
        .get('provideInfoId').value).name);
    }

    if (petition.get('qkTypeId')) {
      petition.get('qkTypeName').setValue(this.qkTypes.find(x => x.id == petition
        .get('qkTypeId').value).name);
    }

    if (petition.get('timeTypeId')) {
      petition.get('timeTypeName').setValue(this.timeTypes.find(x => x.id == petition
        .get('timeTypeId').value).name);
    }

    let birthdate = petition.get('ownerBirthdate').value;
    birthdate = birthdate.replaceAll("-", "");
    if (!birthdate.includes('.')) {
      petition.get('ownerBirthdate').setValue(birthdate.substring(0, 2) + "." + birthdate.substring(2, 4) + "." + birthdate.substring(4, 8));
    }

    let applyPetition = this.fb.group({
      apply: [apply.value],
      petition: [petition.getRawValue()],
      requisite: [this.requisiteForm.getRawValue()]
    });

    console.log(applyPetition.value);



    const dialogRef = this.dialog.open(StateRegistrationDialogComponent, {
      width: '650px',
      position: {
        top: '60px'
      },
      data: { data: applyPetition.value, applytype: this.type, applyTypeName: this.subTitle },
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    });

    this.cabinetService.updateCompany(this.requisiteForm.getRawValue()).subscribe(
      res => {

      },
      err => {
        this.cabinetService.messageDialog("Səhv baş verdi", false);
        this.hasRequest = false;
      });

  }
  
  gonewappeallist() {
    this.router.navigate(['/new-appeal-list'])
  }
  save() {
    let applyPetition = this.fb.group({
      apply: [this.applyForm.getRawValue()],
      petition: [this.petitionForm.getRawValue()],
      requisite: [this.requisiteForm.getRawValue()],
    })
    this.saveApplyPetition(applyPetition.value);
  }

  saveApplyPetition(data) {
    this.hasRequest = true;
    this.cabinetService.saveApplyPetition(data).subscribe(
      res => {
        this.cabinetService.messageDialog(res.status.message, false);
        this.hasRequest = false;
        if (res.status.errorCode == 0) {
          console.log(res.response);
          this.router.navigate(["new-appeal"], { queryParams: { id: `${res.response}` } })
        }

      },
      err => {
        this.cabinetService.messageDialog("Səhv baş verdi", false);
        this.hasRequest = false;
      });

    this.cabinetService.updateCompany(this.requisiteForm.getRawValue()).subscribe(
      res => {

      },
      err => {
        this.cabinetService.messageDialog("Səhv baş verdi", false);
        this.hasRequest = false;
      });
  }

  onChange(document, e) {
    this.clickDoc = document;
    this.showSpinner = true;
    this.upload(document, e);
  }

  upload(document, event) {
    this.uploadError = false;
    this.selectedFile = event.target.files[0];
    if (this.selectedFile != undefined && this.selectedFile.type == 'image/jpeg') {
      let textInfo = "\"JPG, JPEG\" olmayan file yükləyə bilərsiniz.";
      this.cabinetService.messageDialog(textInfo, false);
      this.uploadError = true;
      return;
    }

    var i = 0;
    var j = 0;
    if (this.selectedFile && this.selectedFile != undefined) {
      if (this.selectedFile.type == 'application/x-zip-compressed' || this.selectedFile.type == 'application/zip') {
        jsZip.loadAsync(this.selectedFile).then(async (zip) => {
          Object.keys(zip.files).forEach((filename) => {
            if (j > 0) {
              let textInfo = "ZIP file daxilində sadəcə 1 \"JPG, JPEG\" olmayan file yükləyə bilərsiniz.";
              this.cabinetService.messageDialog(textInfo, false);
              this.uploadError = true;
              return;
            }
            j++;

          });
          Object.keys(zip.files).forEach((filename) => {
            if (i == 0) {
              if (filename.split('.').pop().toUpperCase() == "JPG" || filename.split('.').pop().toUpperCase() == "JPEG") {
                let textInfo = "Sənədi ZIP file daxilində \"JPG, JPEG\" olmayan formatda yükləyə bilərsiniz. Sənədi düzgün formatda daxil edin.";
                this.cabinetService.messageDialog(textInfo, false);
                this.uploadError = true;
                return;
              }
            }
            i++;
          });
          this.uploadFileZip(document, event);
        });
      }
      else {
        this.uploadFileZip(document, event);
      }
    }
  }

  uploadFileZip(doc, e) {
    if (this.uploadError) return;
    this.cabinetService.loadFile(this.selectedFile).subscribe((result) => {
      doc.controls.fileName.setValue(e.target.files[0].name);
      doc.controls.fileSize.setValue("[" + this.sizeToBytes(e.target.files[0].size) + "]");

      let file = this.Base64ToBlob(result, this.selectedFile.type, 512);
      var fileURL = URL.createObjectURL(file);
      var savefile = document.createElement("a");
      savefile.download = "demo.pdf";
      savefile.href = fileURL;
      savefile.click();
      this.showSpinner = false;
    }, (err => {
      console.log("err", err)
      this.cabinetService.messageDialog("Xəta baş verdi.", false);
      this.showSpinner = false;
    }), () => {
      this.showSpinner = false;
      this.selectedFile = null;
    })
  }

  //helper methods
  Base64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  sizeToBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  get f() { return this.applyForm.controls; }
  get t() { return this.f.documentArray as FormArray; }

  getRealApplyType() {
    return this.type == this.applyType.TYPE1 || this.applyPetition?.apply?.applyType?.key == this.applyType.TYPE1;
  }

  cancelDialog() {
    this.dialog.open(CancelDialogComponent, {
      width: '400px',
      position: {
        top: '110px'
      },
      data: {
        id: this.id
      }
    });
  }

  changeFormatDateDashed(date: string | number | Date) {
    return this.cabinetService.changeFormatDateDashed(date)
  }
 

  getRegionBYid(id: number){
    if(id == 0){
      this.requisiteForm.get("regionId").setValue('');
      return;
    }
     
    this.cabinetService.regionById(id).subscribe(result => {
    this.Region=result.result;
    //console.log(this.Region)
      if (this.Region.length == 0) {
        this.requisiteForm.get("regionId").setValue('');
        this.requisiteForm.get("regionId").clearValidators();
        this.requisiteForm.get("regionId").updateValueAndValidity();
        return;
      }
      else {
        this.requisiteForm.get("regionId").setValidators(Validators.required);
        this.requisiteForm.get("regionId").updateValueAndValidity();
      }
    })
  
   }

    getCity(){
      this.cabinetService.city().subscribe(result => {
     this.City=result.result;
    
      })
    
     }
}


