<div class="container-fluid">
    <div class="row pt-3 title">
        <div class="col-10 pb-3">
            <h2>Şirkətin seçimi</h2>
            <span>Sizə bağlı olan şirkəti siyahıdan seçin və nümayəndə olduğunuz şirkəti əlavə edin</span>
        </div>
        <div class="col-2">
            <mat-dialog-actions align="end">
                <button mat-dialog-close class="close-button"><i class="fas fa-times"></i></button>
            </mat-dialog-actions>
        </div>
    </div>
    <mat-dialog-content class="mat-typography">
        <div *ngIf="data.accounts.length ==0">
            <div class="row pt-1">
                <div class="col-12 py-4 ">
                    <span style="font-size: 17px; font-weight: 400;">Sizin səlahiyyətinizdə heç bir şirkət yoxdur.</span>
                </div>
            </div>
        </div>
        <div *ngFor="let el of data.accounts">
            <div class="row pt-1">
                <div class="col-9 pt-3">
                    <span>{{el.fullName}}</span>
                    <p>{{el.tin}}</p>
                </div>
                <div class="col-3 pt-1">
                    <mat-dialog-actions align="end">
                        <button mat-button class="btn btn1-default" type="button" (click)="loginAccount(el.tin, el.companyId, el.type)" [disabled]="hasProcess">Daxil ol</button>
                    </mat-dialog-actions>
                </div>
            </div>
            <hr>

        </div>



    </mat-dialog-content>

</div>