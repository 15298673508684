<header>
    <div [ngClass]="hasToken ? 'container-fluied' : 'container'">
      <div class="row d-flex align-items-center justify-content-between">
        <div class="logo">
          <a href="{{homeUrl}}">
            <img class="logo" src="/assets/images/new_logo.png" alt="logo">
          </a>
        </div>
        <div class="d-lg-none button-container">
          <button class="menu_button" (click)="showToolbar()">
            <i class="material-icons">menu</i>
          </button>
      </div>
      <div class="d-none d-lg-flex col-md-7 col-sm-12"
           [ngClass]="visible? 'mob_menu' :'menu'">
        <ul>
          <li><a href="{{homeUrl}}" target="_blank">ANA SƏHİFƏ</a></li>
          <li><a href="{{cbarUrl}}" target="_blank">MƏRKƏZİ BANK</a></li>
          <li><a href="{{onlineQueueUrl}}" target="_blank">ONLAYN NÖVBƏ</a></li>
          <li><a href="{{contactUrl}}" target="_blank">ELEKTRON MÜRACİƏT</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
