import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from 'src/app/home/components/home/home.service';
import { Cabinet } from '../../cabinet.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  qk_payment_info: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public cabinetService: Cabinet, private homeService: HomeService,) {
    this.cabinetService.clearStorage();
   }

  ngOnInit(): void {
    this.getPaymentInfo()
  }

  getPaymentInfo(){
    this.homeService.static('PAYMENT_INFO').subscribe(
      ((data) => {
        //console.log(data);
        this.qk_payment_info = data.result;
        this.qk_payment_info["description"] = this.decodeReceipt(this.qk_payment_info["description"])
        document.querySelector('.p').innerHTML= this.qk_payment_info["description"] ;
      }
      ));
  }
  public decodeReceipt(data) {
    return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
}
