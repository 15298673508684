import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfig} from 'src/app.config';
import {HomeService} from 'src/app/home/components/home/home.service';
import {AccountService} from 'src/app/home/components/home/account-service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class breadcrumbcomponent implements OnInit {
  homeUrl: string;
  breadcrumbConfig: object = {
    bgColor: '#fff',
    fontSize: '14px',
    fontColor: '#999999',
    lastLinkColor: '#000',
    symbol: ""

  };
  toggleLoginMenu: boolean = false;
  toggleSignUpMenu: boolean = false;
  currentRoute: string

  constructor(
    public homeService: HomeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    let urls = AppConfig.settings.urls;
    this.homeUrl = urls.homeUrl;
    this.currentRoute = this.router.url
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.toggleLoginMenu = false;
      this.toggleSignUpMenu = false;
    })
  }

  go_cabinet() {
    var auth = this.accountService.getPortal();
    if (auth.errorCode == 0) {
      this.router.navigate(["/" + auth.path]);
    }else{
      this.router.navigate(["/"]);
    }

  }

  toggleMenu() {
    this.toggleLoginMenu = !this.toggleLoginMenu;
    this.toggleSignUpMenu = false;
  }

  toggleRegMenu() {
    this.toggleSignUpMenu = !this.toggleSignUpMenu;
    this.toggleLoginMenu = false;
  }
}
