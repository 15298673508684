import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AppConfig } from 'src/app.config';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { Static } from 'src/app/models/constants';
import { AccountService } from '../../../home/account-service';
import { HomeService } from '../../../home/home.service';

@Component({
  selector: 'app-reyestr-home',
  templateUrl: './reyestr-home.component.html',
  styleUrls: ['./reyestr-home.component.scss']
})
export class ReyestrHomeComponent implements OnInit {
  title = "Qiymətli Kağızların Dövlət Reyestri Sistemi";
  bsxmUrl: string;
  showSpinner: boolean;
  testLogin: boolean;
  qkr_home_page: any;
  jwt_user_data: any;
  istestLogin
  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private cabinetService: Cabinet,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private homeService: HomeService,
    private route: ActivatedRoute) {
    this.istestLogin= AppConfig.settings.other.testLogin;

    this.bsxmUrl = AppConfig.settings.other.bsxmLogin;
    var auth = this.accountService.getPortal();
    if (auth.errorCode == 0) {
      this.router.navigate(["/" + auth.path]);
    }
    this.testLogin = AppConfig.settings.other.testLogin;
  }

  ngOnInit(): void {
    this.setTitleAndBredcrumb();
    this.get_data();
  }
  private setTitleAndBredcrumb(): void {
    this.titleService.setTitle(this.title + ' | securities.e-cbar.az');
    const breadcrumb = { title: this.title };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }


  get_data() {
    this.homeService.static(Static.QKR_HOME_PAGE).subscribe(
      ((data) => {
        //console.log(data);
        this.qkr_home_page = data.result;
        this.qkr_home_page["description"] = this.decodeReceipt(this.qkr_home_page["description"])
      }
      ));
  }



  public decodeReceipt(data) {
    return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  certRequest(num) {
    window.location.href = this.bsxmUrl + '/cert-request?type=' + num + "&";
  }

  gotoBsxm(path: string): void {
    window.location.href = this.bsxmUrl + '/' + path + "?";
  }

  searchByEmitentName(){
    this.router.navigate(['search'], { queryParams: { by: `${'EMITENT'}` } }).then(() => {
      window.location.reload();
    });
  }

  searchByTIN(){
    this.router.navigate(['search'], { queryParams: { by: `${'TIN'}` } }).then(() => {
      window.location.reload();
    });
  }


}

