import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Component, OnInit } from '@angular/core';
import { Cabinet } from 'src/app/cabinet/cabinet.service';
import { AccountService } from '../account-service';
import { MatDialog } from '@angular/material/dialog';
import { LoginASANVM } from 'src/app/models/LoginASANVM';


@Component({
  selector: 'app-register-complete-underwriter',
  templateUrl: './register-complete-underwriter.component.html',
  styleUrls: ['./register-complete-underwriter.component.scss']
})
export class RegisterCompleteUnderwriterComponent implements OnInit {
  title = 'Qiymətli kağızları emissiyası sistemi';
  title2 = 'İstifadəçi qeydiyyatı';
  registerForm: FormGroup;
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  showSpinner: boolean;
  Region=[]
  Org=[]
  token: any;
  constructor(
    public dialog: MatDialog,
    private cabinetService: Cabinet,
    private accountService: AccountService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  
  ) {
    localStorage.removeItem("login");
    if (!localStorage.getItem("asanToken") || this.accountService.getUserName().errorCode == 0
      || localStorage.getItem("portal") != "UNDER_WRITER") {

      //this.router.navigate(["/"]);
    } else {
      this.token = accountService.getDecodedAccessToken(localStorage.getItem("asanToken"));

    }
    this.registerForm = this.fb.group({
      orgId: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      name: [this.token.Name, Validators.required],
      surName: [this.token.Surname, Validators.required],
      fatherName: [this.token.Patronymic, Validators.required],
      docPin: [this.token.Pin, Validators.required],
      position: ['', Validators.required],
      userToken: [localStorage.getItem("asanToken"), Validators.required]
    });

  }

  ngOnInit(): void {
    this.setTitleAndBredcrumb();
    this.getOrg();
    
  }

  // get email(): AbstractControl { return this.registerForm.controls.email; }
  // get phone(): AbstractControl { return this.registerForm.controls.phone; }

  private setTitleAndBredcrumb(): void {
    this.titleService.setTitle(this.title + ' | securities.e-cbar.az');
    const breadcrumb = { title: this.title, title2: this.title2 };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }
 
  getOrg(){
    this.Org = [];
    this.cabinetService.getQkAnderrayter().subscribe(result => {
      result.result.forEach(element => {
        if(element.key!="empty"){
          this.Org.push(element);
        }
      });

   console.log(this.Org)
    })
  
   }
  
  register() {
    if (this.registerForm.invalid) {
      console.log(this.registerForm.value)
      return;
    }
    this.showSpinner=true;
    this.accountService.u_registerComplete(this.registerForm.value).subscribe(data => {
    if (data.status.errorCode == 0) {
      this.cabinetService.messageDialog(data.status.message, false);
      this.router.navigate(["/"]);
      
      } else {
        this.cabinetService.messageDialog(data.status.message, false);
      }
      this.showSpinner=false;
      console.log(data.status.message);
      
    },
      err => {
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
        this.showSpinner=false;
      });
      
  }
}
