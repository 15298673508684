import { Component, OnInit, Injectable, ViewChild } from '@angular/core';

import { Title, DomSanitizer } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { Cabinet } from '../../cabinet.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/home/components/home/home.service';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from  '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotficationComponent } from '../../dialogs/notfication/notfication.component';
import { AccountService } from 'src/app/home/components/home/account-service';

// const ELEMENT_DATA: any = [
//   {name: 'İnvestisiya qiymətli kağızlarının buraxılışının dövlət qeydiyyatına alınması',color:"#03A9D9",code:"#72952-492", status:"Yeni",date:"08.07.2021" ,step: 'MƏRHƏLƏLƏR',operation:""},
//   {name: 'İnvestisiya qiymətli kağızlarının Azərbaycan Respublikası ərazisindən kənarda buraxılışı və yerləşdirilməsi',color:"#D90303",code:"#72952-492", status:"Müraciət ləğv edilib",date:"08.07.2021" , step: 'MƏRHƏLƏLƏR',operation:""},

// ];
@Component({
  selector: 'app-cabinet-home',
  templateUrl: './appeals.component.html',
  styleUrls: ['./appeals.component.scss']
})
@Injectable({
  providedIn: 'root'
})

export class Appeals implements OnInit {
  @ViewChild(MatPaginator, {}) paginator: MatPaginator;

  width1 = 0;
  width = 100;
  pageSize: number = 8;
  displayedColumns: string[] = ['type', 'status', 'step', 'notification', 'operation'];
  dataSource = new MatTableDataSource();
  dataLength : number = 0;
  showSpinner = false;
  constructor(
    public dialog: MatDialog,
    public router: Router,
    private cabinetService: Cabinet,
    public datepipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private homeService: HomeService,
    private accountService: AccountService,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private TitleService: Title

  ) {
    this.cabinetService.clearStorage();
    if (!this.accountService.is_emitent()) {
      this.router.navigate(["/appeals-underwriter"]);
    }else{
      this.getAppliesData(1, this.pageSize);
    }
   
  }
  ngOnInit(): void {
    this.setTitle();
  }

  pageChanged(event: any) {
    let pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;
    let previousSize = this.pageSize * pageIndex;
    this.getAppliesData((pageIndex + 1).toString(), this.pageSize.toString());
  }

  //set Title
  setTitle() {
      this.TitleService.setTitle("Müraciətlər" + " | securities.e-cbar.az");
      const breadcrumb = { title: "Müraciətlər" };
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

  }
  getAppliesData(skip, limit){
    this.showSpinner=true;
    this.cabinetService.getApplies(skip, limit).subscribe(
      res => {
         if(res.status.errorCode==0){
          this.dataSource = res.response.data;
          this.paginator.length =  res.response.total;
          console.log(res.response);


          this.dataLength =res.response.data.length; //comment to see


         }else{
          this.cabinetService.messageDialog(res.status.message, false);
         }
         this.showSpinner=false;
      },
      err => {
        if (err.error.status.errorCode != 0)
          this.cabinetService.messageDialog(err.error.status.message, false);
      });
  }
  goToApplyDetail(id){
    this.router.navigate(['new-appeal'], { queryParams: { id: `${id}` } }).then(() => {
      window.location.reload();
    });
   
  }
  goToNewApplyList(){
    this.router.navigate(['new-appeal-list']);
  }
  goToNotifications(id, applyNumber){
    this.dialog.open(NotficationComponent, {
      width: '600px',
      position:{
        top:'110px'
      },
      data:
      {
        applyNumber: `${applyNumber}`,
        id: `${id}`
      }})
  }
}
