import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AccountService } from 'src/app/home/components/home/account-service';
import { Cabinet } from '../../cabinet.service';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}




@Component({
  selector: 'app-profile-underwriter',
  templateUrl: './profile-underwriter.component.html',
  styleUrls: ['./profile-underwriter.component.scss']
})


export class ProfileUnderwriterComponent implements OnInit {
  title='Profil';
  saving = false;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = "";

  data =[];

  applicationForm: FormGroup;
  constructor(public router: Router, private accountService: AccountService, private fb: FormBuilder, private cabinetService: Cabinet, private _sanitizer: DomSanitizer,
    private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private TitleService: Title) {
    this.cabinetService.clearStorage();
    if(this.accountService.is_emitent()){
      this.router.navigate(["appeals"]);
    }else{
      this.getData();

    this.applicationForm = this.fb.group({
      position: [''],
      email: ['', [Validators.required, Validators.email]],
      pin: [''],
      phone: ['']
    });

    }


}
@HostListener('document:keydown', ['$event'])
handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key === 'Tab') {
        return false;
    }

    return true;
}
onKeyUp (event) {
 event.target.disabled=true;
 
   event.preventDefault()
 
}
  ngOnInit(): void {
    this.setTitle(this.title);
  }


  setTitle(title: string) {
    if (title) {
      this.TitleService.setTitle(title + " |  securities.e-cbar.az");
      const breadcrumb = { title: title };
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
    }
  }


  getData() {
    this.cabinetService.u_profile().subscribe(result => {

      // console.log(result);
      if (!(result.Status && result.Status.errorCode == 1)) {
        this.applicationForm.get("email").setValue(result.response.email);
        this.applicationForm.get("position").setValue(result.response.position);
       // this.applicationForm.get("pin").setValue(result.response.pin);
        this.applicationForm.get("email").disable();

      }

    });
  }

  save() {
    this.saving = true;
    if (this.applicationForm.invalid) {
      return;
    }
    // this.showSpinner = true;
    // console.log(this.applicationForm.getRawValue())
    this.cabinetService.u_updateProfile(this.applicationForm.getRawValue()).subscribe(data => {
    this.saving = false;
    this.cabinetService.messageDialog(data.status.message, false);
    this.router.navigate(["/profile-underwriter"]);

      // this.showSpinner = false;
    },
      err => {
        this.saving = false;
        this.cabinetService.messageDialog('Serverdə xəta baş verdi', false);
      });
  }


  get orgName(): AbstractControl { return this.applicationForm.controls.orgName; }
  get branchName(): AbstractControl { return this.applicationForm.controls.branchName; }
  get position(): AbstractControl { return this.applicationForm.controls.position; }
  get email(): AbstractControl { return this.applicationForm.controls.email; }
  get address(): AbstractControl { return this.applicationForm.controls.address; }
  get pin(): AbstractControl { return this.applicationForm.controls.pin; }
  get phone(): AbstractControl { return this.applicationForm.controls.phone; }



}
